import React from 'react';
import classNames from 'classnames';
import { withRouter, matchPath } from 'react-router';

@withRouter
export class NavigationLink extends React.Component {
    _navigate = (evt) => {
        evt.preventDefault();
        this.props.history.push(this.props.to);
    };

    render() {
        let isActive = matchPath(this.props.location.pathname, { path: this.props.to });
        if (this.props.also) {
            isActive = isActive || matchPath(this.props.location.pathname, { path: this.props.also });
        }

        const Tag = this.props.tag || 'dd';
        const propsClassName = this.props.className || '';
        const classes = classNames(propsClassName, { selected: isActive, disabled: this.props.disabled });
        return (
            <Tag className={classes} onClick={this.props.disabled ? null : this._navigate}>
                {this.props.label}
            </Tag>
        );
    }
}
