import React from 'react';
import { withTranslation } from 'react-i18next';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import { formatLocaleDate } from '@SUPPORT/utils';

function mapAuditEntryFieldName(table, field) {
    const qualifiedField = `${table}.${field}`;
    switch (qualifiedField) {
        case 'boat.status':
            return 'statut';
        case 'boat.registration_district_id':
            return 'quartier maritime';
        case 'boat.registration':
            return 'immatriculation';
        case 'boat.name':
            return 'nom';
        case 'boat.size':
            return 'taille';
        case 'boat.motor':
            return 'puissance motrice';
        case 'boat.capacity':
            return 'jauge brute';
        case 'boat.year_built':
            return 'année de construction';
        case 'boat.year_acquired':
            return "année d'acquisition";
        case 'boat.nav_category':
            return 'catégorie de navigation';
        case 'boat.producers_org_id':
            return 'organisation de producteurs';
        case 'boat.vms':
            return 'vms';
        case 'boat.logbook':
            return 'logbook';
        case 'boat.geolocator':
            return 'système de géoloc.';
        case 'boat.docking_district_id':
            return 'port de débarquement';
        case 'boat.crew_size':
            return 'effectif';
        case 'boat.notes':
            return 'notes';
        case 'boat.purchased_boat_id':
            return 'navire vendu';
        default:
            return field;
    }
}

function mapAuditEntryFieldValue(table, field, value, referenceValue, store) {
    const qualifiedField = `${table}.${field}`;
    switch (qualifiedField) {
        case 'boat.status': {
            const statuses = { active: 'actif', inactive: 'inactif', destroyed: 'détruit', sold: 'vendu' };
            return statuses[value] || '-';
        }
        case 'boat.registration_district_id':
        case 'boat.docking_district_id': {
            const registrationDistrict = store.registrationDistrictById(value);
            return registrationDistrict ? registrationDistrict.name : '-';
        }
        case 'boat.producers_org_id': {
            const producersOrg = store.producersOrgById(value);
            return producersOrg ? producersOrg.name : '-';
        }
        case 'boat.size':
        case 'boat.motor':
        case 'boat.capacity': {
            if (!value || (!value.value && !value.unit)) {
                return '-';
            }

            if (value.value && value.unit) {
                return `${value.value} ${value.unit}`;
            } else if (value.value && !value.unit) {
                return `${value.value} ${referenceValue.unit}`;
            } else if (!value.value && value.unit) {
                return `${referenceValue.value} ${value.unit}`;
            }

            return value;
        }
        case 'boat.vms':
        case 'boat.logbook': {
            return value ? 'oui' : 'non';
        }
        case 'boat.purchased_boat_id': {
            const boat = store.boatWithId(value);
            return boat ? boat.name : '-';
        }
        default: {
            return value || '-';
        }
    }
}

@inject('store')
@withTranslation(['common'])
@observer
export class BoatHistorySubSection extends React.Component {
    @observable entries = [];

    componentDidMount() {
        this.props.store.boats.listAuditEntries(this.props.boat.id).then((entries) => {
            this.entries = entries;
        });
    }

    render() {
        return (
            <div className="panel">
                <div className="scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Date - Heure</th>
                                <th>Par</th>
                                <th>Champ</th>
                                <th>Nouvelle valeur</th>
                                <th>Ancienne valeur</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.entries.reduce((rows, entry) => {
                                entry.operations.forEach((operation, i) => {
                                    const isFirst = i === 0;
                                    const isLast = i === entry.operations.length - 1;
                                    const tdStyle = !isLast ? { borderBottom: 'none' } : null;
                                    rows.push(
                                        <tr key={`${entry.id}-${i}`}>
                                            <td style={tdStyle}>
                                                <span>Date :</span>
                                                {isFirst && formatLocaleDate(entry.eventDate, 'P - pp')}
                                            </td>
                                            <td style={tdStyle}>
                                                <span>Par :</span>
                                                {isFirst && entry.userInfo}
                                            </td>
                                            <td style={tdStyle}>
                                                <span>Champ :</span>
                                                {mapAuditEntryFieldName('boat', operation.field)}
                                            </td>
                                            <td style={tdStyle}>
                                                <span>Nouvelle valeur :</span>
                                                {mapAuditEntryFieldValue(
                                                    'boat',
                                                    operation.field,
                                                    operation.newValue,
                                                    operation.previousValue,
                                                    this.props.store.boats
                                                )}
                                            </td>
                                            <td style={tdStyle}>
                                                <span>Ancienne valeur :</span>
                                                {entry.type === 'I'
                                                    ? '-'
                                                    : mapAuditEntryFieldValue(
                                                          'boat',
                                                          operation.field,
                                                          operation.previousValue,
                                                          operation.newValue,
                                                          this.props.store.boats
                                                      )}
                                            </td>
                                        </tr>
                                    );
                                });
                                return rows;
                            }, [])}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
