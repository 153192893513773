import { useQuery, useQueryClient, useMutation } from 'react-query';

import { api } from '@SUPPORT/api';
import { setRollbarPayload } from '@SUPPORT/rollbar';

export function useSessionQuery() {
    return useQuery('session', () => api.getSession().then((response) => response.data), {
        onSuccess: (info) => setRollbarPayload({ user: info.user })
    });
}

export function useLogoutSessionQuery() {
    const mutation = useMutation(() => api.destroySession(), { onSuccess: () => window.location.replace('/login') });
    return mutation.mutate;
}

export function useCurrentUserQuery(withBankInfo = false) {
    const { data: session } = useSessionQuery();
    return useQuery(
        ['currentUser', withBankInfo],
        () => api.getUserInfo(session.user.id, withBankInfo).then((response) => response.data),
        {
            enabled: Boolean(session),
            onSuccess: (user) => api.setUserInfo(user.identifier, user.type)
        }
    );
}

export function useCurrentUserCompaniesListQuery() {
    const { data: user } = useCurrentUserQuery();
    return useQuery(
        ['currentUser', 'companies'],
        () => api.listUserCompanies(user ? user.id : 0).then((response) => response.data),
        { enabled: Boolean(user) }
    );
}

export function useGetCompanyQuery(companyId) {
    return useQuery(['company', companyId], () => api.fetchCompany(companyId).then((response) => response.data), {
        enabled: Boolean(companyId)
    });
}

export function useCurrentUsersBoatsListQuery() {
    const { data: user } = useCurrentUserQuery();
    return useQuery(
        ['currentUser', 'boats'],
        () => api.listUserBoats(user ? user.id : 0).then((response) => response.data),
        {
            enabled: Boolean(user),
            select: (boats) => boats.filter((info) => info.boat.status === 'active')
        }
    );
}

export function useGetBoatQuery(boatId) {
    return useQuery(['boat', boatId], () => api.fetchBoat(boatId).then((response) => response.data), {
        enabled: Boolean(boatId)
    });
}

export function useProducersOrgList(enabled = true) {
    return useQuery(['producers-org'], () => api.listProducersOrgs().then((response) => response.data), { enabled });
}

export function useProducersOrgQuery(orgId) {
    const { data: list } = useProducersOrgList(!!orgId);
    return list && orgId ? list.find((org) => org.id === orgId) : null;
}

export function useBoatDockingDistrictsList(enabled = true) {
    return useQuery(
        ['boat-registration-districts'],
        () => api.listBoatRegistrationDistricts().then((response) => response.data),
        { enabled }
    );
}

export function useBoatDockingDistrictQuery(districtId) {
    const { data: list } = useBoatDockingDistrictsList(!!districtId);
    return list && districtId ? list.find((district) => district.id === districtId) : null;
}

export function useUserImagesQuery(userId) {
    const options = { enabled: Boolean(userId) };
    return useQuery(
        ['userImages', userId],
        () => api.listUserAvatarImage(userId).then((response) => response.data || []),
        options
    );
}

export function useGetAdministrativeDivisionsList() {
    return useQuery('admin-division', () => api.listAdministrativeDivisions().then((response) => response.data));
}

export function useGetAdministrativeDivision(key) {
    const { data: divisions = [] } = useGetAdministrativeDivisionsList();
    return divisions.find((div) => div.administrativeDivisionPath === key);
}

export function useGetPEPProxiesList() {
    return useQuery('pep-proxies', () => api.listLicenseProxies().then((response) => response.data));
}

export function useGetCurrentPEPProxy(request) {
    const { data: proxies = [] } = useGetPEPProxiesList();
    return request
        ? proxies.find(
              (proxy) =>
                  proxy.seasons.findIndex(
                      (season) =>
                          (request.license && season.license.uniqueId === request.license.uniqueId) ||
                          season.license.uniqueId === request.license
                  ) >= 0
          )
        : null;
}

export function useCreatePEPRequest() {
    return useMutation((data) => api.createLicenseRequest(data));
}

export function useUpdatePEPRequest() {
    return useMutation(({ id, data }) => api.updateLicenseRequest(id, data));
}

export function useGetUserPEPRequests(userId, baseYear, licenseType) {
    return useQuery(
        ['pep-requests', baseYear, licenseType],
        () => api.listPescaliceUserToDos(userId, baseYear, licenseType).then((response) => response.data),
        {
            enabled: Boolean(userId)
        }
    );
}

export function useGetUserPEPLicenses(userId) {
    return useQuery(
        ['pep-licenses', userId],
        () => api.listPescaliceUserLicenses(userId).then((response) => response.data),
        {
            enabled: Boolean(userId)
        }
    );
}

export function useGetPEPRequest(requestId) {
    return useQuery(['pep-request', requestId], () => api.fetchLicenseRequest(requestId));
}

export function useDeletePEPRequest() {
    const queryClient = useQueryClient();
    return useMutation(({ id }) => api.deleteLicenseRequest(id), {
        onSuccess: () => {
            queryClient.invalidateQueries('user-requests');
            queryClient.invalidateQueries('pep-requests');
        }
    });
}

export function useGetPEPRequestHint(licenseId, boatId, requestAuthorId, requestAuthorCompanyId) {
    return useQuery(
        ['pep-request-type-hint', licenseId, boatId, requestAuthorId, requestAuthorCompanyId],
        () =>
            api.fetchRequestTypeHint({
                license: licenseId,
                boat: {
                    telecapecheId: boatId
                },
                requestAuthor: {
                    telecapecheId: requestAuthorId,
                    companyTelecapecheId: requestAuthorCompanyId
                }
            }),
        {
            enabled: !!licenseId && !!boatId && !!requestAuthorId,
            select: (data) => data.data
        }
    );
}

export function useGetPAPProxiesList() {
    return useQuery('pap-proxies', () => api.listLicenseProxiesPAP());
}

export function useGetPAPProxy(id) {
    const { data: proxies = [] } = useGetPAPProxiesList();
    return proxies.find((proxy) => proxy.uniqueId === id);
}

export function useGetPAPSeasonsList(enabled = true) {
    return useQuery('pap-seasons', () => api.listSeasonsPAP(), {
        enabled,
        select: (seasons) => seasons.slice().reverse()
    });
}

export function useGetPAPSeason(id) {
    const { data = [] } = useGetPAPSeasonsList(!!id);
    return data.find((season) => season.uniqueId === id);
}

export function useGetUserPAPRequests(userId) {
    return useQuery(
        ['pap-requests'],
        () => api.listPescaliceUserLicensesPAP(userId).then((response) => response.data),
        {
            enabled: Boolean(userId),
            select: (requests) => {
                const now = new Date();
                const currentYear = now.getFullYear();
                return requests.filter(
                    (req) => req.referenceYear <= currentYear || req.referenceYear - 1 === currentYear
                );
            }
        }
    );
}

export function useGetPescaliceUserLicenseList(userId) {
    return useQuery(
        ['user-requests'],
        () => api.listPescaliceUserLicensesPAP(userId).then((response) => response.data),
        { enabled: Boolean(userId) }
    );
}

export function useGetUserPAPLicenseRequest(id, onSuccess = null) {
    return useQuery(['request', id], () => api.fetchLicenseRequestPAP(id).then((response) => response.data), {
        enabled: Boolean(id),
        onSuccess
    });
}

export function useCurrentPAPRequest(userId, onSuccess = null) {
    const { data: requests = [], isLoading: isLoadingList } = useGetPescaliceUserLicenseList(userId);

    const now = new Date();
    const lastPAPReq = requests.find(
        (req) => req.fishingType === 'pescalice.peche-a-pied' && req.referenceYear === now.getFullYear() + 1
    );

    const { data: fullReq, isLoading: isLoadingRequest } = useGetUserPAPLicenseRequest(
        lastPAPReq ? lastPAPReq.licenseRequest.uniqueId : null,
        onSuccess
    );

    return { data: fullReq, isLoading: isLoadingList || isLoadingRequest };
}

export function useGetRequestInfoFor(seasonId, requester, enabled = true) {
    return useQuery(
        ['request-info', seasonId, requester ? requester.id : 0],
        () => api.requestInfoFor(seasonId, requester).then((response) => response.data),
        { enabled: enabled && Boolean(seasonId) && Boolean(requester) }
    );
}

export function useCreatePAPRequest() {
    return useMutation((data) => api.createLicenseRequestPAP(data));
}

export function useUpdatePAPRequest() {
    return useMutation(({ id, data }) => api.updateLicenseRequestPAP(id, data));
}

export function useDeletePAPRequest() {
    const queryClient = useQueryClient();
    return useMutation(({ id }) => api.deleteLicenseRequestPAP(id), {
        onSuccess: () => {
            queryClient.invalidateQueries('user-requests');
            queryClient.invalidateQueries('pap-requests');
        }
    });
}

export function useUserAvatarImages(userId) {
    return useQuery(['user-images', userId], () => api.listUserAvatarImage(userId).then((response) => response.data), {
        enabled: Boolean(userId)
    });
}

export function useUserBankInfo(userId, companyId) {
    return useQuery(
        ['user-bank-info', userId, companyId],
        () => api.getUserBankInfo(userId, companyId).then((response) => response.data),
        {
            enabled: Boolean(userId)
        }
    );
}

export function useUserBankInfoMutation() {
    const queryClient = useQueryClient();
    return useMutation(({ userId, info }) => api.updateUserBankInfo(userId, info), {
        onSuccess: (_, data) => {
            queryClient.invalidateQueries(['user-bank-info', data.userId, data.info.companyId]);
        }
    });
}

export function usePaymentsList(store, baseYear) {
    return useQuery({
        queryKey: ['payments', 'list', baseYear],
        queryFn: () => {
            store.app.setBusy();
            return api.getAllPayments(baseYear).then((response) => response.data);
        },
        onSettled: () => store.app.setBusy(false)
    });
}

export function usePAPPaymentsList(store, baseYear) {
    return useQuery({
        queryKey: ['pap-payments', 'list', baseYear],
        queryFn: () => {
            store.app.setBusy();
            return api.getAllPAPPayments(baseYear).then((response) => response.data);
        },
        onSettled: () => store.app.setBusy(false)
    });
}
