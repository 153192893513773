import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, matchPath } from 'react-router-dom';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

@withRouter
@withTranslation(['pescalice'])
@inject('store')
@observer
export class LicenseSituationSubSection extends React.Component {
    @observable contingentFilter = '';

    _updateContingentFilter = (evt) => {
        this.contingentFilter = evt.target.value;
    };

    render() {
        const { match, quotas, t } = this.props;
        if (!match || !quotas) {
            return null;
        }

        const { globalQuota } = quotas;
        const filteredContingent = quotas.filteredContingent(this.contingentFilter);

        return (
            <div className="panel styledList">
                <div className="scroll">
                    <h2 className="subtitle">
                        {globalQuota.isModeLicensesExtraits ? (
                            <React.Fragment>
                                Contingent global :{' '}
                                {t('pescalice:x-license', { count: globalQuota.initialLicensesCount })} (
                                {t('pescalice:x-used', { count: globalQuota.inUseLicensesCount })} -{' '}
                                {t('pescalice:x-available', { count: globalQuota.remainingLicensesCount })}){' / '}
                                {t('pescalice:x-extract', { count: globalQuota.initialExtraitsCount })} (
                                {t('pescalice:x-used', { count: globalQuota.inUseExtraitsCount })} -{' '}
                                {t('pescalice:x-available', { count: globalQuota.remainingExtraitsCount })})
                            </React.Fragment>
                        ) : globalQuota.initialSlotsCount === -1 ? (
                            t('pescalice:x-used', { count: globalQuota.inUseSlotsCount || 0 })
                        ) : (
                            <React.Fragment>
                                Contingent global : {t('pescalice:x-slot', { count: globalQuota.initialSlotsCount })} (
                                {t('pescalice:x-used', { count: globalQuota.inUseSlotsCount })} -{' '}
                                {t('pescalice:x-available', { count: globalQuota.remainingSlotsCount })})
                            </React.Fragment>
                        )}
                    </h2>
                    <form>
                        <div className="full">
                            <select value={this.contingentFilter} onChange={this._updateContingentFilter}>
                                <option value="">Tous les contingents</option>
                                {quotas.contingents
                                    .filter((c) => c.id !== '-')
                                    .map((contingent) => (
                                        <option value={contingent.id} key={contingent.id}>
                                            {contingent.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </form>

                    <ul className="listStyle">
                        <QuotaSection title="Demandées" status="*" contingents={filteredContingent} quotas={quotas} />
                        <QuotaSection
                            title="Attribuées"
                            className="codeGreen"
                            status="kPSLicenseRequestGlobalStatusAllowed"
                            contingents={filteredContingent}
                            quotas={quotas}
                        />
                        <QuotaSection
                            title="Incomplètes"
                            className="codeOrange"
                            status="kPSLicenseRequestGlobalStatusSuspended"
                            contingents={filteredContingent}
                            quotas={quotas}
                        />
                        <QuotaSection
                            title="Mises en réserve"
                            className="codeOcre"
                            status="kPSLicenseRequestGlobalStatusReserved"
                            contingents={filteredContingent}
                            quotas={quotas}
                        />
                        <QuotaSection
                            title="Refusées"
                            className="codeRed"
                            status="kPSLicenseRequestGlobalStatusRefused"
                            contingents={filteredContingent}
                            quotas={quotas}
                        />
                        <QuotaSection
                            title="Annulées"
                            className="codeRed"
                            status="kPSLicenseRequestGlobalStatusCancelled"
                            contingents={filteredContingent}
                            quotas={quotas}
                        />
                    </ul>
                </div>
            </div>
        );
    }
}

@withTranslation(['pescalice'])
class QuotaSection extends React.Component {
    render() {
        const { t, contingents, quotas, status, className, title } = this.props;
        const count =
            contingents.length === 1
                ? quotas.statuses.get(status).get(contingents[0].id).count
                : quotas.statuses.get(status).get('count');

        return (
            <li className={className}>
                <header>
                    <h2>
                        {title} ({t('pescalice:x-request', { count })})
                    </h2>
                </header>
                <figure>
                    {contingents
                        .sort((c1, c2) => {
                            if (c1.id === '-') {
                                return 1;
                            } else if (c2.id === '-') {
                                return -1;
                            }

                            return c1.name.localeCompare(c2.name, 'fr', { numeric: true });
                        })
                        .map((contingent) => (
                            <ContingentRow
                                status={status}
                                contingent={quotas.statuses.get(status).get(contingent.id)}
                                contingentId={contingent.id}
                                key={`${status}/${contingent.id}`}
                            />
                        ))}
                </figure>
            </li>
        );
    }
}

@withRouter
@withTranslation(['pescalice'])
class ContingentRow extends React.Component {
    _jumpToRequests = (evt) => {
        evt.preventDefault();
        const { contingentId, status } = this.props;
        const match = matchPath(this.props.location.pathname, { path: `/pescalice/license-pep/:id` });
        if (match) {
            if (status === '*') {
                this.props.history.push(`/pescalice/license-pep/${match.params.id}/requests`);
            } else if (contingentId === '-') {
                this.props.history.push(`/pescalice/license-pep/${match.params.id}/requests?st=${status}`);
            } else {
                this.props.history.push(
                    `/pescalice/license-pep/${match.params.id}/requests?ctg=${contingentId}&st=${status}`
                );
            }
        }
    };

    render() {
        const { t, contingent, contingentId, status } = this.props;
        if (!contingent) {
            return null;
        }

        return (
            <div>
                <h3 style={contingentId === '-' ? { fontStyle: 'italic' } : null}>
                    Contingent : {contingentId === '-' ? 'Aucun' : contingent.name}
                </h3>
                <p>
                    <a href="#" onClick={this._jumpToRequests}>
                        {contingent.isModeLicensesExtraits ? (
                            <React.Fragment>
                                Licence : <b>{contingent.licensesCount || 0}</b> sur{' '}
                                <b>{contingent.initialLicensesCount}</b>{' '}
                                {status !== 'kPSLicenseRequestStatusRefused' && (
                                    <React.Fragment>
                                        (<b>{contingent.remainingLicensesCount}</b> dispo)
                                    </React.Fragment>
                                )}
                                <br />
                                Extrait : <b>{contingent.extraitsCount || 0}</b> sur{' '}
                                <b>{contingent.initialExtraitsCount}</b>{' '}
                                {status !== 'kPSLicenseRequestStatusRefused' && (
                                    <React.Fragment>
                                        (<b>{contingent.remainingExtraitsCount}</b> dispo)
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <b>{contingent.count}</b> {t('pescalice:request', { count: contingent.count })}
                                {contingentId !== '-' && (
                                    <React.Fragment>
                                        {' '}
                                        sur <b>{contingent.initialSlotsCount}</b>
                                        {status !== 'kPSLicenseRequestStatusRefused' && (
                                            <React.Fragment>
                                                {' '}
                                                (<b>{contingent.remainingSlotsCount}</b> dispo)
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </a>
                </p>
            </div>
        );
    }
}
