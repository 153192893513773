import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import { validator, isoFDate } from '@SUPPORT/validator';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@inject('store')
@observer
export class LicenseCampaignDialog extends React.Component {
    @observable form = null;

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this._initForm();
        }
    }

    _initForm = () => {
        this.form = new ReactForm(
            {
                fields: {
                    deliberationA: {
                        type: 'text',
                        rules: 'required'
                    },
                    deliberationADate: {
                        type: 'text',
                        rules: 'required|fdate',
                        placeholder: 'jj/mm/aaaa',
                        output: isoFDate
                    },
                    deliberationB: {
                        type: 'text',
                        rules: 'required'
                    },
                    deliberationBDate: {
                        type: 'text',
                        rules: 'required|fdate',
                        placeholder: 'jj/mm/aaaa',
                        output: isoFDate
                    },
                    beginDate: {
                        type: 'text',
                        rules: 'required|fdate',
                        placeholder: 'jj/mm/aaaa',
                        output: isoFDate
                    },
                    endDate: {
                        type: 'text',
                        rules: 'required|fdate|afterf:beginDate',
                        placeholder: 'jj/mm/aaaa',
                        output: isoFDate
                    },
                    requestBeginDate: {
                        type: 'text',
                        rules: 'required|fdate',
                        placeholder: 'jj/mm/aaaa',
                        output: isoFDate
                    },
                    requestEndDate: {
                        type: 'text',
                        rules: 'required|fdate|afterf:requestBeginDate',
                        placeholder: 'jj/mm/aaaa',
                        output: isoFDate
                    }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnInit: true, validateOnChange: true }
            }
        );
    };

    _add = (evt) => {
        evt.preventDefault();

        const { pescalice } = this.props.store;

        const values = this.form.values();
        values.name = values.deliberationA;
        values.season = pescalice.newCampaignForSeason.uniqueId;

        pescalice
            .createSeasonCampaign(values)
            .then(() => this._dismiss())
            .catch((err) => console.log(err));
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.pescalice.setDisplayNewCampaignDialog(false);
        this.form = null;
    };

    render() {
        const { show } = this.props;
        return (
            <div className={classNames('modal', { show })}>
                {this.form && (
                    <div className="sheet">
                        <div className="title">
                            <h3>Ajouter une délibération</h3>
                            <SVGObject objectId="exitCross" onClick={this._cancel} />
                        </div>
                        <div className="scroll">
                            <form>
                                <div>
                                    <label>Délibération cadre :</label>
                                    <input {...this.form.select('deliberationA').bind()} />
                                </div>
                                <div>
                                    <label>Date de délibération cadre :</label>
                                    <input className="date" {...this.form.select('deliberationADate').bind()} />
                                </div>
                                <div>
                                    <label>Délibération conditions particulières d&aposaccès :</label>
                                    <input {...this.form.select('deliberationB').bind()} />
                                </div>
                                <div>
                                    <label>Date de délibération CPA :</label>
                                    <input className="date" {...this.form.select('deliberationBDate').bind()} />
                                </div>
                                <div>
                                    <label>Ouverture du :</label>
                                    <input className="date" {...this.form.select('beginDate').bind()} />
                                    <label className="additional">au :</label>
                                    <input className="date" {...this.form.select('endDate').bind()} />
                                </div>
                                <div>
                                    <label>Demandes du :</label>
                                    <input className="date" {...this.form.select('requestBeginDate').bind()} />
                                    <label className="additional">au :</label>
                                    <input className="date" {...this.form.select('requestEndDate').bind()} />
                                </div>
                            </form>
                        </div>
                        <div className="exit">
                            <div></div>
                            <div>
                                <button onClick={this._cancel}>Annuler</button>
                                <button
                                    onClick={this._add}
                                    disabled={!this.form.isDirty || (this.form.isDirty && !this.form.isValid)}
                                >
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
