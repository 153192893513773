import React, { Fragment, useContext, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { observable, action, reaction, computed } from 'mobx';
import { inject, observer, disposeOnUnmount, MobXProviderContext } from 'mobx-react';

import { getRequestTypeLabel, getRequestStatusLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';

import { formatLocaleDate } from '@SUPPORT/utils';
import { downloadFileFromBase64Data } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { EmptyContent } from '@COMPONENTS/common/EmptyContent';
import { LicenseRequestInfoBtn } from '@COMPONENTS/common/LicenseRequestInfoBtn';

@withRouter
@withTranslation(['pescalice'])
@inject('store')
@observer
export class CompanyLicensesSubSection extends React.Component {
    @observable groups = [];
    @observable papRequests = [];

    @observable companyId = null;

    @observable boatIds = [];
    @observable boatId = null;

    @observable refYears = [];
    @observable refYear = null;

    @observable papSeasonId = null;

    componentDidMount() {
        const { pescalice } = this.props.store;

        // When currentTodo switches to null, i.e. when the request dialog
        // is dismissed, reload the list.
        disposeOnUnmount(
            this,
            reaction(
                () => pescalice.currentTodo,
                () => {
                    if (pescalice.currentTodo === null) {
                        this._update();
                    }
                }
            )
        );

        this.companyId = this.props.company.id;
        this._update();

        if (this.papSeasonId === null) {
            this.papSeasonId = pescalice.seasonsPAP[0] ? pescalice.seasonsPAP[0].uniqueId : null;
        }
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.company && this.props.company !== prevProps.company) {
            this.boatIds = Array.from(
                this.groups
                    .filter((req) => req.companyTelecapecheId === id)
                    .reduce((s, req) => {
                        Object.keys(req.boats).forEach((id) => s.add(parseInt(id, 10)));
                        return s;
                    }, new Set())
                    .values()
            );
            if (this.boatIds.length > 0) {
                this._selectBoatId(this.boatIds[0]);
            }

            this._update();
        }
    }

    @action.bound _setRequests(requests) {
        this.groups = requests;
        if (this.boatIds.length > 0) {
            this._selectBoatId(this.boatIds[0]);
        }
    }

    @action.bound _setPAPRequests(requests) {
        this.papRequests = requests;
    }

    @action.bound _selectBoatId(id) {
        this.boatId = id;

        const refYears = new Set();
        this.groups.forEach((req) => {
            if (!req.boats[id]) {
                return;
            }

            Object.values(req.boats[id])
                .flat()
                .forEach((data) => refYears.add(data.license.season.baseYear));
        });

        this.refYears = Array.from(refYears).sort().reverse();
        this.refYear = this.refYears.length > 0 ? this.refYears[0].toString() : null;
    }

    _handleBoatSelection = (evt) => {
        this._selectBoatId(parseInt(evt.target.value, 10));
    };

    @action _selectRefYear(refYear) {
        this.refYear = refYear;
    }

    _handleRefYearSelection = (evt) => {
        this._selectRefYear(evt.target.value);
    };

    @action _selectPAPSeason(seasonId) {
        this.papSeasonId = seasonId;
    }

    _handlePAPSeasonSelection = (evt) => {
        this._selectPAPSeason(evt.target.value);
    };

    @computed get filteredRequests() {
        return {
            kPSLicenseRequestGlobalStatusInitial: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusInitial'
            ),
            kPSLicenseRequestGlobalStatusAllowed: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusAllowed'
            ),
            kPSLicenseRequestGlobalStatusFrozen: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusFrozen'
            ),
            kPSLicenseRequestGlobalStatusSuspended: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusSuspended'
            ),
            kPSLicenseRequestGlobalStatusReserved: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusReserved'
            ),
            kPSLicenseRequestGlobalStatusRefused: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusRefused'
            ),
            kPSLicenseRequestGlobalStatusCancelled: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusCancelled'
            )
        };
    }

    _getFilteredRequestsForStatus(status) {
        const group = this.groups.find((g) => g.companyTelecapecheId === this.companyId && !!g.boats[this.boatId]);
        if (!group) {
            return [];
        }

        const boatRequests = group.boats[this.boatId];
        if (!boatRequests) {
            return [];
        }

        if (this.refYear === '') {
            return boatRequests[status];
        }

        return boatRequests[status].filter((req) => req.license.season.baseYear.toString() === this.refYear);
    }

    _update = () => {
        const { company, store } = this.props;
        if (!company.mainUserId) {
            return;
        }

        store.pescalice.fetchUserLicenses(company.mainUserId).then((data) => {
            const companyRequests = data.filter((req) => req.companyTelecapecheId === company.id);
            this.boatIds = Array.from(
                companyRequests
                    .reduce((s, req) => {
                        Object.keys(req.boats).forEach((id) => s.add(parseInt(id, 10)));
                        return s;
                    }, new Set())
                    .values()
            );

            Promise.all([...this.boatIds.map((id) => store.boats.fetchBoat(id))]).then(() => {
                this._setRequests(companyRequests);
            });
        });
    };

    _generateNotification = (evt) => {
        evt.preventDefault();

        const { company, store } = this.props;
        store.pescalice.setDisplayGenerateNotificationDialog(true, {
            years: this.refYears,
            user: { id: company.mainUserId, firstName: company.mainUserFirstName, lastName: company.mainUserLastName },
            companyId: company.id,
            boatId: this.boatId
        });
    };

    render() {
        const { match, company, store } = this.props;
        if (!match || !company) {
            return null;
        }

        return (
            <div className="panel styledList">
                <div className="scroll">
                    <form style={{ display: 'flex' }}>
                        <div className="aligned">
                            <select
                                value={this.boatId || ''}
                                onChange={this._handleBoatSelection}
                                disabled={this.boatIds.length === 0}
                            >
                                {this.boatIds.map((id) => {
                                    const boat = store.boats.boatWithId(id);
                                    return boat ? (
                                        <option value={boat.id} key={boat.id}>
                                            {boat.name} - {boat.fullRegistration}
                                        </option>
                                    ) : null;
                                })}
                            </select>
                            {this.refYears && this.refYears.length >= 1 && (
                                <select value={this.refYear} onChange={this._handleRefYearSelection}>
                                    <option value="">Tous</option>
                                    {this.refYears.map((ref) => (
                                        <option value={ref} key={ref}>
                                            {ref}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <button
                                style={{ marginLeft: 'auto' }}
                                onClick={this._generateNotification}
                                disabled={!this.refYear}
                            >
                                Générer la notification
                            </button>
                        </div>
                    </form>

                    {this._renderPEPRequests()}
                </div>
            </div>
        );
    }

    _renderPEPRequests() {
        return !this.boatId ? (
            <EmptyContent />
        ) : (
            <ul>
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusInitial}
                        header="Demandées"
                        title="demande"
                        titlePlural="demandes"
                        update={this._update}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusAllowed}
                        className="codeGreen"
                        header="Attribuées"
                        title="attribution"
                        titlePlural="attributions"
                        update={this._update}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusFrozen}
                        className="codeGray"
                        header="En attente"
                        title="en attente"
                        titlePlural="en attente"
                        update={this._update}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusSuspended}
                        className="codeGray"
                        header="Incomplètes"
                        title="suspension"
                        titlePlural="suspensions"
                        update={this._update}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusReserved}
                        className="codeOrange"
                        header="Mises en réserve"
                        title="mise en réserve"
                        titlePlural="mises en réserve"
                        update={this._update}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusRefused}
                        className="codeRed"
                        header="Refusées"
                        title="refus"
                        titlePlural="refus"
                        update={this._update}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusCancelled}
                        className="codeRed"
                        header="Annulées"
                        title="annulation"
                        titlePlural="annulations"
                        update={this._update}
                    />
                }
            </ul>
        );
    }
}

const LicensesBlock = (props) => {
    return (
        <li className={props.className}>
            <header>
                <h2>{props.header}</h2>
            </header>
            <figure>
                {props.licenses.length === 0 && (
                    <span className="whenWhere">
                        <p>
                            {props.licenses.length} {props.licenses.length <= 1 ? props.title : props.titlePlural}
                        </p>
                        <p></p>
                    </span>
                )}
                <span className="what" style={{ paddingLeft: 0, marginLeft: 0, borderLeft: 'none' }}>
                    <ul className="table threeCols">
                        {props.licenses.map((info) => (
                            <LicenseInfoRow info={info} key={info.uniqueId} update={props.update} />
                        ))}
                    </ul>
                </span>
            </figure>
        </li>
    );
};

const LicenseInfoRow = inject('store')((props) => {
    const { info, store } = props;
    const { session, pescalice } = store;
    const canEdit = !session.info.user.hasTag('psc_ro');

    const edit = (evt) => {
        evt.preventDefault();
        if (evt.shiftKey) {
            const ok = confirm('Voulez-vous vraiment supprimer cette demande ?');
            if (ok) {
                pescalice.deleteLicenseRequest(info.uniqueId).then(props.update);
            }
        } else {
            pescalice.setDisplayNewRequestDialog(true, info.uniqueId, props.update);
        }
    };

    return (
        <li>
            <p style={{ width: '30%' }}>{info.license.name}</p>
            <p style={{ width: canEdit ? '34%' : '42%' }}>{info.license.season.name}</p>
            <p style={{ width: '10%' }}>{formatLocaleDate(info.depositDate, 'P')}</p>
            <p style={{ width: '15%' }}>{getRequestTypeLabel(info)}</p>
            {canEdit && (
                <p style={{ width: '8%' }}>
                    <a href="#" onClick={edit}>
                        Éditer
                    </a>
                </p>
            )}
            <p style={{ width: '26px' }}>
                <LicenseRequestInfoBtn requestId={info.uniqueId} />
            </p>
        </li>
    );
});
