import React from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

@withRouter
@inject('store')
@observer
export class CompaniesSection extends React.Component {
    render() {
        return (
            <div className="selected">
                <div className="header">
                    <h3>Entreprises</h3>
                </div>
                <div className="scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom de l&apos;entreprise</th>
                                <th>Type</th>
                                <th>Personne principale</th>
                                <th>Nbre de collaborateurs</th>
                                <th>Identifiant</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.store.companies.filteredList.map((company) => (
                                <CompanyRow
                                    targetUrl={this.props.altUrl}
                                    company={company}
                                    history={this.props.history}
                                    key={company.id}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function CompanyRow(props) {
    const { company, history, targetUrl } = props;
    const show = (evt) => {
        evt.preventDefault();
        history.push(`${targetUrl}/${company.id}/info`);
    };

    return (
        <tr>
            <td>
                <a href="#" onClick={show}>
                    {company.name}
                </a>
            </td>
            <td>{company.type || '-'}</td>
            <td>{company.mainUserFullName || '-'}</td>
            <td>{company.membersCount || '-'}</td>
            <td>{company.registration}</td>
        </tr>
    );
}
