import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { observer, MobXProviderContext } from 'mobx-react';
import styled, { css } from 'styled-components';
import { parse as parseDate } from 'date-fns';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { formatLocaleDate } from '@SUPPORT/utils';
import { api } from '@SUPPORT/api';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { UserAvatar } from '@COMPONENTS/licensee/common/UserAvatar';

const Modal = styled.div`
    & > div {
        gap: 10px;
        width: auto !important;
        max-width: 90% !important;
    }
`;

const Header = styled.div`
    display: flex;
`;

const Avatar = styled.div`
    padding: 22px;
`;

const BlockNextPicture = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    justify-content: center;
`;

const Title = styled.p`
    font-size: 20px;
    font-weight: 600;
    color: var(--titles-color);
`;

const Name = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: gray;
`;

const Row = styled.tr`
    ${({ $noSeparator }) =>
        $noSeparator
            ? css`
                  & td {
                      border: none;
                  }

                  & + tr td {
                      padding-top: 0;
                  }
              `
            : ''}

    ${({ $warning }) =>
        $warning
            ? css`
                  background-color: var(--lightRed);
              `
            : ''}
`;

const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
`;

const PlusMinusSVG = styled(SVGObject)`
    display: block;
    width: 18px;
    aspect-ratio: 1;
    fill: var(--dark-blue);
    visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
    opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
    cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;

export const PaymentsDialog = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;
    const queryClient = useQueryClient();
    const location = useLocation();

    const isPAP = location.pathname.includes('/payments-pap');
    const requestsKey = isPAP ? 'extractRequests' : 'licenseRequests';

    const [info, setInfo] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (pescalice.paymentDialogEntryData) {
            const localInfo = toJS(pescalice.paymentDialogEntryData, { recurseEverything: true });
            if (isPAP) {
                if (localInfo.entry.payments.length === 0) {
                    localInfo.entry.payments = [
                        {
                            first: true,
                            amount: 0,
                            date: formatLocaleDate(new Date(), 'P'),
                            reference: '',
                            paid: false,
                            splitGroupIdentifier: undefined,
                            method: 'kPSPaymentMethodDebit'
                        }
                    ];
                } else {
                    localInfo.entry.payments.forEach((payment) => {
                        const date = new Date(payment.date || new Date());
                        payment.date = formatLocaleDate(date, 'P');
                    });
                }
            }
            localInfo.entry[requestsKey].forEach((req) => {
                if (req.payments.length === 0) {
                    req.payments = [
                        {
                            first: true,
                            amount: 0,
                            date: '',
                            reference: '',
                            paid: false,
                            splitGroupIdentifier: undefined,
                            method: 'kPSPaymentMethodDebit'
                        }
                    ];
                } else {
                    req.payments.forEach((payment) => {
                        const date = new Date(payment.date);
                        payment.date = formatLocaleDate(date, 'P');
                    });
                }
            });
            setInfo(localInfo);
        }
    }, [pescalice.paymentDialogEntryData]);

    const updatePaymentMode = (payment, mode) => {
        payment.method = mode;
        setInfo({ ...info });
    };

    const updatePaymentReference = (payment, ref) => {
        payment.reference = ref;
        setInfo({ ...info });
    };

    const updatePaymentAmount = (payment, amount) => {
        payment.amount = amount;
        setInfo({ ...info });
    };

    const updatePaymentDate = (payment, date) => {
        payment.date = date;
        setInfo({ ...info });
    };

    const updatePaymentState = (payment, paid) => {
        payment.paid = paid;
        setInfo({ ...info });
    };

    const addPayment = (payments) => {
        const previous = payments[payments.length - 1];
        payments.push({
            amount: 0,
            date: isPAP ? formatLocaleDate(new Date(), 'P') : '',
            reference: '',
            paid: false,
            splitGroupIdentifier: previous.splitGroupIdentifier,
            method: previous.method
        });
        setInfo({ ...info });
    };

    const removePayment = (payments, paymentIndex) => {
        payments.splice(paymentIndex, 1);
        setInfo({ ...info });
    };

    const dismiss = (evt) => {
        evt.preventDefault();
        pescalice.setDisplayPaymentsDialog(false);
    };

    const save = (evt) => {
        evt.preventDefault();

        const data = {};
        if (isPAP) {
            data[info.entry.uniqueId] = structuredClone(info.entry);
            data[info.entry.uniqueId].payments.forEach(preparePaymentForUpdate);
            data[info.entry.uniqueId].extractRequests.forEach((req) => req.payments.forEach(preparePaymentForUpdate));
        } else {
            info.entry[requestsKey].forEach((req) => {
                data[req.uniqueId] = structuredClone(req.payments);
                data[req.uniqueId].forEach(preparePaymentForUpdate);
            });
        }

        const updatePayments = isPAP
            ? api.updatePaymentsByLicenseRequestsPAP(info.user.id, data)
            : api.updatePaymentsByLicenseRequests(info.user.id, data);

        setSaving(true);
        updatePayments
            .then(() => {
                queryClient.invalidateQueries([isPAP ? 'pap-payments' : 'payments', 'list']);
                pescalice.setDisplayPaymentsDialog(false);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const readOnlyMode = !store.session.info.user.hasTag('psc_accountant');

    return (
        <Modal className={classNames('modal', { show: pescalice.displayPaymentsDialog })}>
            {info && (
                <div className="sheet">
                    <Header>
                        <Avatar>
                            <UserAvatar userId={info.user.id} editable={false} />
                        </Avatar>
                        <BlockNextPicture>
                            <Title>Paiements</Title>
                            <Name>
                                {info.user.firstName} {info.user.lastName}
                            </Name>
                            {/* <select disabled>
                                <option>DALTON Joe (en nom propre)</option>
                                <option>DALTON Company</option>
                                <option>DALTON Corp</option>
                            </select> */}
                        </BlockNextPicture>
                    </Header>

                    <div className="scroll">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Montant</th>
                                    <th>Moyen paiement</th>
                                    <th>Référence</th>
                                    <th></th>
                                    <th>Paiement</th>
                                    <th>Date</th>
                                    <th>Payé</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isPAP &&
                                    info.entry.payments.map((payment, i) => (
                                        <Row
                                            $noSeparator={
                                                info.entry.payments.length > 1 && i < info.entry.payments.length - 1
                                            }
                                            $warning={isSuspicious(info.entry, payment)}
                                            key={payment.uniqueId + '-' + i}
                                        >
                                            <td>{i === 0 ? 'Licence pêche à pied' : ''}</td>
                                            <td>
                                                {i === 0
                                                    ? info.entry.payments.reduce(
                                                          (total, p) => total + (parseInt(p.amount, 10) || 0),
                                                          0
                                                      ) + ' €'
                                                    : ''}
                                            </td>
                                            <td>
                                                <select
                                                    value={payment.method}
                                                    onChange={(evt) => updatePaymentMode(payment, evt.target.value)}
                                                    disabled={readOnlyMode}
                                                >
                                                    <option value="">Aucun</option>
                                                    <option value="kPSPaymentMethodCheck">Chèque</option>
                                                    <option value="kPSPaymentMethodDebit">Prélèvement</option>
                                                    <option value="kPSPaymentMethodCreditCard" disabled>
                                                        Carte de crédit
                                                    </option>
                                                    <option value="kPSPaymentMethodWired" disabled>
                                                        Virement
                                                    </option>
                                                    <option value="kPSPaymentMethodOther" disabled>
                                                        Autre
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={payment.reference}
                                                    onChange={(evt) =>
                                                        updatePaymentReference(payment, evt.target.value)
                                                    }
                                                    disabled={readOnlyMode}
                                                />
                                            </td>
                                            <td>
                                                <CellWrapper>
                                                    {i === info.entry.payments.length - 1 && (
                                                        <PlusMinusSVG
                                                            objectId="plus"
                                                            onClick={
                                                                readOnlyMode
                                                                    ? null
                                                                    : () => addPayment(info.entry.payments)
                                                            }
                                                            $isVisible={true}
                                                            $isDisabled={readOnlyMode}
                                                        />
                                                    )}
                                                    <PlusMinusSVG
                                                        objectId="minus"
                                                        onClick={
                                                            readOnlyMode
                                                                ? null
                                                                : () => removePayment(info.entry.payments, i)
                                                        }
                                                        $isVisible={info.entry.payments.length > 1 && i >= 0}
                                                        $isDisabled={readOnlyMode}
                                                    />
                                                </CellWrapper>
                                            </td>
                                            <td>
                                                <CellWrapper>
                                                    <input
                                                        className="short"
                                                        type="text"
                                                        value={payment.amount}
                                                        onChange={(evt) =>
                                                            updatePaymentAmount(payment, evt.target.value)
                                                        }
                                                        disabled={readOnlyMode}
                                                    />
                                                    €
                                                </CellWrapper>
                                            </td>
                                            <td>
                                                <CellWrapper>
                                                    <input
                                                        className="date"
                                                        type="text"
                                                        value={payment.date}
                                                        onChange={(evt) => updatePaymentDate(payment, evt.target.value)}
                                                        disabled={readOnlyMode}
                                                    />
                                                </CellWrapper>
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={payment.paid}
                                                    id={payment.uniqueId + '-' + i}
                                                    onChange={(evt) => updatePaymentState(payment, evt.target.checked)}
                                                    disabled={readOnlyMode}
                                                />
                                                <label htmlFor={payment.uniqueId + '-' + i}></label>
                                            </td>
                                        </Row>
                                    ))}
                                {info.entry[requestsKey].map((req) =>
                                    req.payments.map((payment, i) => (
                                        <Row
                                            $noSeparator={req.payments.length > 1 && i < req.payments.length - 1}
                                            $warning={isSuspicious(req, payment)}
                                            key={payment.uniqueId + '-' + i}
                                        >
                                            <td>{i === 0 ? req[isPAP ? 'targetedExtract' : 'license'].name : ''}</td>
                                            <td>
                                                {i === 0
                                                    ? req.payments.reduce(
                                                          (total, p) => total + (parseInt(p.amount, 10) || 0),
                                                          0
                                                      ) + ' €'
                                                    : ''}
                                            </td>
                                            <td>
                                                <select
                                                    value={payment.method}
                                                    onChange={(evt) => updatePaymentMode(payment, evt.target.value)}
                                                    disabled={readOnlyMode}
                                                >
                                                    <option value="">Aucun</option>
                                                    <option value="kPSPaymentMethodCheck">Chèque</option>
                                                    <option value="kPSPaymentMethodDebit">Prélèvement</option>
                                                    <option value="kPSPaymentMethodCreditCard" disabled>
                                                        Carte de crédit
                                                    </option>
                                                    <option value="kPSPaymentMethodWired" disabled>
                                                        Virement
                                                    </option>
                                                    <option value="kPSPaymentMethodOther" disabled>
                                                        Autre
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={payment.reference}
                                                    onChange={(evt) =>
                                                        updatePaymentReference(payment, evt.target.value)
                                                    }
                                                    disabled={readOnlyMode}
                                                />
                                            </td>
                                            <td>
                                                <CellWrapper>
                                                    {i === req.payments.length - 1 && (
                                                        <PlusMinusSVG
                                                            objectId="plus"
                                                            onClick={
                                                                readOnlyMode ? null : () => addPayment(req.payments)
                                                            }
                                                            $isVisible={true}
                                                            $isDisabled={readOnlyMode}
                                                        />
                                                    )}
                                                    <PlusMinusSVG
                                                        objectId="minus"
                                                        onClick={
                                                            readOnlyMode ? null : () => removePayment(req.payments, i)
                                                        }
                                                        $isVisible={req.payments.length > 1 && i >= 0}
                                                        $isDisabled={readOnlyMode}
                                                    />
                                                </CellWrapper>
                                            </td>
                                            <td>
                                                <CellWrapper>
                                                    <input
                                                        className="short"
                                                        type="text"
                                                        value={payment.amount}
                                                        onChange={(evt) =>
                                                            updatePaymentAmount(payment, evt.target.value)
                                                        }
                                                        disabled={readOnlyMode}
                                                    />
                                                    €
                                                </CellWrapper>
                                            </td>
                                            <td>
                                                <CellWrapper>
                                                    <input
                                                        className="date"
                                                        type="text"
                                                        value={payment.date}
                                                        onChange={(evt) => updatePaymentDate(payment, evt.target.value)}
                                                        disabled={readOnlyMode}
                                                    />
                                                </CellWrapper>
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={payment.paid}
                                                    id={payment.uniqueId + '-' + i}
                                                    onChange={(evt) => updatePaymentState(payment, evt.target.checked)}
                                                    disabled={readOnlyMode}
                                                />
                                                <label htmlFor={payment.uniqueId + '-' + i}></label>
                                            </td>
                                        </Row>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="exit">
                        <div>
                            <div className={classNames('progressIndic', { show: saving })} />
                        </div>
                        <div>
                            <button disabled={saving} onClick={dismiss}>
                                Annuler
                            </button>
                            <button disabled={saving || readOnlyMode} onClick={save}>
                                Sauver
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
});

function isSuspicious(req, payment) {
    return (
        payment.first &&
        (req.globalStatus === 'kPSLicenseRequestGlobalStatusAllowed' ||
            req.globalStatus === 'kPSLicenseRequestGlobalStatusReserved' ||
            req.globalStatus === 'kPSLicenseRequestGlobalStatusSuspended')
    );
}

function preparePaymentForUpdate(payment) {
    const now = new Date();

    delete payment.first;
    if (payment.date !== '') {
        payment.date = parseDate(payment.date + ' 11:30', 'dd/MM/yyyy HH:mm', now);
    }
    if (payment.amount !== '') {
        payment.amount = parseInt(payment.amount, 10) || 0;
    }
}
