import React from 'react';
import classNames from 'classnames';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { capitalizeWords } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { SuggestionsPopup } from '@COMPONENTS/common/SuggestionsPopup';

@inject('store')
@observer
export class NewCompanyMemberDialog extends React.Component {
    @observable query = '';
    @observable suggestions = null;
    @observable selectedUser = null;
    @observable isMainMember = false;
    @observable adding = false;

    @action setAdding(adding = true) {
        this.adding = adding;
    }

    @action _reset() {
        this.query = '';
        this.suggestions = null;
        this.selectedUser = null;
        this.isMainMember = false;
        this.adding = false;
    }

    @action.bound _setMainMember(evt) {
        this.isMainMember = evt.target.checked;
    }

    @action.bound _setSuggestions(suggestions) {
        this.suggestions = suggestions;
    }

    @action.bound _selectSuggestedUser(user) {
        this.query = '';
        this.selectedUser = user;
        this._setSuggestions(null);
    }

    _suggestUsers = (evt) => {
        this.query = evt.target.value;
        this.selectedUser = null;

        if (this.query.length > 0) {
            this.props.store.pescalice.suggestUsers(this.query).then(this._setSuggestions);
        } else {
            this._setSuggestions(null);
        }
    };

    _addMember = (evt) => {
        evt.preventDefault();

        const { companies } = this.props.store;
        const { company, members } = this.props.store.app.modalData;
        members.push({ userId: this.selectedUser.id, shares: 0.1, isMainPerson: this.isMainMember });

        this.setAdding();
        companies
            .updateCompanyMembers(company.id, members)
            .then(() => {
                const { after } = this.props.store.app.modalData;
                if (after) {
                    after();
                }

                this.setAdding(false);
                this._dismiss();
            })
            .catch((err) => {
                console.log(err);
                this.setAdding(false);
            });
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this._reset();
        this.props.store.companies.setShowNewCompanyMemberDialog(false);
    };

    render() {
        if (!this.props.store.companies) {
            return null;
        }

        const { app } = this.props.store;
        const company = app.modalData ? app.modalData.company : null;

        return (
            <div className={classNames('modal', { show: this.props.store.companies.showNewCompanyMemberDialog })}>
                <div className="sheet">
                    <div className="title">
                        <h3>Nouveau collaborateur</h3>
                        <SVGObject objectId="exitCross" onClick={this._cancel} />
                    </div>
                    <div className="scroll">
                        <form>
                            <div className="required">
                                <label>Nom :</label>
                                <div className="datalist">
                                    <input
                                        type="text"
                                        value={this.selectedUser ? this.selectedUser.fullName(false) : this.query}
                                        onChange={this._suggestUsers}
                                    />
                                    <SuggestionsPopup
                                        show={this.suggestions && this.suggestions.length > 0}
                                        suggestions={this.suggestions || []}
                                        getKey={(user) => user.id}
                                        getLabel={(user) => {
                                            const firstName = capitalizeWords(user.firstName);
                                            const lastName = user.lastName.toUpperCase();
                                            const identifier = user.identifier;
                                            return `${lastName} ${firstName} - ${identifier}`;
                                        }}
                                        onClick={this._selectSuggestedUser}
                                    />
                                </div>
                            </div>
                            <div className="aligned">
                                <label />
                                <input
                                    type="checkbox"
                                    id="isMain"
                                    value="isMain"
                                    checked={this.isMainMember}
                                    onChange={this._setMainMember}
                                    disabled={!company || (company && !!company.mainUserId)}
                                />
                                <label htmlFor="isMain">Personne principale</label>
                            </div>
                        </form>
                    </div>
                    <div className="exit">
                        <div></div>
                        <div>
                            <button onClick={this._cancel}>Annuler</button>
                            <button disabled={!this.selectedUser || this.adding} onClick={this._addMember}>
                                Ajouter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
