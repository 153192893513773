import React from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@observer
export class CompaniesSelector extends React.Component {
    _switchToSingle = () => this.props.history.push(this.props.altUrl);
    _filterByName = (evt) => this.props.store.companies.filterByName(evt.target.value);
    _filterByType = (evt) => this.props.store.companies.filterByType(evt.target.value);
    _filterByMainPerson = (evt) => this.props.store.companies.filterByMainPerson(evt.target.value);
    _resetFilters = () => this.props.store.companies.resetFilters();
    _update = () => this.props.store.companies.listAll();

    render() {
        const { session } = this.props.store;
        const canCreate = !session.info.user.hasTag('psc_ro');

        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="list subList withToggle show">
                    <div className="toggle">
                        <span className="selected" title="Liste des entreprises">
                            <SVGObject objectId="allCompanies" />
                        </span>
                        <span className="listSolo" title="Fiches entreprises" onClick={this._switchToSingle}>
                            <SVGObject objectId="singleCompany" />
                        </span>
                    </div>
                    <dl className="show">
                        <dt>
                            Filtres <SVGObject objectId="resetIco" onClick={this._resetFilters} />
                        </dt>
                        <dd className="noSelection selected">
                            <input
                                type="text"
                                placeholder="Nom ou identifiant"
                                value={this.props.store.companies.nameFilter || ''}
                                onChange={this._filterByName}
                            />
                        </dd>
                        <dd className="noSelection">
                            <input
                                type="text"
                                placeholder="Personne principale"
                                value={this.props.store.companies.mainPersonFilter || ''}
                                onChange={this._filterByMainPerson}
                            />
                        </dd>
                        <dd className="noSelection">
                            <select value={this.props.store.companies.typeFilter || ''} onChange={this._filterByType}>
                                <option value="">Type...</option>
                                <option value="SARL">SARL</option>
                                <option value="EURL">EURL</option>
                                <option value="SELARL">SELARL</option>
                                <option value="SA">SA</option>
                                <option value="SAS">SAS</option>
                                <option value="SASU">SASU</option>
                                <option value="SNC">SNC</option>
                                <option value="SCP">SCP</option>
                            </select>
                        </dd>
                    </dl>
                    <ul className="toolbar">
                        {canCreate && (
                            <li className="plus" onClick={() => this.props.store.companies.setShowNewCompanyDialog()} />
                        )}
                    </ul>
                </div>
            </OrgChangeObserver>
        );
    }
}
