import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactForm from 'mobx-react-form';
import classNames from 'classnames';
import { parse as parseDate } from 'date-fns';
import { inject, observer } from 'mobx-react';

import { validator } from '@SUPPORT/validator';
import { formatLocaleDate } from '@SUPPORT/utils';
import { enhanceDateField } from '@SUPPORT/form';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const BoatOwnersSubSection = inject('store')(
    observer((props) => {
        const { store, boat } = props;
        const [loading, setLoading] = useState(true);
        const [saving, setSaving] = useState(false);
        const [renterDirty, setRenterDirty] = useState(false);
        const [ownerDirty, setOwnerDirty] = useState(false);
        const [sailorDirty, setSailorDirty] = useState(false);
        const [embeddedSailorDirty, setEmbeddedSailorDirty] = useState(false);

        const clearState = () => {
            setRenterDirty(false);
            setOwnerDirty(false);
            setSailorDirty(false);
            setLoading(false);
            setSaving(false);
        };

        const refresh = useCallback(() => {
            setLoading(true);
            boat.listPeople().then(clearState);
        }, [boat]);

        const save = () => {
            setSaving(true);
            boat.savePeople().then(clearState);
        };

        useEffect(() => {
            store.companies.listAll().then(refresh);
        }, [refresh, store.companies]);

        const { user } = store.session.info;
        const canEdit = !user.hasTag('psc_ro');

        const isDirty = renterDirty || ownerDirty || sailorDirty || embeddedSailorDirty;
        const isValid = boat.validPeople;

        return (
            <div className="panel styledList">
                <div className="scroll">
                    {!loading && (
                        <ul className="formStyle">
                            <RenterSection
                                boat={boat}
                                renter={boat.renter}
                                addNewRenter={boat.addNewRenter}
                                reportDirty={setRenterDirty}
                                canEdit={canEdit}
                            />
                            <OwnersSection
                                boat={boat}
                                owners={boat.owners}
                                addNewOwner={boat.addNewOwner}
                                reportDirty={setOwnerDirty}
                                canEdit={canEdit}
                            />
                            {user.type === 'admin' && (
                                <SailorsSection
                                    boat={boat}
                                    sailors={boat.sailors}
                                    addNewSailor={boat.addNewSailor}
                                    reportDirty={setSailorDirty}
                                    canEdit={canEdit}
                                />
                            )}
                            {(user.type === 'manager' || user.hasTag('psc')) && (
                                <EmbeddedSailorsSection
                                    boat={boat}
                                    sailors={boat.embeddedSailors}
                                    addNewSailor={boat.addNewEmbeddedSailor}
                                    reportDirty={setEmbeddedSailorDirty}
                                    canEdit={canEdit}
                                />
                            )}
                        </ul>
                    )}
                </div>
                {canEdit && (
                    <div className="exit">
                        <div />
                        <div>
                            <button disabled={!isDirty || loading || saving} onClick={refresh}>
                                Annuler
                            </button>
                            <button disabled={!isDirty || (isDirty && !isValid) || loading || saving} onClick={save}>
                                Sauver
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    })
);

const RenterSection = observer((props) => {
    const { boat, renter, addNewRenter, reportDirty, canEdit } = props;
    const readOnly = boat.status === 'sold' || !canEdit;
    return (
        <li>
            <header>
                <h2>Affréteur</h2>
            </header>
            <figure>
                <form style={{ marginBottom: 4 }}>
                    {!!renter && (
                        <RenterEntry boat={boat} renter={renter} reportDirty={reportDirty} canEdit={canEdit} />
                    )}
                </form>
                {!renter && canEdit && (
                    <div className="full">
                        <button disabled={readOnly} onClick={addNewRenter}>
                            Nouvel affréteur
                        </button>
                    </div>
                )}
            </figure>
        </li>
    );
});

const RenterEntry = inject('store')(
    observer((props) => {
        const { boat, renter, store, reportDirty, canEdit } = props;
        const [ready, setReady] = useState(false);
        const form = useRef();

        useEffect(() => {
            const now = new Date();
            form.current = new ReactForm(
                {
                    fields: {
                        name: {
                            type: 'text',
                            rules: 'string|required',
                            value: renter.fullName(false),
                            hooks: {
                                onChange: (field) => {
                                    if (field.value.length === 0) {
                                        renter.reset();
                                    } else {
                                        suggestRenter(field.value);
                                    }
                                }
                            }
                        },
                        startDate: {
                            type: 'text',
                            rules: 'fdate',
                            placeholder: 'jj/mm/aaaa',
                            value: renter.rentingStartDate ? formatLocaleDate(renter.rentingStartDate, 'P') : '',
                            hooks: {
                                onChange: (field) => {
                                    if (field.value.length === 0) {
                                        renter.setRentingStartDate(null);
                                    } else {
                                        const date = parseDate(field.value + ' 11:30', 'dd/MM/yyyy HH:mm', now);
                                        renter.setRentingStartDate(date);
                                    }
                                    reportDirty(true);
                                }
                            }
                        },
                        endDate: {
                            type: 'text',
                            rules: 'fdate',
                            placeholder: 'jj/mm/aaaa',
                            value: renter.rentingEndDate ? formatLocaleDate(renter.rentingEndDate, 'P') : '',
                            hooks: {
                                onChange: (field) => {
                                    if (field.value.length === 0) {
                                        renter.setRentingEndDate(null);
                                    } else {
                                        const date = parseDate(field.value + ' 11:30', 'dd/MM/yyyy HH:mm', now);
                                        renter.setRentingEndDate(date);
                                    }
                                    reportDirty(true);
                                }
                            }
                        },
                        company: {
                            type: 'text',
                            value: renter.fullCompanyName()
                        },
                        companyRegistration: {
                            type: 'text',
                            value: renter.companyRegistration
                        }
                    }
                },
                {
                    plugins: { dvr: validator },
                    options: { validateOnInit: true, validateOnChange: true }
                }
            );

            enhanceDateField(form.current.select('startDate'));
            enhanceDateField(form.current.select('endDate'));

            setReady(true);
        }, []);

        const [renterSuggestions, suggestRenter, selectSuggestion] = useUserSuggestions(
            store.pescalice,
            store.companies,
            form.current,
            renter,
            reportDirty
        );

        if (!ready) {
            return null;
        }

        const remove = (evt) => {
            evt.preventDefault();
            if (renter.isBeingCreated) {
                boat.removePerson(renter);
            } else {
                store.boats.setShowOwnerRemovalDialog(true, renter, [renter], () => reportDirty(true));
            }
        };

        const allowDates = renter.fullName().trim().length > 0;
        const readOnly = boat.status === 'sold' || !canEdit;
        return (
            <React.Fragment>
                <h4>
                    Affréteur
                    {!readOnly && (
                        <span>
                            <SVGObject objectId="minus" onClick={remove} />
                        </span>
                    )}
                </h4>
                <div className="required">
                    <label>Nom :</label>
                    <div className="datalist">
                        <input {...form.current.select('name').bind()} readOnly={readOnly || !renter.isBeingCreated} />
                        <UserSuggestions suggestions={renterSuggestions} selectSuggestion={selectSuggestion} />
                    </div>
                </div>
                <div>
                    <label>Dates d&apos;affrètement :</label>
                    <div className="together">
                        Du{' '}
                        <input
                            className={classNames('date', {
                                codeRed:
                                    form.current.select('startDate').isDirty &&
                                    !form.current.select('startDate').isValid
                            })}
                            readOnly={readOnly || !allowDates}
                            {...form.current.select('startDate').bind()}
                        />
                        au{' '}
                        <input
                            className={classNames('date', {
                                codeRed:
                                    form.current.select('endDate').isDirty && !form.current.select('endDate').isValid
                            })}
                            readOnly={readOnly || !allowDates}
                            {...form.current.select('endDate').bind()}
                        />
                    </div>
                </div>
                <div className="return"></div>
                <div>
                    <label>Entreprise :</label>
                    <input {...form.current.select('company').bind()} readOnly />
                </div>
                <div>
                    <label>Numéro d&apos;identification :</label>
                    <input {...form.current.select('companyRegistration').bind()} readOnly />
                </div>
            </React.Fragment>
        );
    })
);

const OwnersSection = inject('store')(
    observer((props) => {
        const { store, boat, owners, reportDirty, canEdit } = props;
        const readOnly = boat.status === 'sold' || !canEdit;
        const mainOwner = owners.find((owner) => owner.isPrincipalOwner);

        const remove = (evt) => {
            evt.preventDefault();
            if (mainOwner.isBeingCreated) {
                owners.forEach((owner) => boat.removePerson(owner));
            } else {
                store.boats.setShowOwnerRemovalDialog(true, mainOwner, owners, () => reportDirty(true));
            }
        };

        return (
            <li>
                <header>
                    <h2>Armateur</h2>
                </header>
                <figure>
                    {mainOwner && (
                        <form style={{ marginBottom: 4 }}>
                            <OwnerEntry boat={boat} owner={mainOwner} reportDirty={reportDirty} canEdit={canEdit} />
                        </form>
                    )}
                    {canEdit && (
                        <div className="full">
                            {mainOwner ? (
                                <button disabled={readOnly} onClick={remove}>
                                    Désolidariser
                                </button>
                            ) : (
                                <button disabled={readOnly} onClick={props.addNewOwner}>
                                    Nouvel armateur
                                </button>
                            )}
                        </div>
                    )}
                </figure>
            </li>
        );
    })
);

const OwnerEntry = inject('store')(
    observer((props) => {
        const { boat, owner, store, reportDirty, canEdit } = props;
        const [ready, setReady] = useState(false);
        const form = useRef();

        useEffect(() => {
            form.current = new ReactForm(
                {
                    fields: {
                        name: {
                            type: 'text',
                            rules: 'string|required',
                            value: owner.fullName(false),
                            hooks: {
                                onChange: (field) => suggestOwner(field.value)
                            }
                        },
                        isMainOwner: {
                            type: 'checkbox',
                            value: owner.isPrincipalOwner,
                            hooks: {
                                onChange: (field) => {
                                    owner.setMainOwner(field.value);
                                    reportDirty(true);
                                }
                            }
                        },
                        // share: {
                        //     type: 'text',
                        //     rules: 'integer|min:1|max:100|required',
                        //     value: owner.ownerShares || 0,
                        //     hooks: {
                        //         onChange: (field) => {
                        //             const share = parseInt(field.value, 10);
                        //             owner.setOwnerShare(share || 0);
                        //         }
                        //     }
                        // },
                        company: {
                            type: 'text',
                            value: owner.fullCompanyName()
                        },
                        companyRegistration: {
                            type: 'text',
                            value: owner.companyRegistration
                        }
                    }
                },
                {
                    plugins: { dvr: validator },
                    options: { validateOnInit: true, validateOnChange: true }
                }
            );

            setReady(true);
        }, []);

        const [ownerSuggestions, suggestOwner, selectSuggestion] = useUserSuggestions(
            store.pescalice,
            store.companies,
            form.current,
            owner,
            reportDirty
        );

        if (!ready) {
            return null;
        }

        const readOnly = boat.status === 'sold' || !canEdit;

        return (
            <React.Fragment>
                <div>
                    <label>Contact :</label>
                    <div className="datalist required">
                        <input {...form.current.select('name').bind()} readOnly={readOnly} />
                        <UserSuggestions suggestions={ownerSuggestions} selectSuggestion={selectSuggestion} />
                    </div>
                </div>

                <div className="return"></div>
                <div className="required">
                    <label>Nom :</label>
                    <input {...form.current.select('company').bind()} readOnly />
                </div>
                <div>
                    <label>Numéro d&apos;identification :</label>
                    <input {...form.current.select('companyRegistration').bind()} readOnly />
                </div>
                <input type="hidden" {...form.current.select('isMainOwner').bind()} value={true} readOnly />
            </React.Fragment>
        );
    })
);

const SailorsSection = observer((props) => {
    const { boat, sailors, addNewSailor, reportDirty, canEdit } = props;
    const readOnly = boat.status === 'sold' || !canEdit;
    return (
        <li>
            <header>
                <h2>Marin</h2>
            </header>
            <figure>
                <form style={{ marginBottom: 4 }}>
                    {sailors.map((sailor, i) => (
                        <SailorEntry
                            boat={boat}
                            sailor={sailor}
                            index={i}
                            key={sailor.compoundKey}
                            reportDirty={reportDirty}
                            canEdit={canEdit}
                        />
                    ))}
                </form>
                {canEdit && (
                    <div className="full">
                        <button disabled={readOnly} onClick={() => addNewSailor(false)}>
                            Nouveau marin
                        </button>
                    </div>
                )}
            </figure>
        </li>
    );
});

const EmbeddedSailorsSection = observer((props) => {
    const { boat, sailors, addNewSailor, reportDirty, canEdit } = props;
    const readOnly = boat.status === 'sold' || !canEdit;
    return (
        <li>
            <header>
                <h2>Marin embarqué</h2>
            </header>
            <figure>
                <form style={{ marginBottom: 4 }}>
                    {sailors.map((sailor, i) => (
                        <SailorEntry
                            boat={boat}
                            sailor={sailor}
                            index={i}
                            key={sailor.compoundKey}
                            reportDirty={reportDirty}
                            canEdit={canEdit}
                        />
                    ))}
                </form>
                {canEdit && (
                    <div className="full">
                        <button disabled={readOnly} onClick={() => addNewSailor(true)}>
                            Nouveau marin
                        </button>
                    </div>
                )}
            </figure>
        </li>
    );
});

const SailorEntry = inject('store')(
    observer((props) => {
        const { boat, sailor, index, store, reportDirty, canEdit } = props;
        const [ready, setReady] = useState(false);
        const form = useRef();

        useEffect(() => {
            form.current = new ReactForm(
                {
                    fields: {
                        name: {
                            type: 'text',
                            rules: 'string|required',
                            value: sailor.fullName(false),
                            hooks: {
                                onChange: (field) => suggestSailor(field.value)
                            }
                        },
                        company: {
                            type: 'text',
                            value: sailor.fullCompanyName()
                        },
                        companyRegistration: {
                            type: 'text',
                            value: sailor.companyRegistration
                        }
                    }
                },
                {
                    plugins: { dvr: validator },
                    options: { validateOnInit: true, validateOnChange: true }
                }
            );

            setReady(true);
        }, []);

        const [sailorSuggestions, suggestSailor, selectSuggestion] = useUserSuggestions(
            store.pescalice,
            store.companies,
            form.current,
            sailor,
            reportDirty
        );

        if (!ready) {
            return null;
        }

        const remove = (evt) => {
            evt.preventDefault();
            if (sailor.isBeingCreated) {
                boat.removePerson(sailor);
            } else {
                store.boats.setShowOwnerRemovalDialog(true, sailor, [sailor], () => reportDirty(true));
            }
        };

        const readOnly = boat.status === 'sold' || !canEdit;

        return (
            <React.Fragment>
                <h4>
                    Marin {index + 1}
                    {!readOnly && (
                        <span>
                            <SVGObject objectId="minus" onClick={remove} />
                        </span>
                    )}
                </h4>
                <div className="full required ">
                    <label>Nom :</label>
                    <div className="datalist">
                        <input {...form.current.select('name').bind()} readOnly={readOnly || !sailor.isBeingCreated} />
                        <UserSuggestions suggestions={sailorSuggestions} selectSuggestion={selectSuggestion} />
                    </div>
                </div>
                <div className="return"></div>
                <div>
                    <label>Entreprise :</label>
                    <input {...form.current.select('company').bind()} readOnly />
                </div>
                <div>
                    <label>Numéro d&apos;identification :</label>
                    <input {...form.current.select('companyRegistration').bind()} readOnly />
                </div>
            </React.Fragment>
        );
    })
);

const useUserSuggestions = (pescalice, companies, form, boatPerson, reportDirty) => {
    const [suggestions, setSuggestions] = useState(null);

    const suggestUser = (query) => {
        if (query === '') {
            setSuggestions(null);
            return;
        }

        pescalice
            .suggestUsers(query)
            .then((suggestions) => divideNaturalAndLegalPersons(suggestions, companies))
            .then(setSuggestions);
    };

    const selectSuggestion = (user) => {
        setSuggestions(null);
        form.select('name').set(user.fullName(false));
        form.select('company').set(user.fullCompanyName());
        form.select('companyRegistration').set(user.companyRegistration || '');
        boatPerson.syncWithUser(user);
        reportDirty(form.isDirty);
    };

    return [suggestions, suggestUser, selectSuggestion];
};

const UserSuggestions = (props) => {
    const { suggestions, selectSuggestion } = props;
    if (!suggestions || suggestions.length === 0) {
        return null;
    }

    return (
        <ul className={classNames('dataListResult', { show: true })}>
            {suggestions.map((user) => (
                <li
                    key={user.companyId ? `${user.id}-${user.companyId}` : user.id}
                    onClick={() => selectSuggestion(user)}
                >
                    <SVGObject objectId={user.companyId ? 'singleCompany' : 'singleFisherman'} />
                    {user.fullName(false)}
                    {user.fullCompanyName()}
                </li>
            ))}
        </ul>
    );
};

function divideNaturalAndLegalPersons(suggestions, companies) {
    return suggestions.reduce((users, user) => {
        const userCompanies = companies.withPerson(user.id);
        userCompanies.forEach((company) => users.push(user.withCompany(company)));
        users.push(user.withoutCompanyInfo());
        return users;
    }, []);
}
