import { formatLocaleDate } from './utils';
import { parse as parseDate } from 'date-fns';

export function enhanceDateField(field) {
    return field.observe(({ field, change }) => {
        if (change.type !== 'update') {
            return;
        } else if (field.value === 'a' || field.value === 'A') {
            const now = formatLocaleDate(new Date(), 'P');
            field.set(now);
        } else if (field.value.match(/^(\d\d|\d\d\/\d\d)$/) && change.oldValue.length < change.newValue.length) {
            field.set(field.value + '/');
        }
    });
}

export function onDateFieldChange(evt, oldValue, cb) {
    const { value } = evt.target;
    if (value === 'a' || value === 'A') {
        evt.preventDefault();
        cb(formatLocaleDate(new Date(), 'P'));
    } else if (value.match(/^(\d\d|\d\d\/\d\d)$/) && oldValue.length < value.length) {
        cb(value + '/');
    } else {
        cb(value);
    }
}

export function isValidDateValue(str, format) {
    const parsed = parseDate(str, format, new Date());
    return !isNaN(parsed);
}

export function dateFieldISOValue(str, format) {
    const parsed = parseDate(str, format, new Date()) || new Date();
    return parsed.toISOString();
}
