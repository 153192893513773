import { types, flow, getRoot, applySnapshot } from 'mobx-state-tree';
import { isBefore, isAfter, parseISO, parse as parseDate } from 'date-fns';

import { api } from '@SUPPORT/api';
import { pick } from '@SUPPORT/utils';
import { User } from '@STORES/common/user';

import {
    matchNormalized,
    downloadFileFromData,
    formatLocaleDate,
    formatPhoneNumber,
    buildCSVData,
    paymentMethodLabel
} from '@SUPPORT/utils';

export const FishingType = types.enumeration('FishingType', ['pescalice.peche-embarquee', 'pescalice.peche-a-pied']);

export const AdministrativeDivision = types.model('AdministrativeDivision', {
    administrativeDivisionPath: types.identifier,
    code: types.string,
    name: types.string,
    subDivisions: types.array(types.string),
    upperDivision: types.maybeNull(types.string),
    divisionType: types.string
});

export const LicenceZone = types.model('LicenceZone', {
    uniqueId: types.identifier,
    name: types.string,
    information: types.maybeNull(types.string),
    parentZoneName: types.maybeNull(types.string)
});

export const License = types
    .model('License', {
        uniqueId: types.string,
        pescaliceIdentifier: types.string,
        season: types.string,
        name: types.string,
        fishingType: FishingType,
        administrativeDivision: types.reference(AdministrativeDivision),
        zones: types.maybe(types.array(types.union(types.reference(LicenceZone), LicenceZone))),
        additionalMetadata: types.maybeNull(
            types.model({
                modeLicenceExtraits: types.maybe(types.string),
                wizardInitialStepOptionAccessoryView: types.maybe(
                    types.model({
                        type: types.string
                    })
                ),
                options: types.array(types.string),
                defaultOption: types.maybe(types.string),
                administrativeRequirementsAdditions: types.maybe(
                    types.model({
                        ownsLicenseCSJNationale: types.maybe(types.string),
                        diversAllowedCertificate: types.maybe(types.string),
                        allowsOctopusCaptureDataCommunication: types.maybe(types.string)
                    })
                )
            })
        )
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        additionalMetadata:
            typeof snapshot.additionalMetadata === 'string' && snapshot.additionalMetadata === ''
                ? null
                : snapshot.additionalMetadata
    }))
    .views((self) => {
        return {
            seasonWithId: (id) => {
                return self.seasons.find((season) => season.uniqueId === id);
            }
        };
    });

export const LicenseSeasonPart = types
    .model('LicenseSeasonPart', {
        uniqueId: types.identifier,
        deliberationA: types.string,
        deliberationADate: types.Date,
        deliberationB: types.string,
        deliberationBDate: types.Date,
        beginDate: types.Date,
        endDate: types.Date,
        requestBeginDate: types.Date,
        requestEndDate: types.Date
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        deliberationADate:
            typeof snapshot.deliberationADate === 'string'
                ? new Date(snapshot.deliberationADate)
                : snapshot.deliberationADate,
        deliberationBDate:
            typeof snapshot.deliberationBDate === 'string'
                ? new Date(snapshot.deliberationBDate)
                : snapshot.deliberationBDate,
        beginDate: typeof snapshot.beginDate === 'string' ? new Date(snapshot.beginDate) : snapshot.beginDate,
        endDate: typeof snapshot.endDate === 'string' ? new Date(snapshot.endDate) : snapshot.endDate,
        requestBeginDate:
            typeof snapshot.requestBeginDate === 'string'
                ? new Date(snapshot.requestBeginDate)
                : snapshot.requestBeginDate,
        requestEndDate:
            typeof snapshot.requestEndDate === 'string' ? new Date(snapshot.requestEndDate) : snapshot.requestEndDate
    }));

export const LicenseSeason = types
    .model('LicenseSeason', {
        uniqueId: types.identifier,
        name: types.string,
        baseYear: types.number,
        beginDate: types.Date,
        endDate: types.Date,
        license: License,
        parts: types.array(LicenseSeasonPart)
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        beginDate: typeof snapshot.beginDate === 'string' ? new Date(snapshot.beginDate) : snapshot.beginDate,
        endDate: typeof snapshot.endDate === 'string' ? new Date(snapshot.endDate) : snapshot.endDate
    }))
    .views((self) => {
        return {
            partWithId: (id) => {
                return self.parts.find((part) => part.uniqueId === id);
            }
        };
    });

export const LicenseSeasonPAP = types
    .model('LicenseSeason', {
        uniqueId: types.identifier,
        referenceYear: types.number,
        creationDate: types.Date,
        beginDate: types.Date,
        endDate: types.Date,
        parts: types.array(LicenseSeasonPart),
        extracts: types.array(types.frozen())
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        creationDate:
            typeof snapshot.creationDate === 'string' ? new Date(snapshot.creationDate) : snapshot.creationDate,
        beginDate: typeof snapshot.beginDate === 'string' ? new Date(snapshot.beginDate) : snapshot.beginDate,
        endDate: typeof snapshot.endDate === 'string' ? new Date(snapshot.endDate) : snapshot.endDate
    }))
    .views((self) => {
        return {
            partWithId: (id) => {
                return self.parts.find((part) => part.uniqueId === id);
            }
        };
    });

export const LicenseProxy = types
    .model('LicenseProxy', {
        pescaliceIdentifier: types.string,
        name: types.string,
        fishingType: FishingType,
        administrativeDivision: types.reference(AdministrativeDivision),
        seasons: types.array(LicenseSeason)
    })
    .views((self) => {
        return {
            get uniqueId() {
                return self.pescaliceIdentifier;
            },

            seasonWithId: (id) => {
                return self.seasons.find((season) => season.uniqueId === id);
            }
        };
    });

export const LicenseProxyPAP = types
    .model('LicenseProxyPAP', {
        pescaliceIdentifier: types.string,
        name: types.string,
        shore: types.string,
        administrativeDivision: types.reference(AdministrativeDivision),
        administrativeDivisionName: types.string,
        administrativeDivisionZipCode: types.number,
        displayOrder: types.number,
        seasons: types.array(LicenseSeasonPAP)
    })
    .views((self) => {
        return {
            get uniqueId() {
                return self.pescaliceIdentifier;
            },

            get sortedSeasons() {
                return self.seasons.slice().sort((s1, s2) => s2.referenceYear - s1.referenceYear);
            },

            seasonWithId: (id) => {
                return self.seasons.find((season) => season.uniqueId === id);
            }
        };
    });

export const Quota = types.model('Quota', {
    uniqueId: types.maybe(types.string),
    name: types.string,

    isModeLicensesExtraits: types.boolean,

    // Normal mode
    count: types.maybeNull(types.number),
    initialSlotsCount: types.maybeNull(types.number),
    remainingSlotsCount: types.maybeNull(types.number),

    // Mode licence/extract
    licensesCount: types.maybeNull(types.number),
    initialLicensesCount: types.maybeNull(types.number),
    remainingLicensesCount: types.maybeNull(types.number),
    extraitsCount: types.maybeNull(types.number),
    initialExtraitsCount: types.maybeNull(types.number),
    remainingExtraitsCount: types.maybeNull(types.number)
});

export const LicenseRequestAuthor = types
    .model('LicenseRequestAuthor', {
        telecapecheId: types.number,
        companyTelecapecheId: types.maybeNull(types.number)
    })
    .views((self) => {
        const { users } = getRoot(self);
        return {
            get fullName() {
                const user = users.userWithId(self.telecapecheId);
                return user ? user.fullName(false) : '-';
            }
        };
    });

export const LicenseRequestBoat = types
    .model('LicenseRequestBoat', {
        registrationDistrictCode: types.string,
        registration: types.string,
        length: types.maybeNull(types.number),
        motorPower: types.maybeNull(types.number),
        capacity: types.maybeNull(types.number),
        telecapecheId: types.number
    })
    .views((self) => {
        return {
            get fullRegistration() {
                return self.registrationDistrictCode
                    ? `${self.registrationDistrictCode} ${self.registration}`
                    : self.registration;
            }
        };
    });

export function getRequestTypeLabel(data, abbrev = true) {
    if (data.isFirstInstallation) {
        return abbrev ? '1ère install.' : '1ère installation';
    } else if (data.isLicenseRenewal || data.isExtractRenewal) {
        return abbrev ? 'Renouv.' : 'Renouvellement';
    } else if (data.isNewLicenseRequest || data.isNewExtractRequest) {
        return abbrev ? 'Nouv. demande' : 'Nouvelle demande';
    }

    return '-';
}

export function getRequestTypeLabelForKey(key, abbrev = true) {
    if (key === 'isFirstInstallation') {
        return abbrev ? '1ère install.' : '1ère installation';
    } else if (key === 'isLicenseRenewal' || key === 'isExtractRenewal') {
        return abbrev ? 'Renouv.' : 'Renouvellement';
    } else if (key === 'isNewLicenseRequest' || key === 'isNewExtractRequest') {
        return abbrev ? 'Nouv. demande' : 'Nouvelle demande';
    }

    return '-';
}

export function getRequestTypeKey(data) {
    if (data.isFirstInstallation) {
        return 'isFirstInstallation';
    } else if (data.isNewExtractRequest) {
        return 'isNewExtractRequest';
    } else if (data.isNewLicenseRequest) {
        return 'isNewLicenseRequest';
    } else if (data.isExtractRenewal) {
        return 'isExtractRenewal';
    } else if (data.isLicenseRenewal) {
        return 'isLicenseRenewal';
    }

    return null;
}

// @luc : can we use the function optionLabel(option) from LicenseRequestDialog.jsx instead of reimplementing it here??
export function getOptionStatusLabel(option) {
    if (option === null) {
        return '';
    }

    switch (option) {
        case 'plongee':
            return 'Plongée';
        case 'drague':
            return 'Drague';
        case 'chalut':
            return 'Chalut';
        case 'filet':
            return 'Filet';
        case 'casier':
            return 'Casier';
        case 'filet+casier':
            return 'Filet et Casier';
        default:
            return option;
    }
}

export function getRequestStatusLabel(status, masc = false, possiblyPlural = false) {
    switch (status) {
        case 'kPSLicenseRequestGlobalStatusInitial':
            return masc ? (possiblyPlural ? 'Déposé(s)' : 'Déposé') : possiblyPlural ? 'Déposée(s)' : 'Déposée';
        case 'kPSLicenseRequestGlobalStatusFrozen':
            return 'En attente';
        case 'kPSLicenseRequestGlobalStatusSuspended':
            return masc
                ? possiblyPlural
                    ? 'Incomplet(s)'
                    : 'Incomplet'
                : possiblyPlural
                ? 'Incomplètes(s)'
                : 'Incomplète';
        case 'kPSLicenseRequestGlobalStatusReserved':
            return masc ? (possiblyPlural ? 'Réservé(s)' : 'Réservé') : possiblyPlural ? 'Réservée(s)' : 'Réservée';
        case 'kPSLicenseRequestGlobalStatusAllowed':
            return masc ? (possiblyPlural ? 'Attribué(s)' : 'Attribué') : possiblyPlural ? 'Attribuée(s)' : 'Attribuée';
        case 'kPSLicenseRequestGlobalStatusRefused':
            return masc ? (possiblyPlural ? 'Refusé(s)' : 'Refusé') : possiblyPlural ? 'Refusée(s)' : 'Refusée';
        case 'kPSLicenseRequestGlobalStatusCancelled':
            return masc ? (possiblyPlural ? 'Annulé(s)' : 'Annulé') : possiblyPlural ? 'Annulée(s)' : 'Annulée';

        // Deprecated
        case 'kPSLicenseRequestStatusWaitingForEligibilityProcess':
            return 'En attente';
        case 'kPSLicenseRequestStatusNonEligible':
            return 'Non éligible';
        case 'kPSLicenseRequestStatusWaitingForQuotaProcess':
            return 'Éligible';

        default:
            return '-';
    }
}

export function getRequestStatusColorCode(status) {
    switch (status) {
        case 'kPSLicenseRequestGlobalStatusInitial':
            return '';
        case 'kPSLicenseRequestGlobalStatusFrozen':
            return 'codePurple';
        case 'kPSLicenseRequestGlobalStatusSuspended':
            return 'codeOrange';
        case 'kPSLicenseRequestGlobalStatusReserved':
            return 'codeOrange';
        case 'kPSLicenseRequestGlobalStatusAllowed':
            return 'codeGreen';
        case 'kPSLicenseRequestGlobalStatusRefused':
            return 'codeRed';
        case 'kPSLicenseRequestGlobalStatusCancelled':
            return 'codeRed';

        // Deprecated
        case 'kPSLicenseRequestStatusWaitingForEligibilityProcess':
            return 'codeOrange';
        case 'kPSLicenseRequestStatusNonEligible':
            return 'codeRed';
        case 'kPSLicenseRequestStatusWaitingForQuotaProcess':
            return 'codeOrange';

        default:
            return '-';
    }
}

export function getSavableDataFromRequest(request) {
    return pick(request, [
        'depositDate',
        'isFirstInstallation',
        'papPermitNumber',
        'season',
        'requestAuthor',
        'administrativeRequirement',
        'extractRequests'
    ]);
}

function userShortTitle(title) {
    if (title === 'm') {
        return 'M.';
    } else if (title === 'mme') {
        return 'Mme';
    }

    return '';
}

function getCDPMNameFromPAPPermitNumber(papPermitNumber) {
    // papPermitNumber should be something like PAP22XXXXXXX

    if (papPermitNumber !== null) {
        const match = papPermitNumber.match(/^PAP(\d\d)/i);
        if (match) {
            const zipCode = match[1];
            switch (zipCode) {
                case '22':
                    return "Côtes d'Armor";
                case '29':
                    return 'Finistère';
                case '35':
                    return 'Ille-et-Vilaine';
                case '56':
                    return 'Morbihan';
                default:
                    return 'Hors Bretagne';
            }
        }
    }

    return 'Numéro Permis PAP Invalide';
}

// function getCDPMZipCodeFromPAPPermitNumber(papPermitNumber) {
//     // papPermitNumber should be something like PAP22XXXXXXX
//     if (papPermitNumber !== null) {
//     const match = papPermitNumber.match(/^PAP(\d\d)/i);
//     if (match) {
//         const zipCode = match[1];
//         return zipCode;
//     }
//     }

//     return undefined;
// }

export const LicenseRequest = types
    .model('LicenseRequest', {
        uniqueId: types.string,
        depositDate: types.maybeNull(types.Date),
        isUserDeposited: types.maybe(types.boolean),
        isFirstInstallation: types.boolean,
        isLicenseRenewal: types.boolean,
        isNewLicenseRequest: types.boolean,
        payments: types.array(
            types.model('Payment', {
                date: types.string,
                method: types.string,
                note: types.maybeNull(types.string),
                paid: types.boolean,
                reason: types.maybeNull(types.string),
                splitGroupIdentifier: types.maybeNull(types.string),
                uniqueId: types.string,
                reference: types.maybeNull(types.string),
                amount: types.number
            })
        ),
        pricings: types.maybe(types.array(types.number)),
        administrativeRequirement: types.maybeNull(
            types.model('AdministrativeRequirement', {
                knowsDeliberationsForTheSeason: types.boolean,
                paymentsToProfessionalOrganizationsDone: types.boolean,
                boatPermitIsValid: types.boolean,
                hasGivenFishingStatisticsForPreviousSeason: types.boolean,
                hasPaidForLicenseRequest: types.boolean,
                boatRegistrationIsFrench: types.boolean,
                ownsLicenseCSJNationale: types.maybe(types.boolean),
                diversAllowedCertificate: types.maybe(types.boolean),
                allowsOctopusCaptureDataCommunication: types.maybe(types.boolean)
            })
        ),
        additionalMetadata: types.maybeNull(
            types.model({
                option: types.maybe(types.string),
                modeLicenceExtraits: types.maybe(types.string)
            })
        ),
        divers: types.maybe(
            types.array(
                types.model({
                    telecapecheId: types.number,
                    firstName: types.string,
                    lastName: types.string,
                    enim: types.maybe(types.string),
                    decisionNum: types.maybe(types.string)
                })
            )
        ),
        targetedZones: types.array(types.reference(LicenceZone)),
        obtainedZones: types.array(types.reference(LicenceZone)),
        globalStatus: types.enumeration('LicenseRequestStatus', [
            'kPSLicenseRequestGlobalStatusInitial',
            'kPSLicenseRequestGlobalStatusFrozen',
            'kPSLicenseRequestGlobalStatusSuspended',
            'kPSLicenseRequestGlobalStatusReserved',
            'kPSLicenseRequestGlobalStatusAllowed',
            'kPSLicenseRequestGlobalStatusRefused',
            'kPSLicenseRequestGlobalStatusCancelled',
            // Deprecated
            'kPSLicenseRequestStatusWaitingForEligibilityProcess',
            'kPSLicenseRequestStatusWaitingForQuotaProcess',
            'kPSLicenseRequestStatusNonEligible'
        ]),
        globalStatuses: types.maybe(
            types.array(
                types.model({
                    date: types.string,
                    privateComment: types.maybeNull(types.string),
                    publicComment: types.maybeNull(types.string)
                })
            )
        ),
        license: types.union(License, types.string),
        requestAuthor: LicenseRequestAuthor,
        boat: LicenseRequestBoat,
        targetableQuotas: types.array(Quota),
        inUseQuotas: types.array(Quota)
    })
    .views((self) => {
        return {
            get requestTypeLabel() {
                return getRequestTypeLabel(self);
            }
        };
    })
    .preProcessSnapshot(
        (snapshot) =>
            snapshot && {
                ...snapshot,
                depositDate:
                    typeof snapshot.depositDate === 'string' ? new Date(snapshot.depositDate) : snapshot.depositDate,
                additionalMetadata:
                    typeof snapshot.additionalMetadata === 'string' && snapshot.additionalMetadata === ''
                        ? null
                        : snapshot.additionalMetadata
            }
    );

export const LicenseRequestList = types.array(LicenseRequest);

export const LicenseQuotaDashboard = types
    .model('LicenseQuotaDashboard', {
        globalQuota: types.model('GlobalQuota', {
            isModeLicensesExtraits: types.boolean,

            // Normal mode
            initialSlotsCount: types.maybeNull(types.number),
            inUseSlotsCount: types.maybeNull(types.number),
            remainingSlotsCount: types.maybeNull(types.number),

            // Licence/Extract mode
            initialLicensesCount: types.maybeNull(types.number),
            inUseLicensesCount: types.maybeNull(types.number),
            remainingLicensesCount: types.maybeNull(types.number),
            initialExtraitsCount: types.maybeNull(types.number),
            inUseExtraitsCount: types.maybeNull(types.number),
            remainingExtraitsCount: types.maybeNull(types.number)
        }),
        statuses: types.map(types.map(types.union(Quota, types.number)))
    })
    .views((self) => {
        return {
            get contingents() {
                return Array.from(self.statuses.get('*').entries())
                    .filter((entry) => entry[0] !== 'count')
                    .map((entry) => ({
                        id: entry[0],
                        name: entry[1].name
                    }));
            },

            filteredContingent: (filter) => {
                if (filter === '') {
                    return self.contingents;
                }

                return Array.from(self.statuses.get('*').entries())
                    .filter((entry) => entry[0] === filter)
                    .map((entry) => ({
                        id: entry[0],
                        name: entry[1].name
                    }));
            }
        };
    });

export const PescaliceStore = types
    .model('PescaliceStore', {
        displayNewRequestDialog: false,
        displayNewRequestDialogPAP: false,
        displayNewCampaignDialog: false,
        newCampaignForSeason: types.maybeNull(types.reference(LicenseSeason)),

        displayGenerateNotificationDialog: false,
        displayExportCsvDialogPEP: false,
        displayExportCsvDialogPAP: false,

        administrativeDivisions: types.array(AdministrativeDivision),

        proxies: types.array(LicenseProxy),
        proxiesLoaded: false,
        proxyDivisionFilter: types.optional(types.string, ''),
        proxyNameFilter: types.optional(types.string, ''),

        proxiesPAP: types.array(LicenseProxyPAP),
        proxiesLoadedPAP: false,
        proxyDivisionFilterPAP: types.optional(types.string, ''),
        proxyNameFilterPAP: types.optional(types.string, ''),
        seasonsPAP: types.array(types.frozen()),
        seasonsLoadedPAP: false,

        loadingTodos: false,
        todosLoaded: false,
        todos: LicenseRequestList,
        todoDeptFilter: types.optional(types.string, ''),
        todoLicenseFilter: types.optional(types.string, ''),
        todoRequesterFilter: types.optional(types.string, ''),
        todoBoatFilter: types.optional(types.string, ''),
        todoFromDateFilter: types.optional(types.string, ''),
        todoToDateFilter: types.optional(types.string, ''),
        todoTypeFilter: types.optional(types.string, ''),
        todoStatusFilter: types.optional(types.string, ''),

        loadingTodosPAP: false,
        todosLoadedPAP: false,
        todosPAP: types.array(types.frozen()), // $$ TEMP, use full model object when request editing is implemented
        todoDeptFilterPAP: types.optional(types.string, ''),
        todoLicenseFilterPAP: types.optional(types.string, ''),
        todoRequesterFilterPAP: types.optional(types.string, ''),
        todoBoatFilterPAP: types.optional(types.string, ''),
        todoFromDateFilterPAP: types.optional(types.string, ''),
        todoToDateFilterPAP: types.optional(types.string, ''),
        todoTypeFilterPAP: types.optional(types.string, ''),
        todoStatusFilterPAP: types.optional(types.string, ''),

        paymentRequesterFilter: types.optional(types.string, ''),
        paymentBaseYearFilter: types.optional(types.string, new Date().getFullYear().toString()),
        paymentLicenseFilter: types.optional(types.string, ''),
        paymentDistrictFilter: types.optional(types.string, ''),
        displayPaymentsDialog: false,
        paymentDialogEntryData: types.frozen(),

        papPaymentRequesterFilter: types.optional(types.string, ''),
        papPaymentBaseYearFilter: types.optional(types.string, new Date().getFullYear().toString()),

        licenseRequests: LicenseRequestList,
        licenseRequestsPAP: LicenseRequestList,

        currentTodo: types.maybeNull(LicenseRequest),
        currentTodoIsNew: false,
        currentTodoPAP: types.maybeNull(types.frozen()), // $$ TEMP, use full model object when request editing is implemented
        currentTodoIsNewPAP: false,

        currentPopOverRequestId: types.maybeNull(types.string, null)
    })
    .views((self) => {
        return {
            administrativeDivisionWithCode: (code) => {
                return self.administrativeDivisions.find((division) => division.code === code);
            },

            administrativeDivisionWithPath: (path) => {
                return self.administrativeDivisions.find((division) => division.administrativeDivisionPath === path);
            },

            proxyWithId: (id) => {
                return self.proxies.find((proxy) => proxy.uniqueId === id);
            },

            proxyWithIdPAP: (id) => {
                return self.proxiesPAP.find((proxy) => proxy.uniqueId === id);
            },

            seasonLicenceWithId: (id) => {
                const proxyCount = self.proxies.length;
                for (let i = 0; i < proxyCount; i++) {
                    const proxy = self.proxies[i];
                    const season = proxy.seasons.find((season) => season.license.uniqueId === id);
                    if (season) {
                        return season.license;
                    }
                }
                return null;
            },

            get filteredProxies() {
                const division = self.proxyDivisionFilter
                    ? self.administrativeDivisionWithCode(self.proxyDivisionFilter)
                    : null;

                return self.proxies.filter((proxy) => {
                    if (division && proxy.administrativeDivision !== division) {
                        return false;
                    }

                    if (self.proxyNameFilter && !matchNormalized(proxy.name, self.proxyNameFilter.trim())) {
                        return false;
                    }

                    return true;
                });
            },

            get filteredProxiesPAP() {
                const division = self.proxyDivisionFilterPAP
                    ? self.administrativeDivisionWithCode(self.proxyDivisionFilterPAP)
                    : null;

                return self.proxiesPAP.filter((proxy) => {
                    if (division && proxy.administrativeDivision !== division) {
                        return false;
                    }

                    if (self.proxyNameFilterPAP && !matchNormalized(proxy.name, self.proxyNameFilterPAP.trim())) {
                        return false;
                    }

                    return true;
                });
            },

            get filteredTodos() {
                const { users } = getRoot(self);

                const adminDivision22 = self.administrativeDivisionWithCode('cotes-d-armor');
                const adminDivision29 = self.administrativeDivisionWithCode('finistere');
                const adminDivision35 = self.administrativeDivisionWithCode('ille-et-vilaine');
                const adminDivision56 = self.administrativeDivisionWithCode('morbihan');
                const adminDivisionPaths = [
                    adminDivision22.administrativeDivisionPath,
                    adminDivision29.administrativeDivisionPath,
                    adminDivision35.administrativeDivisionPath,
                    adminDivision56.administrativeDivisionPath
                ];

                return self.todos.filter((todo) => {
                    const user = users.userWithId(todo.requestAuthor.telecapecheId);
                    if (!user) {
                        return false;
                    }

                    if (self.todoLicenseFilter && !matchNormalized(todo.license.name, self.todoLicenseFilter)) {
                        return false;
                    }

                    if (self.todoDeptFilter) {
                        if (
                            self.todoDeptFilter === 'cotes-d-armor' &&
                            todo.license.administrativeDivision.administrativeDivisionPath !==
                                adminDivision22.administrativeDivisionPath
                        ) {
                            return false;
                        } else if (
                            self.todoDeptFilter === 'finistere' &&
                            todo.license.administrativeDivision.administrativeDivisionPath !==
                                adminDivision29.administrativeDivisionPath
                        ) {
                            return false;
                        } else if (
                            self.todoDeptFilter === 'ille-et-vilaine' &&
                            todo.license.administrativeDivision.administrativeDivisionPath !==
                                adminDivision35.administrativeDivisionPath
                        ) {
                            return false;
                        } else if (
                            self.todoDeptFilter === 'morbihan' &&
                            todo.license.administrativeDivision.administrativeDivisionPath !==
                                adminDivision56.administrativeDivisionPath
                        ) {
                            return false;
                        } else if (
                            self.todoDeptFilter === 'hors-bretagne' &&
                            adminDivisionPaths.includes(todo.license.administrativeDivision.administrativeDivisionPath)
                        ) {
                            return false;
                        }
                    }

                    if (
                        self.todoRequesterFilter &&
                        !matchNormalized(user.fullName(), self.todoRequesterFilter.trim())
                    ) {
                        return false;
                    }

                    if (
                        self.todoBoatFilter &&
                        !matchNormalized(todo.boat.registrationDistrictCode, self.todoBoatFilter.trim()) &&
                        !matchNormalized(todo.boat.registration, self.todoBoatFilter.trim()) &&
                        !matchNormalized(todo.boat.name, self.todoBoatFilter.trim())
                    ) {
                        return false;
                    }

                    if (self.todoTypeFilter === 'isFirstInstallation' && !todo.isFirstInstallation) {
                        return false;
                    }

                    if (self.todoTypeFilter === 'isLicenseRenewal' && !todo.isLicenseRenewal) {
                        return false;
                    }

                    if (self.todoTypeFilter === 'isNewLicenseRequest' && !todo.isNewLicenseRequest) {
                        return false;
                    }

                    if (self.todoStatusFilter && todo.globalStatus !== self.todoStatusFilter) {
                        return false;
                    }

                    const fromDate = parseDate(self.todoFromDateFilter, 'dd/MM/yyyy', new Date());
                    if (!isNaN(fromDate) && isBefore(todo.depositDate, fromDate)) {
                        return false;
                    }

                    const toDate = parseDate(self.todoToDateFilter, 'dd/MM/yyyy', new Date());
                    if (!isNaN(toDate) && isAfter(todo.depositDate, toDate)) {
                        return false;
                    }

                    return true;
                });
            },

            get filteredTodosPAP() {
                const { users } = getRoot(self);
                return self.todosPAP.filter((todo) => {
                    const user = users.userWithId(todo.requestAuthor.telecapecheId);
                    if (!user) {
                        return false;
                    }

                    if (
                        ['PAP22', 'PAP29', 'PAP35', 'PAP56'].includes(self.todoDeptFilterPAP) &&
                        user.uniqueIdentifier !== null &&
                        !user.uniqueIdentifier.startsWith(self.todoDeptFilterPAP)
                    ) {
                        return false;
                    }

                    if (
                        self.todoDeptFilterPAP === 'PAPXX' &&
                        user.uniqueIdentifier !== null &&
                        (user.uniqueIdentifier.startsWith(`PAP22`) ||
                            user.uniqueIdentifier.startsWith(`PAP29`) ||
                            user.uniqueIdentifier.startsWith(`PAP35`) ||
                            user.uniqueIdentifier.startsWith(`PAP56`))
                    ) {
                        return false;
                    }

                    if (
                        self.todoLicenseFilterPAP &&
                        !todo.unstableExtractRequests.some((req) => {
                            const season = self.seasonPAPWithRefYear(req.referenceYear);
                            const extract = season.extracts.find((extract) => extract.uniqueId === req.targetedExtract);
                            const f = self.todoLicenseFilterPAP.trim();
                            return matchNormalized(extract.name, f) || matchNormalized(extract.shore, f);
                        })
                    ) {
                        return false;
                    }

                    if (
                        self.todoRequesterFilterPAP &&
                        !matchNormalized(user.fullName(), self.todoRequesterFilterPAP.trim())
                    ) {
                        return false;
                    }

                    if (self.todoTypeFilterPAP === 'isFirstInstallation' && !todo.isFirstInstallation) {
                        return false;
                    }

                    if (self.todoTypeFilterPAP === 'isLicenseRenewal' && !todo.isLicenseRenewal) {
                        return false;
                    }

                    if (self.todoTypeFilterPAP === 'isNewLicenseRequest' && !todo.isNewLicenseRequest) {
                        return false;
                    }

                    const depositDate = parseISO(todo.depositDate);

                    const fromDate = parseDate(self.todoFromDateFilterPAP, 'dd/MM/yyyy', new Date());
                    if (!isNaN(fromDate) && isBefore(depositDate, fromDate)) {
                        return false;
                    }

                    const toDate = parseDate(self.todoToDateFilterPAP, 'dd/MM/yyyy', new Date());
                    if (!isNaN(toDate) && isAfter(depositDate, toDate)) {
                        return false;
                    }

                    return true;
                });
            },

            get proxiesDivisionsPAP() {
                return self.seasonDivisionsPAP({ extracts: self.proxiesPAP });
            },

            seasonDivisionsPAP(season) {
                if (!season) {
                    return [];
                }

                const divisions = new Map();
                season.extracts.forEach((proxy) => {
                    if (!divisions.has(proxy.administrativeDivisionZipCode)) {
                        divisions.set(proxy.administrativeDivisionZipCode, {
                            code: proxy.administrativeDivisionZipCode,
                            name: proxy.administrativeDivisionName
                        });
                    }
                });
                return Array.from(divisions.values());
            },

            proxiesShoresPAP(division) {
                return self.seasonShoresPAP({ extracts: self.proxiesPAP }, division);
            },

            seasonShoresPAP(season, division) {
                if (!season || !division) {
                    return [];
                }

                const shores = new Set();
                season.extracts.forEach((proxy) => {
                    if (proxy.administrativeDivisionZipCode === division) {
                        shores.add(proxy.shore);
                    }
                });
                return Array.from(shores);
            },

            proxiesForPAP(division, shore) {
                return self.seasonExtractsPAPFor({ extracts: self.proxiesPAP }, division, shore);
            },

            seasonExtractsPAPFor(season, division, shore = null) {
                if (!season || !division) {
                    return [];
                }

                return season.extracts.filter(
                    (proxy) => proxy.administrativeDivisionZipCode === division && (!shore || proxy.shore === shore)
                );
            },

            seasonExtractPAPWithId(season, extractId) {
                if (!season) {
                    return null;
                }

                return season.extracts.find((extract) => extract.uniqueId === extractId);
            },

            seasonPAPWithId(seasonId) {
                return self.seasonsPAP.find((season) => season.uniqueId === seasonId);
            },

            seasonPAPWithRefYear(refYear) {
                return self.seasonsPAP.find((season) => season.referenceYear === refYear);
            },

            extractRequestPAPSummary(season, request) {
                const summary = { 22: 0, 29: 0, 35: 0, 56: 0 };
                (request.extractRequests || []).forEach((extractRequest) => {
                    const extract = self.seasonExtractPAPWithId(season, extractRequest.targetedExtract);
                    summary[extract.administrativeDivisionZipCode]++;
                });

                return summary;
            },

            extractRequestHasWarningPAP(extractRequest) {
                if (!extractRequest) {
                    return false;
                }

                const isNonEligible =
                    extractRequest.administrativeStatus === 'kPSLicenseRequestAdministrativeStatusNonEligible';
                const hasWarning =
                    !!extractRequest.globalStatuses &&
                    extractRequest.globalStatuses.length > 0 &&
                    extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1]
                        .administrativeIneligibilityReasonObject;

                return isNonEligible || hasWarning;
            },

            extractRequestHasErrorPAP(extractRequest) {
                if (!extractRequest) {
                    return false;
                }

                const isNonEligible =
                    extractRequest.administrativeStatus === 'kPSLicenseRequestAdministrativeStatusNonEligible';
                const hasError =
                    !!extractRequest.globalStatuses &&
                    extractRequest.globalStatuses.length > 0 &&
                    extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1].nonAllowedReasonObject;

                return isNonEligible || hasError;
            },

            extractRequestWarningsPAP(extractRequest) {
                if (
                    !extractRequest ||
                    !self.extractRequestHasWarningPAP(extractRequest) ||
                    !extractRequest.globalStatuses ||
                    extractRequest.globalStatuses.length === 0 ||
                    !extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1]
                        .administrativeIneligibilityReasonObject ||
                    !extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1]
                        .administrativeIneligibilityReasonObject.failureMessages ||
                    extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1]
                        .administrativeIneligibilityReasonObject.failureMessages.length === 0
                ) {
                    return [];
                }

                return extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1]
                    .administrativeIneligibilityReasonObject.failureMessages;
            },

            extractRequestErrorsPAP(extractRequest) {
                if (
                    !extractRequest ||
                    !self.extractRequestHasErrorPAP(extractRequest) ||
                    !extractRequest.globalStatuses ||
                    extractRequest.globalStatuses.length === 0 ||
                    !extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1].nonAllowedReasonObject ||
                    !extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1].nonAllowedReasonObject
                        .failureMessages ||
                    extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1].nonAllowedReasonObject
                        .failureMessages.length === 0
                ) {
                    return [];
                }

                return extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1].nonAllowedReasonObject
                    .failureMessages;
            },

            extractRequestHasCommentPAP(extractRequest) {
                return (
                    !!extractRequest &&
                    !!extractRequest.globalStatuses &&
                    extractRequest.globalStatuses.length > 0 &&
                    (!!extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1].publicComment ||
                        !!extractRequest.globalStatuses[extractRequest.globalStatuses.length - 1].privateComment)
                );
            },

            userWithId: (id) => {
                const { users } = getRoot(self);
                return users.list.find((user) => user.id === id);
            }
        };
    })
    .volatile(() => ({
        exportCsvCallbackPEP: null,
        exportCsvCallbackPAP: null
    }))
    .actions((self) => {
        return {
            loadInitialData: flow(function* () {
                const { session } = getRoot(self);
                const sessionInfo = yield session.getSession();
                api.setUserInfo(sessionInfo.user.identifier, sessionInfo.user.type);

                // TODO: run the following in parallel
                yield self.listAdministrativeDivisions();
                yield self.listLicenseProxies();
                yield self.listPAPExtractProxies();
                yield self.listPAPSeasons();
            }),

            prefetchNewRequestData: () => {
                const { boats, users } = getRoot(self);
                return Promise.all([self.listLicenseProxies(), boats.listAll(), users.listUsers()]);
            },

            setDisplayNewRequestDialog: flow(function* (display = true, requestId = null, afterDelete = null) {
                const { app } = getRoot(self);
                app.setModal(display);
                self.currentTodo = null;
                self.currentTodAfterDelete = null;

                if (display) {
                    if (requestId) {
                        self.currentTodo = yield self.fetchLicenseRequest(requestId);
                    }
                    self.currentTodoIsNew = requestId === null;
                    self.currentTodAfterDelete = afterDelete;
                } else {
                    self.currentTodoIsNew = false;
                }

                self.displayNewRequestDialog = display;
            }),

            fetchRequestNatureHint: flow(function* (license, requestAuthor, boat) {
                const response = yield api.fetchRequestTypeHint({
                    license: license.uniqueId,
                    boat: {
                        telecapecheId: boat.id
                    },
                    requestAuthor: {
                        telecapecheId: requestAuthor.userId,
                        companyTelecapecheId: requestAuthor.companyId
                    }
                });

                if (response.status !== 'ok') {
                    return null;
                }

                return response.data;
            }),

            setDisplayNewRequestDialogPAP: flow(function* (display = true, requestId = null) {
                const { app } = getRoot(self);
                app.setModal(display);

                self.currentTodoPAP = null;
                if (display) {
                    if (requestId) {
                        self.currentTodoPAP = yield self.fetchLicenseRequestPAP(requestId);
                    }
                    self.currentTodoIsNewPAP = requestId === null;
                } else {
                    self.currentTodoIsNewPAP = false;
                }

                self.displayNewRequestDialogPAP = display;
            }),

            setDisplayNewCampaignDialog: (display, season = null) => {
                const { app } = getRoot(self);
                app.setModal(display);
                self.displayNewCampaignDialog = display;
                self.newCampaignForSeason = season;
            },

            setDisplayGenerateNotificationDialog: (display = true, refYears = null) => {
                const { app } = getRoot(self);
                app.setModal(display, refYears);
                self.displayGenerateNotificationDialog = display;
            },

            setDisplayExportCsvDialogPEP: (display = true, exportCb = null) => {
                const { app } = getRoot(self);
                app.setModal(display);
                self.displayExportCsvDialogPEP = display;
                self.exportCsvCallbackPEP = exportCb;
            },

            setDisplayExportCsvDialogPAP: (display = true, exportCb = null) => {
                const { app } = getRoot(self);
                app.setModal(display);
                self.displayExportCsvDialogPAP = display;
                self.exportCsvCallbackPAP = exportCb;
            },

            setDisplayPaymentsDialog: (display = true, paymentEntry = null) => {
                const { app } = getRoot(self);
                app.setModal(display);
                self.displayPaymentsDialog = display;
                self.paymentDialogEntryData = paymentEntry;
            },

            listAdministrativeDivisions: flow(function* () {
                try {
                    const response = yield api.listAdministrativeDivisions();
                    self.administrativeDivisions = response.data;
                } catch (err) {
                    self.administrativeDivisions = [];
                }
            }),

            listLicenseProxies: flow(function* (force = false) {
                if (self.proxiesLoaded && !force) {
                    return;
                }

                try {
                    const response = yield api.listLicenseProxies();
                    self.proxies = response.data;

                    self.proxies.forEach((proxy) =>
                        proxy.seasons.forEach(
                            (season) =>
                                (season.parts = season.parts
                                    .slice()
                                    .sort((p1, p2) => p2.deliberationADate.getTime() - p1.deliberationADate.getTime()))
                        )
                    );

                    self.proxiesLoaded = true;
                } catch (err) {
                    self.proxies = [];
                    self.proxiesLoaded = false;
                }
            }),

            listPAPExtractProxies: flow(function* (force = false) {
                if (self.proxiesLoadedPAP && !force) {
                    return;
                }

                try {
                    const response = yield api.listLicenseProxiesPAP();
                    self.proxiesPAP = response.data.sort((p1, p2) => p1.displayOrder - p2.displayOrder);

                    // self.proxiesPAP.forEach((proxy) =>
                    //     proxy.seasons.forEach(
                    //         (season) =>
                    //             (season.parts = season.parts
                    //                 .slice()
                    //                 .sort((p1, p2) => p2.deliberationADate.getTime() - p1.deliberationADate.getTime()))
                    //     )
                    // );

                    self.proxiesLoadedPAP = true;
                } catch (err) {
                    console.log(err);
                    self.proxiesPAP = [];
                    self.proxiesLoadedPAP = false;
                }
            }),

            listPAPSeasons: flow(function* (force = false) {
                if (self.seasonsLoadedPAP && !force) {
                    return;
                }

                try {
                    const response = yield api.listSeasonsPAP();
                    self.seasonsPAP = response.sort((s1, s2) => s2.referenceYear - s1.referenceYear);
                    self.seasonsLoadedPAP = true;
                } catch (err) {
                    console.log(err);
                    self.seasonsPAP = [];
                    self.seasonsLoadedPAP = false;
                }
            }),

            listLicenseRequests: flow(function* (force = false) {
                if (self.loadingTodos || (self.todosLoaded && !force)) {
                    return;
                }

                const { app } = getRoot(self);

                app.setBusy();
                self.loadingTodos = !self.todosLoaded;

                try {
                    const response = yield api.listLicenseRequests();
                    self.todos = LicenseRequestList.create(response.data);
                    self.todos = self.todos.slice().sort((t1, t2) => t2.depositDate - t1.depositDate);
                    self.todosLoaded = true;
                } catch (err) {
                    console.log(err);
                    self.todos = [];
                    self.todosLoaded = false;
                } finally {
                    app.setBusy(false);
                    self.loadingTodos = false;
                }
            }),

            listLicenseRequestsPAP: flow(function* (force = false) {
                if (self.loadingTodosPAP || (self.todosLoadedPAP && !force)) {
                    return;
                }

                const { app } = getRoot(self);

                app.setBusy();
                self.loadingTodosPAP = !self.todosLoadedPAP;

                try {
                    const response = yield api.listLicenseRequestsPAP();
                    self.todosPAP = response.data;
                    self.todosPAP = self.todosPAP.slice().sort((t1, t2) => t2.depositDate - t1.depositDate);
                    self.todosLoadedPAP = true;
                } catch (err) {
                    console.log(err);
                    self.todosPAP = [];
                    self.todosLoadedPAP = false;
                } finally {
                    app.setBusy(false);
                    self.loadingTodosPAP = false;
                }
            }),

            fetchLicenseRequest: flow(function* (id) {
                try {
                    const response = yield api.fetchLicenseRequest(id);
                    self.currentTodo = LicenseRequest.create(response.data);
                    return self.currentTodo;
                } catch (err) {
                    console.log(err);
                    return null;
                }
            }),

            fetchLicenseRequestPAP: flow(function* (id) {
                try {
                    const response = yield api.fetchLicenseRequestPAP(id);
                    self.currentTodoPAP = response.data;
                    return self.currentTodoPAP;
                } catch (err) {
                    console.log(err);
                    return null;
                }
            }),

            fetchAllLicenseRequestPAP: flow(function* (baseYear) {
                try {
                    const response = yield api.fetchAllLicenseRequestPAP(baseYear);
                    return response.data;
                } catch (err) {
                    console.log(err);
                    return [];
                }
            }),

            saveLicenseRequest: flow(function* (data) {
                if (data.requestAuthor) {
                    const companyId = data.requestAuthor.companyTelecapecheId;
                    const response = yield api.getUserInfo(data.requestAuthor.telecapecheId);
                    data.requestAuthor = response.data;
                    data.requestAuthor.telecapecheId = response.data.id;
                    data.requestAuthor.companyTelecapecheId = companyId;
                    delete data.requestAuthor.id;
                    delete data.companyId;
                }

                if (self.currentTodo) {
                    const response = yield api.updateLicenseRequest(self.currentTodo.uniqueId, data);
                    if (response.status === 'error') {
                        throw new Error(response.errorMessage);
                    }

                    // Update todo list
                    const todo = self.todos.find((t) => t.uniqueId === self.currentTodo.uniqueId);
                    if (todo) {
                        applySnapshot(todo, response.data);
                    }
                    // Update licence specific requests
                    const req = self.licenseRequests.find((t) => t.uniqueId === self.currentTodo.uniqueId);
                    if (req) {
                        applySnapshot(req, response.data);
                    }
                    return todo;
                }

                const response = yield api.createLicenseRequest(data);
                if (response.status === 'error') {
                    throw new Error(response.errorMessage);
                }

                const todo = LicenseRequest.create(response.data);
                // self.todos.push(todo);
                // self.todos = self.todos.slice().sort((t1, t2) => t2.depositDate - t1.depositDate);
                self.currentTodo = todo;

                return todo;
            }),

            saveLicenseRequestPAP: flow(function* (data) {
                try {
                    const response = self.currentTodoPAP
                        ? yield api.updateLicenseRequestPAP(self.currentTodoPAP.uniqueId, data)
                        : yield api.createLicenseRequestPAP(data);
                    self.currentTodoPAP = response.data;

                    // Refresh list
                    yield self.listLicenseRequestsPAP(true);

                    return self.currentTodoPAP;
                } catch (err) {
                    console.error(err);
                    return err;
                }
            }),

            cancelRequest: flow(function* (data) {
                try {
                    yield api.cancelLicenseRequest(self.currentTodo.uniqueId, data);
                    self.removeLicenseRequest(self.currentTodo.uniqueId);
                } catch (err) {
                    console.error(err);
                }
            }),

            removeLicenseRequest: (id) => {
                self.todos = self.todos.filter((todo) => todo.uniqueId !== id);
            },

            deleteLicenseRequest: flow(function* (id) {
                try {
                    yield api.deleteLicenseRequest(id);
                    self.removeLicenseRequest(id);
                } catch (err) {
                    console.error(err);
                }
            }),

            removeLicenseRequestPAP: (id) => {
                self.todosPAP = self.todosPAP.filter((todo) => todo.uniqueId !== id);
            },

            deleteLicenseRequestPAP: flow(function* (id) {
                try {
                    yield api.deleteLicenseRequestPAP(id);
                    self.removeLicenseRequestPAP(id);
                } catch (err) {
                    console.error(err);
                }
            }),

            processGlobalStatus: flow(function* () {
                const response = yield api.processGlobalStatus(self.currentTodo.uniqueId);
                return response.data;
            }),

            saveGlobalStatus: flow(function* (data) {
                const response = yield api.saveGlobalStatus(self.currentTodo.uniqueId, data);
                const todo = self.todos.find((t) => t.uniqueId === self.currentTodo.it);
                if (todo) {
                    applySnapshot(todo, response.data);
                }
                return response.data;
            }),

            fetchLicense: flow(function* (id) {
                const { app } = getRoot(self);

                app.setBusy();
                try {
                    const response = yield api.licenseWithId(id);
                    if (!response.data) {
                        return null;
                    }

                    // Administrative divisions are already loaded at this point,
                    // so use normalized instead of embedded data;
                    response.data.administrativeDivision =
                        response.data.administrativeDivision.administrativeDivisionPath;

                    return License.create(response.data);
                } finally {
                    app.setBusy(false);
                }
            }),

            fetchLicenseQuotaDashBoard: flow(function* (id) {
                const response = yield api.licenseQuotaDashboard(id);
                return LicenseQuotaDashboard.create(response.data);
            }),

            fetchExtractQuotaDashBoardPAP: flow(function* (id) {
                const response = yield api.extractQuotaDashboardPAP(id);
                return response.data;
            }),

            suggestLicenses: flow(function* (query) {
                try {
                    yield self.listLicenseProxies();
                    return self.proxies.filter((proxy) => matchNormalized(proxy.name, query.trim()));
                } catch (err) {
                    return [];
                }
            }),

            createSeasonCampaign: flow(function* (data) {
                const { app } = getRoot(self);

                app.setBusy();
                try {
                    const response = yield api.createLicenseSeasonPart(data);
                    const part = LicenseSeasonPart.create(response.data);
                    // This assumes that the call was made from the creation dialog
                    self.newCampaignForSeason.parts.unshift(part);
                    return part;
                } finally {
                    app.setBusy(false);
                }
            }),

            suggestBoats: flow(function* (query) {
                const { boats } = getRoot(self);
                try {
                    yield boats.listAll();
                    const pattern = query.trim();
                    return boats.list.filter(
                        (boat) => matchNormalized(boat.name, pattern) || boat.registration.startsWith(pattern)
                    );
                } catch (err) {
                    return [];
                }
            }),

            suggestUsers: flow(function* (query) {
                const { users } = getRoot(self);
                try {
                    yield users.listUsers();
                    return users.list.filter((user) => matchNormalized(user.fullName(), query.trim()));
                } catch (err) {
                    return [];
                }
            }),

            fetchUserInfo: flow(function* (id) {
                try {
                    const response = yield api.getUserInfo(id);
                    return User.create(response.data);
                } catch (err) {
                    return null;
                }
            }),

            fetchUserLicenses: flow(function* (id) {
                const { app } = getRoot(self);

                app.setBusy();
                try {
                    const response = yield api.listPescaliceUserLicenses(id);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            fetchUserLicensesPAP: flow(function* (id) {
                const { app } = getRoot(self);

                app.setBusy();
                try {
                    const response = yield api.listPescaliceUserLicensesPAP(id);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            generateNotificationDoc: flow(function* (userId, companyId, boatId, annualCampaign, seasonalCampaign) {
                const { app } = getRoot(self);

                app.setBusy();
                try {
                    const response = yield api.generateNotificationDoc(
                        userId,
                        companyId,
                        boatId,
                        annualCampaign,
                        seasonalCampaign
                    );
                    return response.data;
                } catch (err) {
                    console.log(err);
                } finally {
                    app.setBusy(false);
                }
            }),

            generateNotificationDocPAP: flow(function* (userId, referenceYear) {
                const { app } = getRoot(self);

                app.setBusy();
                try {
                    const response = yield api.generateNotificationDocPAP(userId, referenceYear);
                    return response;
                } catch (err) {
                    console.log(err);
                } finally {
                    app.setBusy(false);
                }
            }),

            downloadRequestsCSVReport: flow(function* (
                filename,
                requests = [],
                complete = false,
                addLicenseColumn = false
            ) {
                // requests here are PEP license requests
                const { users, companies, boats } = getRoot(self);
                yield users.listUsers();
                yield companies.listAll();
                yield boats.listAll();

                // jehane wants the requests to be sorted by: QM, Armateur (Nom entreprise si entreprise, Nom Armateur si personne physique), Prénom Armateur ("" si entreprise), Nom navire
                const sortedRequests = requests.slice().sort((r1, r2) => {
                    // NOTE TO LUC : does calling boatWithId()
                    const boat1 = boats.boatWithId(r1.boat.telecapecheId);
                    const boat2 = boats.boatWithId(r2.boat.telecapecheId);

                    if (boat1 && boat2) {
                        let sortResult = boat1.registrationDistrictCode.localeCompare(boat2.registrationDistrictCode); // NOTE TO LUC : faster compare() func not available to compare ASCII/Normalized strings?
                        if (sortResult !== 0) {
                            return sortResult;
                        }

                        let requester1LastName = '',
                            requester1FirstName = '';
                        if (r1.requestAuthor.companyTelecapecheId) {
                            const company = companies.companyWithId(r1.requestAuthor.companyTelecapecheId);
                            if (company) {
                                requester1LastName = company.name;
                                requester1FirstName = '';
                            }
                        } else {
                            const requester = users.userWithId(r1.requestAuthor.telecapecheId);
                            if (requester) {
                                requester1LastName = requester.lastName;
                                requester1FirstName = requester.firstName;
                            }
                        }

                        let requester2LastName = '',
                            requester2FirstName = '';
                        if (r2.requestAuthor.companyTelecapecheId) {
                            const company = companies.companyWithId(r2.requestAuthor.companyTelecapecheId);
                            if (company) {
                                requester2LastName = company.name;
                                requester2FirstName = '';
                            }
                        } else {
                            const requester = users.userWithId(r2.requestAuthor.telecapecheId);
                            if (requester) {
                                requester2LastName = requester.lastName;
                                requester2FirstName = requester.firstName;
                            }
                        }

                        sortResult = requester1LastName.localeCompare(requester2LastName, 'fr');
                        if (sortResult !== 0) {
                            return sortResult;
                        }

                        sortResult = requester1FirstName.localeCompare(requester2FirstName, 'fr');
                        if (sortResult !== 0) {
                            return sortResult;
                        }

                        sortResult = boat1.name.localeCompare(boat2.name, 'fr');
                        if (sortResult !== 0) {
                            return sortResult;
                        }

                        return 0;
                    } else if (boat1) {
                        return -1;
                    } else if (boat2) {
                        return 1;
                    }

                    return 0;
                });

                const headers = [
                    addLicenseColumn && 'Licence',
                    'Nature de la demande',
                    'QM',
                    'Immat',
                    'Nom navire',
                    'LHT', // longueur navire hors tout
                    'PM (kW)', // puissance moteur
                    'Jauge', // Jehane would like the unit to be kept in CSV
                    'Civilité',
                    'Armateur',
                    'Prénom',
                    'N° marin',
                    complete && 'EMail',
                    complete && 'Adresse 1',
                    complete && 'Adresse 2',
                    complete && 'Code Postal',
                    complete && 'Ville',
                    complete && 'Tél Mobile 1',
                    complete && 'Tél Mobile 2',
                    complete && 'Tél Fixe',
                    complete && 'Date de naissance',
                    'Décision de la demande',
                    'Date de décision',
                    'Option(s)',
                    'Contingent(s) attribué(s)',
                    'Zone(s) obtenue(s)',
                    'Zone(s) demandées(s)',
                    'Commentaire public',
                    'Commentaire privé',
                    'Paiements'
                ].filter(Boolean);

                let countersByGlobalStatus = {
                    kPSLicenseRequestGlobalStatusInitial: 0,
                    kPSLicenseRequestGlobalStatusFrozen: 0,
                    kPSLicenseRequestGlobalStatusSuspended: 0,
                    kPSLicenseRequestGlobalStatusReserved: 0,
                    kPSLicenseRequestGlobalStatusAllowed: 0,
                    kPSLicenseRequestGlobalStatusRefused: 0,
                    kPSLicenseRequestGlobalStatusCancelled: 0
                };

                const rows = sortedRequests.map((req) => {
                    const row = [];

                    if (addLicenseColumn) {
                        row.push(req.license.name);
                    }

                    row.push(getRequestTypeLabel(req));

                    const boat = boats.boatWithId(req.boat.telecapecheId);
                    row.push(boat ? boat.registrationDistrictCode : '');
                    row.push(boat ? boat.registration : '');
                    row.push(boat ? boat.name : '');
                    row.push(boat && boat.size ? Intl.NumberFormat('fr').format(boat.lengthInMeters) : '');
                    row.push(boat && boat.motor ? Intl.NumberFormat('fr').format(boat.motorPowerInWatts / 1000) : '');
                    row.push(
                        boat && boat.capacity
                            ? Intl.NumberFormat('fr').format(boat.capacity.value) + ' ' + boat.capacity.unit
                            : ''
                    );

                    const requester = users.userWithId(req.requestAuthor.telecapecheId);
                    if (req.requestAuthor.companyTelecapecheId) {
                        const company = companies.companyWithId(req.requestAuthor.companyTelecapecheId);
                        row.push('');
                        row.push(
                            company ? `"${company.type ? `${company.type} ` : ''}${company.name}"` : 'Armateur inconnu'
                        );
                        row.push('');
                    } else {
                        row.push(requester ? userShortTitle(requester.title) : '');
                        row.push(requester && requester.lastName ? requester.lastName.toUpperCase() : '');
                        row.push(requester && requester.firstName ? requester.firstName.toUpperCase() : '');
                    }
                    row.push(requester && requester.licenseNum ? requester.licenseNum : '');

                    if (complete) {
                        row.push(requester && requester.email ? requester.email : '');
                        row.push(requester && requester.address1 ? requester.address1 : '');
                        row.push(requester && requester.address2 ? requester.address2 : '');
                        row.push(requester && requester.zipCode ? requester.zipCode : '');
                        row.push(requester && requester.city ? requester.city : '');
                        row.push(requester && requester.mobileNum1 ? formatPhoneNumber(requester.mobileNum1) : '');
                        row.push(requester && requester.mobileNum2 ? formatPhoneNumber(requester.mobileNum2) : '');
                        row.push(requester && requester.phoneNum ? formatPhoneNumber(requester.phoneNum) : '');
                        row.push(
                            requester && requester.birthDate ? formatLocaleDate(requester.birthDate, 'dd/MM/yyyy') : ''
                        );
                    }

                    row.push(getRequestStatusLabel(req.globalStatus));
                    countersByGlobalStatus[req.globalStatus] = countersByGlobalStatus[req.globalStatus] + 1;

                    const statusInfo =
                        req.globalStatuses && req.globalStatuses.length > 0
                            ? req.globalStatuses[req.globalStatuses.length - 1]
                            : null;

                    if (
                        req.globalStatus === 'kPSLicenseRequestGlobalStatusInitial' ||
                        req.globalStatus === 'kPSLicenseRequestGlobalStatusFrozen'
                    ) {
                        row.push(formatLocaleDate(req.depositDate, 'dd/MM/yyyy'));
                    } else if (statusInfo) {
                        row.push(formatLocaleDate(statusInfo.date, 'dd/MM/yyyy'));
                    } else {
                        row.push('');
                    }

                    let option = req.additionalMetadata && req.additionalMetadata.option;
                    if (option !== null) {
                        row.push(getOptionStatusLabel(option));
                    } else {
                        row.push('');
                    }

                    if (req.inUseQuotas && req.inUseQuotas.length > 0) {
                        const obtainedContingents = req.inUseQuotas.map((q) => q.name).join(', ');
                        row.push(`"${obtainedContingents}"`);
                    } else {
                        row.push('');
                    }

                    if (req.obtainedZones && req.obtainedZones.length > 0) {
                        let prettyObtainedZones = beautifyZones(req, req.obtainedZones, self);
                        row.push(`"${prettyObtainedZones}"`);
                    } else {
                        row.push('');
                    }

                    if (req.targetedZones && req.targetedZones.length > 0) {
                        let prettyTargetedZones = beautifyZones(req, req.targetedZones, self);
                        row.push(`"${prettyTargetedZones}"`);
                    } else {
                        row.push('');
                    }

                    if (statusInfo) {
                        // double quotes are required to support CR/LR in comments
                        row.push(statusInfo.publicComment ? '"' + statusInfo.publicComment + '"' : '');
                        row.push(statusInfo.privateComment ? '"' + statusInfo.privateComment + '"' : '');
                    } else {
                        row.push('');
                        // AH: lack another row.push('') for the private comment no?
                    }

                    row.push(
                        req.payments
                            .map((payment) =>
                                `${paymentMethodLabel(payment.method)} ${payment.reference || ''}: ${Intl.NumberFormat(
                                    'fr'
                                ).format(payment.amount)}`.trim()
                            )
                            .join(' + ')
                    );

                    return row;
                });

                // Jehane also wants a summary of numbers of requests per global status. They basically do not want a CSV or spreadsheet. They want a PDF like presentation......
                //console.log(countersByGlobalStatus);
                rows.push([['']]);
                rows.push([['']]);
                rows.push([['']]);
                // keep the globalStatus order defined here
                [
                    'kPSLicenseRequestGlobalStatusFrozen',
                    'kPSLicenseRequestGlobalStatusSuspended',
                    'kPSLicenseRequestGlobalStatusReserved',
                    'kPSLicenseRequestGlobalStatusAllowed',
                    'kPSLicenseRequestGlobalStatusRefused',
                    'kPSLicenseRequestGlobalStatusCancelled'
                ].forEach((globalStatusKey) => {
                    rows.push([
                        [
                            'Total de demandes avec la décision : "' +
                                getRequestStatusLabel(globalStatusKey) +
                                '" = ' +
                                countersByGlobalStatus[globalStatusKey]
                        ]
                    ]);
                });

                const blob = buildCSVData(headers, rows);
                downloadFileFromData(blob, filename);
            }),

            downloadRequestsCSVReportPAP: flow(function* (filename, requests = []) {
                // requests here are PAP *extract* request records not LicenseRequest records. LicenseRequest records can be obtained though.
                const { users } = getRoot(self);
                yield users.listUsers();

                // jehane wants the requests to be sorted by: Département d'origine (via le Numero de Permis PAP du pêcheur (aka uniqueIdentifier in Telecapeche DB). Ex: PAP2299999999 -> Département 22), Nom demandeur, Prénom demandeur
                const sortedRequests = requests.slice().sort((extractRequest1, extractRequest2) => {
                    const licenseRequest1 = extractRequest1.licenseRequest;
                    const licenseRequest2 = extractRequest2.licenseRequest;

                    const requester1 = users.userWithId(parseInt(licenseRequest1.requestAuthor, 10));
                    const requester2 = users.userWithId(parseInt(licenseRequest2.requestAuthor, 10));

                    const requester1PAPPermitNumber = requester1.uniqueIdentifier; // should be like PAP22XXXXXXX
                    let requester1CDPM = getCDPMNameFromPAPPermitNumber(requester1PAPPermitNumber); // "Finistère"
                    const requester2PAPPermitNumber = requester2.uniqueIdentifier;
                    let requester2CDPM = getCDPMNameFromPAPPermitNumber(requester2PAPPermitNumber);

                    if (requester1CDPM && requester2CDPM) {
                        let sortResult = requester1CDPM.localeCompare(requester2CDPM, undefined, {
                            numeric: true
                        });
                        if (sortResult !== 0) {
                            return sortResult;
                        }

                        sortResult = requester1.lastName.localeCompare(requester2.lastName, 'fr');
                        if (sortResult !== 0) {
                            return sortResult;
                        }

                        sortResult = requester1.firstName.localeCompare(requester2.firstName, 'fr');
                        if (sortResult !== 0) {
                            return sortResult;
                        }
                    } else if (requester1CDPM) {
                        return -1;
                    } else if (requester2CDPM) {
                        return 1;
                    }

                    return 0;
                });

                const headers = [
                    'CDPM',
                    'Numéro de Permis PAP',
                    'Civilité',
                    'Nom demandeur',
                    'Prénom demandeur',
                    'Régime social',
                    'Décision licence',
                    'Date décision licence',
                    'Nature',
                    // Infos navires dans le futur (QM, Immat, Nom)
                    'Décision', // de *l'extract* request
                    'Date décision', // de *l'extract* request
                    'Commentaire public',
                    'Commentaire privé',
                    'Paiements'
                ];
                const rows = sortedRequests.map((req) => {
                    // req is an *extract* request
                    const row = [];

                    const requester = users.userWithId(parseInt(req.licenseRequest.requestAuthor, 10));

                    row.push(
                        getCDPMNameFromPAPPermitNumber(
                            requester && requester.uniqueIdentifier
                                ? requester.uniqueIdentifier
                                : 'Numéro Permis PAP Invalide'
                        )
                    ); // uniqueIdentifier is the PAP Permit Number
                    row.push(requester && requester.uniqueIdentifier ? requester.uniqueIdentifier : '');
                    row.push(requester ? userShortTitle(requester.title) : '');
                    row.push(requester && requester.lastName ? requester.lastName.toUpperCase() : '');
                    row.push(requester && requester.firstName ? requester.firstName.toUpperCase() : '');
                    row.push(requester && requester.socialRegime ? requester.socialRegime : '');

                    row.push(getRequestStatusLabel(req.licenseRequest.globalStatus)); // Décision de la license request
                    let licenseRequestStatusInfo =
                        req.licenseRequest.globalStatuses && req.licenseRequest.globalStatuses.length > 0
                            ? req.licenseRequest.globalStatuses[req.licenseRequest.globalStatuses.length - 1]
                            : null;

                    if (
                        req.licenseRequest.globalStatus === 'kPSLicenseRequestGlobalStatusInitial' ||
                        req.licenseRequest.globalStatus === 'kPSLicenseRequestGlobalStatusFrozen'
                    ) {
                        row.push(formatLocaleDate(req.depositDate, 'dd/MM/yyyy')); // Date de dépose de la demande
                    } else if (licenseRequestStatusInfo) {
                        row.push(formatLocaleDate(licenseRequestStatusInfo.date, 'dd/MM/yyyy')); // Date de décision de la license request
                    } else {
                        row.push('');
                    }

                    // Infos navires dans le futur (QM, Immat, Nom)

                    row.push(getRequestTypeLabel(req));
                    row.push(getRequestStatusLabel(req.globalStatus)); // Décision de l'extract request
                    let extractRequestStatusInfo =
                        req.globalStatuses && req.globalStatuses.length > 0
                            ? req.globalStatuses[req.globalStatuses.length - 1]
                            : null;

                    if (
                        req.globalStatus === 'kPSLicenseRequestGlobalStatusInitial' ||
                        req.globalStatus === 'kPSLicenseRequestGlobalStatusFrozen'
                    ) {
                        row.push(formatLocaleDate(req.depositDate, 'dd/MM/yyyy')); // Date de dépose de la demande
                    } else if (extractRequestStatusInfo) {
                        row.push(formatLocaleDate(extractRequestStatusInfo.date, 'dd/MM/yyyy')); // Date de décision de l'extract request
                    }

                    if (extractRequestStatusInfo) {
                        // double quotes are required to support CR/LR in comments
                        row.push(
                            extractRequestStatusInfo.publicComment
                                ? '"' + extractRequestStatusInfo.publicComment + '"'
                                : ''
                        );
                        row.push(
                            extractRequestStatusInfo.privateComment
                                ? '"' + extractRequestStatusInfo.privateComment + '"'
                                : ''
                        );
                    } else {
                        row.push('');
                        row.push('');
                        row.push('');
                    }

                    row.push(
                        req.payments
                            .map((payment) =>
                                `${payment.reference} : ${Intl.NumberFormat('fr').format(payment.amount)}`.trim()
                            )
                            .join(' + ')
                    );

                    return row;
                });

                const blob = buildCSVData(headers, rows);
                downloadFileFromData(blob, filename);
            }),

            downloadRequestsCSVGlobalReportPAP: flow(function* (filename, season, divisionCode, requests) {
                // requests here are PAP *license* request records not ExtractRequests records.
                const { users } = getRoot(self);
                yield users.listUsers();

                // jehane wants the requests to be sorted by: Département d'origine (via le Numero de Permis PAP du pêcheur (aka uniqueIdentifier in Telecapeche DB). Ex: PAP2299999999 -> Département 22), Nom demandeur, Prénom demandeur
                const sortedRequests = requests.slice().sort((licenseRequest1, licenseRequest2) => {
                    const requester1 = users.userWithId(parseInt(licenseRequest1.requestAuthor.telecapecheId, 10));
                    const requester1PAPPermitNumber = requester1 ? requester1.uniqueIdentifier : null; // should be like PAP22XXXXXXX
                    let requester1CDPM = requester1PAPPermitNumber
                        ? getCDPMNameFromPAPPermitNumber(requester1PAPPermitNumber)
                        : null; // "Finistère"

                    const requester2 = users.userWithId(parseInt(licenseRequest2.requestAuthor.telecapecheId, 10));
                    const requester2PAPPermitNumber = requester2 ? requester2.uniqueIdentifier : null;
                    let requester2CDPM = requester2PAPPermitNumber
                        ? getCDPMNameFromPAPPermitNumber(requester2PAPPermitNumber)
                        : null;

                    if (requester1CDPM && requester2CDPM) {
                        let sortResult = requester1CDPM.localeCompare(requester2CDPM, 'fr');
                        if (sortResult !== 0) {
                            return sortResult;
                        }

                        sortResult = requester1.lastName.localeCompare(requester2.lastName, 'fr');
                        if (sortResult !== 0) {
                            return sortResult;
                        }

                        sortResult = requester1.firstName.localeCompare(requester2.firstName, 'fr');
                        if (sortResult !== 0) {
                            return sortResult;
                        }
                    } else if (requester1CDPM) {
                        return -1;
                    } else if (requester2CDPM) {
                        return 1;
                    }

                    return 0;
                });

                let divisions = [];
                if (divisionCode === '') {
                    // Bretagne, so get all sub administrative divisions of Bretagne
                    divisions = [
                        self.administrativeDivisionWithPath(
                            'root=__.continent=europe.country=fr.region=bretagne.department=cotes-d-armor'
                        ),
                        self.administrativeDivisionWithPath(
                            'root=__.continent=europe.country=fr.region=bretagne.department=finistere'
                        ),
                        self.administrativeDivisionWithPath(
                            'root=__.continent=europe.country=fr.region=bretagne.department=ille-et-vilaine'
                        ),
                        self.administrativeDivisionWithPath(
                            'root=__.continent=europe.country=fr.region=bretagne.department=morbihan'
                        )
                    ];
                } else {
                    divisions = [self.administrativeDivisionWithCode(divisionCode)];
                }

                // static headers
                const headers = [
                    'CDPM',
                    'Numéro de Permis PAP',
                    'Civilité',
                    'Nom demandeur',
                    'Prénom demandeur',
                    'Régime social',
                    'Décision licence',
                    'Date décision licence',
                    'Paiement Licence'
                    // Infos navires dans le futur (QM, Immat, Nom)
                ];

                // dynamic headers
                // for each division
                divisions.forEach((division) => {
                    if (headers[headers.length - 1] === '|') {
                        headers.pop(); // remove spurious separator
                    }

                    headers.push(division.name.toUpperCase() + ' :');

                    // for each extract of the division
                    season.extracts.forEach((proxy) => {
                        if (proxy.administrativeDivision === division.administrativeDivisionPath) {
                            //headers.push(proxy.shore); helpful or not?
                            headers.push(proxy.name); // or push '' ?
                            headers.push('Nature'); // de *l'extract* request
                            headers.push('Décision'); // de *l'extract* request
                            headers.push('Date décision'); // de *l'extract* request
                            headers.push('Commentaire public');
                            headers.push('Commentaire privé');
                            headers.push('Paiements');

                            headers.push('|'); // insert a blank column as a cheap separator
                        }
                    });

                    if (headers[headers.length - 1] !== '|') {
                        headers.push('|'); // insert a blank column as a cheap separator
                    }
                });

                const rows = sortedRequests.reduce((rows, request) => {
                    // request is a *license* request
                    const requester = users.userWithId(request.requestAuthor.telecapecheId);
                    const row = [];

                    let rowIsEmpty = true; // set to false if the requester made a license request containing at least one extract request targeting an administrative division present in `divisions`.
                    // fulfill static header rows
                    row.push(
                        getCDPMNameFromPAPPermitNumber(
                            requester && requester.uniqueIdentifier
                                ? requester.uniqueIdentifier
                                : 'Numéro Permis PAP Invalide'
                        )
                    ); // uniqueIdentifier is the PAP Permit Number
                    row.push(requester && requester.uniqueIdentifier ? requester.uniqueIdentifier : '');
                    row.push(requester ? userShortTitle(requester.title) : '');
                    row.push(requester && requester.lastName ? requester.lastName.toUpperCase() : '');
                    row.push(requester && requester.firstName ? requester.firstName.toUpperCase() : '');
                    row.push(requester && requester.socialRegime ? requester.socialRegime : '');

                    row.push(getRequestStatusLabel(request.globalStatus)); // Décision de la license request
                    let licenseRequestStatusInfo =
                        request.globalStatuses && request.globalStatuses.length > 0
                            ? request.globalStatuses[request.globalStatuses.length - 1]
                            : null;
                    if (licenseRequestStatusInfo) {
                        row.push(formatLocaleDate(licenseRequestStatusInfo.date, 'dd/MM/yyyy')); // Date de décision de la license request
                    } else {
                        row.push('');
                    }
                    // license payments info on one line
                    row.push(
                        request.payments
                            .map((payment) => {
                                if (
                                    payment.reason !== null &&
                                    payment.reason === 'pescalice.peche-a-pied.license.payment'
                                ) {
                                    return `${payment.reference} : ${Intl.NumberFormat('fr').format(
                                        payment.amount
                                    )}`.trim();
                                }
                            })
                            .join(' + ')
                    );

                    // Infos navires dans le futur (QM, Immat, Nom)

                    // COMPLETELY SUB-OPTIMIZED. AT LEAST O(n^3). Looping over the same arrays over and over again......
                    // dynamic : for each division
                    divisions.forEach((division) => {
                        if (row[row.length - 1] === '|') {
                            row.pop(); // remove spurious separator
                        }
                        row.push('||'); // nothing special to put in the Division column
                        // for each extract of the division
                        season.extracts.forEach((proxy) => {
                            if (proxy.administrativeDivision === division.administrativeDivisionPath) {
                                // find if the license request contains an extract request targeting the proxy
                                let extractRequestTargetsTheProxyExtract = false;
                                for (let i = 0; i < request.extractRequests.length; i++) {
                                    // must use for loop since forEach can't break in JS 8-/  WTF!?
                                    let extractRequest = request.extractRequests[i];
                                    if (
                                        extractRequest.targetedExtract.pescaliceIdentifier === proxy.pescaliceIdentifier
                                    ) {
                                        extractRequestTargetsTheProxyExtract = true;
                                        rowIsEmpty = false;

                                        row.push(proxy.name); // or push '' ?
                                        row.push(getRequestTypeLabel(extractRequest));
                                        row.push(getRequestStatusLabel(extractRequest.globalStatus));
                                        // Date décision
                                        let statusInfo =
                                            extractRequest.globalStatuses && extractRequest.globalStatuses.length > 0
                                                ? extractRequest.globalStatuses[
                                                      extractRequest.globalStatuses.length - 1
                                                  ]
                                                : null;
                                        if (statusInfo) {
                                            row.push(formatLocaleDate(statusInfo.date, 'dd/MM/yyyy'));
                                            // double quotes are required to support CR/LR in comments
                                            row.push(
                                                statusInfo.publicComment ? '"' + statusInfo.publicComment + '"' : ''
                                            );
                                            row.push(
                                                statusInfo.privateComment ? '"' + statusInfo.privateComment + '"' : ''
                                            );
                                        } else {
                                            row.push('');
                                            row.push('');
                                            row.push('');
                                        }
                                        // payments info on one line
                                        row.push(
                                            extractRequest.payments
                                                .map((payment) =>
                                                    `${payment.reference} : ${Intl.NumberFormat('fr').format(
                                                        payment.amount
                                                    )}`.trim()
                                                )
                                                .join(' + ')
                                        );

                                        break;
                                    }
                                }

                                if (extractRequestTargetsTheProxyExtract === false) {
                                    row.push(proxy.name); // or push '' ?
                                    row.push('');
                                    row.push('');
                                    row.push('');
                                    row.push('');
                                    row.push('');
                                    row.push('');
                                }

                                row.push('|'); // insert a blank column as a cheap separator
                            }
                        });

                        if (row[row.length - 1] !== '|') {
                            row.push('|'); // insert a blank column as a cheap separator
                        }
                        //row.push(''); // insert a blank column as a cheap separator
                    });

                    if (!rowIsEmpty) {
                        rows.push(row);
                    }

                    //     const extract = self.seasonExtractPAPWithId(season, extractRequest.targetedExtract);
                    //     if (division === '' || extract.administrativeDivision.endsWith(division)) {
                    //         const row = [];

                    //         row.push(
                    //             getCDPMNameFromPAPPermitNumber(
                    //                 requester && requester.uniqueIdentifier
                    //                     ? requester.uniqueIdentifier
                    //                     : 'Numéro Permis PAP Invalide'
                    //             )
                    //         );

                    //         row.push('');
                    //         row.push('');
                    //         row.push('');
                    //         row.push('');
                    //         row.push('');
                    //         row.push('');
                    //         row.push('');
                    //         row.push('');
                    //         row.push('');
                    //         row.push('');

                    //         rows.push(row);

                    return rows;
                }, []);

                const blob = buildCSVData(headers, rows);
                downloadFileFromData(blob, filename);
            }),

            listLicenseContingents: flow(function* (id) {
                const { app } = getRoot(self);

                app.setBusy();
                try {
                    const response = yield api.listLicenseContingents(id);
                    self.licenseRequests = LicenseRequestList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            }),

            listLicenseContingentsPAP: flow(function* (id) {
                const { app } = getRoot(self);

                app.setBusy();
                try {
                    const response = yield api.listLicenseContingentsPAP(id);
                    return response.data || [];
                } finally {
                    app.setBusy(false);
                }
            }),

            resetLicenseContingents: () => {
                self.licenseRequests = [];
            },

            setProxyDivisionFilter: (value) => {
                self.proxyDivisionFilter = value;
            },

            setProxyNameFilter: (value) => {
                self.proxyNameFilter = value;
            },

            resetProxyFilters: () => {
                self.proxyDivisionFilter = '';
                self.proxyNameFilter = '';
            },

            setProxyDivisionFilterPAP: (value) => {
                self.proxyDivisionFilterPAP = value;
            },

            setProxyNameFilterPAP: (value) => {
                self.proxyNameFilterPAP = value;
            },

            resetProxyFiltersPAP: () => {
                self.proxyDivisionFilterPAP = '';
                self.proxyNameFilterPAP = '';
            },

            setTodoDeptFilter: (value) => {
                self.todoDeptFilter = value;
            },

            setTodoLicenseFilter: (value) => {
                self.todoLicenseFilter = value;
            },

            setTodoRequesterFilter: (value) => {
                self.todoRequesterFilter = value;
            },

            setTodoBoatFilter: (value) => {
                self.todoBoatFilter = value;
            },

            setTodoFromDateFilter: (value) => {
                self.todoFromDateFilter = value;
            },

            setTodoToDateFilter: (value) => {
                self.todoToDateFilter = value;
            },

            setTodoTypeFilter: (value) => {
                self.todoTypeFilter = value;
            },

            setTodoStatusFilter: (value) => {
                self.todoStatusFilter = value;
            },

            resetTodoFilters: () => {
                self.todoDeptFilter = '';
                self.todoLicenseFilter = '';
                self.todoRequesterFilter = '';
                self.todoBoatFilter = '';
                self.todoFromDateFilter = '';
                self.todoToDateFilter = '';
                self.todoTypeFilter = '';
                self.todoStatusFilter = '';
            },

            setTodoDeptFilterPAP: (value) => {
                self.todoDeptFilterPAP = value;
            },

            setTodoLicenseFilterPAP: (value) => {
                self.todoLicenseFilterPAP = value;
            },

            setTodoRequesterFilterPAP: (value) => {
                self.todoRequesterFilterPAP = value;
            },

            setTodoBoatFilterPAP: (value) => {
                self.todoBoatFilterPAP = value;
            },

            setTodoFromDateFilterPAP: (value) => {
                self.todoFromDateFilterPAP = value;
            },

            setTodoToDateFilterPAP: (value) => {
                self.todoToDateFilterPAP = value;
            },

            setTodoTypeFilterPAP: (value) => {
                self.todoTypeFilterPAP = value;
            },

            setTodoStatusFilterPAP: (value) => {
                self.todoStatusFilterPAP = value;
            },

            resetTodoFiltersPAP: () => {
                self.todoDeptFilterPAP = '';
                self.todoLicenseFilterPAP = '';
                self.todoRequesterFilterPAP = '';
                self.todoBoatFilterPAP = '';
                self.todoFromDateFilterPAP = '';
                self.todoToDateFilterPAP = '';
                self.todoTypeFilterPAP = '';
                self.todoStatusFilterPAP = '';
            },

            setCurrentPopOverRequestId: (id) => {
                self.currentPopOverRequestId = id;
            },

            setPaymentRequesterFilter: (value) => {
                self.paymentRequesterFilter = value;
            },

            setPaymentBaseYearFilter: (value) => {
                self.paymentBaseYearFilter = value;
            },

            setPaymentLicenseFilter: (value) => {
                self.paymentLicenseFilter = value;
            },

            setPaymentDistrictFilter: (value) => {
                self.paymentDistrictFilter = value;
            },

            resetPaymentsFilters: () => {
                self.paymentRequesterFilter = '';
                self.paymentBaseYearFilter = new Date().getFullYear().toString();
                self.paymentLicenseFilter = '';
                self.paymentDistrictFilter = '';
            },

            setPAPPaymentRequesterFilter: (value) => {
                self.papPaymentRequesterFilter = value;
            },

            setPAPPaymentBaseYearFilter: (value) => {
                self.papPaymentBaseYearFilter = value;
            },

            resetPAPPaymentsFilters: () => {
                self.papPaymentRequesterFilter = '';
                self.papPaymentBaseYearFilter = new Date().getFullYear().toString();
            }
        };
    });

function beautifyZones(req, zones, self) {
    const sortedZones = zones.slice().sort((z1, z2) => {
        // group sortedZones by parentZoneName (sorted by parentZoneName too)
        // and sort *inside* those groups by zone name.
        if (z1.parentZoneName && z2.parentZoneName) {
            const z1FullName = z1.parentZoneName + z1.name;
            const z2FullName = z2.parentZoneName + z2.name;
            return z1FullName.localeCompare(z2FullName, 'fr', { numeric: true });
        } else if (z1.parentZoneName) {
            return -1;
        } else if (z2.parentZoneName) {
            return 1;
        }

        return 0;
    });

    // semi complex code just to tweak the sorted zones of Canot licence
    // which is pretty complex : we want the parentZoneName to be displayed
    // *once* per group of zones, and for Canot only!
    let seasonLicence = null;
    let prettySortedZones = '';
    let currentParentZoneNameForCanot = null;

    sortedZones.forEach((z, i) => {
        // note: req.license here is the uniqueId of the license. not a full object.
        if (!seasonLicence || seasonLicence.uniqueId !== req.license) {
            // This function is mostly called with LicenseRequests from the same license.
            // So there's a potential optimization since the seasonLicence should not change.
            // So we can avoid calling seasonLicenceWithId() which is slow
            seasonLicence = self.seasonLicenceWithId(req.license);
        }
        if (seasonLicence && seasonLicence.pescaliceIdentifier === 'pescalice.peche-embarquee.license.bretagne.canot') {
            // License Canot has lots of zones that are undistinguishable from Filet, Crustacés
            // and Metier de l'Hamecon. So tweak the zone naming to show it's related to Canot.
            if (z.parentZoneName === currentParentZoneNameForCanot) {
                // do not add the parent zone name again since it has already been "printed" before
                prettySortedZones = prettySortedZones + '+' + z.name;
            } else {
                currentParentZoneNameForCanot = z.parentZoneName;
                if (currentParentZoneNameForCanot.length > 0) {
                    const separator = i > 0 ? ',\r\n' : ''; // do not start the string by ","
                    prettySortedZones = prettySortedZones + separator + currentParentZoneNameForCanot + ' = ';
                }
                // do not add the parent zone name again since it has already been "printed" before
                prettySortedZones = prettySortedZones + z.name;
            }
        } else {
            const separator = i > 0 ? ', ' : ''; // do not start the string by ","
            prettySortedZones = prettySortedZones + separator + z.name;
        }
    });

    return prettySortedZones;
}
