import React from 'react';
import classNames from 'classnames';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import debounce from 'lodash/debounce';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';
import { WithOrgTag } from '@COMPONENTS/common/WithOrgTag';

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class UsersSelector extends React.Component {
    @observable fromDeclarationDateFilter = null;
    @observable isFromDeclarationDateValid = true;
    @observable fromDeclarationTimeFilter = null;
    @observable isFromDeclarationTimeValid = true;
    @observable toDeclarationDateFilter = null;
    @observable isToDeclarationDateValid = true;
    @observable toDeclarationTimeFilter = null;
    @observable isToDeclarationTimeValid = true;

    _debouncedUpdate = null;

    _update = () => {
        this.props.store.users.listUsers(true);
    };

    _switchToSingle = () => {
        this.props.history.push(this.props.altUrl);
    };

    _resetFilters = (evt) => {
        evt.preventDefault();
        this.props.store.users.resetFilters();
        this.props.store.users.listUsers(true);
    };

    _updateNameFilter = (evt) => {
        this.props.store.users.setNameFilter(evt.target.value);

        if (this._debouncedUpdate === null) {
            this._debouncedUpdate = debounce(() => this.props.store.users.listUsers(true), 350);
        }
        this._debouncedUpdate();
    };

    _updateStatusFilter = (evt) => {
        this.props.store.users.setStatusFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _updateZipCodeFilter = (evt) => {
        this.props.store.users.setZipCodeFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _updatePaymentInfosFilter = (evt) => {
        this.props.store.users.setPaymentInfosFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _updateTypeFilter = (evt) => {
        this.props.store.users.setTypeFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _updateDeclarationStatusFilter = (evt) => {
        this.props.store.users.setDeclarationStatusFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _upateSpeciesFilter = (evt) => {
        this.props.store.users.setSpeciesFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _upateZoneFilter = (evt) => {
        this.props.store.users.setZoneFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _updateDeclarationMomentFilter = (evt) => {
        this.props.store.users.setDeclarationMomentFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _updateFromDeclarationDateFilter = (evt) => {
        this._updateDeclarationDateFilter(evt.target.value, 'fromDeclarationDateFilter', 'isFromDeclarationDateValid');
        this._updateFromDeclarationEventDateFilter();
    };

    _updateFromDeclarationTimeFilter = (evt) => {
        this._updateDeclarationTimeFilter(evt.target.value, 'fromDeclarationTimeFilter', 'isFromDeclarationTimeValid');
        this._updateFromDeclarationEventDateFilter();
    };

    _updateFromDeclarationEventDateFilter = () => {
        if (this.fromDeclarationDateFilter) {
            const timeFilter = this.fromDeclarationTimeFilter || { hour: 0, minutes: 0 };
            this.props.store.users.setFromDeclarationDateFilter(
                new Date(
                    Date.UTC(
                        this.fromDeclarationDateFilter.year,
                        this.fromDeclarationDateFilter.month - 1,
                        this.fromDeclarationDateFilter.day,
                        timeFilter.hour,
                        timeFilter.minutes
                    )
                )
            );
            this.props.store.users.listUsers(true);
        }
    };

    _updateToDeclarationDateFilter = (evt) => {
        this._updateDeclarationDateFilter(evt.target.value, 'toDeclarationDateFilter', 'isToDeclarationDateValid');
        this._updateToDeclarationEventDateFilter();
    };

    _updateToDeclarationTimeFilter = (evt) => {
        this._updateDeclarationTimeFilter(evt.target.value, 'toDeclarationTimeFilter', 'isToDeclarationTimeValid');
        this._updateToDeclarationEventDateFilter();
    };

    _updateToDeclarationEventDateFilter = () => {
        if (this.toDeclarationDateFilter) {
            const timeFilter = this.toDeclarationTimeFilter || { hour: 23, minutes: 59 };
            this.props.store.users.setToDeclarationDateFilter(
                new Date(
                    Date.UTC(
                        this.toDeclarationDateFilter.year,
                        this.toDeclarationDateFilter.month - 1,
                        this.toDeclarationDateFilter.day,
                        timeFilter.hour,
                        timeFilter.minutes
                    )
                )
            );
            this.props.store.users.listUsers(true);
        }
    };

    _updateDeclarationDateFilter = (value, filterKey, errorFlagKey) => {
        if (value === '') {
            this[errorFlagKey] = true;
            this[filterKey] = null;
        } else {
            const match = value.match(/(\d{2})\/(\d{2})\/(\d{4})/);
            if (match) {
                this[errorFlagKey] = true;
                this[filterKey] = {
                    day: parseInt(match[1], 10),
                    month: parseInt(match[2], 10),
                    year: parseInt(match[3], 10)
                };
            } else {
                this[errorFlagKey] = false;
                this[filterKey] = null;
            }
        }
    };

    _updateDeclarationTimeFilter = (value, filterKey, errorFlagKey) => {
        if (value === '') {
            this[errorFlagKey] = true;
            this[filterKey] = null;
        } else {
            const match = value.match(/(\d{2}):(\d{2})/);
            if (match) {
                this[errorFlagKey] = true;
                this[filterKey] = {
                    hour: parseInt(match[1], 10),
                    minutes: parseInt(match[2], 10)
                };
            } else {
                this[errorFlagKey] = false;
                this[filterKey] = null;
            }
        }
    };

    _updateValidationsFilter = (evt) => {
        this.props.store.users.setValidationsFilter(evt.target.value);
        this.props.store.users.listUsers(true);
    };

    _addUser = () => {
        this.props.store.app.setModal(true);
        this.props.store.users.setShowNewUserDialog();
    };

    render() {
        const { t, store } = this.props;
        const sessionUser = store.session.info.user;
        const canCreate = !sessionUser.hasTag('psc_ro');

        return (
            <OrgChangeObserver onChange={this._update}>
                <div
                    className="list subList withToggle show"
                    style={{ width: sessionUser.type === 'manager' ? 'var(--left-column-width-large)' : undefined }}
                >
                    <div className="toggle">
                        <span
                            className="selected"
                            title={
                                sessionUser.type === 'manager'
                                    ? 'Liste des personnes'
                                    : t('admin:user-selector.fishermen-list')
                            }
                        >
                            <SVGObject objectId="allFishermen" />
                        </span>
                        <span
                            title={
                                sessionUser.type === 'manager'
                                    ? 'Fiches demandeurs'
                                    : t('admin:user-selector.fishermen-cards')
                            }
                            onClick={this._switchToSingle}
                        >
                            <SVGObject objectId="singleFisherman" />
                        </span>
                    </div>

                    <dl className="show">
                        <div className="burger">
                            <span />
                        </div>
                        <h6>{t('admin:users-selector.active-fishermen')}</h6>
                        <dt title={t('admin:users-selector.reset-parameters')}>
                            {t('common:filters')}
                            <SVGObject objectId="resetIco" onClick={this._resetFilters} />
                        </dt>
                        <dd className="noSelection">
                            <input
                                type="text"
                                placeholder={
                                    sessionUser.type === 'manager'
                                        ? 'Nom de la personne'
                                        : t('admin:users-selector.fisherman-name')
                                }
                                onChange={this._updateNameFilter}
                                value={this.props.store.users.nameFilter}
                            />
                        </dd>
                        <dd className="noSelection selected">
                            <select
                                onChange={this._updateStatusFilter}
                                value={this.props.store.users.statusFilter || ''}
                            >
                                <option value="">
                                    {sessionUser.type === 'manager'
                                        ? 'Tous les demandeurs'
                                        : t('admin:users-selector.all-fishermen')}
                                </option>
                                <option value="active">
                                    {sessionUser.type === 'manager'
                                        ? 'Demandeurs en activité'
                                        : t('admin:users-selector.active-fishermen')}
                                </option>
                                <option value="inactive">
                                    {sessionUser.type === 'manager'
                                        ? 'Demandeurs suspendus'
                                        : t('admin:users-selector.suspended-fishermen')}
                                </option>
                                <option value="archived">
                                    {sessionUser.type === 'manager'
                                        ? 'Demandeurs arrêtés'
                                        : t('admin:users-selector.terminated-fishermen')}
                                </option>
                            </select>
                        </dd>
                        <dd className="noSelection not-implemented">
                            <select disabled="disabled" defaultValue="*">
                                <option value="*">{t('common:all-zones')}</option>
                            </select>
                        </dd>
                        {this.props.store.session.info.user.type === 'manager' && (
                            <React.Fragment>
                                <dd className="noSelection">
                                    <select value={this.props.store.users.typeFilter} onChange={this._updateTypeFilter}>
                                        <option value="">Tous les types</option>
                                        <option value="pap">PAP - Pêche à pied</option>
                                    </select>
                                </dd>
                                <dd className="noSelection">
                                    <select
                                        value={this.props.store.users.zipCodeFilter}
                                        onChange={this._updateZipCodeFilter}
                                    >
                                        <option value="">Tous les codes postaux</option>
                                        <option value="22">Côtes d&apos;Armor</option>
                                        <option value="29">Finistère</option>
                                        <option value="35">Ille et Vilaine</option>
                                        <option value="56">Morbihan</option>
                                        <option value="HB">Hors Bretagne</option>
                                    </select>
                                </dd>
                                <dd className="noSelection">
                                    <select
                                        value={this.props.store.users.paymentInfosFilter}
                                        onChange={this._updatePaymentInfosFilter}
                                    >
                                        <option value="">Avec ou sans infos de paiement</option>
                                        <option value="withFullBankInfos">Avec infos de paiement complètes</option>
                                        <option value="withIncompleteBankInfos">
                                            Avec infos de paiement incompletes
                                        </option>
                                    </select>
                                </dd>
                            </React.Fragment>
                        )}
                        {this.props.store.session.info.user.type !== 'manager' && (
                            <React.Fragment>
                                <dt className="sub">{t('common:declarations')}</dt>
                                <dd className="noSelection">
                                    <select
                                        value={this.props.store.users.declarationStatusFilter}
                                        onChange={this._updateDeclarationStatusFilter}
                                    >
                                        <option value="">{t('admin:users-selector.did-declare-or-not')}</option>
                                        <option value="did-declare">{t('admin:users-selector.did-declare')}</option>
                                        <option value="did-not-declare">
                                            {t('admin:users-selector.did-not-declare')}
                                        </option>
                                    </select>
                                </dd>
                                <dd className="noSelection">
                                    <select
                                        value={this.props.store.users.speciesFilter}
                                        onChange={this._upateSpeciesFilter}
                                    >
                                        <option value="">{t('admin:users-selector.any-species')}</option>
                                        {this.props.store.species.orgList.map((sp) => (
                                            <option value={sp.code} key={sp.code}>
                                                {sp.code} - {sp.name}
                                            </option>
                                        ))}
                                    </select>
                                </dd>
                                <dd className="noSelection">
                                    <select value={this.props.store.users.zoneFilter} onChange={this._upateZoneFilter}>
                                        <option value="">{t('admin:users-selector.any-zone')}</option>
                                        {this.props.store.zones.list.map((z) => (
                                            <option value={z.id} key={z.id}>
                                                {z.name} [{z.code}]
                                            </option>
                                        ))}
                                    </select>
                                </dd>
                                <dd className="noSelection">
                                    <select
                                        value={this.props.store.users.declarationMomentFilter}
                                        onChange={this._updateDeclarationMomentFilter}
                                    >
                                        <option value="">{t('admin:users-selector.at-any-date')}</option>
                                        <option value="today">{t('common:today')}</option>
                                        <option value="yesterday">{t('common:yesterday')}</option>
                                        <option value="this-week">{t('common:this-week')}</option>
                                        <option value="last-week">{t('common:last-week')}</option>
                                        <option value="this-month">{t('common:this-month')}</option>
                                        <option value="last-month">{t('common:last-month')}</option>
                                        <option value="custom">{t('common:between')}...</option>
                                    </select>
                                </dd>
                                {this.props.store.users.declarationMomentFilter === 'custom' && (
                                    <React.Fragment>
                                        <dd className="noSelection aligned">
                                            <label>{t('admin:users-selector.the')}</label>
                                            <input
                                                className={classNames('date', {
                                                    codeRed: !this.isFromDeclarationDateValid
                                                })}
                                                type="text"
                                                placeholder="dd/mm/yyyy"
                                                onChange={this._updateFromDeclarationDateFilter}
                                            />
                                            <input
                                                className={classNames('time', {
                                                    codeRed: !this.isFromDeclarationTimeValid
                                                })}
                                                type="text"
                                                placeholder="hh:mm"
                                                style={{ marginLeft: 4 }}
                                                onChange={this._updateFromDeclarationTimeFilter}
                                            />
                                        </dd>
                                        <dd className="noSelection aligned">
                                            <label>{t('admin:users-selector.and-the')}</label>
                                            <input
                                                className={classNames('date', {
                                                    codeRed: !this.isToDeclarationDateValid
                                                })}
                                                type="text"
                                                placeholder="dd/mm/yyyy"
                                                onChange={this._updateToDeclarationDateFilter}
                                            />
                                            <input
                                                className={classNames('time', {
                                                    codeRed: !this.isToDeclarationTimeValid
                                                })}
                                                type="text"
                                                placeholder="hh:mm"
                                                style={{ marginLeft: 4 }}
                                                onChange={this._updateToDeclarationTimeFilter}
                                            />
                                        </dd>{' '}
                                    </React.Fragment>
                                )}
                                <WithOrgTag tag="pap">
                                    <dt className="sub">{t('common:validations')}</dt>
                                    <dd className="noSelection">
                                        <select
                                            value={this.props.store.users.validationsFilter}
                                            onChange={this._updateValidationsFilter}
                                        >
                                            <option value="">{t('admin:users-selector.did-validate-or-not')}</option>
                                            <option value="are-up-to-date">
                                                {t('admin:users-selector.are-up-to-date')}
                                            </option>
                                            <option value="are-not-up-to-date">
                                                {t('admin:users-selector.are-not-up-to-date')}
                                            </option>
                                            <option value="did-validate-this-month">
                                                {t('admin:users-selector.did-validate-this-month')}
                                            </option>
                                            <option value="did-not-validate-this-month">
                                                {t('admin:users-selector.did-not-validate-this-month')}
                                            </option>
                                            <option value="did-validate-last-month">
                                                {t('admin:users-selector.did-validate-last-month')}
                                            </option>
                                            <option value="did-not-validate-last-month">
                                                {t('admin:users-selector.did-not-validate-last-month')}
                                            </option>
                                        </select>
                                    </dd>
                                </WithOrgTag>
                            </React.Fragment>
                        )}
                    </dl>
                    <ul className="toolbar">{canCreate && <li className="plus" onClick={this._addUser} />}</ul>
                </div>
            </OrgChangeObserver>
        );
    }
}
