/* eslint-disable react/no-children-prop */
import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { observer, MobXProviderContext } from 'mobx-react';

import { api } from '@SUPPORT/api';
import { usePaymentsList } from '@SUPPORT/queries';
import { matchNormalized, formatLocaleDate, downloadFileFromBase64Data, paymentMethodLabel } from '@SUPPORT/utils';

import { getRequestStatusLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

import {
    NoPaymentTable,
    Chevron,
    CellForChevron,
    CellNoWrap,
    CellForCheckbox,
    TriStateCheckbox,
    Label,
    PaymentRow,
    NoPaymentRow,
    TdStrong
} from './PaymentsElements';

export const PaymentsSection = observer(() => {
    const { store } = useContext(MobXProviderContext);

    const baseYear = store.pescalice.paymentBaseYearFilter;
    const { data: paymentsMap = {} } = usePaymentsList(store, baseYear);

    const payments = [];
    const noPayment = [];
    Object.entries(paymentsMap).forEach(([personId, entries]) => {
        const user = store.users.userWithId(personId);
        if (!user) {
            return;
        }

        entries.forEach((entry, i) => {
            let company;
            if (entry.companyTelecapecheId) {
                company = store.companies.companyWithId(entry.companyTelecapecheId);
            }

            const name = company ? company.name : `${user.firstName}${user.lastName}`;
            if (
                store.pescalice.paymentRequesterFilter.length > 0 &&
                !matchNormalized(name, store.pescalice.paymentRequesterFilter)
            ) {
                return;
            }

            let filteredEntry = entry;
            if (store.pescalice.paymentLicenseFilter !== '' || store.pescalice.paymentDistrictFilter !== '') {
                filteredEntry = {
                    ...entry,
                    licenseRequests: entry.licenseRequests.filter((req) => {
                        if (
                            store.pescalice.paymentLicenseFilter !== '' &&
                            req.license.uniqueId !== store.pescalice.paymentLicenseFilter
                        ) {
                            return false;
                        }
                        if (
                            store.pescalice.paymentDistrictFilter !== '' &&
                            req.boat.registrationDistrictCode !== store.pescalice.paymentDistrictFilter
                        ) {
                            return false;
                        }
                        return true;
                    })
                };
            }

            const totalPayments = filteredEntry.licenseRequests.reduce((t2, req) => t2 + req.payments.length, 0);
            const isSuspicious =
                totalPayments === 0 &&
                filteredEntry.licenseRequests.some(
                    (req) =>
                        req.globalStatus === 'kPSLicenseRequestGlobalStatusAllowed' ||
                        req.globalStatus === 'kPSLicenseRequestGlobalStatusReserved' ||
                        req.globalStatus === 'kPSLicenseRequestGlobalStatusSuspended'
                );
            if (isSuspicious) {
                noPayment.push({ user, company, entry: filteredEntry, groupIndex: i, groupTotal: entries.length });
                return;
            }

            if (totalPayments > 0) {
                payments.push({ user, company, entry: filteredEntry, groupIndex: i, groupTotal: entries.length });
            }
        });
    });

    payments.sort((e1, e2) => e1.user.lastName.localeCompare(e2.user.lastName));
    const totalCount = payments.reduce(
        (t1, info) => t1 + info.entry.licenseRequests.reduce((t2, req) => t2 + req.payments.length, 0),
        0
    );

    const allPaymentIds = payments.reduce((arr, payment) => {
        payment.entry.licenseRequests.forEach((req) => {
            arr = arr.concat(
                req.payments
                    .filter((payment) => !payment.paid && req.globalStatus === 'kPSLicenseRequestGlobalStatusAllowed')
                    .map((payment) => payment.uniqueId)
            );
        });
        return arr;
    }, []);

    const [selection, setSelection] = useState(() => new Set());
    const selectionState =
        allPaymentIds.length > 0 && allPaymentIds.every((id) => selection.has(id))
            ? 'three'
            : allPaymentIds.some((id) => selection.has(id))
            ? 'two'
            : 'one';
    const handleFullSelection = () => {
        if (selectionState === 'one' || selectionState === 'two') {
            allPaymentIds.forEach((id) => selection.add(id));
        } else {
            allPaymentIds.forEach((id) => selection.delete(id));
        }
        setSelection(new Set([...selection]));
    };

    const queryClient = useQueryClient();
    const [exporting, setExporting] = useState(false);
    const generateXML = () => {
        const message =
            selection.size === 1
                ? `Voulez-vous vraiment générer 1 demande de virement ?`
                : `Voulez-vous vraiment générer ${selection.size} demandes de virement ?`;
        const ok = confirm(message);
        if (ok) {
            setExporting(true);
            api.generateTransferOrders(Array.from(selection))
                .then((res) => {
                    queryClient.invalidateQueries(['payments', 'list']);
                    setTimeout(
                        () => downloadFileFromBase64Data(res.data.debitBankXMLFileData, 'paiements.xml', 'text/xml'),
                        100
                    );
                    setTimeout(
                        () => downloadFileFromBase64Data(res.data.csvSummaryFileData, 'paiements.csv', 'text/csv'),
                        500
                    );
                })
                .catch((err) => {
                    if (err.wrapped) {
                        alert(`Erreur: ${err.wrapped.errorCode}\nInfo: ${err.wrapped.errorMessage}`);
                    } else {
                        alert('Erreur inconnue');
                    }
                })
                .finally(() => {
                    setSelection(new Set());
                    setExporting(false);
                });
        }
    };

    const [showNoPayments, setShowNoPayments] = useState(true);
    const readOnlyMode = !store.session.info.user.hasTag('psc_accountant');

    return (
        <div className="selected">
            <div className="header" style={{ flexDirection: 'row', alignItems: 'center' }}>
                <h3>
                    Liste des paiements
                    <span>
                        {totalCount + (totalCount <= 1 ? ' paiement' : ' paiements')}
                        {selection.size > 0
                            ? ' - ' + selection.size + (selection.size <= 1 ? ' sélectionné' : ' sélectionnés')
                            : ''}
                    </span>
                </h3>
                <button
                    style={{ marginLeft: 'auto' }}
                    onClick={generateXML}
                    disabled={selection.size === 0 || exporting || readOnlyMode}
                >
                    Exporter lots de virements
                </button>
            </div>

            <div className="scroll">
                {noPayment.length > 0 && (
                    <NoPaymentTable>
                        <thead>
                            <tr>
                                <CellForChevron onClick={() => setShowNoPayments((show) => !show)}>
                                    <Chevron $expanded={showNoPayments} />
                                </CellForChevron>
                                <th colSpan="7">Demandes attribuées / suspendues / reservées sans paiement</th>
                            </tr>
                        </thead>
                        <tbody>
                            {showNoPayments &&
                                noPayment.map((payment) => (
                                    <NoPaymentEntry
                                        key={`${payment.entry.telecapecheId}-${payment.entry.companyTelecapecheId}`}
                                        payment={payment}
                                    />
                                ))}
                        </tbody>
                    </NoPaymentTable>
                )}

                <table>
                    <thead>
                        <tr>
                            <CellForCheckbox as="th">
                                <TriStateCheckbox
                                    id="all"
                                    state={selectionState}
                                    onClick={handleFullSelection}
                                    $disabled={allPaymentIds.length === 0 || readOnlyMode}
                                />
                                <label htmlFor="all"></label>
                            </CellForCheckbox>
                            <th colSpan="8">Sélectionner tous les paiements (non payés)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => (
                            <PaymentEntry
                                key={`${payment.entry.telecapecheId}-${payment.entry.companyTelecapecheId}`}
                                payment={payment}
                                selection={selection}
                                setSelection={setSelection}
                                readOnly={readOnlyMode}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
});

function NoPaymentEntry(props) {
    const { payment } = props;

    const noPaymentEntries = payment.entry.licenseRequests
        .filter(
            (req) =>
                req.globalStatus === 'kPSLicenseRequestGlobalStatusAllowed' ||
                req.globalStatus === 'kPSLicenseRequestGlobalStatusReserved' ||
                req.globalStatus === 'kPSLicenseRequestGlobalStatusSuspended'
        )
        .map((req) => ({ request: req, payment: null }))
        .flat()
        .sort((e1, e2) => e1.request.license.name.localeCompare(e2.request.license.name));

    return (
        <>
            <PaymentGroupHeader payment={payment} />

            <PaymentRow noSeparator={true}>
                <CellForCheckbox></CellForCheckbox>
                <PaymentUserNameRowHeader payment={payment} />
            </PaymentRow>

            {noPaymentEntries.map((entry, i) => (
                <NoPaymentRow
                    noSeparator={
                        (payment.groupTotal > 1 && payment.groupIndex < payment.groupTotal - 1) ||
                        i < noPaymentEntries.length - 1
                    }
                    key={entry.request.uniqueId}
                >
                    <CellForCheckbox></CellForCheckbox>
                    <CellForCheckbox></CellForCheckbox>
                    <td>{entry.request.license.name}</td>
                    <td colSpan={4}></td>
                </NoPaymentRow>
            ))}
        </>
    );
}

function PaymentEntry(props) {
    const { payment, selection, setSelection, readOnly } = props;

    const key = payment.company ? `${payment.user.id}-${payment.company.id}` : payment.user.id;
    const paymentEntries = payment.entry.licenseRequests
        .map((req) => req.payments.map((reqPayment) => ({ request: req, payment: reqPayment })))
        .flat()
        .sort((e1, e2) => e1.request.license.name.localeCompare(e2.request.license.name));
    const unpaidPaymentEntries = paymentEntries.filter((entry) => !entry.payment.paid);

    const selectionState =
        unpaidPaymentEntries.length > 0 && unpaidPaymentEntries.every((entry) => selection.has(entry.payment.uniqueId))
            ? 'three'
            : unpaidPaymentEntries.some((entry) => selection.has(entry.payment.uniqueId))
            ? 'two'
            : 'one';

    const handleFullSelection = () => {
        if (selectionState === 'one' || selectionState === 'two') {
            unpaidPaymentEntries.forEach((entry) => selection.add(entry.payment.uniqueId));
        } else {
            unpaidPaymentEntries.forEach((entry) => selection.delete(entry.payment.uniqueId));
        }
        setSelection(new Set([...selection]));
    };

    const handleSingleSelection = (paymentId, checked) => {
        if (checked) {
            selection.add(paymentId);
        } else {
            selection.delete(paymentId);
        }
        setSelection(new Set([...selection]));
    };

    return (
        <>
            <PaymentGroupHeader payment={payment} />

            <PaymentRow noSeparator={true}>
                <CellForCheckbox>
                    <TriStateCheckbox
                        id={key}
                        $disabled={
                            paymentEntries.every(
                                (entry) =>
                                    entry.payment.paid ||
                                    entry.request.globalStatus !== 'kPSLicenseRequestGlobalStatusAllowed'
                            ) || readOnly
                        }
                        state={selectionState}
                        onClick={handleFullSelection}
                    />
                    <Label htmlFor={key}></Label>
                </CellForCheckbox>
                <PaymentUserNameRowHeader payment={payment} />
            </PaymentRow>

            {paymentEntries.map((entry, i) => (
                <PaymentRow
                    suspicious={
                        entry.payment.paid &&
                        entry.payment.method === 'kPSPaymentMethodDebit' &&
                        entry.request.globalStatus !== 'kPSLicenseRequestGlobalStatusAllowed'
                    }
                    noSeparator={
                        (payment.groupTotal > 1 && payment.groupIndex < payment.groupTotal - 1) ||
                        i < paymentEntries.length - 1
                    }
                    key={entry.request.uniqueId + '-' + entry.payment.uniqueId}
                >
                    <CellForCheckbox></CellForCheckbox>
                    <CellForCheckbox>
                        <input
                            type="checkbox"
                            id={entry.request.uniqueId + '-' + entry.payment.uniqueId}
                            disabled={
                                readOnly ||
                                entry.payment.paid ||
                                entry.request.globalStatus !== 'kPSLicenseRequestGlobalStatusAllowed'
                            }
                            checked={selection.has(entry.payment.uniqueId)}
                            onChange={(evt) => handleSingleSelection(entry.payment.uniqueId, evt.currentTarget.checked)}
                        />
                        <label htmlFor={entry.request.uniqueId + '-' + entry.payment.uniqueId}></label>
                    </CellForCheckbox>
                    <td>{entry.request.license.name}</td>
                    <td className={getRequestStatusColorCode(entry.request.globalStatus)}>
                        {getRequestStatusLabel(entry.request.globalStatus)}
                    </td>
                    <CellNoWrap style={{ textAlign: 'end' }}>{entry.payment.amount} €</CellNoWrap>
                    <CellNoWrap>{formatLocaleDate(entry.payment.date, 'dd/MM/yyyy')}</CellNoWrap>
                    <td>{paymentMethodLabel(entry.payment.method)}</td>
                    <td>{entry.payment.paid ? 'Payé' : 'Non payé'}</td>
                    <td></td>
                </PaymentRow>
            ))}
        </>
    );
}

function PaymentGroupHeader(props) {
    const { payment } = props;
    return (
        payment.groupTotal > 1 &&
        payment.groupIndex === 0 && (
            <PaymentRow noSeparator={true}>
                <td></td>
                <TdStrong colSpan="7">
                    {payment.user.lastName} {payment.user.firstName}
                </TdStrong>
            </PaymentRow>
        )
    );
}

function PaymentUserNameRowHeader(props) {
    const { payment } = props;

    const { store } = useContext(MobXProviderContext);
    const openPaymentsDialog = (evt) => {
        evt.preventDefault();
        store.pescalice.setDisplayPaymentsDialog(true, payment);
    };

    return (
        <td colSpan="7">
            {payment.company ? (
                <>
                    <SVGObject objectId="singleCompany" className="smallTableIcon" />
                    <a href="#" onClick={openPaymentsDialog}>
                        {payment.company.name}
                    </a>
                </>
            ) : (
                <>
                    <SVGObject objectId="singleFisherman" className="smallTableIcon" />
                    <a href="#" onClick={openPaymentsDialog}>
                        {payment.user.lastName} {payment.user.firstName}&nbsp;
                        {payment.groupTotal > 1 ? ' (en nom propre)' : ''}
                    </a>
                </>
            )}
        </td>
    );
}
