import React from 'react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { formatLocaleDate } from '@SUPPORT/utils';

@inject('store')
@observer
export class LicenseInfoSubSectionPAP extends React.Component {
    @observable selectedPart = null;

    componentDidMount() {
        this._selectDefaultPart();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.season !== this.props.season && this.props.season) {
            this._selectDefaultPart();
        }
    }

    _selectDefaultPart() {
        const { season } = this.props;
        if (season && season.parts) {
            this.setSelectedPart(season.parts[0]);
        }
    }

    @action setSelectedPart(part) {
        this.selectedPart = part;
    }

    _selectPart = (evt) => {
        const part = this.props.season.partWithId(evt.target.value);
        this.setSelectedPart(part);
    };

    _displayCreateDialog = (evt) => {
        evt.preventDefault();
        this.props.store.pescalice.setDisplayNewCampaignDialog(true, this.props.season);
    };

    render() {
        if (!this.selectedPart) {
            return null;
        }

        const { session } = this.props.store;
        const canCreate = !session.info.user.hasTag('psc_ro');

        const extract = this.props.season.extracts.find(
            (extract) => extract.pescaliceIdentifier === this.props.extractId
        );

        return (
            <div className="panel styledList">
                <div className="scroll">
                    <ul className="formStyle">
                        <li>
                            <div style={{ marginBottom: 12 }}>
                                <label style={{ display: 'inline', marginRight: 12 }}>Déliberation :</label>
                                {this.props.season && (
                                    <select value={this.selectedPart.uniqueId} onChange={this._selectPart}>
                                        {this.props.season.parts.map((part) => (
                                            <option value={part.uniqueId} key={part.uniqueId}>
                                                {part.deliberationA}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <figure>
                                <form>
                                    <div>
                                        <label>Délibération cadre :</label>
                                        <input type="text" value={this.selectedPart.deliberationA} readOnly />
                                    </div>
                                    <div>
                                        <label>Date de délibération cadre :</label>
                                        <input
                                            type="text"
                                            value={formatLocaleDate(this.selectedPart.deliberationADate, 'P')}
                                            readOnly
                                        />
                                    </div>
                                    <div className="return" />
                                    <div>
                                        <label>Délibération B :</label>
                                        <input type="text" value={this.selectedPart.deliberationB} readOnly />
                                    </div>
                                    <div>
                                        <label>Date de délibération B :</label>
                                        <input
                                            type="text"
                                            value={formatLocaleDate(this.selectedPart.deliberationBDate, 'P')}
                                            readOnly
                                        />
                                    </div>
                                    <h4>Ouverture</h4>
                                    <div>
                                        <label>Du :</label>
                                        <input
                                            className="date"
                                            type="text"
                                            value={formatLocaleDate(this.selectedPart.beginDate, 'P')}
                                            readOnly
                                        />
                                    </div>
                                    <div>
                                        <label>au :</label>
                                        <input
                                            className="date"
                                            type="text"
                                            value={formatLocaleDate(this.selectedPart.endDate, 'P')}
                                            readOnly
                                        />
                                    </div>
                                    <div className="return" />
                                    <div>
                                        <label>Période de demandes du :</label>
                                        <input
                                            className="date"
                                            type="text"
                                            value={formatLocaleDate(this.selectedPart.requestBeginDate, 'P')}
                                            readOnly
                                        />
                                    </div>
                                    <div>
                                        <label>au :</label>
                                        <input
                                            className="date"
                                            type="text"
                                            value={formatLocaleDate(this.selectedPart.requestEndDate, 'P')}
                                            readOnly
                                        />
                                    </div>
                                    {extract.additionalMetadata && extract.additionalMetadata.notes && (
                                        <React.Fragment>
                                            <h4>Notes</h4>
                                            <div style={{ gridColumn: '1 / 3' }}>
                                                {extract.additionalMetadata.notes.map((note, i) => (
                                                    <p style={{ maxWidth: '100%', minHeight: 0 }} key={i}>
                                                        {note}
                                                    </p>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </form>
                            </figure>
                        </li>
                    </ul>
                </div>
                {canCreate && (
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._displayCreateDialog}>Nouvelle délibération</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
