import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@withTranslation(['admin'])
@observer
export class BoatsSelector extends React.Component {
    _update = () => {
        this.props.store.boats.listAll();
    };

    _switchToSingle = () => {
        this.props.history.push(this.props.altUrl);
    };

    _resetFilters = () => {
        this.props.store.boats.resetFilters();
    };

    _updateBoatFilter = (evt) => this.props.store.boats.updateBoatFilter(evt.target.value);
    _updateRegistrationDistrictFilter = (evt) =>
        this.props.store.boats.updateRegistrationDistrictFilter(evt.target.value);
    _updateDockingDistrictFilter = (evt) => this.props.store.boats.updateDockingDistrictFilter(evt.target.value);
    _updatePeopleFilter = (evt) => this.props.store.boats.updatePeopleFilter(evt.target.value);
    _updateCategoryFilter = (evt) => this.props.store.boats.updateCategoryFilter(evt.target.value);
    _updateYearBuiltFilter = (evt) => this.props.store.boats.updateYearBuiltFilter(evt.target.value);
    _updateYearAcquiredFilter = (evt) => this.props.store.boats.updateYearAcquiredFilter(evt.target.value);

    render() {
        const { t } = this.props;
        const { boats, session } = this.props.store;
        const { user } = session.info;

        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="list subList withToggle show">
                    <div className="toggle">
                        <span className="selected" title={t('admin:boats-selector.boat-list')}>
                            <SVGObject objectId="allBoats" />
                        </span>
                        <span
                            className="listSolo"
                            title={t('admin:boats-selector.boat-cards')}
                            onClick={this._switchToSingle}
                        >
                            <SVGObject objectId="singleBoat" />
                        </span>
                    </div>
                    <dl className="show">
                        <div className="burger">
                            <span />
                        </div>
                        <h6>{t('common:boats')}</h6>
                        <dt>
                            {t('common:filters')}
                            <SVGObject objectId="resetIco" onClick={this._resetFilters} />
                        </dt>
                        <dd className="noSelection selected">
                            <input
                                type="text"
                                placeholder={t('admin:boats-selector.boat-name')}
                                value={boats.boatFilter}
                                onChange={this._updateBoatFilter}
                            />
                        </dd>
                        <dd className="noSelection">
                            <input
                                type="text"
                                placeholder={t('admin:boats-selector.maritime district')}
                                value={boats.registrationDistrictFilter}
                                onChange={this._updateRegistrationDistrictFilter}
                            />
                        </dd>
                        <dd className="noSelection aligned">
                            <input
                                type="text"
                                placeholder={t('admin:boats-selector.port-of-landing')}
                                value={boats.dockingDistrictFilter}
                                onChange={this._updateDockingDistrictFilter}
                            />
                        </dd>
                        <dd className="noSelection">
                            <input
                                type="text"
                                placeholder={t('common:owner')}
                                value={boats.peopleFilter}
                                onChange={this._updatePeopleFilter}
                            />
                        </dd>
                        <dd className="noSelection">
                            <select value={boats.categoryFilter} onChange={this._updateCategoryFilter}>
                                <option value="">{t('admin:boats-selector.category')}...</option>
                                <option value="1">Cat 1</option>
                                <option value="2">Cat 2</option>
                                <option value="3">Cat 3</option>
                                <option value="4">Cat 4</option>
                                <option value="5">Cat 5</option>
                            </select>
                        </dd>
                        {user.type !== 'manager' && (
                            <React.Fragment>
                                <dd className="noSelection">
                                    <select value={boats.yearBuiltFilter} onChange={this._updateYearBuiltFilter}>
                                        <option value="">{t('admin:boats-selector.construction-year')}...</option>
                                        {boats.yearsBuiltList.map((year) => (
                                            <option value={year} key={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </dd>
                                <dd className="noSelection">
                                    <select value={boats.yearAcquiredFilter} onChange={this._updateYearAcquiredFilter}>
                                        <option value="">{t('admin:boats-selector.acquisition-year')}...</option>
                                        {boats.yearsAcquiredList.map((year) => (
                                            <option value={year} key={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </dd>
                            </React.Fragment>
                        )}
                    </dl>

                    <ul className="toolbar"></ul>
                </div>
            </OrgChangeObserver>
        );
    }
}
