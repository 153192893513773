import React, { useContext, useEffect, useState, useCallback } from 'react';
import { MobXProviderContext } from 'mobx-react';

export function UserAvatar({ userId, editable }) {
    const { store } = useContext(MobXProviderContext);
    const { app, users } = store;

    const [image, setImage] = useState(null);

    const refresh = useCallback(() => {
        setImage(null);
        if (userId) {
            users.listAvatarImages(userId).then((images) => {
                if (images && images.length > 0) {
                    setImage(images[0]);
                }
            });
        }
    }, [users, userId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        if (!users.showAvatarDialog) {
            refresh();
        }
    }, [users.showAvatarDialog, refresh]);

    const addImage = () => {
        users.setShowAvatarDialog();
    };

    const deleteImage = () => {
        app.alert({
            title: 'Supprimer la photo',
            message1: 'Voulez-vous vraiment supprimer cette photo ?',
            label1: 'Supprimer',

            validationCallback: () => {
                users.deleteAvatarImage(userId, image.id);
                app.dismissAlert();
                setImage(null);
            }
        });
    };

    return (
        <div className="picContainer">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 80 100">
                <image
                    xlinkHref={image ? image.url : '/graphics/silhouette@2x.png'}
                    width="80"
                    height="100"
                    clipPath="url(#clipping)"
                />
                <image xlinkHref="/graphics/shapesvgstroke.svg" width="80" height="100" />
            </svg>
            {editable && <span onClick={addImage}>Ajouter</span>}
            {editable && image && <span onClick={deleteImage}>Supprimer</span>}
        </div>
    );
}
