import React, { useCallback, useState, useContext, useEffect } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { formatLocaleDate } from '@SUPPORT/utils';
import { getRequestTypeLabel, getRequestStatusLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const LicenseContingentsSubSectionPAP = observer(({ season, extractId }) => {
    const { store } = useContext(MobXProviderContext);
    const { session, pescalice } = store;
    const canEdit = !session.info.user.hasTag('psc_ro');

    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search);

    const [natureFilter, setNatureFilter] = useState(urlParams.get('tp') || '');
    const [statusFilter, setStatusFilter] = useState(urlParams.get('st') || '');
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const isFiltered = natureFilter !== '' || statusFilter !== '';
    const resetFilters = useCallback(() => {
        setNatureFilter('');
        setStatusFilter('');
    }, []);

    const [exporting, setExporting] = useState(false);
    const exportCSV = useCallback(
        (evt) => {
            evt.preventDefault();

            setExporting(true);

            const extract = season.extracts.find((extract) => extract.pescaliceIdentifier === extractId);

            let filename = 'pap';
            filename += '_' + extract.name.replace(/\s/g, '_');
            filename += '_' + (season.referenceYear - 1) + '_' + season.referenceYear;
            filename += '_' + formatLocaleDate(Date.now(), 'dd_MM_yyyy_HH_mm');
            filename += '.csv';

            pescalice
                .downloadRequestsCSVReportPAP(filename, filteredRequests)
                .then(() => setExporting(false))
                .catch(() => setExporting(false));
        },
        [pescalice, season, extractId, filteredRequests]
    );

    const refresh = useCallback(() => {
        if (season && !pescalice.currentTodoPAP) {
            const extract = season.extracts.find((extract) => extract.pescaliceIdentifier === extractId);
            pescalice.listLicenseContingentsPAP(extract.uniqueId).then(setRequests);
        }
    }, [pescalice, season, extractId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        const filtered = requests.reduce((arr, req) => {
            if (natureFilter !== '' && !req[natureFilter]) {
                return arr;
            }

            if (statusFilter !== '' && req.globalStatus !== statusFilter) {
                return arr;
            }

            arr.push(req);
            return arr;
        }, []);

        setFilteredRequests(filtered);
    }, [requests, natureFilter, statusFilter]);

    useEffect(() => {
        const urlParams = new URLSearchParams();
        if (natureFilter !== '') {
            urlParams.append('tp', natureFilter);
        }
        if (statusFilter !== '') {
            urlParams.append('st', statusFilter);
        }

        history.push({ search: urlParams.toString() });
    }, [natureFilter, statusFilter, history]);

    return (
        <div className="panel">
            <div className="scroll">
                <div className="toolkit">
                    <select value={natureFilter} onChange={(evt) => setNatureFilter(evt.target.value)}>
                        <option value="">Toutes les natures</option>
                        <option value="isExtractRenewal">Renouvellement</option>
                        <option value="isFirstInstallation">1ère installation</option>
                        <option value="isNewExtractRequest">Nouvelle demande</option>
                    </select>
                    <select value={statusFilter} onChange={(evt) => setStatusFilter(evt.target.value)}>
                        <option value="">Tous les statuts</option>
                        <option value="kPSLicenseRequestGlobalStatusInitial">Demandées</option>
                        <option value="kPSLicenseRequestGlobalStatusFrozen">En attente</option>
                        <option value="kPSLicenseRequestGlobalStatusSuspended">Incomplètes</option>
                        <option value="kPSLicenseRequestGlobalStatusReserved">Mises en réserve</option>
                        <option value="kPSLicenseRequestGlobalStatusAllowed">Attribuées</option>
                        <option value="kPSLicenseRequestGlobalStatusRefused">Refusées</option>
                        <option value="kPSLicenseRequestGlobalStatusCancelled">Annulées</option>
                    </select>
                    {isFiltered && <SVGObject objectId="resetIco" onClick={resetFilters} />}
                    <button
                        style={{ marginLeft: 'auto', alignSelf: 'flex-end' }}
                        onClick={exportCSV}
                        disabled={filteredRequests.length === 0 || exporting}
                    >
                        Exporter CSV
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Date de dépôt</th>
                            <th>Demandeur</th>
                            <th>Navire</th>
                            <th>Nature</th>
                            <th>Statut</th>
                            {canEdit && <th />}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredRequests.map((request) => (
                            <ContingentRow
                                request={request}
                                canEdit={canEdit}
                                afterDelete={refresh}
                                key={request.uniqueId}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
});

function ContingentRow({ request, canEdit, afterDelete }) {
    const { store } = useContext(MobXProviderContext);
    const { pescalice, users } = store;

    const history = useHistory();

    const openRequester = (evt) => {
        evt.preventDefault();
        const userId = request.licenseRequest.requestAuthor;
        history.push(`/pescalice/user/${userId}/info`);
    };

    // const openBoat = (evt) => {
    //     evt.preventDefault();
    //     const boatId = request.boat.telecapecheId;
    //     history.push(`/pescalice/boat/${boatId}/info`);
    // };

    const edit = useCallback(
        (evt) => {
            evt.preventDefault();
            if (evt.shiftKey) {
                const ok = confirm('Voulez-vous vraiment supprimer cette demande ?');
                if (ok) {
                    pescalice.deleteLicenseRequestPAP(request.licenseRequest.uniqueId).then(afterDelete);
                }
            } else {
                pescalice.setDisplayNewRequestDialogPAP(true, request.licenseRequest.uniqueId);
            }
        },
        [pescalice, request, afterDelete]
    );

    const requester = users.userWithId(request.licenseRequest.requestAuthor);

    return (
        <tr>
            <td>
                <span>Date de dépôt:</span>
                {formatLocaleDate(request.depositDate, 'PP')}
            </td>
            <td>
                <span>Demandeur :</span>
                <a href="#" onClick={openRequester}>
                    {requester ? requester.fullName(false) : '?'}
                    {requester && requester.companyTelecapecheId
                        ? ` (${requester ? requester.fullCompanyName() : '?'})`
                        : ''}
                </a>
            </td>
            <td>
                <span>Navire :</span>
                {/* <a
                    href="#"
                    onClick={openBoat}
                >{`${request.boat.registrationDistrictCode} ${request.boat.registration}`}</a> */}
            </td>
            <td>
                <span>Nature :</span>
                {getRequestTypeLabel(request)}
                {request.licenseRequest.isUserDeposited ? (
                    <SVGObject
                        objectId="singleFisherman"
                        style={{ fill: 'blue', width: 12, opacity: 0.15, height: 10, marginLeft: 10 }}
                    />
                ) : null}
            </td>
            <td className={getRequestStatusColorCode(request.globalStatus)}>
                <span>Statut :</span>
                {getRequestStatusLabel(request.globalStatus)}
            </td>
            {canEdit && (
                <td>
                    <span />
                    {request.globalStatus !== 'kPSLicenseRequestGlobalStatusCancelled' && (
                        <a href="#" onClick={edit}>
                            Éditer
                        </a>
                    )}
                </td>
            )}
        </tr>
    );
}
