import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MobXProviderContext } from 'mobx-react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';

export const LicenseSituationSubSectionPAP = ({ season, extractId }) => {
    const { t } = useTranslation(['pescalice']);
    const [globalQuota, setGlobalQuota] = useState({});
    const [contingents, setContingents] = useState({});

    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    useEffect(() => {
        if (season) {
            const extract = season.extracts.find((extract) => extract.pescaliceIdentifier === extractId);
            pescalice.fetchExtractQuotaDashBoardPAP(extract.uniqueId).then((data) => {
                setGlobalQuota(data.globalQuota || {});
                setContingents(data.statuses || {});
            });
        }
    }, [pescalice, season, extractId]);

    return (
        <div className="panel styledList">
            <div className="scroll">
                <h2 className="subtitle">
                    Contingent global :&nbsp;
                    {globalQuota.initialSlotsCount === -1 ? (
                        t('pescalice:x-used', { count: globalQuota.inUseSlotsCount || 0 })
                    ) : (
                        <React.Fragment>
                            {t('pescalice:x-slot', { count: globalQuota.initialSlotsCount || 0 })} (
                            {t('pescalice:x-used', { count: globalQuota.inUseSlotsCount || 0 })} -{' '}
                            {t('pescalice:x-available', { count: globalQuota.remainingSlotsCount || 0 })})
                        </React.Fragment>
                    )}
                </h2>
                <ul className="listStyle">
                    <QuotaSection title="Demandées" status="*" contingents={contingents['*']} />
                    <QuotaSection
                        title="Attribuées"
                        className="codeGreen"
                        status="kPSLicenseRequestGlobalStatusAllowed"
                        contingents={contingents.kPSLicenseRequestGlobalStatusAllowed}
                    />
                    <QuotaSection
                        title="Incomplètes"
                        className="codeOrange"
                        status="kPSLicenseRequestGlobalStatusSuspended"
                        contingents={contingents.kPSLicenseRequestGlobalStatusSuspended}
                    />
                    <QuotaSection
                        title="Mises en réserve"
                        className="codeOcre"
                        status="kPSLicenseRequestGlobalStatusReserved"
                        contingents={contingents.kPSLicenseRequestGlobalStatusReserved}
                    />
                    <QuotaSection
                        title="Refusées"
                        className="codeRed"
                        status="kPSLicenseRequestGlobalStatusRefused"
                        contingents={contingents.kPSLicenseRequestGlobalStatusRefused}
                    />
                    <QuotaSection
                        title="Annulées"
                        className="codeRed"
                        status="kPSLicenseRequestGlobalStatusCancelled"
                        contingents={contingents.kPSLicenseRequestGlobalStatusCancelled}
                    />
                </ul>
            </div>
        </div>
    );
};

const QuotaSection = ({ title, className, status, contingents }) => {
    const { t } = useTranslation(['pescalice']);
    if (!contingents) {
        return null;
    }

    return (
        <li className={className}>
            <header>
                <h2>
                    {title} ({t('pescalice:x-request', { count: contingents.count })})
                </h2>
            </header>
            <figure>
                <Contingent
                    status={status}
                    tp="isExtractRenewal"
                    title="Renouvellements"
                    contingent={contingents['Renouvellement']}
                />
                <Contingent
                    status={status}
                    tp="isNewExtractRequest"
                    title="Nouvelles Demandes"
                    contingent={contingents['Nouvelle Demande']}
                />
                <Contingent
                    status={status}
                    tp="isFirstInstallation"
                    title="1ères Installations"
                    contingent={contingents['1ère Installation']}
                />
            </figure>
        </li>
    );
};

const Contingent = ({ title, contingent, status, tp }) => {
    const history = useHistory();
    const location = useLocation();

    const jumpToRequests = useCallback(
        (evt) => {
            evt.preventDefault();

            const match = matchPath(location.pathname, { path: `/pescalice/license-pap/:id` });
            if (match) {
                if (status === '*') {
                    history.push(`/pescalice/license-pap/${match.params.id}/requests`);
                } else {
                    history.push(`/pescalice/license-pap/${match.params.id}/requests?tp=${tp}&st=${status}`);
                }
            }
        },
        [history, location.pathname, tp, status]
    );

    const { t } = useTranslation(['pescalice']);
    if (!contingent) {
        return null;
    }

    return (
        <div>
            <h3>{title}</h3>
            <p>
                <a href="#" onClick={jumpToRequests}>
                    <b>{contingent.count}</b> {t('pescalice:request', { count: contingent.count })}
                </a>
            </p>
        </div>
    );
};
