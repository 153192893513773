import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { observable, action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import { validator } from '@SUPPORT/validator';
import { enhanceDateField } from '@SUPPORT/form';
import { formatLocaleDate, formatPhoneNumber, formatPhoneNumberForStorage } from '@SUPPORT/utils';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class UserInfoSubSection extends React.Component {
    @observable form = null;
    @observable externalOrgInfo = null;
    @observable _error = null;

    @computed get errorMessage() {
        switch (this._error) {
            case 'duplicate_identifier1':
            case 'duplicate_identifier2':
            case 'duplicate_identifier3':
                return 'Une personne avec un identifiant similaire existe déjà';
            case 'unknown':
                return 'Erreur inconnue, données non sauvegardées';
            default:
                return null;
        }
    }

    @action _setError(error) {
        this._error = error;
    }

    get _countryCode() {
        return (
            this.props.user.countryCode ||
            (this.props.store.session.info.org && this.props.store.session.info.org.countryCode) ||
            'fr'
        ).toUpperCase();
    }

    _inputPhoneNumber(value) {
        return formatPhoneNumber(value, 'National', this._countryCode);
    }

    _updateForm(values = {}) {
        const readOnly =
            this.props.user.isFromAnotherOrg ||
            !this.props.store.session.info.user.isNotRestricted ||
            this.props.store.session.info.user.hasTag('psc_ro');
        const fields = {
            title: { value: values.title },
            firstName: { type: 'text', rules: 'required|string', value: values.firstName || '' },
            firstName2: { type: 'text', rules: 'string', value: values.firstName2 || '' },
            lastName: { type: 'text', rules: 'required|string', value: values.lastName || '' },
            birthDate: {
                type: 'text',
                rules: 'fdate',
                placeholder: readOnly ? '' : 'jj/mm/aaaa',
                value: values.birthDate ? formatLocaleDate(values.birthDate, 'P') : ''
            },
            address1: { type: 'text', rules: 'string', value: values.address1 || '' },
            address2: { type: 'text', rules: 'string', value: values.address2 || '' },
            zipCode: { type: 'text', rules: 'string', value: values.zipCode || '' },
            city: { type: 'text', rules: 'string', value: values.city || '' },
            status: { type: 'select', rules: 'required', value: values.status || '' },
            statusPSC: { type: 'select', rules: 'required', value: values.statusPSC || '' },
            boatName: { type: 'text', value: values.boatName || '' },
            boatRegistration: { type: 'text', value: values.boatRegistration || '' },
            identifier: { type: 'text', rules: 'required|string', value: values.identifier || '' },
            identifier2: { type: 'text', rules: 'string', value: values.identifier2 || '' },
            identifier3: { type: 'text', rules: 'string', value: values.identifier3 || '' },
            uniqueIdentifier: { type: 'text', rules: 'string', value: values.uniqueIdentifier || '' },
            licenseNum: { type: 'text', value: values.licenseNum || '' },
            socialRegime: {
                type: 'text',
                value: values.socialRegime || '',
                rules: 'requiredIfPAPRequester',
                hooks: {
                    onChange: () => setTimeout(() => this.form.select('papRequester').validate(), 0)
                }
            },
            papRequester: {
                type: 'checkbox',
                value: (values.tags || []).includes('psc_pap'),
                rules: 'requiresSocialRegime',
                hooks: {
                    onChange: () => setTimeout(() => this.form.select('socialRegime').validate(), 0)
                }
            },
            email: { type: 'text', rules: 'email|string', value: values.email || '' },
            mobileNum1: { type: 'text', rules: 'string', value: this._inputPhoneNumber(values.mobileNum1 || '') },
            mobileNum2: { type: 'text', rules: 'string', value: this._inputPhoneNumber(values.mobileNum2 || '') },
            phoneNum: { type: 'text', rules: 'string', value: this._inputPhoneNumber(values.phoneNum || '') },

            password: {
                type: 'password',
                rules: 'required_with:passwordConfirmation|string|min:4'
            },
            passwordConfirmation: {
                type: 'password',
                rules: 'required_with:password|string|min:4|same:password'
            }
        };

        const bankInfos = values.bankInfos ? JSON.parse(values.bankInfos) : [{}];
        if (bankInfos.length === 0) {
            bankInfos.push({});
        }
        bankInfos.forEach(
            (info) =>
                (fields[`bankOk-${info.company_id || 'perso'}`] = {
                    type: 'checkbox',
                    value: !!info.signature_date
                })
        );

        if (this.props.store.session.info.user.hasTag('pap')) {
            const customData = JSON.parse(values.customData || '{"pap":{}}');
            fields.permitDate = { type: 'text', rules: 'fdate', value: (customData.pap || {}).permitDate || '' };
            fields.permitStatus = { type: 'text', rules: 'string', value: (customData.pap || {}).permitStatus || '' };
            fields.permitDDTM = { type: 'text', rules: 'string', value: (customData.pap || {}).permitDDTM || '' };
        }

        this.form = new ReactForm(
            { fields },
            {
                plugins: { dvr: validator },
                options: {
                    validateOnChange: true,
                    validationDebounceOptions: { leading: true, trailing: true }
                }
            }
        );

        enhanceDateField(this.form.select('birthDate'));
        this._setError(null);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this._update();
        }
    }

    _update = () => {
        let promise;
        if (this.props.user.isFromAnotherOrg) {
            this.externalOrgInfo = null;
            promise = this.props.store.users
                .getUserOrgInfo(this.props.user.orgId)
                .then((info) => (this.externalOrgInfo = info));
        } else {
            promise = Promise.resolve();
        }

        promise.then(() => this._updateForm(this.props.user));
    };

    _reset = (evt) => {
        evt.preventDefault();
        this.form.reset();
    };

    _save = (evt) => {
        evt.preventDefault();

        const outputPhoneNumber = (value) => formatPhoneNumberForStorage(value, this._countryCode);
        const values = this.form.values();

        values.mobileNum1 = outputPhoneNumber(values.mobileNum1);
        values.mobileNum2 = outputPhoneNumber(values.mobileNum2);
        values.phoneNum = outputPhoneNumber(values.phoneNum);

        if (values.birthDate) {
            const dob = new Date(values.birthDate.replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3-$2-$1'));
            values.birthDate = dob.toISOString();
        }

        if (this.props.store.session.info.user.hasTag('pap')) {
            values.customData = JSON.stringify({
                pap: {
                    permitDate: values.permitDate || '',
                    permitStatus: values.permitStatus || '',
                    permitDDTM: values.permitDDTM || ''
                }
            });
        }

        values.tags = (this.props.user.tags || []).slice();
        if (values.papRequester && !values.tags.includes('psc_pap')) {
            values.tags.push('psc_pap');
        } else if (!values.papRequester && values.tags.includes('psc_pap')) {
            values.tags = values.tags.filter((tag) => tag !== 'psc_pap');
        }

        this.props.store.users
            .updateUserInfo(this.props.user.id, values, true)
            .then((data) => this._updateForm(data))
            .catch((err) => this._setError(err.wrapped ? err.wrapped.errorCode : 'unknown'));
    };

    render() {
        const { t, store } = this.props;
        const sessionUser = store.session.info.user;
        const canEdit = !sessionUser.hasTag('psc_ro');
        const readOnly = this.props.user.isFromAnotherOrg || !sessionUser.isNotRestricted || !canEdit;

        const bankInfos = this.props.user.bankInfos
            ? JSON.parse(this.props.user.bankInfos)
            : [{ iban: '', bic: '', agency: '' }];

        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="panel styledList">
                    <div className="scroll">
                        {this.props.user.isFromAnotherOrg ? (
                            <ul>
                                <li className="codeOrange">
                                    <header>
                                        <h2>
                                            {this.externalOrgInfo ? (
                                                <b>
                                                    {this.externalOrgInfo.name} ({this.externalOrgInfo.code})
                                                </b>
                                            ) : null}
                                        </h2>
                                    </header>
                                    <figure>
                                        <span className="whenWhere">
                                            <p>
                                                {this.externalOrgInfo
                                                    ? t('admin:x-admins', {
                                                          count: this.externalOrgInfo.admins.length
                                                      })
                                                    : null}
                                            </p>
                                            <p />
                                        </span>
                                        <span className="what">
                                            <ul className="table threeCols">
                                                {this.externalOrgInfo
                                                    ? this.externalOrgInfo.admins.map((admin) => (
                                                          <li key={admin.id}>
                                                              <p>
                                                                  {admin.firstName} {admin.lastName}
                                                              </p>
                                                              <p>
                                                                  <a href={`mailto:${admin.email}`}>{admin.email}</a>
                                                              </p>
                                                              <p>
                                                                  <a
                                                                      href={formatPhoneNumber(
                                                                          admin.mobileNum1,
                                                                          'RFC3966'
                                                                      )}
                                                                  >
                                                                      {formatPhoneNumber(admin.mobileNum1)}
                                                                  </a>
                                                              </p>
                                                          </li>
                                                      ))
                                                    : null}
                                            </ul>
                                        </span>
                                    </figure>
                                </li>
                            </ul>
                        ) : null}

                        {this.form && (
                            <form>
                                <div style={{ gridColumn: '1 / -1' }}>
                                    <label>Civilité :</label>
                                    <select {...this.form.select('title').bind()} readOnly={readOnly}>
                                        <option value="">Non attribuée</option>
                                        <option value="m">Monsieur</option>
                                        <option value="mme">Madame</option>
                                    </select>
                                </div>
                                <div className="required">
                                    <label>{t('common:name')} :</label>
                                    <input {...this.form.select('lastName').bind()} readOnly={readOnly} />
                                </div>
                                <div className="required">
                                    <label>{t('common:first-name')} :</label>
                                    <input {...this.form.select('firstName').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>{t('common:second-first-name')} :</label>
                                    <input {...this.form.select('firstName2').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>{t('common:date-of-birth')} :</label>
                                    <input
                                        {...this.form.select('birthDate').bind()}
                                        className={classNames('date', {
                                            codeRed:
                                                this.form.select('birthDate').isDirty &&
                                                !this.form.select('birthDate').isValid
                                        })}
                                        readOnly={readOnly}
                                    />
                                </div>
                                {(this.props.store.session.info.user.hasTag('pap') ||
                                    sessionUser.type === 'manager') && (
                                    <React.Fragment>
                                        <div>
                                            <label>{t('common:social-regime')} :</label>
                                            {this.props.store.session.info.user.hasOnlyTag('pap') ||
                                            sessionUser.type === 'manager' ? (
                                                <select
                                                    {...this.form.select('socialRegime').bind()}
                                                    disabled={readOnly}
                                                >
                                                    <option value="">-</option>
                                                    <option value="MSA">MSA</option>
                                                    <option value="ENIM">ENIM</option>
                                                </select>
                                            ) : (
                                                <input
                                                    {...this.form.select('socialRegime').bind()}
                                                    readOnly={readOnly}
                                                />
                                            )}
                                        </div>
                                        <div style={{ alignSelf: 'flex-end' }}>
                                            <input {...this.form.select('papRequester').bind()} disabled={readOnly} />
                                            <label htmlFor={this.form.select('papRequester').id}>Demandeur PAP</label>
                                        </div>
                                        <div>
                                            <label>
                                                {sessionUser.type === 'manager'
                                                    ? 'Numero de permis PAP'
                                                    : t('common:unique-identifier')}{' '}
                                                :
                                            </label>
                                            <input
                                                {...this.form.select('uniqueIdentifier').bind()}
                                                readOnly={!this.form.select('papRequester').value || readOnly}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                                {sessionUser.type === 'manager' &&
                                    bankInfos
                                        .filter((info) => info.company_id === null)
                                        .map((userBankInfos, i) => (
                                            <React.Fragment key={i}>
                                                <h4>Informations bancaires</h4>
                                                <div>
                                                    <label>IBAN :</label>
                                                    <input
                                                        type="text"
                                                        placeholder="FR00 0000 0000 0000 0000 0000 000"
                                                        defaultValue={userBankInfos ? userBankInfos.iban : ''}
                                                        readOnly
                                                    />
                                                </div>
                                                <div>
                                                    <label>Code BIC (ou SWIFT) :</label>
                                                    <input
                                                        type="text"
                                                        placeholder="AAAA-BB-CC-123"
                                                        defaultValue={userBankInfos ? userBankInfos.bic : ''}
                                                        readOnly
                                                    />
                                                </div>
                                                <div>
                                                    <label>Agence de domiciliation :</label>
                                                    <input
                                                        type="text"
                                                        defaultValue={userBankInfos ? userBankInfos.agency : ''}
                                                        readOnly
                                                    />
                                                </div>
                                                <div style={{ alignSelf: 'flex-end' }}>
                                                    <label>Autorisation papier :</label>
                                                    <input
                                                        {...this.form
                                                            .select(`bankOk-${userBankInfos.company_id || 'perso'}`)
                                                            .bind()}
                                                        disabled={readOnly}
                                                    />
                                                    <label>Signature reçue</label>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                <h4>{t('common:address')}</h4>
                                <div>
                                    <label>{t('common:address')} 1:</label>
                                    <input {...this.form.select('address1').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>{t('common:address')} 2:</label>
                                    <input {...this.form.select('address2').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>{t('common:zipcode')} :</label>
                                    <input {...this.form.select('zipCode').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>{t('common:city')} :</label>
                                    <input {...this.form.select('city').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>{t('common:mobile-number')} 1:</label>
                                    <input {...this.form.select('mobileNum1').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>{t('common:mobile-number')} 2:</label>
                                    <input {...this.form.select('mobileNum2').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>{t('common:phone-number')} :</label>
                                    <input {...this.form.select('phoneNum').bind()} readOnly={readOnly} />
                                </div>
                                {sessionUser.type !== 'manager' && (
                                    <React.Fragment>
                                        <h4>{t('common:boat')}</h4>
                                        <div>
                                            <label>{t('common:name')} :</label>
                                            <input {...this.form.select('boatName').bind()} readOnly={readOnly} />
                                        </div>
                                        <div>
                                            <label>{t('common:registration-num')} :</label>
                                            <input
                                                {...this.form.select('boatRegistration').bind()}
                                                readOnly={readOnly}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                                <h4>{t('common:status')}</h4>
                                <div>
                                    <label>
                                        {sessionUser.type === 'manager'
                                            ? 'Statut du demandeur'
                                            : t('common:fisherman-status')}{' '}
                                        :
                                    </label>
                                    <select
                                        {...this.form
                                            .select(sessionUser.type === 'manager' ? 'statusPSC' : 'status')
                                            .bind()}
                                        disabled={readOnly}
                                    >
                                        <option value="active">{t('common:user-status.active')}</option>
                                        <option value="inactive">{t('common:user-status.suspended')}</option>
                                        <option value="archived">{t('common:user-status.terminated')}</option>
                                    </select>
                                </div>
                                {!readOnly && (
                                    <div>
                                        <label>{t('common:cause')} :</label>
                                        <select disabled="disabled">
                                            <option>-</option>
                                            <option>{t('common:user-cause.illness')}</option>
                                            <option>{t('common:user-cause.on-hold')}</option>
                                            <option>{t('common:user-cause.no-fishing')}</option>
                                            <option>{t('common:user-cause.fishing-closed')}</option>
                                            <option>{t('common:user-cause.license-refused')}</option>
                                            <option>{t('common:user-cause.other')}</option>
                                        </select>
                                    </div>
                                )}
                                {this.props.store.session.info.user.hasTag('pap') && (
                                    <React.Fragment>
                                        <h4>Permis PAP</h4>
                                        <div>
                                            <label>{t('common:date')} :</label>
                                            <input
                                                {...this.form.select('permitDate').bind()}
                                                readOnly={readOnly}
                                                className="date"
                                            />
                                        </div>
                                        <div>
                                            <label>{t('common:status')} :</label>
                                            <input {...this.form.select('permitStatus').bind()} readOnly={readOnly} />
                                        </div>
                                        <div>
                                            <label>DDTM ayant délivré le permis :</label>
                                            <input {...this.form.select('permitDDTM').bind()} readOnly={readOnly} />
                                        </div>
                                    </React.Fragment>
                                )}
                                <h4>{t('common:account')}</h4>
                                <div className="required">
                                    <label>{t('common:identifier')} :</label>
                                    <input
                                        {...this.form.select('identifier').bind()}
                                        readOnly={readOnly}
                                        className={classNames({ codeRed: this._error === 'duplicate_identifier1' })}
                                    />
                                </div>
                                <div>
                                    <label>{t('common:identifier')} 2 :</label>
                                    <input
                                        {...this.form.select('identifier2').bind()}
                                        readOnly={readOnly}
                                        className={classNames({ codeRed: this._error === 'duplicate_identifier2' })}
                                    />
                                </div>
                                <div>
                                    <label>{t('common:identifier')} 3 :</label>
                                    <input
                                        {...this.form.select('identifier3').bind()}
                                        readOnly={readOnly}
                                        className={classNames({ codeRed: this._error === 'duplicate_identifier3' })}
                                    />
                                </div>
                                <div>
                                    <label>{t('common:email')} :</label>
                                    <input {...this.form.select('email').bind()} readOnly={readOnly} />
                                </div>
                                <div>
                                    <label>
                                        {sessionUser.type === 'manager' && sessionUser.hasTag('psc')
                                            ? 'Numéro de Marin'
                                            : t('common:license-number')}{' '}
                                        :
                                    </label>
                                    <input {...this.form.select('licenseNum').bind()} readOnly={readOnly} />
                                </div>

                                {readOnly ? null : (
                                    <React.Fragment>
                                        <h4>{t('common:new-password')}</h4>
                                        <div
                                            className={
                                                this.form.select('passwordConfirmation').value ? 'required' : null
                                            }
                                        >
                                            <label>{t('common:password')} :</label>
                                            <input {...this.form.select('password').bind()} />
                                        </div>
                                        <div
                                            className={this.form.select('password').value ? 'required' : null}
                                            disabled={!this.form.select('password').value}
                                        >
                                            <label>
                                                {t('common:password')} ({t('common:confirmation')}) :
                                            </label>
                                            <input {...this.form.select('passwordConfirmation').bind()} />
                                        </div>
                                    </React.Fragment>
                                )}
                            </form>
                        )}
                    </div>
                    {canEdit && (
                        <div className="exit">
                            <div>
                                <button className="codeOrange not-implemented">{t('common:access-account')}</button>
                                <span style={{ color: 'red' }}>{this.errorMessage}</span>
                            </div>
                            <div>
                                <button
                                    disabled={
                                        (this.form && !this.form.isDirty) || (this.form && this.props.store.app.isBusy)
                                    }
                                    onClick={this._reset}
                                >
                                    {t('common:reset')}
                                </button>
                                <button
                                    disabled={
                                        (this.form && !this.form.isDirty) ||
                                        (this.form && !this.form.isValid) ||
                                        this.props.store.app.isBusy
                                    }
                                    onClick={this._save}
                                >
                                    {t('common:save')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </OrgChangeObserver>
        );
    }
}
