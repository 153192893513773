import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { withRouter } from 'react-router';
import dvr from 'mobx-react-form/lib/validators/DVR';
import { observable, action, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { rawValidator } from '@SUPPORT/validator';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class NewBoatDialog extends React.Component {
    @observable busy = false;
    @observable error = null;

    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    registrationDistrict: { rules: 'required' },
                    registration: { type: 'text', rules: 'required|validRegistration' },
                    name: { type: 'text', rules: 'required' }
                }
            },

            {
                plugins: {
                    dvr: dvr({
                        package: rawValidator,
                        extend: ({ validator }) => {
                            validator.register(
                                'validRegistration',
                                (value) => props.store.boats.isValidRegistrationNumber(value),
                                ''
                            );
                        }
                    })
                },
                options: { validateOnChange: true }
            }
        );

        reaction(
            () => this.props.store.boats && this.props.store.boats.showNewBoatDialog,
            (show) => {
                if (show) {
                    const initialRegistrationDistrict = this.props.store.boats.registrationDistricts[0];
                    this.form.select('registrationDistrict').set('value', initialRegistrationDistrict.id);
                }
            }
        );
    }

    @action.bound
    setBusy(busy = true) {
        this.busy = busy;
    }

    @action.bound
    setError(error = null) {
        this.error = error;
    }

    _create = (evt) => {
        evt.preventDefault();

        const data = this.form.values();
        data.registrationDistrict = parseInt(data.registrationDistrict, 10);
        data.status = 'active';

        this.setBusy();
        this.props.store.boats
            .createBoat(data)
            .then((boat) => {
                this._dismiss();
                this.setError();
                this.setBusy(false);

                if (boat) {
                    const { history } = this.props;
                    history.push(`${this.props.baseUrl}/boat/${boat.id}`);
                }
            })
            .catch((err) => {
                if (err.wrapped.status === 'error') {
                    if (err.wrapped.errorMessage === 'registration value already exists') {
                        this.setError('Un navire avec cette même immatriculation existe déjà');
                    } else {
                        this.setError('Erreur inconnue');
                    }
                }
                this.setBusy(false);
            });
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
        this.setError();
    };

    _dismiss = () => {
        this.props.store.boats.setShowNewBoatDialog(false);
        this.form.clear();
    };

    render() {
        if (!this.props.store.boats) {
            return null;
        }

        const { t } = this.props;
        return (
            <div className={classNames('modal', { show: this.props.store.boats.showNewBoatDialog })}>
                <div className="sheet">
                    <div className="title">
                        <h3>{t('admin:new-boat-dialog.new-boat')}</h3>
                        <SVGObject objectId="exitCross" onClick={this._cancel} />
                    </div>
                    <div className="scroll">
                        <form>
                            <div className="required">
                                <label>{t('admin:new-boat-dialog.maritime-district')} :</label>
                                <select
                                    {...this.form.select('registrationDistrict').bind()}
                                    style={{ marginRight: 'inherit' }}
                                >
                                    {this.props.store.boats.registrationDistricts.map((district) => (
                                        <option value={district.id} key={district.id}>
                                            {district.code} - {district.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="required">
                                <label>{t('admin:new-boat-dialog.registration-number')} :</label>
                                <input {...this.form.select('registration').bind()} />
                            </div>
                            <div className="required">
                                <label>{t('common:name')} :</label>
                                <input {...this.form.select('name').bind()} />
                            </div>
                            {this.error && <div style={{ color: 'red', justifyContent: 'flex-end' }}>{this.error}</div>}
                        </form>
                    </div>
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._cancel}>{t('common:cancel')}</button>
                            <button onClick={this._create} disabled={!this.form.isValid || this.busy}>
                                {t('common:create')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
