/* eslint-disable require-atomic-updates */
import { types, getRoot, flow, applySnapshot } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';
import { safeRegExp } from '@SUPPORT/utils';

export const Company = types
    .model('Company', {
        id: types.identifierNumber,
        name: types.string,
        type: types.maybeNull(types.string),
        countryCode: types.string,
        address1: types.string,
        address2: types.maybeNull(types.string),
        phoneNum: types.maybeNull(types.string),
        zipCode: types.string,
        city: types.string,
        email: types.maybeNull(types.string),
        registration: types.string,
        registration2: types.maybeNull(types.string),

        mainUserId: types.maybeNull(types.number),
        mainUserFirstName: types.maybeNull(types.string),
        mainUserLastName: types.maybeNull(types.string),

        membersCount: types.number
    })
    .views((self) => {
        return {
            get mainUserFullName() {
                if (self.mainUserFirstName && !self.mainUserLastName) {
                    return self.mainUserFirstName;
                } else if (self.mainUserLastName && !self.mainUserFirstName) {
                    return self.mainUserLastName;
                } else if (self.mainUserFirstName && self.mainUserLastName) {
                    return `${self.mainUserFirstName} ${self.mainUserLastName}`;
                }

                return '';
            }
        };
    })
    .actions((self) => {
        return {
            updateWithData: flow(function* (data) {
                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const response = yield api.updateCompany(self.id, data);
                    applySnapshot(self, response.data);
                    return self;
                } catch (err) {
                    console.log(err);
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });

const CompanyList = types.array(Company);

const CompanyCollaborator = types
    .model('Collaborator', {
        userId: types.identifierNumber,
        lastName: types.string,
        firstName: types.string,
        isMainPerson: types.boolean
    })
    .views((self) => {
        return {
            get fullName() {
                return self.firstName + ' ' + self.lastName;
            }
        };
    });

const CollaboratorList = types.array(CompanyCollaborator);

export const CompaniesStore = types
    .model('Companies', {
        list: CompanyList,
        partialList: true,
        fetchingList: false,

        showNewCompanyDialog: false,
        showNewCompanyMemberDialog: false,

        nameFilter: types.maybeNull(types.string),
        typeFilter: types.maybeNull(types.string),
        mainPersonFilter: types.maybeNull(types.string)
    })
    .views((self) => {
        return {
            get filteredList() {
                return self.list.filter((company) => {
                    if (
                        self.nameFilter &&
                        !company.name.match(safeRegExp(self.nameFilter, 'i')) &&
                        !company.registration.match(safeRegExp(self.nameFilter, 'i'))
                    ) {
                        return false;
                    }

                    if (
                        self.mainPersonFilter &&
                        !company.mainUserFullName.match(safeRegExp(self.mainPersonFilter, 'i'))
                    ) {
                        return false;
                    }

                    if (self.typeFilter && company.type !== self.typeFilter) {
                        return false;
                    }

                    return true;
                });
            },

            companyWithId(id) {
                return self.list.find((company) => company.id === id);
            },

            withPerson(personId) {
                return self.list.filter((company) => company.mainUserId === personId);
            }
        };
    })
    .actions((self) => {
        return {
            listAll: flow(function* (force = false) {
                if ((self.fetchingList || (self.list && self.list.length > 0 && !force)) && !self.partialList) {
                    return;
                }

                const { app } = getRoot(self);
                app.setBusy();
                self.fetchingList = true;

                try {
                    const response = yield api.listCompanies();
                    self.list = response.data ? CompanyList.create(response.data) : [];
                    self.partialList = false;
                } finally {
                    app.setBusy(false);
                    self.fetchingList = false;
                }
            }),

            fetchCompany: flow(function* (id) {
                if (!id) {
                    return null;
                }

                const company = self.companyWithId(id);
                if (company) {
                    return company;
                }

                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const response = yield api.fetchCompany(id);
                    const company = response.data ? Company.create(response.data) : null;
                    if (company) {
                        self.list.push(company);
                        self.partialList = true;
                    }
                    return company;
                } finally {
                    app.setBusy(false);
                }
            }),

            listCompanyMembers: flow(function* (companyId) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.listCompanyMembers(companyId);
                    return CollaboratorList.create(response.data || []);
                } catch (err) {
                    console.log(err);
                    return [];
                } finally {
                    app.setBusy(false);
                }
            }),

            createCompany: flow(function* (data) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.createCompany(data);
                    const company = Company.create(response.data);
                    self.list.push(company);
                    return company;
                } catch (err) {
                    console.log(err);
                    return null;
                } finally {
                    app.setBusy(false);
                }
            }),

            updateCompanyMembers: flow(function* (companyId, members) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    yield api.updateCompanyMembers(companyId, members);

                    const company = self.companyWithId(companyId);
                    if (company) {
                        const response = yield api.fetchCompany(companyId);
                        if (response.data) {
                            applySnapshot(company, response.data);
                        }
                    }
                } catch (err) {
                    console.log(err);
                    return null;
                } finally {
                    app.setBusy(false);
                }
            }),

            resetFilters: () => {
                self.nameFilter = null;
                self.typeFilter = null;
                self.mainPersonFilter = null;
            },

            filterByName: (name) => {
                self.nameFilter = name;
            },

            filterByType: (type) => {
                self.typeFilter = type;
            },

            filterByMainPerson: (person) => {
                self.mainPersonFilter = person;
            },

            setShowNewCompanyDialog: (show = true) => {
                const { app } = getRoot(self);
                app.setModal(show);
                self.showNewCompanyDialog = show;
            },

            setShowNewCompanyMemberDialog: (show = true, company = null, members = null, after = null) => {
                const { app } = getRoot(self);
                app.setModal(show, { company, members, after });
                self.showNewCompanyMemberDialog = show;
            }
        };
    });
