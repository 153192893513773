import React, { useContext, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { observer, MobXProviderContext } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const UserPicDialog = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { users } = store;

    const match = useRouteMatch('*/user/:id');
    const userId = match ? match.params.id : 0;

    const [file, setFile] = useState();
    const [saving, setSaving] = useState(false);
    const input = useRef();
    const img = useRef();
    const fileUrl = useRef();
    const cropper = useRef();

    useEffect(() => {
        setSaving(false);
        setFile(null);
        URL.revokeObjectURL(fileUrl.current);
        fileUrl.current = null;
    }, [users.showAvatarDialog]);

    useEffect(() => {
        if (cropper.current) {
            cropper.current.destroy();
            cropper.current = null;
        }

        if (file) {
            cropper.current = new Cropper(img.current, {
                viewMode: 2,
                aspectRatio: 0.8,
                guides: true
            });
        }
    }, [file]);

    const selectPicFile = (evt) => {
        evt.preventDefault();
        input.current.click();
    };

    const handleSelectedFile = (evt) => {
        if (evt.target.files.length === 0) {
            return;
        }

        const file = evt.target.files[0];
        fileUrl.current = URL.createObjectURL(file);
        setFile(file);
    };

    const dismiss = (evt) => {
        evt.preventDefault();
        users.setShowAvatarDialog(false);
    };

    const save = (evt) => {
        evt.preventDefault();

        setSaving(true);
        cropper.current.getCroppedCanvas().toBlob((blob) => {
            users.uploadAvatarImage(userId, blob).then(() => {
                users.setShowAvatarDialog(false);
                setSaving(false);
            });
        }, 'image/jpeg');
    };

    return (
        <div className={classNames('modal', { show: users.showAvatarDialog })}>
            <div className="sheet">
                <div className="title">
                    <h3>Photo</h3>
                    <SVGObject objectId="exitCross" onClick={dismiss} />
                </div>

                <div className="scroll">
                    <div
                        style={{
                            height: 400,
                            width: '100%',
                            backgroundColor: 'var(--bleu-layette)',
                            border: '1px solid var(--light-blue)'
                        }}
                    >
                        <img ref={img} src={fileUrl.current} style={{ display: 'block', maxWidth: '100%' }} />
                    </div>
                </div>

                <div className="exit">
                    <div style={{ display: 'flex' }}>
                        <button onClick={selectPicFile}>Sélectionner image...</button>
                        <div className={classNames('progressIndic', { show: saving })} />
                    </div>
                    <div>
                        <button disabled={saving} onClick={dismiss}>
                            Annuler
                        </button>
                        <button disabled={!file || saving} onClick={save}>
                            Sauver
                        </button>
                    </div>
                </div>

                <input
                    type="file"
                    accept="image/*"
                    value=""
                    onChange={handleSelectedFile}
                    style={{ display: 'none' }}
                    ref={input}
                />
            </div>
        </div>
    );
});
