import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { observer, MobXProviderContext } from 'mobx-react';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';

export const LicensesSectionPAP = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    const prepareExport = (evt) => {
        evt.preventDefault();
        pescalice.setDisplayExportCsvDialogPAP(true, (division, seasonId) => {
            const season = pescalice.seasonPAPWithId(seasonId);

            let filename = 'pap';
            filename += '_' + (division || 'bretagne');
            filename += '_' + (season.referenceYear - 1) + '_' + season.referenceYear;
            filename += '.csv';

            pescalice.fetchAllLicenseRequestPAP(season.referenceYear).then((requests) => {
                pescalice.downloadRequestsCSVGlobalReportPAP(filename, season, division, requests);
            });
        });
    };

    return (
        <div className="selected">
            <div className="header" style={{ flexDirection: 'row', alignItems: 'center' }}>
                <h3>Timbres</h3>
                <button style={{ marginLeft: 'auto' }} onClick={prepareExport}>
                    Exporter CSV...
                </button>
            </div>
            {pescalice.filteredProxiesPAP.length === 0 ? (
                <EmptyContent />
            ) : (
                <div className="scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                            </tr>
                        </thead>
                        <tbody>{buildList(pescalice.filteredProxiesPAP)}</tbody>
                    </table>
                </div>
            )}
        </div>
    );
});

function buildList(proxies) {
    const list = [];

    let currentDiv = '';
    let currentShore = '';
    proxies.forEach((proxy) => {
        if (proxy.administrativeDivisionName !== currentDiv) {
            currentDiv = proxy.administrativeDivisionName;
            list.push(
                <DivisionSection
                    code={proxy.administrativeDivisionZipCode}
                    name={proxy.administrativeDivisionName}
                    key={proxy.administrativeDivisionZipCode}
                />
            );
        }

        if (proxy.shore !== currentShore) {
            currentShore = proxy.shore;
            list.push(<ShoreSection name={proxy.shore} key={proxy.shore} />);
        }

        list.push(<ExtractProxyRow proxy={proxy} key={proxy.uniqueId} />);
    });

    return list;
}

const DivisionSection = ({ code, name }) => {
    return (
        <tr>
            <td>
                <strong>
                    {code} - {name}
                </strong>
            </td>
        </tr>
    );
};

const ShoreSection = ({ name }) => {
    return (
        <tr className="sub">
            <td>
                <strong className="light">{name}</strong>
            </td>
        </tr>
    );
};

const ExtractProxyRow = ({ proxy }) => {
    const history = useHistory();

    const selectProxy = useCallback(
        (evt) => {
            evt.preventDefault();
            history.push(`/pescalice/license-pap/${proxy.uniqueId}`);
        },
        [history, proxy.uniqueId]
    );

    return (
        <tr className="sub">
            <td>
                <a href="#" onClick={selectProxy}>
                    {proxy.name}
                </a>
            </td>
        </tr>
    );
};
