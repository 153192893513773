import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { i18n } from '@SUPPORT/i18n';
import { newEmptyDeclaration } from '@STORES/common/declaration';

import { UnlessUserTag } from '@COMPONENTS/common/UnlessUserTag';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';
import { CapturesList } from '@COMPONENTS/common/CapturesList';
import { DeclarationsList } from '@COMPONENTS/common/DeclarationsList';

@inject('store')
@withTranslation(['common', 'user'])
@observer
export class UserHistorySubSection extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.user.id !== prevProps.user.id) {
            this._update();
        }
    }

    _update = () => {
        this.props.store.users.listDeclarations(this.props.user.id);
    };

    _newDeclaration = (evt) => {
        evt.preventDefault();

        if (this.props.user.hasTag('pfp')) {
            this.props.store.declarationWizard.showForNewDeclaration(this.props.user, new Date(), this._update);
        } else {
            const now = new Date();
            const defaultSpecies = this.props.store.session.info.user.species[0] || this.props.store.species.orgList[0];
            const speciesCode = defaultSpecies ? defaultSpecies.code : 'EL1';
            const declaration = newEmptyDeclaration(
                this.props.user.id,
                now,
                this.props.store.species.list,
                speciesCode
            );

            this.props.store.users.pushDeclaration(declaration);
            this.props.store.declarationDialog.showForDeclaration(this.props.user, declaration, this._update);
        }
    };

    _removeDeclaration = (declaration) => {
        this.props.store.app.alert({
            title: i18n.t('user:declarations.report.remove-alert.title'),
            message1: i18n.t('user:declarations.report.remove-alert.line1'),
            message2: i18n.t('user:declarations.report.remove-alert.line2'),
            label1: i18n.t('common:remove'),

            validationCallback: () => {
                this.props.store.app.setBusy();
                declaration.deprecate().then(() => {
                    this.props.store.app.dismissAlert();
                    this._update();
                    this.props.store.app.setBusy(false);
                });
            }
        });
    };

    _downloadCSV = (evt) => {
        evt.preventDefault();
        this.props.store.users.downloadDeclarationsCSV(this.props.user.id);
    };

    render() {
        const { t } = this.props;
        const declarations = this.props.store.users.declarations
            ? this.props.store.users.declarations.filter((decl) => !decl.isNew)
            : [];

        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="panel">
                    <div className="toolkit">
                        <UnlessUserTag tag="dpma">
                            <button onClick={this._newDeclaration}>{t('common:declare')}</button>
                        </UnlessUserTag>
                        <button onClick={this._downloadCSV} disabled={declarations.length === 0}>
                            {t('common:download-csv')}
                        </button>
                    </div>
                    <div className="scroll">
                        {this.props.user.hasTag('pfp') ? (
                            <CapturesList
                                user={this.props.user}
                                declarations={declarations}
                                update={this._update}
                                remove={this._removeDeclaration}
                            />
                        ) : (
                            <DeclarationsList
                                user={this.props.user}
                                declarations={declarations}
                                update={this._update}
                            />
                        )}
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}
