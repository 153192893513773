import React from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@observer
export class CompanySelector extends React.Component {
    _switchToAll = () => this.props.history.push(this.props.altUrl);
    _update = () => this.props.store.companies.listAll();

    render() {
        const companies = this.props.store.companies.list || [];

        let defaultRoute = null;
        const firstCompany = companies.length > 0 ? companies[0] : null;
        if (firstCompany) {
            const defaultSelection = `${this.props.baseUrl}/${firstCompany.id}/info`;
            defaultRoute = <Route exact path={this.props.baseUrl} render={() => <Redirect to={defaultSelection} />} />;
        }

        const { session } = this.props.store;
        const canCreate = !session.info.user.hasTag('psc_ro');

        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="list subList withToggle show">
                    <div className="toggle">
                        <span title="Liste des entreprises" onClick={this._switchToAll}>
                            <SVGObject objectId="allCompanies" />
                        </span>
                        <span className="listSolo selected" title="Fiches entreprises">
                            <SVGObject objectId="singleCompany" />
                        </span>
                    </div>
                    <dl className="listSolo show">
                        <div className="burger">
                            <span />
                        </div>
                        <dt>Entreprises</dt>
                        {this.props.store.companies.list.map((company) => (
                            <SelectorLink
                                className="row"
                                label={company.name}
                                to={`${this.props.baseUrl}/${company.id}`}
                                key={company.id}
                            />
                        ))}
                        {defaultRoute}
                    </dl>
                    <ul className="toolbar">
                        {canCreate && (
                            <li className="plus" onClick={() => this.props.store.companies.setShowNewCompanyDialog()} />
                        )}
                    </ul>
                </div>
            </OrgChangeObserver>
        );
    }
}
