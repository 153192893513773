import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { UserHistorySubSection } from './UserHistorySubSection';
import { UserZonesSubSection } from './UserZonesSubSection';
import { UserInfoSubSection } from './UserInfoSubSection';
import { UserBoatsSubSection } from './UserBoatsSubSection';
import { UserMessagesSubSection } from './UserMessagesSubSection';

import { UserLicensesSubSection as PescaliceUserLicensesSubSection } from '@COMPONENTS/pescalice/UserLicensesSubSection';

import { UserAvatar } from '@COMPONENTS/common/UserAvatar';
import { WithUserTag } from '@COMPONENTS/common/WithUserTag';
import { UnlessUserTag } from '@COMPONENTS/common/UnlessUserTag';
import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class UserSection extends React.Component {
    render() {
        const userId = parseInt(this.props.match.params.id, 10);
        const user = this.props.store.users.userWithId(userId);
        if (!user) {
            return null;
        }

        const adminUser = this.props.store.session.info.user;

        const { t } = this.props;
        return (
            <div
                className={classNames('selected', {
                    fromOutside: user.isFromAnotherOrg,
                    styledList: user.hasTag('pfp')
                })}
            >
                <div className="header noBottom">
                    <div className="profilePic">
                        <UserAvatar userId={userId} editable={true} />
                        <h3>{user.fullName()}</h3>
                    </div>
                    <nav>
                        <ul>
                            {adminUser.isNotRestricted && adminUser.type !== 'manager' && (
                                <NavigationLink
                                    to={`${this.props.baseUrl}/${user.id}/history`}
                                    label={t('common:declarations')}
                                    tag="li"
                                />
                            )}
                            <UnlessUserTag tag="dpma">
                                <NavigationLink
                                    to={`${this.props.baseUrl}/${user.id}/licenses`}
                                    label={t('common:licenses')}
                                    tag="li"
                                />
                                {adminUser.isNotRestricted && adminUser.type !== 'manager' && (
                                    <NavigationLink
                                        to={`${this.props.baseUrl}/${user.id}/zones`}
                                        label={
                                            this.props.store.session.info.org.hasTag('pfp')
                                                ? t('common:fishing-lots')
                                                : t('common:zones')
                                        }
                                        tag="li"
                                    />
                                )}
                                <NavigationLink
                                    to={`${this.props.baseUrl}/${user.id}/info`}
                                    label={t('common:info')}
                                    tag="li"
                                />
                                {adminUser.type === 'manager' && (
                                    <NavigationLink
                                        to={`${this.props.baseUrl}/${user.id}/boats`}
                                        label="Navires"
                                        tag="li"
                                    />
                                )}
                            </UnlessUserTag>
                            <NavigationLink
                                to={`${this.props.baseUrl}/${user.id}/sms`}
                                label={t('common:sms')}
                                tag="li"
                                className="not-implemented"
                            />
                        </ul>
                    </nav>
                </div>
                <div className="panels">
                    {adminUser.isNotRestricted && adminUser.type !== 'manager' && (
                        <Route
                            path={`${this.props.baseUrl}/:id/history`}
                            render={() => <UserHistorySubSection user={user} />}
                        />
                    )}
                    <Route path={`${this.props.baseUrl}/:id/sms`} component={UserMessagesSubSection} />
                    <UnlessUserTag tag="dpma">
                        <WithUserTag tag="psc">
                            <Route
                                path={`${this.props.baseUrl}/:id/licenses`}
                                render={() => <PescaliceUserLicensesSubSection user={user} />}
                            />
                        </WithUserTag>
                        {adminUser.isNotRestricted && adminUser.type !== 'manager' && (
                            <Route
                                path={`${this.props.baseUrl}/:id/zones`}
                                render={() => <UserZonesSubSection user={user} />}
                            />
                        )}
                        <Route
                            path={`${this.props.baseUrl}/:id/info`}
                            render={() => <UserInfoSubSection user={user} />}
                        />
                        {adminUser.type === 'manager' && (
                            <Route
                                path={`${this.props.baseUrl}/:id/boats`}
                                render={() => <UserBoatsSubSection rootUrl={this.props.rootUrl} user={user} />}
                            />
                        )}
                    </UnlessUserTag>

                    <Route
                        exact
                        path={`${this.props.baseUrl}/:id`}
                        render={() => (
                            <Redirect
                                to={
                                    adminUser.isNotRestricted && adminUser.type !== 'manager'
                                        ? `${this.props.baseUrl}/${user.id}/history`
                                        : `${this.props.baseUrl}/${user.id}/info`
                                }
                            />
                        )}
                    />
                    {!adminUser.isNotRestricted && (
                        <Route
                            exact
                            path={`${this.props.baseUrl}/:id/history`}
                            render={() => <Redirect to={`${this.props.baseUrl}/${user.id}/info`} />}
                        />
                    )}
                </div>
            </div>
        );
    }
}
