import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import ReactForm from 'mobx-react-form';

import { capitalize } from '@SUPPORT/utils';
import { validator } from '@SUPPORT/validator';

@inject('store')
@withTranslation(['manager', 'common'])
@observer
export class GearSection extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: this.formFields(this.gearWithId(props.match.params.id))
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.form.init(this.formFields(this.gearWithId(this.props.match.params.id)));
        }
    }

    gearWithId(idParam) {
        const id = parseInt(idParam, 10);
        return this.props.store.gears.getBy('id', id);
    }

    formFields(gear) {
        if (!gear) {
            return null;
        }

        return {
            name: { type: 'text', rules: 'required', value: gear.name },
            code: { type: 'text', rules: '', value: gear.code },
            code2: { type: 'text', rules: '', value: gear.code2 },
            lengthInfo: { type: 'checkbox', value: gear.lengthInfo },
            surfaceInfo: { type: 'checkbox', value: gear.surfaceInfo },
            meshInfo: { type: 'checkbox', value: gear.meshInfo },
            numHooksInfo: { type: 'checkbox', value: gear.numHooksInfo },
            numGearsInfo: { type: 'checkbox', value: gear.numGearsInfo },
            enabled: { type: 'checkbox', value: gear.enabled }
        };
    }

    _reset = (evt) => {
        evt.preventDefault();
        this.form.init(this.formFields(this.gearWithId(this.props.match.params.id)));
    };

    _save = (evt) => {
        evt.preventDefault();

        const values = this.form.values();
        this.props.store.gears
            .updateGearInfo(parseInt(this.props.match.params.id, 10), values)
            .then((gear) => this.form.init(this.formFields(gear)));
    };

    render() {
        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header">
                    <h3>
                        {this.form.select('name').value}
                        <span>{t('common:gear')}</span>
                    </h3>
                </div>
                <div className="scroll">
                    <form>
                        <div className="full">
                            <input {...this.form.select('enabled').bind()} />
                            <label htmlFor={this.form.select('enabled').id}>{t('manager:gear.active')}</label>
                        </div>
                        <div className="full">
                            <label>{t('manager:gear.label')} :</label>
                            <input {...this.form.select('name').bind()} />
                        </div>
                        <div>
                            <label>{t('manager:gear.fao-code')} :</label>
                            <input {...this.form.select('code').bind()} />
                        </div>
                        <div>
                            <label>{t('manager:gear.snpe-code')} :</label>
                            <input {...this.form.select('code2').bind()} />
                        </div>
                        <h4>{t('manager:gear.additional-info')}</h4>
                        <div>
                            <input {...this.form.select('lengthInfo').bind()} />
                            <label htmlFor={this.form.select('lengthInfo').id}>
                                {capitalize(t('common:gear-info.length'))}
                            </label>
                        </div>
                        <div className="return" />
                        <div>
                            <input {...this.form.select('surfaceInfo').bind()} />
                            <label htmlFor={this.form.select('surfaceInfo').id}>
                                {capitalize(t('common:gear-info.surface'))}
                            </label>
                        </div>
                        <div className="return" />
                        <div>
                            <input {...this.form.select('meshInfo').bind()} />
                            <label htmlFor={this.form.select('meshInfo').id}>
                                {capitalize(t('common:gear-info.mesh'))}
                            </label>
                        </div>
                        <div className="return" />
                        <div>
                            <input {...this.form.select('numHooksInfo').bind()} />
                            <label htmlFor={this.form.select('numHooksInfo').id}>
                                {capitalize(t('common:gear-info.num-hooks'))}
                            </label>
                        </div>
                        <div className="return" />
                        <div>
                            <input {...this.form.select('numGearsInfo').bind()} />
                            <label htmlFor={this.form.select('numGearsInfo').id}>
                                {capitalize(t('common:gear-info.num-gears'))}
                            </label>
                        </div>
                    </form>
                </div>
                <div className="exit">
                    <div />
                    <div>
                        <button disabled={!this.form.isDirty || this.props.store.app.isBusy} onClick={this._reset}>
                            {t('common:reset')}
                        </button>
                        <button
                            disabled={!this.form.isDirty || !this.form.isValid || this.props.store.app.isBusy}
                            onClick={this._save}
                        >
                            {t('common:save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
