import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { withRouter } from 'react-router';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { validator } from '@SUPPORT/validator';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class NewCompanyDialog extends React.Component {
    @observable busy = false;

    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    name: { type: 'text', rules: 'required|string' },
                    registration: { type: 'text', rules: 'required|string' },
                    address1: { type: 'text', rules: 'required|string' },
                    address2: { type: 'text', rules: 'string' },
                    zipCode: { type: 'text', rules: 'required|string' },
                    city: { type: 'text', rules: 'required|string' },
                    countryCode: { rules: 'required|string', value: 'fr' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    @action.bound
    setBusy(busy = true) {
        this.busy = busy;
    }

    _create = (evt) => {
        evt.preventDefault();

        const data = this.form.values();

        this.setBusy();
        this.props.store.companies
            .createCompany(data)
            .then((company) => {
                this._dismiss();

                const { history } = this.props;
                history.push(`${this.props.baseUrl}/company/${company.id}`);
            })
            .finally(() => this.setBusy(false));
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.companies.setShowNewCompanyDialog(false);
        this.form.clear();
    };

    render() {
        if (!this.props.store.companies) {
            return null;
        }

        const { t } = this.props;
        return (
            <div className={classNames('modal', { show: this.props.store.companies.showNewCompanyDialog })}>
                <div className="sheet">
                    <div className="title">
                        <h3>Nouvelle entreprise</h3>
                        <SVGObject objectId="exitCross" onClick={this._cancel} />
                    </div>
                    <div className="scroll">
                        <form>
                            <div className="required">
                                <label>Nom de l&apos;entreprise :</label>
                                <input {...this.form.select('name').bind()} />
                            </div>
                            <div className="required">
                                <label>Identifiant :</label>
                                <input {...this.form.select('registration').bind()} />
                            </div>
                            <div className="required">
                                <label>Rue :</label>
                                <input {...this.form.select('address1').bind()} />
                            </div>
                            <div>
                                <label>Rue (complément) :</label>
                                <input {...this.form.select('address2').bind()} />
                            </div>
                            <div className="required">
                                <label>Code postal :</label>
                                <input className="mid" {...this.form.select('zipCode').bind()} />
                            </div>
                            <div className="required">
                                <label>Ville :</label>
                                <input {...this.form.select('city').bind()} />
                            </div>
                            <div className="required">
                                <label>Pays :</label>
                                <select {...this.form.select('countryCode').bind()}>
                                    <option value="fr">France</option>
                                    <option value="uk">United Kingdom</option>
                                    <option value="be">Belgique</option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._cancel} disabled={this.busy}>
                                {t('common:cancel')}
                            </button>
                            <button onClick={this._create} disabled={!this.form.isValid || this.busy}>
                                {t('common:create')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
