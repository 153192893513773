import styled, { css } from 'styled-components';

import ChevronSVG from '@GRAPHICS/licensee/chevron.svg';

export const NoPaymentTable = styled.table`
    background-color: var(--lightRed);

    & th {
        background-color: var(--lightRed);
        border-bottom: 1px solid #f4f4f4;
    }

    & tr {
        border-color: #f4f4f4;
    }
`;

export const Chevron = styled(ChevronSVG)`
    height: 12px;
    rotate: ${({ $expanded }) => ($expanded ? '90deg' : '0')};
`;

export const CellForChevron = styled.th`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const CellSmallerPadding = styled.td`
    padding: 1px 0 3px 6px;
`;

export const CellNoWrap = styled.td`
    white-space: nowrap;
`;

export const CellForCheckbox = styled(CellSmallerPadding)`
    width: 48px;

    &:has(input[type='checkbox']:disabled) {
        opacity: 0.3;
    }
`;

export const TriStateCheckbox = styled.div`
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    align-self: flex-start;
    width: 38px;
    height: 22px;
    border-radius: 30px;
    transition: all 0.16s ease-out;
    margin-top: 5px;
    opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 30px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.2);
        background-color: var(--form-elements-bkd-color);
        transform: translateX(0);
        transition: transform 0.2s cubic-bezier(0, -0.55, 0.265, 1.55);
    }

    ${({ state }) =>
        state === 'one'
            ? css`
                  border: 2px solid #cacaca;
                  background: var(--main-background);
                  &::after {
                      transform: translateX(0);
                  }
              `
            : state === 'two'
            ? css`
                  border: 2px solid #cacaca;
                  background: #cacaca;
                  &::after {
                      transform: translateX(8px);
                  }
              `
            : state === 'three'
            ? css`
                  border: 2px solid var(--green);
                  background: var(--dark-blue);
                  &::after {
                      transform: translateX(16px);
                  }
              `
            : ''}

    & span {
        display: flex;
        height: 100%;
        width: 33%;
        cursor: pointer;
    }
`;

export const Label = styled.label`
    &:first-of-type {
        top: 4px;
        margin: 0;
    }
`;

export const PaymentRow = styled.tr`
    background-color: ${({ suspicious }) => (suspicious ? 'var(--lightOrange)' : 'transparent')};
    ${({ noSeparator }) =>
        noSeparator
            ? css`
                  & td {
                      border: none;
                  }
              `
            : ''}
`;

export const NoPaymentRow = styled.tr`
    ${({ noSeparator }) =>
        noSeparator
            ? css`
                  & td {
                      border: none;
                  }
              `
            : css`
                  & td {
                      border-bottom: 1px solid #f4f4f4;
                  }
              `}
`;

export const TdStrong = styled.td`
    padding-top: 10px;
    font-weight: 700;
`;
