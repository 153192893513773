import { types, getRoot, flow } from 'mobx-state-tree';

import { UserList } from '@STORES/common/user';

import { api } from '@SUPPORT/api';

export const License = types.model('License', {
    id: types.identifierNumber,
    name: types.string,
    orgId: types.number,
    orgCode: types.string,
    orgName: types.string,
    // folloginw two are only null when using license shims
    sector: types.maybeNull(types.string),
    category: types.maybeNull(
        types.enumeration(['Pêche embarquée', 'Pêche à pied des coquillages', 'Récolte des algues de rive'])
    ),
    maxActive: types.number,
    season: types.string
});

const LicenseList = types.array(License);

export const LicensesStore = types
    .model('LicensesStore', {
        seasons: types.optional(types.array(types.string), []),
        currentSeason: types.optional(types.string, ''),

        list: types.optional(LicenseList, [])
    })
    .views((self) => {
        return {
            byId(id) {
                return self.list.find((license) => license.id === id);
            }
        };
    })
    .actions((self) => {
        return {
            /*listAll: flow(function*() {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    // FIXME: hardcoded for Jehannne / Pescalice

                    const orgsResponse = yield api.listOrgs();
                    const orgs = orgsResponse.data;

                    let org;
                    let response;
                    let list;

                    org = orgs.find((org) => org.code === '22');
                    response = yield api.listOrgLicenses(org.id);
                    list = LicenseList.create(response.data || []);

                    org = orgs.find((org) => org.code === '29');
                    response = yield api.listOrgLicenses(org.id);
                    list = list.concat(LicenseList.create(response.data || []));

                    org = orgs.find((org) => org.code === '35');
                    response = yield api.listOrgLicenses(org.id);
                    list = list.concat(LicenseList.create(response.data || []));

                    org = orgs.find((org) => org.code === '56');
                    response = yield api.listOrgLicenses(org.id);
                    list = list.concat(LicenseList.create(response.data || []));

                    self.list = list;
                } finally {
                    app.setBusy(false);
                }
            }),
*/
            setCurrentSeason: (season) => {
                self.currentSeason = season || '';
            },

            listOrgLicenseSeasons: flow(function* () {
                const { session, app } = getRoot(self);
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listOrgLicenseSeasons(info.org.id);
                    self.seasons = response.data || [];
                } finally {
                    app.setBusy(false);
                }
            }),

            listOrgLicenses: flow(function* () {
                if (!self.currentSeason) {
                    self.list = [];
                    return;
                }

                const { session, app } = getRoot(self);
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listOrgLicenses(info.org.id, self.currentSeason);
                    self.list = LicenseList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            }),

            listLicensees: flow(function* (licenseId) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.listLicensees(licenseId);
                    return UserList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            }),

            downloadAttributionsCSV: (licenseId) => {
                api.downloadLicenseAttributions(licenseId);
            }
        };
    });
