import React from 'react';
import { inject } from 'mobx-react';

@inject('store')
export class WithOrgTag extends React.Component {
    render() {
        const org = this.props.store.session.info.org;
        if (org && org.tags && org.tags.includes(this.props.tag)) {
            return this.props.children;
        }

        return null;
    }
}
