import React, { useState } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { withRouter, matchPath } from 'react-router';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

const Dialog = (props) => {
    const { location, store } = props;
    const [reason, setReason] = useState(null);

    const match = matchPath(location.pathname, { path: `/pescalice/boat/:id` });
    const boatId = match && parseInt(match.params.id, 10);
    const boat = store.boats && store.boats.boatWithId(boatId);
    const person = store.app && store.app.modalData && store.app.modalData.refPerson;
    const personsToRemove = store.app && store.app.modalData && store.app.modalData.personsToRemove;

    const selectReason = (evt) => setReason(evt.target.value);

    const dismiss = () => {
        store.boats.setShowOwnerRemovalDialog(false);
        setReason(null);
    };

    const cancel = (evt) => {
        evt.preventDefault();
        dismiss();
    };

    const disassociate = (evt) => {
        evt.preventDefault();

        personsToRemove.forEach((p) => boat.removePerson(p, reason));
        if (store.app.modalData.onRemoval) {
            store.app.modalData.onRemoval();
        }

        dismiss();
    };

    let nameLabel;
    if (person && person.isOwner) {
        nameLabel = 'Nom du propriétaire';
    } else if (person && person.isRenter) {
        nameLabel = "Nom de l'affréteur";
    } else {
        nameLabel = 'Nom du marin';
    }

    return (
        <div className={classNames('modal', { show: store.boats && store.boats.showOwnerRemovalDialog })}>
            {boat && person && (
                <div className="sheet">
                    <div className="title">
                        <h3>Désolidariser du navire</h3>
                        <SVGObject objectId="exitCross" onClick={dismiss} />
                    </div>
                    <div className="scroll">
                        <form>
                            <div>
                                <label>{nameLabel} :</label>
                                <input type="text" value={person.fullName(false)} readOnly />
                            </div>
                            <div>
                                <label>Nom du navire :</label>
                                <input type="text" value={boat.name} readOnly />
                            </div>
                            <div>
                                <label>Raison :</label>
                                <select value={reason || ''} onChange={selectReason}>
                                    <option value="">Sélectionner...</option>
                                    {person.isOwner && <option value="sold">Navire vendu</option>}
                                    <option value="quit">Sortie de la personne</option>
                                    <option value="mistake">Erreur de saisie</option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="exit">
                        <div></div>
                        <div>
                            <button onClick={cancel}>Annuler</button>
                            <button onClick={disassociate} disabled={!reason}>
                                Désolidariser
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export const BoatOwnerRemovalDialog = withRouter(inject('store')(observer(Dialog)));
