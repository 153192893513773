import classNames from 'classnames';
import React, { Fragment, useState, useContext, useEffect, useCallback } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';

import { getRequestStatusLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';
import { formatLocaleDate } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { EmptyContent } from '@COMPONENTS/common/EmptyContent';

export const ToDosSectionPAP = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice, session } = store;
    const canEdit = !session.info.user.hasTag('psc_ro');

    useEffect(() => {
        pescalice.listLicenseRequestsPAP();
    }, [pescalice]);

    const newRequest = (evt) => {
        evt.preventDefault();
        pescalice.setDisplayNewRequestDialogPAP();
    };

    return (
        <div className="selected">
            <div className="header">
                <h3>À traiter</h3>
            </div>
            <div className="scroll" style={{ paddingBottom: 0 }}>
                {pescalice.filteredTodosPAP.length === 0 ? (
                    <EmptyContent />
                ) : (
                    <table className="withCollapseTriangle">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Demandeur</th>
                                <th>Nature</th>
                                <th>Date de dépôt</th>
                                {canEdit && <th></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {pescalice.filteredTodosPAP.map((todo) => (
                                <Todo request={todo} canEdit={canEdit} key={todo.uniqueId} />
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {canEdit && (
                <div className="exit">
                    <div />
                    <div>
                        <button onClick={newRequest}>Créer une demande</button>
                    </div>
                </div>
            )}
        </div>
    );
});

const Todo = ({ request, canEdit }) => {
    const { store } = useContext(MobXProviderContext);
    const { users, pescalice } = store;

    const [expanded, setExpanded] = useState(false);

    const user = users.userWithId(request.requestAuthor.telecapecheId);
    const edit = useCallback(
        (evt) => {
            evt.preventDefault();
            if (evt.shiftKey) {
                const ok = confirm('Voulez-vous vraiment supprimer cette demande ?');
                if (ok) {
                    pescalice.deleteLicenseRequestPAP(request.uniqueId);
                }
            } else {
                pescalice.setDisplayNewRequestDialogPAP(true, request.uniqueId);
            }
        },
        [pescalice, request]
    );

    return (
        <Fragment>
            <tr className={classNames({ selected: expanded })}>
                <td onClick={() => setExpanded(!expanded)}>
                    <i className={classNames('arrow', { down: expanded, right: !expanded })}></i>
                </td>
                <td>{user.fullName(false)}</td>
                <td>
                    {request.isFirstInstallation
                        ? 'Première installation'
                        : request.isNewLicenseRequest
                        ? 'Nouvelle demande'
                        : 'Renouvellement'}
                </td>
                <td>
                    {formatLocaleDate(request.depositDate, 'dd/MM/yyyy')}{' '}
                    {request.isUserDeposited ? (
                        <SVGObject
                            objectId="singleFisherman"
                            style={{ fill: 'blue', width: 12, opacity: 0.15, height: 10, marginLeft: 10 }}
                        />
                    ) : null}
                </td>
                {canEdit && (
                    <td>
                        <a href="#" onClick={edit}>
                            Éditer
                        </a>
                    </td>
                )}
            </tr>
            <tr className={classNames('collapsed', { show: expanded })}>
                <td colSpan={canEdit ? '5' : '4'}>
                    <table>
                        <tbody>
                            <tr>
                                <th>Département</th>
                                <th>Côte</th>
                                <th>Timbre</th>
                                <th>Nature</th>
                                <th>Statut</th>
                            </tr>
                            {request.unstableExtractRequests.map((extractReq) => (
                                <ExtractRequest request={extractReq} key={extractReq.uniqueId} />
                            ))}
                        </tbody>
                    </table>
                </td>
            </tr>
        </Fragment>
    );
};

const ExtractRequest = ({ request }) => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    const season = pescalice.seasonPAPWithRefYear(request.referenceYear);
    const extract = season.extracts.find((extract) => extract.uniqueId === request.targetedExtract);

    return (
        <tr>
            <td>
                {extract.administrativeDivisionZipCode} - {extract.administrativeDivisionName}
            </td>
            <td>{extract.shore || '-'}</td>
            <td>{extract.name || '-'}</td>
            <td>
                {request.isFirstInstallation
                    ? 'Première Installation'
                    : request.isExtractRenewal
                    ? 'Renouvellement'
                    : 'Nouvelle Demande'}
            </td>
            <td className={getRequestStatusColorCode(request.globalStatus)}>
                {getRequestStatusLabel(request.globalStatus)}
            </td>
        </tr>
    );
};
