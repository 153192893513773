import React, { useState, useContext, useEffect } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import classNames from 'classnames';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

import { formatLocaleDate } from '@SUPPORT/utils';
import { downloadFileFromBase64Data } from '@SUPPORT/utils';

export const GenerateNotificationDialog = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { app, pescalice, boats, companies } = store;

    const [annualCampaign, setAnnualCampaign] = useState('');
    const [seasonalCampaign, setSeasonalCampaign] = useState('');
    const [generating, setGenerating] = useState(false);

    const { years = [], user, companyId, boatId } = app.modalData || {};
    const canGenerate = annualCampaign !== '' || seasonalCampaign !== '';

    useEffect(() => {
        if (!pescalice.displayGenerateNotificationDialog) {
            setAnnualCampaign('');
            setSeasonalCampaign('');
        }
    }, [pescalice.displayGenerateNotificationDialog]);

    const dismiss = (evt) => {
        evt.preventDefault();
        pescalice.setDisplayGenerateNotificationDialog(false);
    };

    const generate = () => {
        setGenerating(true);
        pescalice
            .generateNotificationDoc(user.id, companyId, boatId, annualCampaign, seasonalCampaign)
            .then((res) => {
                if (res.status === 'error') {
                    alert(`Erreur: ${res.errorMessage}\nInfo: ${res.devMessage}`);
                } else {
                    const boat = boats.boatWithId(boatId);
                    let filename = 'pescalice.peche-embarquee'; //this.fishingType;
                    if (companyId) {
                        const company = companies.companyWithId(companyId);
                        if (company) {
                            if (company.type) {
                                filename += '_' + company.type.replace(' ', '_');    
                            }
                            filename += '_' + company.name.replace(' ', '_');
                        }
                    }
                    else {
                        filename += '_' + user.lastName.toUpperCase().replace(' ', '_');
                        filename += '_' + user.firstName.toUpperCase().replace(' ', '_');    
                    }
                    filename += '_' + boat.fullRegistration.replace(' ', '');
                    if (seasonalCampaign) {
                        filename += '_seasonal_' + seasonalCampaign;
                    }
                    if (annualCampaign) {
                        filename += '_annual_' + annualCampaign;
                    }
                    filename += '_' + formatLocaleDate(Date.now(), 'dd_MM_yyyy_HH_mm');
                    filename += '.pdf';
                    downloadFileFromBase64Data(res.data.notificationPDFData, filename, 'application/pdf');
                }
            })
            .finally(() => {
                setGenerating(false);
                pescalice.setDisplayGenerateNotificationDialog(false);
            });
    };

    return (
        <div className={classNames('modal', { show: pescalice.displayGenerateNotificationDialog })}>
            <div className="sheet">
                <div className="title">
                    <h3>Générer une notification</h3>
                    <SVGObject objectId="exitCross" onClick={dismiss} />
                </div>

                <div className="scroll">
                    <form>
                        <div>
                            <label>Campagne annuelle :</label>
                            <select value={annualCampaign} onChange={(evt) => setAnnualCampaign(evt.target.value)}>
                                <option value="">Aucune</option>
                                {years.map((year) => (
                                    <option value={year} key={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Campagne saisonnière :</label>
                            <select value={seasonalCampaign} onChange={(evt) => setSeasonalCampaign(evt.target.value)}>
                                <option value="">Aucune</option>
                                {years.map((year) => (
                                    <option value={year} key={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </form>
                </div>

                <div className="exit">
                    <div>
                        <div className={classNames('progressIndic', { show: generating })} />
                    </div>
                    <div>
                        <button disabled={generating} onClick={dismiss}>
                            Annuler
                        </button>
                        <button disabled={!canGenerate || generating} onClick={generate}>
                            Générer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});
