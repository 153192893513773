/* eslint-disable react/no-children-prop */

import React from 'react';
import { observable, computed, reaction } from 'mobx';
import { inject, observer, disposeOnUnmount } from 'mobx-react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect, matchPath } from 'react-router-dom';

import { LicenseSituationSubSection } from './LicenseSituationSubSection';
import { LicenseInfoSubSection } from './LicenseInfoSubSection';
import { LicenseContingentsSubSection } from './LicenseContingentsSubSection';
import { LicenseHistorySubSection } from './LicenseHistorySubSection';

import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@withRouter
@inject('store')
@observer
export class LicenseSection extends React.Component {
    @observable selectedProxy = null;
    @observable selectedSeason = null;
    @observable license = null;
    @observable quotas = null;

    componentDidMount() {
        disposeOnUnmount(
            this,
            reaction(() => this.matchedLicenseId, this._update, { fireImmediately: true })
        );
        disposeOnUnmount(
            this,
            reaction(
                () => this.selectedSeason && this.selectedSeason.parts.length,
                () => this._updateForSeason(this.selectedSeason)
            )
        );
    }

    @computed
    get matchedLicenseId() {
        const match = matchPath(this.props.location.pathname, { path: `/pescalice/license-pep/:id` });
        return match ? match.params.id : null;
    }

    _update = () => {
        const { pescalice } = this.props.store;
        this.selectedProxy = pescalice.proxyWithId(this.matchedLicenseId);
        if (this.selectedProxy) {
            this._updateForSeason(this.selectedProxy.seasons[0]);
        }
    };

    _updateForSeason = (season) => {
        const { pescalice } = this.props.store;

        this.selectedSeason = season;
        if (this.selectedSeason) {
            pescalice.fetchLicense(this.selectedSeason.license.uniqueId).then((license) => (this.license = license));
            pescalice
                .fetchLicenseQuotaDashBoard(this.selectedSeason.license.uniqueId)
                .then((quotas) => (this.quotas = quotas));
        }
    };

    _selectSeason = (evt) => {
        const season = this.selectedProxy.seasonWithId(evt.target.value);
        this._updateForSeason(season);
    };

    render() {
        if (this.quotas) {
            // Hack to ensure that sub sections depending on those observables passed as props
            // are correctly re-rendered
        }

        return (
            <div className="selected">
                <div className="header noBottom">
                    <h3>{this.selectedProxy ? this.selectedProxy.name : ''}</h3>
                    <form>
                        <div className="full">
                            <label>Campagne :</label>
                            {this.selectedProxy && (
                                <select value={this.selectedSeason.uniqueId} onChange={this._selectSeason}>
                                    {this.selectedProxy.seasons.map((season) => (
                                        <option value={season.uniqueId} key={season.uniqueId}>
                                            {season.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    </form>
                    <nav>
                        <ul>
                            <NavigationLink
                                to={`/pescalice/license-pep/${this.matchedLicenseId}/situation`}
                                label="Situation"
                                tag="li"
                            />
                            <NavigationLink
                                to={`/pescalice/license-pep/${this.matchedLicenseId}/infos`}
                                label="Informations"
                                tag="li"
                            />
                            <NavigationLink
                                to={`/pescalice/license-pep/${this.matchedLicenseId}/requests`}
                                label="Demandes"
                                tag="li"
                            />
                            <NavigationLink
                                to={`/pescalice/license-pep/${this.matchedLicenseId}/history`}
                                label="Historique"
                                tag="li"
                            />
                        </ul>
                    </nav>
                </div>
                <div className="panels">
                    <Switch>
                        <Route
                            path="/pescalice/license-pep/:id/situation"
                            children={() => <LicenseSituationSubSection quotas={this.quotas} />}
                        />
                        <Route
                            path="/pescalice/license-pep/:id/infos"
                            children={({ match }) => match && <LicenseInfoSubSection season={this.selectedSeason} />}
                        />
                        <Route
                            path="/pescalice/license-pep/:id/requests"
                            children={() => (
                                <LicenseContingentsSubSection quotas={this.quotas} season={this.selectedSeason} />
                            )}
                        />
                        <Route path="/pescalice/license-pep/:id/history" render={() => <LicenseHistorySubSection />} />
                    </Switch>
                </div>

                <Route
                    exact
                    path="/pescalice/license-pep/:id"
                    render={() => <Redirect to={`/pescalice/license-pep/${this.matchedLicenseId}/situation`} />}
                />
            </div>
        );
    }
}
