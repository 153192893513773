import React, { useContext, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { observer, MobXProviderContext } from 'mobx-react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const ExportCsvDialogPEP = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    const [exportType, setExportType] = useState('basic');
    const [exporting, setExporting] = useState(false);

    const handleTypeChange = useCallback((evt) => {
        setExportType(evt.target.value);
    }, []);

    useEffect(() => {
        if (pescalice.displayExportCsvDialogPEP) {
            setExportType('basic');
        }
    }, [pescalice.displayExportCsvDialogPEP]);

    const dismiss = (evt) => {
        evt.preventDefault();
        pescalice.setDisplayExportCsvDialogPEP(false);
    };

    const exportCsv = useCallback(
        (evt) => {
            evt.preventDefault();

            setExporting(true);
            pescalice.exportCsvCallbackPEP(exportType).then(() => {
                pescalice.setDisplayExportCsvDialogPEP(false);
                setExporting(false);
            });
        },
        [pescalice, exportType]
    );

    return (
        <div className={classNames('modal', { show: pescalice.displayExportCsvDialogPEP })}>
            <div className="sheet" style={{ maxWidth: 400, minHeight: 'auto' }}>
                <div className="title">
                    <h3>Export CSV</h3>
                    <SVGObject objectId="exitCross" onClick={dismiss} />
                </div>

                <div className="scroll">
                    <form>
                        <div className="aligned">
                            <label>Type d&apos;export :</label>
                            <input
                                id="basicExport"
                                type="radio"
                                name="basicExport"
                                value="basic"
                                checked={exportType === 'basic'}
                                onChange={handleTypeChange}
                            />
                            <label htmlFor="basicExport">Basique</label>
                        </div>
                        <div className="aligned">
                            <label />
                            <input
                                id="completeExport"
                                type="radio"
                                name="completeExport"
                                value="complete"
                                checked={exportType === 'complete'}
                                onChange={handleTypeChange}
                            />
                            <label htmlFor="completeExport">Complet</label>
                        </div>
                    </form>
                </div>

                <div className="exit">
                    <div>
                        <div className={classNames('progressIndic', { show: exporting })} />
                    </div>
                    <div>
                        <button disabled={exporting} onClick={dismiss}>
                            Annuler
                        </button>
                        <button disabled={exporting} onClick={exportCsv}>
                            Exporter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});
