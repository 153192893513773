/* eslint-disable react/no-children-prop */

import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { AutoSizer, List } from 'react-virtualized';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class BoatSelector extends React.Component {
    _update = () => {
        this.props.store.boats.listAll();
    };

    _switchToAll = () => {
        this.props.history.push(this.props.altUrl);
    };

    render() {
        const boatList = this.props.store.boats.list || [];

        let defaultRoute = null;
        const firstBoat = boatList.length > 0 ? boatList[0] : null;
        if (firstBoat) {
            const defaultSelection = `${this.props.baseUrl}/${firstBoat.id}/info`;
            defaultRoute = <Route exact path={this.props.baseUrl} render={() => <Redirect to={defaultSelection} />} />;
        }

        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="list subList withToggle show">
                    <div className="toggle">
                        <span title={t('admin:boat-selector.boat-list')}>
                            <SVGObject objectId="allBoats" onClick={this._switchToAll} />
                        </span>
                        <span className="selected listSolo" title={t('admin:boat-selector.boat-cards')}>
                            <SVGObject objectId="singleBoat" />
                        </span>
                    </div>

                    <dl className="listSolo show">
                        <div className="burger">
                            <span />
                        </div>
                        <h6>{firstBoat ? firstBoat.name : null}</h6>
                        <dt>{t('common:boats')}</dt>
                        <AutoSizer
                            children={({ width, height }) => (
                                <List
                                    width={width}
                                    height={height}
                                    rowCount={boatList.length}
                                    overscanRowCount={10}
                                    rowHeight={25}
                                    rowRenderer={(props) => {
                                        const boat = boatList[props.index];
                                        return (
                                            <SelectorLink
                                                className={classNames('row', { codeOrange: boat.partial })}
                                                label={boat.name}
                                                to={`${this.props.baseUrl}/${boat.id}`}
                                                style={props.style}
                                                key={boat.id}
                                            />
                                        );
                                    }}
                                />
                            )}
                        />
                        {defaultRoute}
                    </dl>

                    <ul className="toolbar"></ul>
                </div>
            </OrgChangeObserver>
        );
    }
}
