import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route, Redirect, withRouter } from 'react-router-dom';

import { SelectorLink } from '@COMPONENTS/common/SelectorLink';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class GearsSelector extends React.Component {
    render() {
        const { list } = this.props.store.gears;
        const firstGear = list.length > 0 ? list[0] : null;
        const defaultSelection = firstGear ? `${this.props.baseUrl}/${firstGear.id}` : null;

        const { t, baseUrl, allowCreation } = this.props;
        return (
            <div className="list subList show">
                <dl className="show">
                    <div className="burger">
                        <span />
                    </div>
                    <h6>Nets</h6>
                    <dt>{t('common:gears')}</dt>
                    {list.map((gear) => (
                        <Route
                            render={() => (
                                <SelectorLink
                                    label={gear.name}
                                    to={`${baseUrl}/${gear.id}`}
                                    style={gear.enabled ? null : { color: 'var(--disabled-color)' }}
                                />
                            )}
                            key={gear.id}
                        />
                    ))}

                    {defaultSelection ? (
                        <Route exact path={baseUrl} render={() => <Redirect to={defaultSelection} />} />
                    ) : null}
                </dl>
                <ul className="toolbar">{allowCreation ? <li className="plus" /> : null}</ul>
            </div>
        );
    }
}
