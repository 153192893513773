import { types, flow } from 'mobx-state-tree';

import { SessionStore } from '@STORES/common/session';
import { Country } from '@STORES/common/country';
import { AppStore } from '@STORES/common/app';
import { GearsStore } from '@STORES/common/gears';
import { PescaliceStore } from '@STORES/common/pescalice';
import { UsersStore } from '@STORES/admin/people.users';
import { BoatsStore } from '@STORES/admin/things.boats';
import { CompaniesStore } from '@STORES/admin/things.companies';

import { api } from '@SUPPORT/api';

const ManagerStore = types
    .model('ManagerStore', {
        app: types.optional(AppStore, {}),
        session: types.optional(SessionStore, {}),
        countries: types.array(Country),

        gears: types.maybeNull(GearsStore),
        pescalice: types.maybeNull(PescaliceStore),
        users: types.maybeNull(UsersStore),
        boats: types.maybeNull(BoatsStore),
        companies: types.maybeNull(CompaniesStore)
    })
    .actions((self) => {
        return {
            loadInitialData: flow(function* () {
                const sessionInfo = yield self.session.getSession();
                const { user } = sessionInfo;

                const response = yield api.listCountries();
                self.countries = response.data || [];

                if (user.hasTag('gear')) {
                    self.gears = GearsStore.create({});
                    yield self.gears.listTagged('pfp');
                }

                if (user.hasTag('psc')) {
                    self.pescalice = PescaliceStore.create({});
                    self.users = UsersStore.create({});
                    self.boats = BoatsStore.create({});
                    self.companies = CompaniesStore.create({});

                    yield Promise.all([
                        self.pescalice.loadInitialData(),
                        self.boats.loadInitialData(),
                        self.users.listUsers(true, true),
                        self.companies.listAll()
                    ]);
                }

                self.app.setReady();
            })
        };
    });

export const store = ManagerStore.create({});
