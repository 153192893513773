import React, { useContext } from 'react';
import classNames from 'classnames';
import { observer, MobXProviderContext } from 'mobx-react';

import { onDateFieldChange, isValidDateValue } from '@SUPPORT/form';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const ToDosFilters = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    return (
        <div className="list subList show">
            <dl className="show">
                <div className="burger">
                    <span />
                </div>
                <h6>Filtres</h6>
                <dt>
                    Filtres
                    <SVGObject objectId="resetIco" onClick={() => pescalice.resetTodoFilters()} />
                </dt>
                <dd className="noSelection selected">
                    <select
                        value={pescalice.todoDeptFilter}
                        onChange={(evt) => pescalice.setTodoDeptFilter(evt.target.value)}
                    >
                        <option value="">Tous les départements</option>
                        <option value="cotes-d-armor">22 - Côtes d&apos;Armor</option>
                        <option value="finistere">29 - Finistère</option>
                        <option value="ille-et-vilaine">35 - Ille et Vilaine</option>
                        <option value="morbihan">56 - Morbihan</option>
                        <option value="hors-bretagne">Hors Bretagne</option>
                    </select>
                </dd>
                <dd className="noSelection">
                    <input
                        type="text"
                        placeholder="Licence"
                        value={pescalice.todoLicenseFilter}
                        onChange={(evt) => pescalice.setTodoLicenseFilter(evt.target.value)}
                    />
                </dd>
                <dd className="noSelection">
                    <input
                        type="text"
                        placeholder="Armateur"
                        value={pescalice.todoRequesterFilter}
                        onChange={(evt) => pescalice.setTodoRequesterFilter(evt.target.value)}
                    />
                </dd>
                <dd className="noSelection aligned">
                    <input
                        type="text"
                        placeholder="Navire"
                        value={pescalice.todoBoatFilter}
                        onChange={(evt) => pescalice.setTodoBoatFilter(evt.target.value)}
                    />
                </dd>
                <dd className="noSelection aligned">
                    <select
                        value={pescalice.todoTypeFilter}
                        onChange={(evt) => pescalice.setTodoTypeFilter(evt.target.value)}
                    >
                        <option value="">Toutes les natures</option>
                        <option value="isFirstInstallation">1ères installations</option>
                        <option value="isLicenseRenewal">Renouvellements</option>
                        <option value="isNewLicenseRequest">Nouvelles demandes</option>
                    </select>
                </dd>
                <dd className="noSelection aligned">
                    <select
                        value={pescalice.todoStatusFilter}
                        onChange={(evt) => pescalice.setTodoStatusFilter(evt.target.value)}
                    >
                        <option value="">Tous les statuts</option>
                        <option value="kPSLicenseRequestGlobalStatusInitial">Demandes déposées</option>
                        <option value="kPSLicenseRequestGlobalStatusFrozen">Demandes en attente</option>
                        <option value="kPSLicenseRequestGlobalStatusSuspended">Demandes incomplètes</option>
                        <option value="kPSLicenseRequestGlobalStatusReserved">Demandes réservées</option>
                        <option value="kPSLicenseRequestGlobalStatusAllowed">Demandes attribuées</option>
                        <option value="kPSLicenseRequestGlobalStatusRefused">Demandes refusées</option>
                        <option value="kPSLicenseRequestGlobalStatusCancelled">Demandes annulées</option>
                    </select>
                </dd>
                <dd className="noSelection aligned">
                    <label>À partir du :</label>
                    <input
                        type="text"
                        className={classNames('date', {
                            codeRed:
                                pescalice.todoFromDateFilter &&
                                !isValidDateValue(pescalice.todoFromDateFilter, 'dd/MM/yyyy')
                        })}
                        placeholder="dd/mm/yyyy"
                        value={pescalice.todoFromDateFilter}
                        onChange={(evt) =>
                            onDateFieldChange(evt, pescalice.todoFromDateFilter, pescalice.setTodoFromDateFilter)
                        }
                    />
                </dd>
                <dd className="noSelection aligned">
                    <label>Jusqu&apos;au:</label>
                    <input
                        type="text"
                        className={classNames('date', {
                            codeRed:
                                pescalice.todoToDateFilter &&
                                !isValidDateValue(pescalice.todoToDateFilter, 'dd/MM/yyyy')
                        })}
                        placeholder="dd/mm/yyyy"
                        value={pescalice.todoToDateFilter}
                        onChange={(evt) =>
                            onDateFieldChange(evt, pescalice.todoToDateFilter, pescalice.setTodoToDateFilter)
                        }
                    />
                </dd>
            </dl>
            <ul className="toolbar" />
        </div>
    );
});
