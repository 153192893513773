import React from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';

import { CompanyInfoSubSection } from './CompanyInfoSubSection';
import { CompanyMembersSubSection } from './CompanyMembersSubSection';
import { CompanyLicensesSubSection as PescaliceCompanyLicensesSubSection } from '@COMPONENTS/pescalice/CompanyLicensesSubSection';
import { CompanyBoatsSubSection as PescaliceCompanyBoatsSubSection } from '@COMPONENTS/pescalice/CompanyBoatsSubSection';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';
import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class CompanySection extends React.Component {
    render() {
        const companies = this.props.store.companies.list || [];
        const company = companies.find((c) => c.id === parseInt(this.props.match.params.id, 10));
        if (!company) {
            return (
                <div className="selected">
                    <EmptyContent />
                </div>
            );
        }

        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header noBottom">
                    <h3>{company.name}</h3>
                    <nav>
                        <ul>
                            <NavigationLink
                                to={`${this.props.baseUrl}/${company.id}/licenses`}
                                label="Licenses"
                                tag="li"
                            />
                            <NavigationLink
                                to={`${this.props.baseUrl}/${company.id}/info`}
                                label={t('common:info')}
                                tag="li"
                            />
                            <NavigationLink
                                to={`${this.props.baseUrl}/${company.id}/members`}
                                label={t('common:collaborators')}
                                tag="li"
                            />
                            <NavigationLink to={`${this.props.baseUrl}/${company.id}/boats`} label="Navires" tag="li" />
                        </ul>
                    </nav>
                </div>

                <div className="panels">
                    <Route
                        path={`${this.props.baseUrl}/:id/licenses`}
                        render={() => <PescaliceCompanyLicensesSubSection company={company} />}
                    />
                    <Route
                        path={`${this.props.baseUrl}/:id/info`}
                        render={() => <CompanyInfoSubSection company={company} />}
                    />
                    <Route
                        path={`${this.props.baseUrl}/:id/members`}
                        render={() => <CompanyMembersSubSection company={company} />}
                    />
                    <Route
                        path={`${this.props.baseUrl}/:id/boats`}
                        render={() => <PescaliceCompanyBoatsSubSection company={company} rootUrl="/pescalice" />}
                    />

                    <Route
                        exact
                        path={`${this.props.baseUrl}/:id`}
                        render={() => <Redirect to={`${this.props.baseUrl}/${company.id}/info`} />}
                    />
                </div>
            </div>
        );
    }
}
