import React from 'react';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { observable, action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import { withRouter } from 'react-router';

import { i18n } from '@SUPPORT/i18n';
import { validator } from '@SUPPORT/validator';
import { parseFloatingNum } from '@SUPPORT/utils';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class BoatInfoSubSection extends React.Component {
    @observable busy = false;
    @observable form = null;

    componentDidUpdate(prevProps) {
        if (this.props.boat && prevProps.boat && this.props.boat.id !== prevProps.boat.id) {
            this._update();
        }
    }

    get _countryCode() {
        return (
            (this.props.store.session.info.user && this.props.store.session.info.user.countryCode) ||
            'fr'
        ).toUpperCase();
    }

    @computed
    get _motorCorrespondance() {
        const motor = parseFloatingNum(this.form.select('motor').value);
        if (isNaN(motor)) {
            return ' ';
        }

        const unit = this.form.select('motorUnit').value;
        if (unit === 'cv') {
            return (motor * 0.735499).toLocaleString(i18n.language); // 0.735499 sur le convertisseur d'unité de Google
        } else if (unit === 'kw') {
            return (motor * 1.35962).toLocaleString(i18n.language); // 1.35962 sur le convertisseur d'unité de Google
        }

        return ' ';
    }

    @computed
    get _motorUnitCorrespondance() {
        const unit = this.form.select('motorUnit').value;
        if (unit === 'cv') {
            return 'kw';
        } else if (unit === 'kw') {
            return 'cv';
        }

        return ' ';
    }

    @action
    _setBusy(busy = true) {
        this.props.store.app.setBusy(busy);
        this.busy = busy;
    }

    _update = () => {
        this._updateWith(this.props.boat);
    };

    @action
    _updateWith(boat) {
        this.form = new ReactForm(
            {
                fields: {
                    status: {
                        type: 'text',
                        rules: 'required|in:active,inactive,sold,destroyed',
                        value: boat.status,
                        hooks: { onChange: this._handleStatusChange }
                    },
                    registrationDistrict: { rules: 'required|numeric', value: boat.registrationDistrict.id },
                    name: { type: 'text', rules: 'required|string', value: boat.name },
                    registration: { type: 'text', rules: 'required|string', value: boat.registration },
                    size: {
                        type: 'text',
                        rules: 'fnumeric|required_with:sizeUnit',
                        value: boat.size ? Intl.NumberFormat(this._countryCode).format(boat.size.value) : ''
                    },
                    sizeUnit: { type: 'text', rules: 'required_with:size|in:m', value: 'm', defaultValue: 'm' },
                    motor: {
                        type: 'text',
                        rules: 'fnumeric|required_with:motorUnit',
                        value: boat.motor ? Intl.NumberFormat(this._countryCode).format(boat.motor.value) : '',
                        hooks: { onChange: () => this.form.validate() }
                    },
                    motorUnit: {
                        rules: 'string|in:cv,kw|required_with:motor',
                        value: boat.motor ? boat.motor.unit : '',
                        hooks: { onChange: () => this.form.validate() }
                    },
                    capacity: {
                        type: 'text',
                        rules: 'fnumeric|required_with:capacityUnit',
                        value: boat.capacity ? Intl.NumberFormat(this._countryCode).format(boat.capacity.value) : '',
                        hooks: { onChange: () => this.form.validate() }
                    },
                    capacityUnit: {
                        rules: 'string|in:gt,ums|required_with:capacity',
                        value: boat.capacity ? boat.capacity.unit : '',
                        hooks: { onChange: () => this.form.validate() }
                    },
                    yearBuilt: { type: 'text', rules: 'digits:4', placeholder: 'yyyy', value: boat.yearBuilt || '' },
                    yearAcquired: {
                        type: 'text',
                        rules: 'digits:4',
                        placeholder: 'yyyy',
                        value: boat.yearAcquired || ''
                    },
                    navCategory: { rules: 'integer', value: boat.navCategory || '' },
                    producersOrg: { rules: 'numeric', value: boat.producersOrg ? boat.producersOrg.id : '' },
                    hasVMS: { type: 'checkbox', value: boat.hasVMS },
                    hasLogbook: { type: 'checkbox', value: boat.hasLogbook },
                    hasOtherGeoLoc: {
                        type: 'checkbox',
                        value: !!boat.geolocator,
                        hooks: {
                            onChange: (field) => {
                                if (!field.value) {
                                    this.form.select('geolocator').set('');
                                }
                                this.form.validate();
                            }
                        }
                    },
                    geolocator: { type: 'text', value: boat.geolocator, rules: 'required_with:hasOtherGeoLoc' },
                    dockingDistrict: { rules: 'numeric', value: boat.dockingDistrict ? boat.dockingDistrict.id : '' },
                    crewSize: { type: 'text', rules: 'integer', value: boat.crewSize || '' },
                    notes: { rules: 'string', value: boat.notes || '' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true, validationDebounceOptions: { leading: true, trailing: true } }
            }
        );
    }

    _handleStatusChange = async () => {
        const status = this.form.select('status').value;
        if (status !== 'sold') {
            return;
        }

        const { app, boats } = this.props.store;
        const { boat, history } = this.props;
        await boat.listPeople();

        const principalOwnersName = boat.principalOwner
            ? ` (${boat.principalOwner.firstName} ${boat.principalOwner.lastName})`
            : '';
        const newName = `EX ${boat.name}${principalOwnersName}`;
        const newRegistration = `EX ${boat.registration}${principalOwnersName}`;

        app.alert({
            title: 'Vente du navire',
            message1:
                'Voulez vous vraiment marquer le navire comme "vendu" ?<br/><br/>Vous pouvez choisir de cloner le navire vendu afin de lui attribuer un nouveau bénéficiaire ou bien simplement clôturer le navire vendu.',
            message2: `Dans tous les cas le navire vendu sera renommé en : "${newName}", sera réimmatriculé en : "${newRegistration}" et le navire ne sera plus modifiable. Les demandes rattachées à ce navire seront conservées et toujours visibles dans Pescalice.`,

            cancelCallback: () => {
                this.form.select('status').reset();
                app.dismissAlert();
            },

            label0: 'Cloner',
            validation0Callback: () => {
                app.setAlertBusy();
                boat.markAsSold(newName, newRegistration, true).then((boat) => {
                    boats.add(boat);
                    app.dismissAlert();
                    history.push(`/pescalice/boat/${boat.id}/info`);
                });
            },

            label1: 'Clôturer',
            validationCallback: () => {
                app.setAlertBusy();
                boat.markAsSold(newName, newRegistration).then(() => {
                    app.dismissAlert();
                });
            }
        });
    };

    _reset = () => {
        this.form.reset();
    };

    _save = (evt) => {
        evt.preventDefault();

        const values = this.form.values();
        values.registrationDistrict = parseInt(values.registrationDistrict, 10);
        values.dockingDistrict = values.dockingDistrict !== '' ? parseInt(values.dockingDistrict, 10) : null;
        values.producersOrg = values.producersOrg !== '' ? parseInt(values.producersOrg) : null;
        values.size = values.size ? { value: parseFloatingNum(values.size), unit: values.sizeUnit } : null;
        values.motor = values.motor ? { value: parseFloatingNum(values.motor), unit: values.motorUnit } : null;
        values.capacity = values.capacity
            ? { value: parseFloatingNum(values.capacity), unit: values.capacityUnit }
            : null;
        values.notes = values.notes === '' ? null : values.notes;

        const { boat } = this.props;
        values.purchasedId = boat.purchasedId;

        this._setBusy();
        this.props.store.boats
            .updateBoat(this.props.boat.id, values)
            .then((data) => {
                applySnapshot(this.props.boat, data);
                this._updateWith(this.props.boat);
                this._setBusy(false);
            })
            .catch((err) => {
                if (err.wrapped.status === 'error') {
                    if (err.wrapped.errorMessage === 'registration value already exists') {
                        alert('Un navire avec cette même immatriculation existe déjà');
                    } else {
                        alert('Erreur inconnue');
                    }
                }
                this._setBusy(false);
            });
    };

    render() {
        const images = [];
        const { store, t } = this.props;
        const { session } = store;

        const canEdit = !session.info.user.hasTag('psc_ro');
        const readOnly = (this.form && this.form.select('status').value === 'sold') || !canEdit;

        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="panel">
                    <div className="scroll">
                        {this.form && (
                            <form>
                                <div>
                                    <label>Statut :</label>
                                    <select {...this.form.select('status').bind()} disabled={readOnly}>
                                        <option value="active">En activité</option>
                                        <option value="inactive">En arrêt</option>
                                        <option value="sold">Vendu</option>
                                        <option value="destroyed">Détruit</option>
                                    </select>
                                </div>
                                <div className="return" />
                                <div className="required">
                                    <label>Quartier maritime :</label>
                                    <select {...this.form.select('registrationDistrict').bind()} disabled={readOnly}>
                                        {this.props.store.boats.registrationDistricts.map((district) => (
                                            <option value={district.id} key={district.id}>
                                                {district.code} - {district.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="required">
                                    <label>Immatriculation :</label>
                                    <input {...this.form.select('registration').bind()} readOnly={readOnly} />
                                </div>
                                <div className="required">
                                    <label>Nom du bateau :</label>
                                    <input {...this.form.select('name').bind()} readOnly={readOnly} />
                                </div>
                                <div className="required">
                                    <label>Taille (hors tout - en mètres) :</label>
                                    <input className="short" {...this.form.select('size').bind()} readOnly={readOnly} />
                                </div>
                                <div className="required">
                                    <label>Puissance motrice :</label>
                                    <div className="together">
                                        <input
                                            className="short"
                                            {...this.form.select('motor').bind()}
                                            readOnly={readOnly}
                                        />
                                        <select {...this.form.select('motorUnit').bind()} disabled={readOnly}>
                                            <option value="">Sélectionnez...</option>
                                            <option value="cv">Chevaux</option>
                                            <option value="kw">Kilowatts</option>
                                        </select>
                                        <input
                                            type="text"
                                            className="short right"
                                            value={this._motorCorrespondance}
                                            readOnly
                                        />
                                        <label>{this._motorUnitCorrespondance}</label>
                                    </div>
                                </div>
                                <div>
                                    <label>Jauge brute :</label>
                                    <div>
                                        <input
                                            className="short"
                                            {...this.form.select('capacity').bind()}
                                            readOnly={readOnly}
                                        />
                                        <select {...this.form.select('capacityUnit').bind()} disabled={readOnly}>
                                            <option value="">Sélectionnez...</option>
                                            <option value="gt">Tonneaux</option>
                                            <option value="ums">UMS</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <label>Année de construction :</label>
                                    <input
                                        className="short"
                                        {...this.form.select('yearBuilt').bind()}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div>
                                    <label>Année d&apos;acquisition :</label>
                                    <input
                                        className="short"
                                        {...this.form.select('yearAcquired').bind()}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div>
                                    <label>Catégorie de navigation :</label>
                                    <select {...this.form.select('navCategory').bind()} disabled={readOnly}>
                                        <option value="">Sélectionnez...</option>
                                        <option value="1">Catégorie 1</option>
                                        <option value="2">Catégorie 2</option>
                                        <option value="3">Catégorie 3</option>
                                        <option value="4">Catégorie 4</option>
                                        <option value="5">Catégorie 5</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Organisation de producteurs :</label>
                                    <select {...this.form.select('producersOrg').bind()} disabled={readOnly}>
                                        <option value="">Non spécifiée...</option>
                                        {this.props.store.boats.producersOrgs.map((org) => (
                                            <option value={org.id} key={org.id}>
                                                {org.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <h4>Systèmes embarqués</h4>
                                <div>
                                    <input {...this.form.select('hasVMS').bind()} disabled={readOnly} />
                                    <label htmlFor={this.form.select('hasVMS').id}>VMS</label>
                                </div>
                                <div>
                                    <input {...this.form.select('hasLogbook').bind()} disabled={readOnly} />
                                    <label htmlFor={this.form.select('hasLogbook').id}>LogBook électronique</label>
                                </div>
                                <div className="return" />
                                <div>
                                    <label />
                                    <input {...this.form.select('hasOtherGeoLoc').bind()} disabled={readOnly} />
                                    <label htmlFor={this.form.select('hasOtherGeoLoc').id}>
                                        Autre système de géolocalisation
                                    </label>
                                </div>
                                <div>
                                    <label>Nom du système de géolocalisation :</label>
                                    <input
                                        {...this.form.select('geolocator').bind()}
                                        readOnly={!this.form.select('hasOtherGeoLoc').value}
                                    />
                                </div>
                                <h4>Infos complémentaires</h4>
                                <div>
                                    <label>Port de débarquement :</label>
                                    <select {...this.form.select('dockingDistrict').bind()} disabled={readOnly}>
                                        <option value="">Non spécifié</option>
                                        <option value="" disabled></option>
                                        {this.props.store.boats.registrationDistricts.map((district) => (
                                            <option value={district.id} key={district.id}>
                                                {district.code} - {district.name}
                                            </option>
                                        ))}
                                        <option value="" disabled></option>
                                        {this.props.store.boats.dockingDistricts.map((district) => (
                                            <option value={district.id} key={district.id}>
                                                {district.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label>Effectif de l’équipage :</label>
                                    <input
                                        className="short"
                                        {...this.form.select('crewSize').bind()}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="full">
                                    <label>Notes :</label>
                                    <textarea {...this.form.select('notes').bind()} disabled={readOnly} />
                                </div>
                                <h4>Photos du navire :</h4>
                                {images.map((image) => (
                                    <div className="block" key={image.id}>
                                        <img src={image.url} />
                                    </div>
                                ))}
                                {canEdit && (
                                    <div className="full">
                                        <button disabled={readOnly}>Ajouter une photo</button>
                                    </div>
                                )}
                            </form>
                        )}
                    </div>
                    {canEdit && (
                        <div className="exit">
                            <div />
                            <div>
                                <button onClick={this._reset} disabled={!this.form || !this.form.isDirty || this.busy}>
                                    {t('common:reset')}
                                </button>
                                <button
                                    onClick={this._save}
                                    disabled={
                                        !this.form ||
                                        !this.form.isDirty ||
                                        (this.form.isDirty && !this.form.isValid) ||
                                        this.busy
                                    }
                                >
                                    {t('common:save')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </OrgChangeObserver>
        );
    }
}
