import { types, getRoot, flow, resolveIdentifier, applySnapshot } from 'mobx-state-tree';

import {
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    subDays,
    subMonths
} from 'date-fns';

import { api } from '@SUPPORT/api';
import { User, UserStatus } from '@STORES/common/user';
import { DeclarationList } from '@STORES/common/declaration';
import { License } from '@STORES/common/license';

import { byFullName, downloadFileFromData, formatLocaleDate, formatPhoneNumber, buildCSVData } from '@SUPPORT/utils';

const UserRuleInfo = types.model('UserRuleInfo', {
    allowed: types.boolean,
    quota: types.number,
    zones: types.map(types.boolean)
});
const UserSpeciesRuleMap = types.map(UserRuleInfo);

export const UsersStore = types
    .model('UsersStore', {
        nameFilter: types.optional(types.string, ''),
        statusFilter: types.maybeNull(UserStatus),
        speciesFilter: types.optional(types.string, ''),
        zoneFilter: types.optional(types.string, ''),
        typeFilter: types.optional(types.string, ''),
        zipCodeFilter: types.optional(types.string, ''),
        paymentInfosFilter: types.optional(types.string, ''),
        declarationStatusFilter: types.optional(
            types.enumeration('declarationStatusFilter', ['', 'did-declare', 'did-not-declare']),
            ''
        ),
        declarationMomentFilter: types.optional(
            types.enumeration('declarationMomentFilter', [
                '',
                'today',
                'yesterday',
                'this-week',
                'last-week',
                'this-month',
                'last-month',
                'custom'
            ]),
            ''
        ),
        declarationFromDateFilter: types.maybeNull(types.Date),
        declarationToDateFilter: types.maybeNull(types.Date),
        validationsFilter: types.optional(
            types.enumeration('validationsFilter', [
                '',
                'did-validate-this-month',
                'did-not-validate-this-month',
                'did-validate-last-month',
                'did-not-validate-last-month',
                'are-up-to-date',
                'are-not-up-to-date'
            ]),
            ''
        ),

        list: types.array(User),
        archivedList: types.array(User),
        filteredList: types.array(types.reference(User)),
        listIsLoading: false,
        listLoaded: false,
        selection: types.map(types.reference(User)),

        declarations: types.maybeNull(DeclarationList),
        licenses: types.maybeNull(types.array(License)),
        rules: types.optional(UserSpeciesRuleMap, {}),

        showNewUserDialog: false,
        showAvatarDialog: false
    })
    .views((self) => {
        return {
            isSelected(userId) {
                return self.selection.get(userId) !== undefined;
            },

            userWithId(userId) {
                return resolveIdentifier(User, self.list, userId);
            },

            get groupedLicenses() {
                return self.licenses.reduce((arr, license) => {
                    const group = arr.find((g) => g.name === license.name);
                    if (group) {
                        group.seasons.push(license.season);
                    } else {
                        arr.push({ name: license.name, seasons: [license.season] });
                    }
                    return arr;
                }, []);
            },

            get licensesSeasons() {
                const seasons = self.licenses.reduce((s, license) => s.add(license.season), new Set());
                return Array.from(seasons).sort((s1, s2) => s1.localeCompare(s2));
            },

            licensesForSeason(season) {
                return self.licenses.filter((license) => license.season === season);
            },

            licensesOrgsForSeason(season) {
                const orgs = self
                    .licensesForSeason(season)
                    .reduce(
                        (m, license) =>
                            m.set(license.orgId, { id: license.orgId, code: license.orgCode, name: license.orgName }),
                        new Map()
                    );
                return Array.from(orgs.values()).sort((o1, o2) => o1.code.localeCompare(o2.code));
            },

            licensesForSeasonInOrg(season, orgId) {
                return self.licenses.filter((license) => license.season === season && license.orgId === orgId);
            }
        };
    })
    .actions((self) => {
        return {
            setStatusFilter: (filter) => {
                self.statusFilter = filter || null;
            },

            setSpeciesFilter: (filter) => {
                self.speciesFilter = filter;
            },

            setZoneFilter: (filter) => {
                self.zoneFilter = filter;
            },

            setTypeFilter: (filter) => {
                self.typeFilter = filter;
            },

            setZipCodeFilter: (filter) => {
                self.zipCodeFilter = filter;
            },

            setPaymentInfosFilter: (filter) => {
                self.paymentInfosFilter = filter;
            },

            setNameFilter: (filter) => {
                self.nameFilter = filter;
            },

            setDeclarationStatusFilter: (filter) => {
                self.declarationStatusFilter = filter;
            },

            setDeclarationMomentFilter: (filter) => {
                self.declarationMomentFilter = filter;
                switch (filter) {
                    case '': {
                        self.setFromDeclarationDateFilter(null);
                        self.setToDeclarationDateFilter(null);
                        break;
                    }

                    case 'today': {
                        self.setFromDeclarationDateFilter(startOfToday());
                        self.setToDeclarationDateFilter(endOfToday());
                        break;
                    }

                    case 'yesterday': {
                        self.setFromDeclarationDateFilter(startOfYesterday());
                        self.setToDeclarationDateFilter(endOfYesterday());
                        break;
                    }

                    case 'this-week': {
                        const now = Date.now();
                        self.setFromDeclarationDateFilter(startOfWeek(now, { weekStartsOn: 1 }));
                        self.setToDeclarationDateFilter(endOfWeek(now, { weekStartsOn: 1 }));
                        break;
                    }

                    case 'last-week': {
                        const lastWeek = subDays(Date.now(), 7);
                        self.setFromDeclarationDateFilter(startOfWeek(lastWeek, { weekStartsOn: 1 }));
                        self.setToDeclarationDateFilter(endOfWeek(lastWeek, { weekStartsOn: 1 }));
                        break;
                    }

                    case 'this-month': {
                        const now = Date.now();
                        self.setFromDeclarationDateFilter(startOfMonth(now));
                        self.setToDeclarationDateFilter(endOfMonth(now));
                        break;
                    }

                    case 'last-month': {
                        const lastMonth = subMonths(Date.now(), 1);
                        self.setFromDeclarationDateFilter(startOfMonth(lastMonth));
                        self.setToDeclarationDateFilter(endOfMonth(lastMonth));
                        break;
                    }

                    case 'custom':
                    default: {
                        break;
                    }
                }
            },

            setFromDeclarationDateFilter: (date) => {
                self.declarationFromDateFilter = date;
            },

            setToDeclarationDateFilter: (date) => {
                self.declarationToDateFilter = date;
            },

            setValidationsFilter: (filter) => {
                self.validationsFilter = filter;
            },

            resetFilters: () => {
                self.setNameFilter('');
                self.setStatusFilter('');
                self.setSpeciesFilter('');
                self.setZoneFilter('');
                self.setTypeFilter('');
                self.setZipCodeFilter('');
                self.setPaymentInfosFilter('');
                self.setDeclarationStatusFilter('');
                self.setDeclarationMomentFilter('');
                self.setValidationsFilter('');
            },

            listUsers: flow(function* (forceUpdate = false, allUsers = false) {
                if (self.listIsLoading || (self.listLoaded && !forceUpdate)) {
                    return self.list;
                }

                const { app, session } = getRoot(self);
                app.setBusy();

                try {
                    const filters = {};
                    if (self.nameFilter) {
                        filters.name = self.nameFilter;
                    }
                    if (self.statusFilter || allUsers) {
                        filters.status = allUsers ? '*' : self.statusFilter;
                    }
                    if (self.speciesFilter) {
                        filters.species = self.speciesFilter;
                    }
                    if (self.zoneFilter) {
                        filters.zone = self.zoneFilter;
                    }
                    if (self.typeFilter) {
                        filters.type = self.typeFilter;
                    }
                    if (self.zipCodeFilter) {
                        filters.zipCode = self.zipCodeFilter;
                    }
                    if (self.paymentInfosFilter) {
                        filters.paymentInfos = self.paymentInfosFilter;
                    }
                    if (self.declarationStatusFilter) {
                        filters.declarationStatus = self.declarationStatusFilter;
                    }
                    if (self.declarationFromDateFilter) {
                        filters.fromDate = self.declarationFromDateFilter.toISOString();
                    }
                    if (self.declarationToDateFilter) {
                        filters.toDate = self.declarationToDateFilter.toISOString();
                    }
                    if (self.validationsFilter) {
                        filters.validations = self.validationsFilter;
                    }

                    const info = yield session.getSession();
                    const apiCall =
                        session.info.user.type === 'manager'
                            ? api.listPescaliceUsers({ ...filters, withBankInfo: true })
                            : api.listOrgUsers(info.org.id, filters);
                    const response = yield apiCall;

                    const data = response.data || [];
                    if (allUsers || Object.keys(filters).length === 0) {
                        self.list = data.sort(byFullName);
                    } else if (self.statusFilter === 'archived') {
                        self.archivedList = data.sort(byFullName);
                    }

                    self.filteredList = data.sort(byFullName).map((user) => user.id);
                    self.listIsLoading = false;
                    self.listLoaded = true;
                } finally {
                    app.setBusy(false);
                }
            }),

            downloadFilteredUsers: (filename) => {
                const headers = [
                    'Identifiant',
                    'Nom',
                    'Prénom',
                    'Prénom 2',
                    'Numéro de Marin',
                    'Régime Social',
                    'Numéro mobile 1',
                    'Numéro mobile 2',
                    'Numéro fixe',
                    'Email',
                    'Date de Naissance',
                    'Adresse 1',
                    'Adresse 2',
                    'Code Postal',
                    'Ville'
                ];

                const rows = self.filteredList.map((user) => [
                    user.identifier || '',
                    user.lastName || '',
                    user.firstName || '',
                    user.firstName2 || '',
                    user.licenseNum || '',
                    user.socialRegime || '',
                    user.mobileNum1 ? formatPhoneNumber(user.mobileNum1) : '',
                    user.mobileNum2 ? formatPhoneNumber(user.mobileNum2) : '',
                    user.phoneNum ? formatPhoneNumber(user.phoneNum) : '',
                    user.email || '',
                    user.birthDate ? formatLocaleDate(user.birthDate, 'dd/MM/yyyy') : '',
                    `"${user.address1 || ''}"`,
                    `"${user.address2 || ''}"`,
                    user.zipCode || '',
                    user.city || ''
                ]);

                const blob = buildCSVData(headers, rows);
                downloadFileFromData(blob, filename);
            },

            selectAllUsers: (select) => {
                if (select) {
                    self.selection.replace(self.list.map((user) => [user.id, user]));
                } else {
                    self.selection.clear();
                }
            },

            selectUser: (userId, select) => {
                const user = resolveIdentifier(User, self.list, userId);
                if (select) {
                    self.selection.put(user);
                } else {
                    self.selection.delete(user.id);
                }
            },

            createUser: flow(function* (data, fallbackOrgCode = '__') {
                const { app, session } = getRoot(self);
                app.setBusy();

                try {
                    const info = yield session.getSession();
                    let org = info.org ? info.org.id : null;
                    if (!org && fallbackOrgCode) {
                        const orgsResponse = yield api.listOrgs();
                        const fallbackOrg = (orgsResponse.data || []).find((o) => o.code === fallbackOrgCode);
                        org = fallbackOrg ? fallbackOrg.id : null;
                    }

                    const response = yield api.createUser(org, data);
                    self.list.push(response.data);
                    self.list = self.list.sort(byFullName);
                    return response.data;
                } catch (err) {
                    console.error(err);
                } finally {
                    app.setBusy(false);
                }
            }),

            addExternalUser: flow(function* (userId) {
                const { app, session } = getRoot(self);
                app.setBusy();

                try {
                    const info = yield session.getSession();
                    yield api.addExternalUser(info.org.id, userId);
                } finally {
                    app.setBusy(false);
                }
            }),

            listDeclarations: flow(function* (userId) {
                const { app } = getRoot(self);
                app.setBusy();

                self.declarations = [];
                try {
                    const response = yield api.listUserDeclarations(userId);
                    self.declarations = DeclarationList.create(response.data || []);
                } catch (err) {
                    console.log(err);
                } finally {
                    app.setBusy(false);
                }
            }),

            pushDeclaration: (declaration) => {
                self.declarations.push(declaration);
            },

            listLicenses: flow(function* (userId) {
                const { app } = getRoot(self);
                app.setBusy();

                self.licenses = null;
                try {
                    const response = yield api.listUserLicenses(userId);
                    self.licenses = response.data || [];
                } finally {
                    app.setBusy(false);
                }
            }),

            downloadDeclarationsCSV: (userId) => {
                api.downloadUserDeclarationsCSV(userId);
            },

            listRules: flow(function* (userId) {
                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const rules = yield api.listUserRules(userId);
                    self.rules = UserSpeciesRuleMap.create(rules.data);
                } finally {
                    app.setBusy(false);
                }
            }),

            listZones: flow(function* (userId) {
                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const response = yield api.listUserZones(userId);
                    return response.data || [];
                } finally {
                    app.setBusy(false);
                }
            }),

            updateRuleWithSpeciesStatus: flow(function* (userId, species, allow) {
                const { app, session } = getRoot(self);
                app.setBusy();

                try {
                    const info = yield session.getSession();
                    yield api.updateUserSpeciesStatusRule(userId, info.org.id, species, allow);
                    const rule = self.rules.get(species);
                    if (rule) {
                        rule.allowed = allow;
                    }
                } finally {
                    app.setBusy(false);
                }
            }),

            updateRuleWithQuota(species, quota) {
                self.rules.get(species).quota = quota;
            },

            updateRuleWithZoneStatus: flow(function* (userId, species, zoneId, allow) {
                const { app } = getRoot(self);
                app.setBusy();

                try {
                    yield api.updateUserZoneStatusRule(userId, species, zoneId, allow);
                    const rule = self.rules.get(species);
                    if (rule) {
                        rule.zones.set(zoneId, allow);
                    }
                } finally {
                    app.setBusy(false);
                }
            }),

            getUserOrgInfo: flow(function* (orgId) {
                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const response = yield api.getOrgInfo(orgId);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            getUserInfo: flow(function* (userId) {
                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const response = yield api.getUserInfo(userId);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            updateUserInfo: flow(function* (userId, values, returnBankInfos = false) {
                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const response = yield api.updateUserInfo(userId, values, returnBankInfos);
                    const user = resolveIdentifier(User, self.list, userId);
                    applySnapshot(user, response.data);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            setShowNewUserDialog: (show = true) => {
                self.showNewUserDialog = show;
            },

            setShowAvatarDialog: (show = true) => {
                const { app } = getRoot(self);
                app.setModal(show);
                self.showAvatarDialog = show;
            },

            listAvatarImages: flow(function* (userId) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.listUserAvatarImage(userId);
                    return response.data;
                } catch (err) {
                    console.error(err);
                } finally {
                    app.setBusy(false);
                }
            }),

            uploadAvatarImage: flow(function* (userId, file) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.uploadUserAvatarImage(userId, file);
                    return response.data;
                } catch (err) {
                    console.error(err);
                } finally {
                    app.setBusy(false);
                }
            }),

            deleteAvatarImage: flow(function* (userId, imageId) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.deleteUserAvatarImage(userId, imageId);
                    return response.data;
                } catch (err) {
                    console.error(err);
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
