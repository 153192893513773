import React from 'react';
import styled from 'styled-components';

import { useUserImagesQuery } from '@SUPPORT/queries';

const Container = styled.div`
    width: 8rem;
    height: 10rem;
`;

export function UserAvatar({ userId }) {
    const { data: images } = useUserImagesQuery(userId);
    const image = images ? images[0] : null;

    return (
        <Container>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 80 100">
                <image
                    xlinkHref={image ? image.url : '/graphics/silhouette@2x.png'}
                    width="80"
                    height="100"
                    clipPath="url(#clipping)"
                />
                <image xlinkHref="/graphics/shapesvgstroke.svg" width="80" height="100" />
            </svg>
        </Container>
    );
}
