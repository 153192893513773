import { types, getRoot, flow, resolveIdentifier, detach, getSnapshot } from 'mobx-state-tree';
import { isValid } from 'date-fns';

import { AuditEntry } from '@STORES/common/audit';

import { api } from '@SUPPORT/api';
import { ISODate } from '@SUPPORT/isoDate';
import { reportError, userInitials, matchNormalized, safeRegExp } from '@SUPPORT/utils';

export const BoatPerson = types
    .model('BoatPerson', {
        userId: types.number,
        firstName: types.string,
        lastName: types.string,

        companyId: types.maybeNull(types.number),
        companyName: types.maybeNull(types.string),
        companyType: types.maybeNull(types.string),
        companyRegistration: types.maybeNull(types.string),

        isRenter: types.boolean,
        rentingStartDate: types.maybeNull(ISODate),
        rentingEndDate: types.maybeNull(ISODate),

        isOwner: types.boolean,
        isPrincipalOwner: types.boolean,
        ownerShares: types.maybeNull(types.number),

        isEmbedded: types.boolean
    })
    .volatile(() => {
        return {
            isBeingCreated: false
        };
    })
    .views((self) => {
        return {
            get initials() {
                return userInitials(self.firstName, self.lastName);
            },

            get compoundKey() {
                return self.companyId ? `${self.userId}-${self.companyId}` : self.userId.toString();
            },

            fullName(lastNameFirst = true) {
                if (self.firstName2) {
                    if (lastNameFirst) {
                        return `${self.lastName.toUpperCase()} ${self.firstName} ${self.firstName2}`.trim();
                    }

                    return `${self.firstName} ${self.firstName2} ${self.lastName.toUpperCase()}`.trim();
                }

                if (lastNameFirst) {
                    return `${self.lastName.toUpperCase()} ${self.firstName}`.trim();
                }

                return `${self.firstName} ${self.lastName.toUpperCase()}`.trim();
            },

            fullCompanyName(fallback = '') {
                if (!self.companyId) {
                    return fallback;
                }

                const parts = [];
                if (self.companyType) {
                    parts.push(self.companyType);
                }
                if (self.companyName) {
                    parts.push(self.companyName);
                }

                if (parts.lenght === 0) {
                    return fallback;
                }

                return parts.join(' ');
            }
        };
    })
    .actions((self) => {
        return {
            setIsBeingCreated: (created = true) => {
                self.isBeingCreated = created;
            },

            syncWithUser: (user) => {
                self.userId = user.id;
                self.firstName = user.firstName;
                self.lastName = user.lastName;
                self.companyId = user.companyId;
                self.companyName = user.companyName;
                self.companyType = user.companyType;
                self.companyRegistration = user.companyRegistration;
            },

            reset: () => {
                self.userId = Math.round(Math.random() * 1000000);
                self.firstName = '';
                self.lastName = '';
            },

            setRentingStartDate: (date) => {
                self.rentingStartDate = date;
            },

            setRentingEndDate: (date) => {
                self.rentingEndDate = date;
            },

            setMainOwner: (isMain) => {
                self.isPrincipalOwner = isMain;
            },

            setOwnerShare: (share) => {
                self.ownerShares = share;
            }
        };
    });

export const BoatPersonList = types.array(BoatPerson);

function _newPerson(isRenter, isOwner, embedded, principalOwner = false) {
    const person = BoatPerson.create({
        userId: Math.round(Math.random() * 1000000),
        firstName: '',
        lastName: '',

        companyId: null,
        companyName: null,
        companyType: null,
        companyRegistration: null,

        isRenter: isRenter,
        rentingStartDate: null,
        rentingEndDate: null,

        isOwner: isOwner,
        isPrincipalOwner: principalOwner,
        ownerShares: principalOwner ? 100 : null,

        isEmbedded: embedded
    });

    person.setIsBeingCreated();

    return person;
}

const BoatMotor = types.model('BoatMotor', {
    value: types.number,
    unit: types.enumeration('MotorUnit', ['cv', 'kw'])
});

const BoatSize = types.model('BoatSize', {
    value: types.number,
    unit: types.enumeration('SizeUnit', ['m'])
});

const BoatCapacity = types.model('BoatCapacity', {
    value: types.number,
    unit: types.enumeration('BoatCapacityUnit', ['ums', 'gt'])
});

const BoatRegistrationDistrict = types.model('BoatRegistrationDistrict', {
    id: types.identifierNumber,
    code: types.string,
    name: types.string,
    countryCode: types.string
});

const ProducersOrg = types.model('ProducersOrg', {
    id: types.identifierNumber,
    name: types.string,
    countryCode: types.string
});

function mapFieldValue(field, value) {
    switch (field) {
        case 'vms':
        case 'logbook':
            return value;
        default:
            return value ? value : '-';
    }
}

export const Boat = types
    .model('Boat', {
        id: types.identifierNumber,
        creationDate: ISODate,
        registrationDistrict: types.reference(BoatRegistrationDistrict),
        registrationDistrictCode: types.string,
        registrationDistrictName: types.string,
        name: types.string,
        status: types.enumeration('BoatStatus', ['active', 'inactive', 'sold', 'destroyed']),
        partial: types.boolean,

        registration: types.string,
        yearAcquired: types.maybeNull(types.number),
        yearBuilt: types.maybeNull(types.number),

        motor: types.maybeNull(BoatMotor),
        size: types.maybeNull(BoatSize),
        capacity: types.maybeNull(BoatCapacity),

        hasVMS: types.boolean,
        hasLogbook: types.boolean,
        geolocator: types.maybeNull(types.string),

        dockingDistrict: types.maybeNull(types.reference(BoatRegistrationDistrict)),
        dockingDistrictCode: types.maybeNull(types.string),
        dockingDistrictName: types.maybeNull(types.string),
        crewSize: types.maybeNull(types.number),
        navCategory: types.maybeNull(types.number),

        producersOrg: types.maybeNull(types.reference(ProducersOrg)),

        notes: types.maybeNull(types.string),

        people: BoatPersonList,
        ownerIds: types.maybeNull(types.array(types.number)),
        ownerNames: types.maybeNull(types.array(types.string)),
        removals: types.array(
            types.model({
                person: BoatPerson,
                reason: types.string
            })
        ),

        purchasedId: types.maybeNull(types.number)
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        creationDate:
            typeof snapshot.creationDate === 'string' ? new Date(snapshot.creationDate) : snapshot.creationDate
    }))
    .views((self) => {
        return {
            get fullRegistration() {
                return self.registrationDistrict
                    ? `${self.registrationDistrict.code} ${self.registration}`
                    : self.registration;
            },

            get fullDisplayName() {
                return `${self.name} - ${self.fullRegistration}`;
            },

            get lengthInMeters() {
                return self.size ? self.size.value : 0;
            },

            get motorPowerInWatts() {
                return self.motor
                    ? self.motor.unit === 'kw'
                        ? self.motor.value * 1000
                        : self.motor.value * 735.499 // 0.735499 sur le convertisseur d'unité de Google
                    : 0;
            },

            get renter() {
                return self.people.find((person) => person.isRenter);
            },

            get owners() {
                return self.people.filter((person) => person.isOwner);
            },

            get sailors() {
                return self.people.filter((person) => !person.isOwner && !person.isRenter && !person.isEmbedded);
            },

            get embeddedSailors() {
                return self.people.filter((person) => !person.isOwner && !person.isRenter && person.isEmbedded);
            },

            get principalOwner() {
                return self.people.find((person) => person.isPrincipalOwner);
            },

            get validPeople() {
                if (self.people.length === 0) {
                    return true;
                }

                for (let i = 0; i < self.people.length; i++) {
                    const person = self.people[i];
                    if (!person.userId || !person.firstName || !person.lastName) {
                        console.warn('Invalid person');
                        return false;
                    }
                }

                if (self.renter && self.renter.rentingStartDate && !isValid(self.renter.rentingStartDate)) {
                    console.warn('Invalid renter start date');
                    return false;
                }

                if (self.renter && self.renter.rentingEndDate && !isValid(self.renter.rentingEndDate)) {
                    console.warn('Invalid renter end date');
                    return false;
                }

                const mainOwners = self.owners.reduce((c, p) => c + (p.isPrincipalOwner ? 1 : 0), 0);
                if (mainOwners !== 1) {
                    console.warn('Invalid number of owner(s) :', mainOwners);
                    return false;
                }

                const invalidShares = self.owners.some((p) => !p.ownerShares);
                if (invalidShares) {
                    console.warn('At least one owner has a share number equal to zero');
                    return false;
                }

                const totalShares = self.owners.reduce((s, p) => s + p.ownerShares, 0);
                if (totalShares !== 100) {
                    console.warn('Invalid total owner shares: ', totalShares);
                    return false;
                }

                return true;
            },

            personWithId(personId, companyId = null) {
                return self.people.find((person) => person.userId === personId && person.companyId === companyId);
            }
        };
    })
    .actions((self) => {
        return {
            listPeople: flow(function* () {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.listBoatPeople(self.id);
                    self.people = BoatPersonList.create(response.data || []);
                } catch (err) {
                    console.log(err);
                    self.people = BoatPersonList.create([]);
                } finally {
                    app.setBusy(false);
                }
            }),

            savePeople: flow(function* () {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    yield Promise.all(
                        self.removals.map((removal) =>
                            api.deleteBoatPerson(self.id, removal.person.userId, removal.reason)
                        )
                    );
                    self.removals = [];

                    yield api.updateBoatPeople(self.id, self.people);
                    self.people.forEach((person) => person.setIsBeingCreated(false));
                } catch (err) {
                    reportError(err.wrapped);
                } finally {
                    app.setBusy(false);
                }
            }),

            addNewRenter: () => {
                self.people.push(_newPerson(true, false, false));
            },

            addNewOwner: () => {
                self.people.push(_newPerson(false, true, false, true));
            },

            addNewSailor: (embedded = false) => {
                self.people.push(_newPerson(false, false, embedded));
            },

            addNewEmbeddedSailor: () => {
                self.addNewSailor(true);
            },

            removePerson: (person, reason) => {
                if (person.isBeingCreated) {
                    const index = self.people.indexOf(person);
                    self.people.splice(index, 1);
                } else {
                    detach(person);
                    self.removals.push({ person, reason });
                }
            },

            markAsSold: flow(function* (newName, newRegistration, createClone = false) {
                const newIncarnation = { ...getSnapshot(self) };

                self.status = 'sold';
                self.name = newName;
                self.registration = newRegistration;
                yield api.updateBoat(self.id, getSnapshot(self));

                let newBoat = undefined;
                if (createClone) {
                    delete newIncarnation.id;
                    delete newIncarnation.creationDate;
                    delete newIncarnation.people;
                    delete newIncarnation.ownerIds;
                    delete newIncarnation.ownerNames;
                    delete newIncarnation.removals;
                    newIncarnation.purchasedId = self.id;

                    const response = yield api.createBoat(newIncarnation);
                    newBoat = Boat.create(response.data);
                }

                return newBoat;
            })
        };
    });

const BoatList = types.array(Boat);

const AUDIT_FIELDS = [
    'name',
    'registration',
    'registration_district_id',
    'nav_category',
    'year_built',
    'year_acquired',
    'docking_district_id',
    'size',
    'capacity',
    'motor',
    'crew_size',
    'producers_org_id',
    'vms',
    'logbook',
    'geolocator',
    'producers_org_id',
    'notes'
];

export const BoatsStore = types
    .model('BoatsStore', {
        registrationDistricts: types.array(BoatRegistrationDistrict),
        dockingDistricts: types.array(BoatRegistrationDistrict),
        producersOrgs: types.array(ProducersOrg),

        list: types.optional(BoatList, []),
        partialList: true,
        fetchingList: false,

        boatFilter: '',
        registrationDistrictFilter: '',
        dockingDistrictFilter: '',
        peopleFilter: '',
        categoryFilter: '',
        yearBuiltFilter: '',
        yearAcquiredFilter: '',

        selection: types.map(types.reference(Boat)),
        showNewBoatDialog: false,
        showOwnerRemovalDialog: false
    })
    .views((self) => {
        return {
            isSelected(boatId) {
                return self.selection.get(boatId) !== undefined;
            },

            isValidRegistrationNumber(number) {
                const { app } = getRoot(self);
                const pattern = app.country.boatRegistrationExp;
                if (!pattern) {
                    return true;
                }

                return !!number.match(pattern);
            },

            boatWithId(id) {
                return self.list.find((boat) => boat.id === id);
            },

            registrationDistrictById(id) {
                return self.registrationDistricts.find((rd) => rd.id === id);
            },

            registrationDistrictByCode(code) {
                return self.registrationDistricts.find((rd) => rd.code === code);
            },

            producersOrgById(id) {
                return self.producersOrgs.find((po) => po.id === id);
            },

            get yearsBuiltList() {
                const set = new Set();
                self.list.forEach((boat) => {
                    if (boat.yearBuilt) {
                        set.add(boat.yearBuilt);
                    }
                });
                return Array.from(set).sort().reverse();
            },

            get yearsAcquiredList() {
                const set = new Set();
                self.list.forEach((boat) => {
                    if (boat.yearAcquired) {
                        set.add(boat.yearAcquired);
                    }
                });
                return Array.from(set).sort().reverse();
            },

            get filteredList() {
                return self.list.filter((boat) => {
                    if (self.boatFilter) {
                        const boatFilter = self.boatFilter.trim();
                        const pattern = safeRegExp(boatFilter, 'i');
                        if (
                            boat.name &&
                            !matchNormalized(boat.name, boatFilter) &&
                            boat.registration &&
                            !boat.registration.match(pattern)
                        ) {
                            return false;
                        }
                    }

                    if (self.registrationDistrictFilter) {
                        const pattern = safeRegExp(self.registrationDistrictFilter, 'i');
                        if (boat.registrationDistrictCode && !boat.registrationDistrictCode.match(pattern)) {
                            return false;
                        }
                    }

                    if (self.dockingDistrictFilter) {
                        const pattern = safeRegExp(self.dockingDistrictFilter, 'i');
                        if (
                            boat.dockingDistrictCode &&
                            !boat.dockingDistrictCode.match(pattern) &&
                            boat.dockingDistrictName &&
                            !boat.dockingDistrictName.match(pattern)
                        ) {
                            return false;
                        }
                    }

                    if (self.peopleFilter) {
                        const pattern = safeRegExp(self.peopleFilter, 'i');
                        if (
                            !boat.ownerNames ||
                            boat.ownerNames.length === 0 ||
                            !boat.ownerNames.some((k) => k.match(pattern))
                        ) {
                            return false;
                        }
                    }

                    if (self.categoryFilter && boat.navCategory !== parseInt(self.categoryFilter, 10)) {
                        return false;
                    }

                    if (self.yearBuiltFilter && boat.yearBuilt !== parseInt(self.yearBuiltFilter, 10)) {
                        return false;
                    }

                    if (self.yearAcquiredFilter && boat.yearAcquired !== parseInt(self.yearAcquiredFilter, 10)) {
                        return false;
                    }

                    return true;
                });
            }
        };
    })
    .actions((self) => {
        // This store is dynamically added so we can't get to the "app"
        // store here, we must access it locally from each action
        return {
            loadInitialData: () => {
                return Promise.all([self.listRegistrationDistricts(), self.listProducersOrgs()]);
            },

            listRegistrationDistricts: flow(function* () {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.listBoatRegistrationDistricts();
                    self.registrationDistricts = response.data
                        ? response.data.filter((d) => !d.code.startsWith('_CRPM'))
                        : [];
                    self.dockingDistricts = response.data
                        ? response.data
                              .filter((d) => d.code.startsWith('_CRPM'))
                              .sort((d1, d2) => d1.name.localeCompare(d2.name))
                        : [];
                } finally {
                    app.setBusy(false);
                }
            }),

            listProducersOrgs: flow(function* () {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.listProducersOrgs();
                    self.producersOrgs = response.data || [];
                } finally {
                    app.setBusy(false);
                }
            }),

            listAll: flow(function* (force = false) {
                if ((self.fetchingList || (self.list && self.list.length > 0) || force) && !self.partialList) {
                    return;
                }

                const { app } = getRoot(self);
                app.setBusy();
                self.fetchingList = true;

                try {
                    const response = yield api.listBoats();
                    self.list = response.data ? BoatList.create(response.data) : [];
                    self.partialList = false;
                } finally {
                    app.setBusy(false);
                    self.fetchingList = false;
                }
            }),

            listUserBoats: flow(function* (userId) {
                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const response = yield api.listUserBoats(userId);
                    return response.data || [];
                } catch (err) {
                    console.error(err);
                    return [];
                } finally {
                    app.setBusy(false);
                }
            }),

            fetchBoat: flow(function* (id) {
                const boat = self.boatWithId(id);
                if (boat) {
                    return boat;
                }

                const { app } = getRoot(self);
                app.setBusy();

                try {
                    const response = yield api.fetchBoat(id);
                    const boat = response.data ? Boat.create(response.data) : null;
                    if (boat) {
                        self.add(boat);
                        self.partialList = true;
                    }
                    return boat;
                } finally {
                    app.setBusy(false);
                }
            }),

            selectAllBoats: (select) => {
                if (select) {
                    self.selection.replace(self.list.map((boat) => [boat.id, boat]));
                } else {
                    self.selection.clear();
                }
            },

            selectBoat: (boatId, select) => {
                const boat = resolveIdentifier(Boat, self.list, boatId);
                if (select) {
                    self.selection.put(boat);
                } else {
                    self.selection.delete(boat.id);
                }
            },

            setShowNewBoatDialog: (show = true) => {
                const { app } = getRoot(self);
                self.showNewBoatDialog = show;
                app.setModal(show);
            },

            add: (boat) => {
                self.list.push(boat);
            },

            createBoat: flow(function* (data) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.createBoat({ ...data, hasVMS: false, hasLogBook: false });
                    if (response.data) {
                        const boat = Boat.create(response.data);
                        self.add(boat);
                        return boat;
                    }
                } finally {
                    app.setBusy(false);
                }
            }),

            updateBoat: flow(function* (boatId, data) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.updateBoat(boatId, data);
                    return response.data;
                } finally {
                    app.setBusy(false);
                }
            }),

            resetFilters: () => {
                self.boatFilter = '';
                self.registrationDistrictFilter = '';
                self.dockingDistrictFilter = '';
                self.peopleFilter = '';
                self.categoryFilter = '';
                self.yearBuiltFilter = '';
                self.yearAcquiredFilter = '';
            },

            updateBoatFilter: (query) => {
                self.boatFilter = query;
            },

            updateRegistrationDistrictFilter: (query) => {
                self.registrationDistrictFilter = query;
            },

            updateDockingDistrictFilter: (query) => {
                self.dockingDistrictFilter = query;
            },

            updatePeopleFilter: (query) => {
                self.peopleFilter = query;
            },

            updateCategoryFilter: (query) => {
                self.categoryFilter = query;
            },

            updateYearBuiltFilter: (query) => {
                self.yearBuiltFilter = query;
            },

            updateYearAcquiredFilter: (query) => {
                self.yearAcquiredFilter = query;
            },

            setShowOwnerRemovalDialog: (show = true, refPerson, personsToRemove, onRemoval) => {
                const { app } = getRoot(self);
                self.showOwnerRemovalDialog = show;
                app.setModal(show, { refPerson, personsToRemove, onRemoval });
            },

            listAuditEntries: flow(function* (boatId) {
                const { app } = getRoot(self);
                app.setBusy();
                try {
                    const response = yield api.listBoatAuditEntries(boatId);
                    const data = response.data || [];
                    const entries = [];

                    data.forEach((row) => {
                        const original = JSON.parse(row.original);
                        const diff = JSON.parse(row.difference || '{}');
                        const operations =
                            row.operationType === 'I'
                                ? AUDIT_FIELDS.map((fieldName) => ({
                                      field: fieldName,
                                      previousValue: '-',
                                      newValue: original[fieldName]
                                  }))
                                : Object.keys(diff)
                                      .filter((f) => f !== 'partial')
                                      .map((fieldName) => ({
                                          field: fieldName,
                                          previousValue: mapFieldValue(fieldName, original[fieldName]),
                                          newValue: mapFieldValue(fieldName, diff[fieldName])
                                      }));

                        entries.push(
                            AuditEntry.create({
                                id: row.id,
                                eventDate: row.eventDate,
                                type: row.operationType,
                                userFirstName: row.userFirstName,
                                userLastName: row.userLastName,
                                operations: operations
                            })
                        );
                    });

                    return entries;
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
