import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { BoatInfoSubSection } from './BoatInfoSubSection';
import { BoatOwnersSubSection } from './BoatOwnersSubSection';
import { BoatHistorySubSection } from './BoatHistorySubSection';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';
import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class BoatSection extends React.Component {
    render() {
        const { boats } = this.props.store;
        const boatId = parseInt(this.props.match.params.id, 10);
        const boat = boats.boatWithId(boatId);
        if (!boat) {
            return (
                <div className="selected">
                    <EmptyContent />
                </div>
            );
        }

        let parentBoat = null;
        if (boat.purchasedId) {
            parentBoat = boats.boatWithId(boat.purchasedId);
        }

        const { t, baseUrl, history } = this.props;
        return (
            <div className="selected">
                <div className="header noBottom">
                    <h3>
                        {boat.name}
                        {parentBoat && (
                            <a
                                href="#"
                                style={{ fontSize: 12 }}
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    history.push(`${baseUrl}/${parentBoat.id}/info`);
                                }}
                            >
                                &gt; {parentBoat.name}
                            </a>
                        )}
                        <span>{boat.partial && <i className="codeOrange">(dossier incomplet)</i>}</span>
                    </h3>
                    <nav>
                        <ul>
                            <NavigationLink to={`${baseUrl}/${boat.id}/info`} label={t('common:info')} tag="li" />
                            <NavigationLink to={`${baseUrl}/${boat.id}/owners`} label={t('common:owners')} tag="li" />
                            <NavigationLink to={`${baseUrl}/${boat.id}/audit`} label={t('common:history')} tag="li" />
                        </ul>
                    </nav>
                </div>
                <div className="scroll">
                    <Route path={`${baseUrl}/:id/info`} render={() => <BoatInfoSubSection boat={boat} />} />
                    <Route path={`${baseUrl}/:id/owners`} render={() => <BoatOwnersSubSection boat={boat} />} />
                    <Route path={`${baseUrl}/:id/audit`} render={() => <BoatHistorySubSection boat={boat} />} />

                    <Route
                        exact
                        path={`${baseUrl}/:id`}
                        render={() => <Redirect to={`${baseUrl}/${boat.id}/info`} />}
                    />
                </div>
            </div>
        );
    }
}
