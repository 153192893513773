import React from 'react';
import { withTranslation } from 'react-i18next';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withTranslation(['common'])
export class EmptyContent extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="void">
                <SVGObject objectId="seagull" />
                <h6>&quot;{t('common:nothing-to-see')}&quot;</h6>
            </div>
        );
    }
}
