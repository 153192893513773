import React from 'react';
import ReactForm from 'mobx-react-form';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { validator } from '@SUPPORT/validator';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';
import { formatPhoneNumber, formatPhoneNumberForStorage } from '@SUPPORT/utils';

@withRouter
@inject('store')
@observer
export class CompanyInfoSubSection extends React.Component {
    @observable form = null;
    @observable saving = false;

    _update = () => {
        this._updateWithData(this.props.company);
    };

    get _countryCode() {
        return (
            (this.props.store.session.info.user && this.props.store.session.info.user.countryCode) ||
            'fr'
        ).toUpperCase();
    }

    @action.bound
    _updateWithData(data = {}) {
        const { session } = this.props.store;
        const disabled = session.info.user.hasTag('psc_ro');

        const formFields = {
            name: { type: 'text', rules: 'required|string', value: data.name, disabled },
            type: { rules: 'string', value: data.type || '', disabled },
            registration: {
                type: 'text',
                rules: 'required|string',
                placeholder: 'SIRET, SIREN, numéro TVA...',
                value: data.registration,
                disabled
            },
            registration2: {
                type: 'text',
                rules: 'string',
                placeholder: 'SIRET, SIREN, numéro TVA...',
                value: data.registration2 || '',
                disabled
            },
            mainUser: { type: 'text', value: data.mainUserFullName, disabled },
            mainUserId: { type: 'hidden', value: data.mainUserId },
            mainUserFirstName: { type: 'hidden', value: data.mainUserFirstName },
            mainUserLastName: { type: 'hidden', value: data.mainUserLastName },
            address1: { type: 'text', rules: 'required|string', value: data.address1, disabled },
            address2: { type: 'text', rules: 'string', value: data.address2 || '', disabled },
            zipCode: { type: 'text', rules: 'required|string', value: data.zipCode, disabled },
            city: { type: 'text', rules: 'required|string', value: data.city, disabled },
            countryCode: { type: 'text', rules: 'required|string', value: data.countryCode || 'fr', disabled },
            phoneNum: {
                type: 'text',
                rules: 'string',
                value: data.phoneNum ? formatPhoneNumber(data.phoneNum, 'National', this._countryCode) : '',
                disabled
            },
            email: { type: 'text', rules: 'string|email', value: data.email || '', disabled }
        };

        const users = this.props.store.users.list || [];
        const mainPerson = users.find((u) => !!data && u.id === data.mainUserId);
        const allBankInfos =
            !!mainPerson && mainPerson.bankInfos
                ? JSON.parse(mainPerson.bankInfos)
                : [{ iban: '', bic: '', agency: '', company_id: data.id }];
        this.bankInfos = allBankInfos.find((info) => !!data && info.company_id === data.id);

        formFields[`bankOk-${this.bankInfos ? this.bankInfos.company_id : 'unknown'}`] = {
            type: 'checkbox',
            value: this.bankInfos && !!this.bankInfos.signature_date
        };

        this.form = new ReactForm(
            {
                fields: formFields
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true, validationDebounceOptions: { leading: true, trailing: true } },
                hooks: {
                    onSubmit: (form) => {
                        const values = form.values();
                        values.phoneNum = formatPhoneNumberForStorage(values.phoneNum, this._countryCode);
                        delete values.mainUser;

                        this.setSaving();
                        this.props.company.updateWithData(values).then((data) => {
                            this._updateWithData(data);
                            this.setSaving(false);
                        });
                    }
                }
            }
        );
    }

    @action setSaving(saving = true) {
        this.saving = saving;
    }

    render() {
        const { session } = this.props.store;
        const canEdit = !session.info.user.hasTag('psc_ro');
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="panel">
                    <div className="scroll">
                        {this.form && (
                            <form>
                                <div className="required">
                                    <label>Nom de l&apos;entreprise :</label>
                                    <input {...this.form.select('name').bind()} />
                                </div>
                                <div>
                                    <label>Type d&apos;entreprise :</label>
                                    <select {...this.form.select('type').bind()}>
                                        <option value="">Sélectionner...</option>
                                        <option value="Copropriété">Copropriété</option>
                                        <option value="SARL">SARL - Société à responsabilité limitée</option>
                                        <option value="EURL">
                                            EURL - Entreprise unipersonnelle à responsabilité limitée
                                        </option>
                                        <option value="EI">EI - Entreprise Individuelle (En nom propre)</option>
                                        <option value="SELARL">
                                            SELARL - Société d’exercice libéral à responsabilité limitée
                                        </option>
                                        <option value="SA">SA - Société anonyme</option>
                                        <option value="SAS">SAS - Société par action simplifiée</option>
                                        <option value="SASU">
                                            SASU - Société par action simplifiée unipersonnelle
                                        </option>
                                        <option value="SNC">SNC - Société en nom collectif</option>
                                        <option value="SCP">SCP - Société civile professionnelle</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Personne principale :</label>
                                    <div className="datalist">
                                        <input {...this.form.select('mainUser').bind()} readOnly />
                                    </div>
                                </div>
                                <div className="required">
                                    <label>Identification :</label>
                                    <input {...this.form.select('registration').bind()} />
                                </div>
                                <div>
                                    <label>Identification 2 :</label>
                                    <input {...this.form.select('registration2').bind()} />
                                </div>
                                <h4>Autres infos</h4>
                                <div className="required">
                                    <label>Rue :</label>
                                    <input {...this.form.select('address1').bind()} />
                                </div>
                                <div>
                                    <label>Rue (complément) :</label>
                                    <input {...this.form.select('address2').bind()} />
                                </div>
                                <div className="required">
                                    <label>Code postal :</label>
                                    <input className="date" {...this.form.select('zipCode').bind()} />
                                </div>
                                <div className="required">
                                    <label>Ville :</label>
                                    <input {...this.form.select('city').bind()} />
                                </div>
                                <div className="required">
                                    <label>Pays :</label>
                                    <select {...this.form.select('countryCode').bind()}>
                                        {this.props.store.countries.map((country) => (
                                            <option value={country.code} key={country.code}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="return" />
                                <div>
                                    <label>Téléphone de la société :</label>
                                    <input {...this.form.select('phoneNum').bind()} />
                                </div>
                                <div>
                                    <label>Email de la société :</label>
                                    <input {...this.form.select('email').bind()} />
                                </div>
                                {session.info.user.type === 'manager' && (
                                    <React.Fragment>
                                        <h4>Informations bancaires</h4>
                                        <div>
                                            <label>IBAN :</label>
                                            <input
                                                type="text"
                                                placeholder="FR00 0000 0000 0000 0000 0000 000"
                                                defaultValue={this.bankInfos ? this.bankInfos.iban : ''}
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <label>Code BIC (ou SWIFT) :</label>
                                            <input
                                                type="text"
                                                placeholder="AAAA-BB-CC-123"
                                                defaultValue={this.bankInfos ? this.bankInfos.bic : ''}
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <label>Agence de domiciliation :</label>
                                            <input
                                                type="text"
                                                defaultValue={this.bankInfos ? this.bankInfos.agency : ''}
                                                readOnly
                                            />
                                        </div>
                                        <div style={{ alignSelf: 'flex-end' }}>
                                            <label>Autorisation papier :</label>
                                            <input
                                                {...this.form
                                                    .select(
                                                        `bankOk-${
                                                            this.bankInfos ? this.bankInfos.company_id : 'unknown'
                                                        }`
                                                    )
                                                    .bind()}
                                                disabled={!canEdit}
                                            />
                                            <label>Signature reçue</label>
                                        </div>
                                    </React.Fragment>
                                )}
                            </form>
                        )}
                    </div>
                    {canEdit && (
                        <div className="exit">
                            <div />
                            {this.form && (
                                <div>
                                    <button onClick={this.form.onReset} disabled={!this.form.isDirty || this.saving}>
                                        Reset
                                    </button>
                                    <button
                                        onClick={this.form.onSubmit}
                                        disabled={
                                            !this.form.isDirty ||
                                            (this.form.isDirty && !this.form.isValid) ||
                                            this.saving
                                        }
                                    >
                                        Sauver
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </OrgChangeObserver>
        );
    }
}
