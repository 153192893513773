import React, { useCallback } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { withRouter, matchPath, Route, Redirect, useHistory } from 'react-router';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@observer
export class LicenseSelectorPAP extends React.Component {
    _switchToAll = () => {
        this.props.history.push('/pescalice/licenses-pap');
    };

    render() {
        const { pescalice } = this.props.store;
        const match = matchPath(this.props.location.pathname, { path: `/pescalice/license-pap/:id` });
        const currentProxyId = match && match.params ? match.params.id : null;

        return (
            <div className="list subList withToggle large show">
                <div className="toggle">
                    <span title="Liste des extraits" onClick={this._switchToAll}>
                        <SVGObject objectId="allLicenses" />
                    </span>
                    <span className="listSolo selected" title="Fiches Extraits">
                        <SVGObject objectId="singleLicense" />
                    </span>
                </div>
                <dl className="listSolo show">
                    <div className="burger">
                        <span />
                    </div>
                    <h6 />
                    <dt>Timbres</dt>
                    {buildList(pescalice.proxiesPAP, currentProxyId)}
                </dl>
                <ul className="toolbar" />

                {
                    <Route
                        exact
                        path="/pescalice/license-pap"
                        render={() => {
                            const { proxiesPAP } = pescalice;
                            const firstProxy = proxiesPAP.length > 0 ? proxiesPAP[0] : null;
                            return firstProxy ? (
                                <Redirect to={`/pescalice/license-pap/${firstProxy.uniqueId}/situation`} />
                            ) : null;
                        }}
                    />
                }
            </div>
        );
    }
}

function buildList(proxies, currentProxyId) {
    const list = [];

    let currentDiv = '';
    let currentShore = '';
    proxies.forEach((proxy) => {
        if (proxy.administrativeDivisionName !== currentDiv) {
            currentDiv = proxy.administrativeDivisionName;
            list.push(
                <dt key={proxy.administrativeDivisionZipCode}>
                    {proxy.administrativeDivisionZipCode} - {proxy.administrativeDivisionName}
                </dt>
            );
        }

        if (proxy.shore !== currentShore) {
            currentShore = proxy.shore;
            list.push(
                <dd className="noSelection bold" key={proxy.shore}>
                    {proxy.shore}
                </dd>
            );
        }

        const selected = currentProxyId === proxy.uniqueId;
        list.push(<ProxyRow proxy={proxy} selected={selected} key={proxy.uniqueId} />);
    });

    return list;
}

const ProxyRow = ({ proxy, selected }) => {
    const history = useHistory();
    const select = useCallback(() => history.push(`/pescalice/license-pap/${proxy.uniqueId}`), [proxy.uniqueId]);
    return (
        <dd className={classNames('sub', { selected })} title={proxy.name} onClick={select}>
            <span>{proxy.name}</span>
        </dd>
    );
};
