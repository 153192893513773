import React from 'react';
import { observable, computed, reaction } from 'mobx';
import { inject, observer, disposeOnUnmount } from 'mobx-react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect, matchPath } from 'react-router-dom';

import { LicenseSituationSubSectionPAP } from './LicenseSituationSubSectionPAP';
import { LicenseInfoSubSectionPAP } from './LicenseInfoSubSectionPAP';
import { LicenseContingentsSubSectionPAP } from './LicenseContingentsSubSectionPAP';

import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@withRouter
@inject('store')
@observer
export class LicenseSectionPAP extends React.Component {
    @observable selectedProxy = null;
    @observable selectedSeason = null;

    componentDidMount() {
        disposeOnUnmount(
            this,
            reaction(() => this.matchedExtractId, this._update, { fireImmediately: true })
        );
        disposeOnUnmount(
            this,
            reaction(
                () => this.selectedSeason && this.selectedSeason.parts.length,
                () => this._updateForSeason(this.selectedSeason)
            )
        );
    }

    @computed
    get matchedExtractId() {
        const match = matchPath(this.props.location.pathname, { path: `/pescalice/license-pap/:id` });
        return match && match.params ? match.params.id : null;
    }

    _update = () => {
        const { pescalice } = this.props.store;
        this.selectedProxy = pescalice.proxyWithIdPAP(this.matchedExtractId);
        if (this.selectedProxy) {
            this._updateForSeason(this.selectedProxy.seasons[0]);
        }
    };

    _updateForSeason = (season) => {
        // const { pescalice } = this.props.store;

        this.selectedSeason = season;
        // if (this.selectedSeason) {
        //     pescalice.fetchLicense(this.selectedSeason.license.uniqueId).then((license) => (this.license = license));
        //     pescalice
        //         .fetchLicenseQuotaDashBoard(this.selectedSeason.license.uniqueId)
        //         .then((quotas) => (this.quotas = quotas));
        // }
    };

    _selectSeason = (evt) => {
        const season = this.selectedProxy.seasonWithId(evt.target.value);
        this._updateForSeason(season);
    };

    render() {
        // if (this.quotas) {
        //     // Hack to ensure that sub sections depending on those observables passed as props
        //     // are correctly re-rendered
        // }

        return (
            <div className="selected">
                <div className="header noBottom">
                    <h3>
                        {this.selectedProxy ? this.selectedProxy.name : ''}
                        {this.selectedProxy && (
                            <span>
                                {this.selectedProxy.administrativeDivisionZipCode}&nbsp;-&nbsp;
                                {this.selectedProxy.administrativeDivisionName}&nbsp;-&nbsp;{this.selectedProxy.shore}
                            </span>
                        )}
                    </h3>
                    <form>
                        <div className="full">
                            <label>Campagne :</label>
                            {this.selectedProxy && (
                                <select value={this.selectedSeason.uniqueId} onChange={this._selectSeason}>
                                    {this.selectedProxy.sortedSeasons.map((season) => (
                                        <option value={season.uniqueId} key={season.uniqueId}>
                                            {season.referenceYear - 1} - {season.referenceYear}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    </form>
                    <nav>
                        <ul>
                            <NavigationLink
                                to={`/pescalice/license-pap/${this.matchedExtractId}/situation`}
                                label="Situation"
                                tag="li"
                            />
                            <NavigationLink
                                to={`/pescalice/license-pap/${this.matchedExtractId}/infos`}
                                label="Informations"
                                tag="li"
                            />
                            <NavigationLink
                                to={`/pescalice/license-pap/${this.matchedExtractId}/requests`}
                                label="Demandes"
                                tag="li"
                            />
                        </ul>
                    </nav>
                </div>
                <div className="panels">
                    <Switch>
                        <Route path="/pescalice/license-pap/:id/situation">
                            <LicenseSituationSubSectionPAP
                                season={this.selectedSeason}
                                extractId={this.matchedExtractId}
                            />
                        </Route>
                        <Route path="/pescalice/license-pap/:id/infos">
                            <LicenseInfoSubSectionPAP season={this.selectedSeason} extractId={this.matchedExtractId} />
                        </Route>
                        <Route path="/pescalice/license-pap/:id/requests">
                            <LicenseContingentsSubSectionPAP
                                season={this.selectedSeason}
                                extractId={this.matchedExtractId}
                            />
                        </Route>
                    </Switch>
                </div>

                <Route exact path="/pescalice/license-pap/:id">
                    <Redirect to={`/pescalice/license-pap/${this.matchedExtractId}/situation`} />
                </Route>
            </div>
        );
    }
}
