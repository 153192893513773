import React from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';

@inject('store')
@observer
export class LicensesSection extends React.Component {
    render() {
        const { pescalice } = this.props.store;
        return (
            <div className="selected">
                <div className="header">
                    <h3>Licences</h3>
                </div>
                {pescalice.filteredProxies.length === 0 ? (
                    <EmptyContent />
                ) : (
                    <div className="scroll">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pescalice.filteredProxies.map((proxy) => (
                                    <LicenseProxyRow proxy={proxy} key={proxy.uniqueId} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
}

@withRouter
class LicenseProxyRow extends React.Component {
    _selectProxy = (evt) => {
        evt.preventDefault();
        this.props.history.push(`/pescalice/license-pep/${this.props.proxy.uniqueId}`);
    };

    render() {
        const { proxy } = this.props;
        return (
            <tr>
                <td>
                    <span> Nom :</span>
                    <a href="#" onClick={this._selectProxy}>
                        {proxy.name}
                    </a>
                    <aside>&nbsp;-&nbsp;{proxy.administrativeDivision.name}</aside>
                </td>
            </tr>
        );
    }
}
