import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { observable, action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { api } from '@SUPPORT/api';
import { validator } from '@SUPPORT/validator';
import { capitalizeWords } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { SuggestionsPopup } from '@COMPONENTS/common/SuggestionsPopup';

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class NewUserDialog extends React.Component {
    @observable _existing = false;
    @observable _suggestions = [];
    @observable _showSuggestions = false;
    @observable _error = null;

    _selectedUser = null;

    constructor(props) {
        super(props);

        const fishingTypes = this.props.store.session.info.org ? this.props.store.session.info.org.fishingTypes : [];
        this.form = new ReactForm(
            {
                fields: {
                    lastName: {
                        type: 'text',
                        rules: 'required',
                        hooks: {
                            onChange: (field) => this._suggestUsers(field.value)
                        }
                    },
                    firstName: { type: 'text', rules: 'required' },
                    identifier: { type: 'text', rules: 'required' },
                    tags: { rules: fishingTypes.length > 1 ? 'required' : '' }
                }
            },

            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _clearForm() {
        this.form.select('identifier').set('');
        this.form.select('firstName').set('');
        this.form.select('lastName').set('');
        this.form.select('tags').set('');
    }

    @computed get errorMessage() {
        switch (this._error) {
            case 'duplicate_identifier':
                return 'Une personne avec un identifiant similaire existe déjà';
            case 'unknown':
                return "Erreur inconnue, la personne n'a pas pu être créee";
            default:
                return null;
        }
    }

    @action.bound _setError(error) {
        this._error = error;
    }

    @action.bound _setNewUser() {
        this._existing = false;
        this._clearForm();
    }

    @action.bound _setExistingUser() {
        this._existing = true;
        this._clearForm();
    }

    @action.bound _setShowSuggestions(show = true) {
        this._showSuggestions = show;
    }

    @action.bound _setSuggestions(suggestions) {
        this._suggestions = suggestions || [];
    }

    _suggestUsers = (query) => {
        if (this._existing && query.length > 0 && this.props.store.session.info.org) {
            api.suggestNonOrgUsers(this.props.store.session.info.org.id, query).then((response) => {
                this._setSuggestions(response.data);
                this._setShowSuggestions(this._suggestions.length > 0);
            });
        } else {
            this._setShowSuggestions(false);
        }
    };

    _selectSuggestedUser = (user) => {
        this.form.select('identifier').set(user.identifier);
        this.form.select('firstName').set(user.firstName);
        this.form.select('lastName').set(user.lastName);
        this.form.select('tags').set('');
        this._selectedUser = user;

        this._setShowSuggestions(false);
    };

    _handleTagChange = (evt) => {
        const checked = evt.target.checked;
        const tagsField = this.form.select('tags');
        const tags = tagsField.value === '' ? [] : tagsField.value.split(',');
        if (checked) {
            tags.push(evt.target.id);
        } else {
            tags.splice(tags.indexOf(evt.target.id), 1);
        }
        tagsField.set(tags.join(','));
    };

    _create = (evt) => {
        evt.preventDefault();

        this._setError(null);
        if (this._existing) {
            this.props.store.users.addExternalUser(this._selectedUser.id).then(() => {
                this.props.store.users.listUsers(true);
                this._dismiss();
            });
        } else {
            const data = this.form.values();
            if (
                this.props.store.session.info.user.type === 'manager' &&
                this.props.store.session.info.user.hasTag('psc')
            ) {
                const tags = data.tags ? data.tags.split(',') : [];
                tags.push('psc');
                tags.push('pep');
                data.tags = tags.join(',');
            }

            this.props.store.users
                .createUser(data)
                .then((user) => {
                    this.props.history.push(`${this.props.baseUrl}/user/${user.id}/info`);
                    this._dismiss();
                })
                .catch((err) => this._setError(err.wrapped ? err.wrapped.errorCode : 'unknown'));
        }
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.users.setShowNewUserDialog(false);
        this.props.store.app.setModal(false);
        this._selectedUser = null;
        this._setSuggestions();
        this._setShowSuggestions(false);
        this._setError(null);
        this.form.clear();
    };

    render() {
        const { t } = this.props;
        const psc = this.props.store.session.info.user && this.props.store.session.info.user.type === 'manager';
        const fishingTypes = this.props.store.session.info.org ? this.props.store.session.info.org.fishingTypes : [];

        return (
            <div
                className={classNames('modal', {
                    show: this.props.store.users && this.props.store.users.showNewUserDialog
                })}
            >
                <div className="sheet">
                    <div className="title">
                        <h3>{psc ? t('admin:new-user-dialog.title-psc') : t('admin:new-user-dialog.title')}</h3>
                        <SVGObject objectId="exitCross" onClick={this._cancel} />
                    </div>
                    <div className="scroll">
                        <form>
                            {!psc && (
                                <React.Fragment>
                                    <div className="aligned">
                                        <label />
                                        <input
                                            type="radio"
                                            name="nouvPech"
                                            value="nouveauPech"
                                            checked={!this._existing}
                                            onChange={this._setNewUser}
                                        />
                                        <label htmlFor="nouvPech">{t('admin:new-user-dialog.new-user')}</label>
                                    </div>
                                    <div className="aligned">
                                        <label />
                                        <input
                                            type="radio"
                                            name="nouvPech"
                                            value="ancienPech"
                                            checked={this._existing}
                                            onChange={this._setExistingUser}
                                        />
                                        <label htmlFor="nouvPech">{t('admin:new-user-dialog.existing-user')}</label>
                                    </div>
                                </React.Fragment>
                            )}
                            <div>
                                <label>{t('common:name')} :</label>
                                {this._existing ? (
                                    <div className="datalist">
                                        <input {...this.form.select('lastName').bind()} className="datalist" />
                                        <SuggestionsPopup
                                            show={this._showSuggestions}
                                            suggestions={this._suggestions}
                                            getKey={(user) => user.id}
                                            getLabel={(user) => {
                                                const firstName = capitalizeWords(user.firstName);
                                                const lastName = user.lastName.toUpperCase();
                                                const identifier = user.identifier;
                                                return `${lastName} ${firstName} - ${identifier}`;
                                            }}
                                            onClick={this._selectSuggestedUser}
                                        />
                                    </div>
                                ) : (
                                    <input {...this.form.select('lastName').bind()} />
                                )}
                            </div>
                            <div>
                                <label>{t('common:first-name')} :</label>
                                <input {...this.form.select('firstName').bind()} readOnly={this._existing} />
                            </div>
                            <div>
                                <label>{t('common:identifier')} :</label>
                                <input
                                    {...this.form.select('identifier').bind()}
                                    readOnly={this._existing}
                                    className={classNames({ codeRed: this._error === 'duplicate_identifier' })}
                                />
                            </div>
                            {fishingTypes.length > 1 && (
                                <React.Fragment>
                                    <div>
                                        <label />
                                        <label>Types de pêche :</label>
                                    </div>
                                    {fishingTypes.map((fishingType) => (
                                        <div className="aligned" key={fishingType.tag}>
                                            <label />
                                            <input
                                                type="checkbox"
                                                id={fishingType.tag}
                                                disabled={this._existing}
                                                onChange={this._handleTagChange}
                                                checked={this.form.select('tags').value.includes(fishingType.tag)}
                                            />
                                            <label htmlFor={fishingType.tag}>{fishingType.name}</label>
                                        </div>
                                    ))}
                                </React.Fragment>
                            )}
                        </form>
                    </div>
                    <div className="exit">
                        <div>
                            <span style={{ color: 'red' }}>{this.errorMessage}</span>
                        </div>
                        <div>
                            <button onClick={this._cancel}>{t('common:cancel')}</button>
                            <button onClick={this._create} disabled={!this.form.isValid}>
                                {t('common:add')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
