import React, { Fragment, useContext, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { observable, action, reaction, computed } from 'mobx';
import { inject, observer, disposeOnUnmount, MobXProviderContext } from 'mobx-react';

import { getRequestTypeLabel, getRequestStatusLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';

import { formatLocaleDate } from '@SUPPORT/utils';
import { downloadFileFromBase64Data } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { EmptyContent } from '@COMPONENTS/common/EmptyContent';
import { LicenseRequestInfoBtn } from '@COMPONENTS/common/LicenseRequestInfoBtn';

@withRouter
@withTranslation(['pescalice'])
@inject('store')
@observer
export class UserLicensesSubSection extends React.Component {
    @observable fishingType = 'pescalice.peche-embarquee';
    @observable groups = [];
    @observable papRequests = [];

    @observable companyIds = [];
    @observable companyId = null;

    @observable boatIds = [];
    @observable boatId = null;

    @observable refYears = [];
    @observable refYear = null;

    @observable papSeasonId = null;

    componentDidMount() {
        const { pescalice } = this.props.store;

        // When currentTodo switches to null, i.e. when the request dialog
        // is dismissed, reload the list.
        disposeOnUnmount(
            this,
            reaction(
                () => pescalice.currentTodo,
                () => {
                    if (pescalice.currentTodo === null) {
                        this._updatePEP();
                    }
                }
            )
        );
        disposeOnUnmount(
            this,
            reaction(
                () => pescalice.currentTodoPAP,
                () => {
                    if (pescalice.currentTodoPAP === null) {
                        this._updatePAP();
                    }
                }
            )
        );

        this._update();

        if (this.papSeasonId === null) {
            this.papSeasonId = pescalice.seasonsPAP[0] ? pescalice.seasonsPAP[0].uniqueId : null;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this._update();
        }
    }

    @action.bound _setFishingType(evt) {
        this.fishingType = evt.target.value;
        if (this.fishingType === 'pescalice.peche-a-pied') {
            this._selectCompanyId(null);
            this._selectBoatId(null);
        }

        this._update();
    }

    @action.bound _setRequests(requests, companyIds) {
        this.groups = requests;
        this.companyIds = companyIds;
        this._selectCompanyId(null);
    }

    @action.bound _setPAPRequests(requests) {
        this.papRequests = requests;
    }

    @action.bound _selectCompanyId(id) {
        this.companyId = id;
        this.boatIds = Array.from(
            this.groups
                .filter((req) => req.companyTelecapecheId === id)
                .reduce((s, req) => {
                    Object.keys(req.boats).forEach((id) => s.add(parseInt(id, 10)));
                    return s;
                }, new Set())
                .values()
        );
        if (this.boatIds.length > 0) {
            this._selectBoatId(this.boatIds[0]);
        }
    }

    _handleCompanySelection = (evt) => {
        this._selectCompanyId(parseInt(evt.target.value, 10) || null);
    };

    @action.bound _selectBoatId(id) {
        this.boatId = id;

        const refYears = new Set();
        this.groups.forEach((req) => {
            if (!req.boats[id]) {
                return;
            }

            Object.values(req.boats[id])
                .flat()
                .forEach((data) => refYears.add(data.license.season.baseYear));
        });

        this.refYears = Array.from(refYears).sort().reverse();
        this.refYear = this.refYears.length > 0 ? this.refYears[0].toString() : null;
    }

    _handleBoatSelection = (evt) => {
        this._selectBoatId(parseInt(evt.target.value, 10));
    };

    @action _selectRefYear(refYear) {
        this.refYear = refYear;
    }

    _handleRefYearSelection = (evt) => {
        this._selectRefYear(evt.target.value);
    };

    @action _selectPAPSeason(seasonId) {
        this.papSeasonId = seasonId;
    }

    _handlePAPSeasonSelection = (evt) => {
        this._selectPAPSeason(evt.target.value);
    };

    @computed get filteredRequests() {
        return {
            kPSLicenseRequestGlobalStatusInitial: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusInitial'
            ),
            kPSLicenseRequestGlobalStatusAllowed: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusAllowed'
            ),
            kPSLicenseRequestGlobalStatusFrozen: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusFrozen'
            ),
            kPSLicenseRequestGlobalStatusSuspended: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusSuspended'
            ),
            kPSLicenseRequestGlobalStatusReserved: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusReserved'
            ),
            kPSLicenseRequestGlobalStatusRefused: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusRefused'
            ),
            kPSLicenseRequestGlobalStatusCancelled: this._getFilteredRequestsForStatus(
                'kPSLicenseRequestGlobalStatusCancelled'
            )
        };
    }

    _getFilteredRequestsForStatus(status) {
        const group = this.groups.find((g) => g.companyTelecapecheId === this.companyId && !!g.boats[this.boatId]);
        if (!group) {
            return [];
        }

        const boatRequests = group.boats[this.boatId];
        if (!boatRequests) {
            return [];
        }

        if (this.refYear === '') {
            return boatRequests[status];
        }

        return boatRequests[status].filter((req) => req.license.season.baseYear.toString() === this.refYear);
    }

    _update = () => {
        if (this.fishingType === 'pescalice.peche-embarquee') {
            this._updatePEP();
        } else if (this.fishingType === 'pescalice.peche-a-pied') {
            this._updatePAP();
        }
    };

    _updatePEP = () => {
        const { user, store } = this.props;
        store.pescalice.fetchUserLicenses(user.id).then((data) => {
            const companyIds = data.map((req) => req.companyTelecapecheId);
            const boatIds = Array.from(
                data
                    .reduce((s, req) => {
                        Object.keys(req.boats).forEach(s.add.bind(s));
                        return s;
                    }, new Set())
                    .values()
            );

            Promise.all([
                ...companyIds.map((id) => store.companies.fetchCompany(id)),
                ...boatIds.map((id) => store.boats.fetchBoat(id))
            ]).then(() => {
                this._setRequests(data, companyIds);
            });
        });
    };

    _updatePAP = () => {
        const { user, store } = this.props;
        store.pescalice.fetchUserLicensesPAP(user.id).then(this._setPAPRequests);
    };

    _generateNotification = (evt) => {
        evt.preventDefault();

        const { user, store } = this.props;
        store.pescalice.setDisplayGenerateNotificationDialog(true, {
            years: this.refYears,
            user,
            companyId: this.companyId,
            boatId: this.boatId
        });
    };

    _generateNotificationPAP = (evt) => {
        evt.preventDefault();

        const { user, store } = this.props;
        const season = store.pescalice.seasonPAPWithId(this.papSeasonId);

        store.pescalice.generateNotificationDocPAP(user.id, season.referenceYear).then((res) => {
            if (res.status === 'error') {
                alert(`Erreur: ${res.errorMessage}\nInfo: ${res.devMessage}`);
            } else {
                let filename = this.fishingType;
                filename += '_' + user.lastName.toUpperCase();
                filename += '_' + user.firstName.toUpperCase();
                if (this.companyId) {
                    const company = store.companies.companyWithId(this.companyId);
                    if (company) {
                        filename += '_' + company.name.replace(' ', '_');
                    }
                }
                filename += '_' + (season.referenceYear - 1);
                filename += '_' + season.referenceYear;
                filename += '_' + formatLocaleDate(Date.now(), 'dd_MM_yyyy_HH_mm');
                filename += '.pdf';
                downloadFileFromBase64Data(res.data.notificationPDFData, filename, 'application/pdf');
            }
        });
    };

    render() {
        const { match, user, store } = this.props;
        if (!match || !user) {
            return null;
        }

        const { pescalice } = store;

        return (
            <div className="panel styledList">
                <div className="scroll">
                    <form style={{ display: 'flex' }}>
                        <div className="aligned">
                            <select
                                value={this.fishingType || ''}
                                onChange={this._setFishingType}
                                style={{ maxWidth: 150 }}
                            >
                                <option value="pescalice.peche-embarquee">Pêche embarquée</option>
                                <option value="pescalice.peche-a-pied">Pêche à pied</option>
                                <option value="pescalice.algues-de-rive" disabled>
                                    Récolte des algues de rive
                                </option>
                            </select>
                            {this.fishingType === 'pescalice.peche-embarquee' && (
                                <Fragment>
                                    <select
                                        value={this.companyId || ''}
                                        onChange={this._handleCompanySelection}
                                        disabled={this.companyIds.length === 0}
                                    >
                                        <option value="">Personne physique</option>
                                        {this.companyIds.map((id) => {
                                            const company = store.companies.companyWithId(id);
                                            return company ? (
                                                <option value={company.id} key={company.id}>
                                                    {company.name}
                                                </option>
                                            ) : null;
                                        })}
                                    </select>
                                    <select
                                        value={this.boatId || ''}
                                        onChange={this._handleBoatSelection}
                                        disabled={this.boatIds.length === 0}
                                    >
                                        {this.boatIds.map((id) => {
                                            const boat = store.boats.boatWithId(id);
                                            return boat ? (
                                                <option value={boat.id} key={boat.id}>
                                                    {boat.name} - {boat.fullRegistration}
                                                </option>
                                            ) : null;
                                        })}
                                    </select>
                                    {this.refYears && this.refYears.length >= 1 && (
                                        <select value={this.refYear} onChange={this._handleRefYearSelection}>
                                            <option value="">Tous</option>
                                            {this.refYears.map((ref) => (
                                                <option value={ref} key={ref}>
                                                    {ref}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    <button
                                        style={{ marginLeft: 'auto' }}
                                        onClick={this._generateNotification}
                                        disabled={!this.refYear}
                                    >
                                        Générer la notification
                                    </button>
                                </Fragment>
                            )}
                            {this.fishingType === 'pescalice.peche-a-pied' && (
                                <Fragment>
                                    <select value={this.papSeasonId || ''} onChange={this._handlePAPSeasonSelection}>
                                        {pescalice.seasonsPAP.map((season) => (
                                            <option value={season.uniqueId} key={season.uniqueId}>
                                                {season.referenceYear - 1} - {season.referenceYear}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        style={{ marginLeft: 'auto' }}
                                        onClick={this._generateNotificationPAP}
                                        disabled={!this.papSeasonId}
                                    >
                                        Générer la notification
                                    </button>
                                </Fragment>
                            )}
                        </div>
                    </form>
                    {this.fishingType === 'pescalice.peche-embarquee' && this._renderPEPRequests()}
                    {this.fishingType === 'pescalice.peche-a-pied' && this._renderPAPRequests()}
                </div>
            </div>
        );
    }

    _renderPEPRequests() {
        return !this.boatId ? (
            <EmptyContent />
        ) : (
            <ul>
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusInitial}
                        header="Demandées"
                        title="demande"
                        titlePlural="demandes"
                        update={this._updatePEP}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusAllowed}
                        className="codeGreen"
                        header="Attribuées"
                        title="attribution"
                        titlePlural="attributions"
                        update={this._updatePEP}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusFrozen}
                        className="codeGray"
                        header="En attente"
                        title="en attente"
                        titlePlural="en attente"
                        update={this._updatePEP}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusSuspended}
                        className="codeGray"
                        header="Incomplètes"
                        title="suspension"
                        titlePlural="suspensions"
                        update={this._updatePEP}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusReserved}
                        className="codeOrange"
                        header="Mises en réserve"
                        title="mise en réserve"
                        titlePlural="mises en réserve"
                        update={this._updatePEP}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusRefused}
                        className="codeRed"
                        header="Refusées"
                        title="refus"
                        titlePlural="refus"
                        update={this._updatePEP}
                    />
                }
                {
                    <LicensesBlock
                        licenses={this.filteredRequests.kPSLicenseRequestGlobalStatusCancelled}
                        className="codeRed"
                        header="Annulées"
                        title="annulation"
                        titlePlural="annulations"
                        update={this._updatePEP}
                    />
                }
            </ul>
        );
    }

    _renderPAPRequests() {
        const season = this.papRequests.find((season) => season.uniqueId === this.papSeasonId);
        if (!season || !season.licenseRequest) {
            return <EmptyContent />;
        }

        return <LicenseRequestPAP request={season.licenseRequest} update={this._updatePAP} />;
    }
}

const LicensesBlock = (props) => {
    return (
        <li className={props.className}>
            <header>
                <h2>{props.header}</h2>
            </header>
            <figure>
                {props.licenses.length === 0 && (
                    <span className="whenWhere">
                        <p>
                            {props.licenses.length} {props.licenses.length <= 1 ? props.title : props.titlePlural}
                        </p>
                        <p></p>
                    </span>
                )}
                <span className="what" style={{ paddingLeft: 0, marginLeft: 0, borderLeft: 'none' }}>
                    <ul className="table threeCols">
                        {props.licenses.map((info) => (
                            <LicenseInfoRow info={info} key={info.uniqueId} update={props.update} />
                        ))}
                    </ul>
                </span>
            </figure>
        </li>
    );
};

const LicenseInfoRow = inject('store')((props) => {
    const { info, store } = props;
    const { session, pescalice } = store;
    const canEdit = !session.info.user.hasTag('psc_ro');

    const edit = (evt) => {
        evt.preventDefault();
        if (evt.shiftKey) {
            const ok = confirm('Voulez-vous vraiment supprimer cette demande ?');
            if (ok) {
                pescalice.deleteLicenseRequest(info.uniqueId).then(props.update);
            }
        } else {
            pescalice.setDisplayNewRequestDialog(true, info.uniqueId, props.update);
        }
    };

    return (
        <li>
            <p style={{ width: '30%' }}>{info.license.name}</p>
            <p style={{ width: canEdit ? '34%' : '42%' }}>{info.license.season.name}</p>
            <p style={{ width: '10%' }}>{formatLocaleDate(info.depositDate, 'P')}</p>
            <p style={{ width: '15%' }}>{getRequestTypeLabel(info)}</p>
            {canEdit && (
                <p style={{ width: '8%' }}>
                    <a href="#" onClick={edit}>
                        Éditer
                    </a>
                </p>
            )}
            <p style={{ width: '26px' }}>
                <LicenseRequestInfoBtn requestId={info.uniqueId} />
            </p>
        </li>
    );
});

const LicenseRequestPAP = ({ request, update }) => {
    const { store } = useContext(MobXProviderContext);
    const { session, pescalice } = store;
    const canEdit = !session.info.user.hasTag('psc_ro');

    const edit = useCallback(
        (evt) => {
            evt.preventDefault();
            if (evt.shiftKey) {
                const ok = confirm('Voulez-vous vraiment supprimer cette demande ?');
                if (ok) {
                    pescalice.deleteLicenseRequestPAP(request.uniqueId).then(update);
                }
            } else {
                pescalice.setDisplayNewRequestDialogPAP(true, request.uniqueId);
            }
        },
        [pescalice, request, update]
    );

    const hasExtractRequests = request.extractRequests && request.extractRequests.length > 0;

    return (
        <table className="withCollapseTriangle">
            <tbody>
                <tr className="expanded">
                    <td style={{ width: 1 }}></td>
                    <td style={{ fontSize: 14, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        {request.isFirstInstallation
                            ? 'Première installation'
                            : request.isNewLicenseRequest
                            ? 'Nouvelle demande'
                            : 'Renouvellement'}
                        {request.isUserDeposited ? (
                            <SVGObject
                                objectId="singleFisherman"
                                style={{ fill: 'blue', width: 13, opacity: 0.15, height: 13, marginLeft: 10 }}
                            />
                        ) : null}
                    </td>
                    {canEdit && (
                        <td style={{ textAlign: 'end', paddingRight: 20, fontSize: 14 }}>
                            <a href="#" onClick={edit}>
                                Éditer
                            </a>
                        </td>
                    )}
                </tr>
                <tr className="collapsed show" style={hasExtractRequests ? null : { backgroundColor: '#e6c2a7' }}>
                    <td colSpan={canEdit ? '3' : '2'} style={{ padding: 12 }}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Département</th>
                                    <th>Côte</th>
                                    <th>Timbre</th>
                                    <th>Nature</th>
                                    <th>Statut</th>
                                </tr>
                                {hasExtractRequests ? (
                                    request.extractRequests.map((extractReq) => (
                                        <ExtractRequest request={extractReq} key={extractReq.uniqueId} />
                                    ))
                                ) : (
                                    <tr colSpan="5">
                                        <td className="codeOrange">
                                            La demande de licence est annulée car aucune demande de timbre n&apos;y est
                                            associée.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const ExtractRequest = ({ request }) => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    const season = pescalice.seasonPAPWithRefYear(request.referenceYear);
    const extract = season.extracts.find((extract) => extract.uniqueId === request.targetedExtract);

    return (
        <tr>
            <td>
                {extract.administrativeDivisionZipCode} - {extract.administrativeDivisionName}
            </td>
            <td>{extract.shore || '-'}</td>
            <td>{extract.name || '-'}</td>
            <td>
                {request.isFirstInstallation
                    ? 'Première Installation'
                    : request.isExtractRenewal
                    ? 'Renouvellement'
                    : 'Nouvelle Demande'}
            </td>
            <td className={getRequestStatusColorCode(request.globalStatus)}>
                {getRequestStatusLabel(request.globalStatus)}
            </td>
        </tr>
    );
};
