import React from 'react';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@inject('store')
@observer
class ZoneActivator extends React.Component {
    @observable working = false;

    _handleChange = (evt) => {
        this.working = true;
        this.props
            .update(this.props.rule.zoneId, evt.target.checked)
            .then(() => (this.working = false))
            .catch(() => (this.working = false));
    };

    render() {
        return (
            <div className="aligned">
                <input
                    type="checkbox"
                    id={this.props.rule.zoneCode}
                    checked={this.props.rule.allowed}
                    disabled={this.props.disabled || this.working}
                    onChange={this._handleChange}
                />
                <label htmlFor={this.props.rule.zoneCode}>
                    {this.props.rule.zoneCode} - {this.props.rule.zoneName}
                </label>
            </div>
        );
    }
}

@inject('store')
@withTranslation(['common'])
@observer
export class UserZonesSubSection extends React.Component {
    @observable working = false;
    @observable allAllowed = false;
    @observable rulesMap = new Map();

    _ruleForZoneId(zoneId) {
        if (!this.rulesMap.has(zoneId)) {
            const zone = this.props.store.zones.list.find((z) => z.id === zoneId);
            this.rulesMap.set(zoneId, { zoneId, zoneCode: zone.code, zoneName: zone.name, allowed: true });
        }

        return this.rulesMap.get(zoneId);
    }

    _isAllowed = (zone) => {
        const rule = this.rulesMap.get(zone.id);
        return rule && rule.allowed;
    };

    _changeAll = (evt) => {
        this.working = true;
        Promise.all(
            this.props.store.zones.list.map((zone) =>
                this.props.store.users.updateRuleWithZoneStatus(this.props.user.id, '***', zone.id, evt.target.checked)
            )
        )
            .then(() => this._update())
            .then(() => (this.working = false))
            .catch(() => (this.working = false));
    };

    _updateZoneStatus = (zoneId, allowed) => {
        return this.props.store.users
            .updateRuleWithZoneStatus(this.props.user.id, '***', zoneId, allowed)
            .then(() => {
                const rule = this.rulesMap.get(zoneId);
                if (rule) {
                    rule.allowed = allowed;
                }
            })
            .then(this._updateAllStatus);
    };

    _updateAllStatus = () => {
        this.allAllowed = this.props.store.zones.list.every(this._isAllowed);
    };

    _update = () => {
        this.props.store.users.listZones(this.props.user.id).then((zones) => {
            this.rulesMap = new Map();
            zones.forEach((rule) => this.rulesMap.set(rule.zoneId, rule));
            this._updateAllStatus();
        });
    };

    render() {
        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="panel">
                    <div className="scroll">
                        <form className="smallFormat">
                            <div className="full">
                                <input
                                    type="checkbox"
                                    id="all"
                                    checked={this.allAllowed}
                                    disabled={this.working}
                                    onChange={this._changeAll}
                                />
                                <label htmlFor="all">{t('common:all-zones')}</label>
                            </div>

                            <div className="separator" />

                            {this.props.store.zones.list.map((zone) => (
                                <ZoneActivator
                                    user={this.props.user}
                                    rule={this._ruleForZoneId(zone.id)}
                                    allowed={this._isAllowed(zone)}
                                    disabled={this.working}
                                    update={this._updateZoneStatus}
                                    key={zone.code}
                                />
                            ))}
                        </form>
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}
