import { types } from 'mobx-state-tree';
import { parseISO, isValid } from 'date-fns';

export const ISODate = types.custom({
    name: 'ISODate',
    fromSnapshot(value) {
        return parseISO(value);
    },
    toSnapshot(value) {
        if (isValid(value)) {
            return value.toISOString();
        }
        return '';
    },
    isTargetType(value) {
        return value instanceof Date;
    },
    getValidationMessage(value) {
        const date = parseISO(value);
        if (isValid(date)) {
            return '';
        }
        return `'${value}' doesn't look like a valid ISO date`;
    }
});
