import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { reaction } from 'mobx';
import { inject, observer, disposeOnUnmount } from 'mobx-react';
import { AutoSizer, Table, Column } from 'react-virtualized';

import { formatLocaleDate } from '@SUPPORT/utils';
import { getRequestStatusLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { EmptyContent } from '@COMPONENTS/common/EmptyContent';
import { LicenseRequestInfoBtn } from '@COMPONENTS/common/LicenseRequestInfoBtn';

/* eslint-disable  react/no-children-prop */

@inject('store')
@withRouter
@observer
export class ToDosSection extends React.Component {
    componentDidMount() {
        this._update();

        // When currentTodo switches to null, i.e. when the request dialog
        // is dismissed, reload the list.
        const { pescalice } = this.props.store;
        disposeOnUnmount(
            this,
            reaction(
                () => pescalice.currentTodo,
                () => {
                    if (pescalice.currentTodo === null) {
                        this._update(true);
                    }
                }
            )
        );
    }

    _update = (force = false) => {
        this.props.store.pescalice.listLicenseRequests(force);
    };

    _newRequest = (evt) => {
        evt.preventDefault();
        this.props.store.pescalice.setDisplayNewRequestDialog();
    };

    _exportCSV = (evt) => {
        evt.preventDefault();

        // AH: exporting stuff commented because J'y comprends rien :)
        //this.setExporting();

        let filename = 'pep_a_traiter';
        filename += '_' + formatLocaleDate(Date.now(), 'dd_MM_yyyy_HH_mm');
        filename += '.csv';

        const { pescalice } = this.props.store;
        const { filteredTodos } = pescalice;
        pescalice.downloadRequestsCSVReport(filename, filteredTodos, false, true);
        //.then(() => this.setExporting(false))
        //.catch(() => this.setExporting(false));
    };

    render() {
        const { session, pescalice, users } = this.props.store;

        const canCreate = !session.info.user.hasTag('psc_ro');
        const todos = pescalice.filteredTodos;
        const count = todos.length;

        return (
            <div className="selected">
                <div className="header" style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <h3>À traiter</h3>
                    <button style={{ marginLeft: 'auto' }} onClick={this._exportCSV}>
                        Exporter CSV
                    </button>
                </div>
                <div className="scroll noButNoScroll" style={{ paddingBottom: 0 }}>
                    {pescalice.loadingTodos ? null : (
                        <AutoSizer
                            children={({ width, height }) => (
                                <Table
                                    width={width}
                                    height={height}
                                    headerHeight={25}
                                    rowHeight={25}
                                    rowCount={count}
                                    rowGetter={({ index }) => todos[index]}
                                    noRowsRenderer={() => <EmptyContent />}
                                >
                                    <Column
                                        label="Licence"
                                        dataKey="licence"
                                        cellRenderer={(props) => (
                                            <LicenceCell
                                                session={session}
                                                pescalice={pescalice}
                                                history={this.props.history}
                                                afterDelete={() => this.update(true)}
                                                {...props}
                                            />
                                        )}
                                        width={1}
                                        flexGrow={3}
                                    />
                                    <Column
                                        label="Contingent"
                                        dataKey="contingent"
                                        cellRenderer={(props) => <ContingentCell {...props} />}
                                        width={1}
                                        flexGrow={1}
                                    />
                                    <Column
                                        label="Date de dépôt"
                                        dataKey="submission-date"
                                        cellRenderer={(props) => <SubmissionDateCell {...props} />}
                                        width={1}
                                        flexGrow={1}
                                    />
                                    <Column
                                        label="Armateur"
                                        dataKey="requester"
                                        cellRenderer={(props) => (
                                            <RequesterCell users={users} history={this.props.history} {...props} />
                                        )}
                                        width={1}
                                        flexGrow={1}
                                    />
                                    <Column
                                        label="Navire"
                                        dataKey="boat"
                                        cellRenderer={(props) => <BoatCell history={this.props.history} {...props} />}
                                        width={1}
                                        flexGrow={1}
                                    />
                                    <Column
                                        label="Nature"
                                        dataKey="nature"
                                        cellRenderer={(props) => <NatureCell {...props} />}
                                        width={1}
                                        flexGrow={1}
                                    />
                                    <Column
                                        label="Statut"
                                        dataKey="status"
                                        cellRenderer={(props) => <StatusCell {...props} />}
                                        width={1}
                                        flexGrow={1}
                                    />
                                    <Column
                                        label=""
                                        dataKey="edit"
                                        cellRenderer={(props) => <InfoCell {...props} />}
                                        width={1}
                                        minWidth={26}
                                    />
                                </Table>
                            )}
                        />
                    )}
                </div>
                {canCreate && (
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._newRequest}>Créer une demande</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function LicenceCell(props) {
    const { rowData, history, pescalice, session, afterDelete } = props;

    const handleClick = (evt) => {
        evt.preventDefault();
        if (evt.shiftKey) {
            const ok = confirm('Voulez-vous vraiment supprimer cette demande ?');
            if (ok) {
                pescalice.deleteLicenseRequest(rowData.uniqueId).then(afterDelete);
            }
        } else if (session.info.user.hasTag('psc_ro')) {
            history.push(`/pescalice/license-pep/${rowData.license.pescaliceIdentifier}/situation`);
        } else {
            pescalice.setDisplayNewRequestDialog(true, rowData.uniqueId, afterDelete);
        }
    };

    return (
        <a href="#" onClick={handleClick} title={rowData.license ? rowData.license.name : null}>
            {rowData.license ? rowData.license.name : '-'}
        </a>
    );
}

function ContingentCell(props) {
    const { rowData } = props;
    return rowData.targetableQuotas.length > 0 ? rowData.targetableQuotas[0].name : '-';
}

function SubmissionDateCell(props) {
    const { rowData } = props;
    return (
        <Fragment>
            {rowData.depositDate ? formatLocaleDate(rowData.depositDate, 'P') : '-'}
            {rowData.isUserDeposited ? (
                <SVGObject
                    objectId="singleFisherman"
                    style={{ fill: 'blue', width: 12, opacity: 0.15, height: 10, marginLeft: 10 }}
                />
            ) : null}
        </Fragment>
    );
}

function RequesterCell(props) {
    const { rowData, history, users } = props;

    const userId = rowData.requestAuthor.telecapecheId;
    const user = users.userWithId(userId);
    const companyInfo = rowData.requestAuthor.companyTelecapecheId ? user ? `${user.fullCompanyName()}` : '?' : '';
    const label = rowData.requestAuthor.companyTelecapecheId ? companyInfo : user ? `${rowData.requestAuthor.fullName}` : '';

    const openRequester = (evt) => {
        evt.preventDefault();

        if (rowData.requestAuthor.companyTelecapecheId != null) {
            history.push(`/pescalice/company/${rowData.requestAuthor.companyTelecapecheId}/info`);    
        }
        else {
            history.push(`/pescalice/user/${userId}/info`);
        }
    };

    return (
        <a href="#" onClick={openRequester} title={label}>
            {label}
        </a>
    );
}

function BoatCell(props) {
    const { rowData, history } = props;
    const { boat } = rowData;

    const openBoat = (evt) => {
        evt.preventDefault();
        history.push(`/pescalice/boat/${boat.telecapecheId}/info`);
    };

    return (
        <a href="#" onClick={openBoat}>
            {boat ? boat.fullRegistration : '-'}
        </a>
    );
}

function NatureCell(props) {
    const { rowData } = props;
    return rowData.requestTypeLabel;
}

function StatusCell(props) {
    const { rowData } = props;
    return (
        <span className={getRequestStatusColorCode(rowData.globalStatus)} style={{ fontSize: 11 }}>
            {getRequestStatusLabel(rowData.globalStatus)}
        </span>
    );
}

function InfoCell(props) {
    const { rowData } = props;
    return <LicenseRequestInfoBtn requestId={rowData.uniqueId} />;
}
