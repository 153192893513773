import styled from 'styled-components';
import React, { useCallback } from 'react';
import { inject, observer } from 'mobx-react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const LicenseRequestInfoBtn = inject('store')(
    observer(({ store, requestId }) => {
        const { pescalice } = store;

        const onMouseEnter = useCallback(() => {
            pescalice.setCurrentPopOverRequestId(requestId);
        }, [pescalice, requestId]);

        const onMouseLeave = useCallback(() => {
            pescalice.setCurrentPopOverRequestId(null);
        }, [pescalice]);

        return (
            <a
                href={`/pescalice/request-summary/pep/${requestId}`}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <SVGObject objectId="infoIco" className="infoIcon" />
            </a>
        );
    })
);

const PopoverContainer = styled.div`
    visibility: hidden;
    position: absolute;
    width: 600px;
    height: 1px;
    max-height: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 2px solid #ccc;
    background-color: var(--main-background);
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    & iframe {
        width: 100%;
        height: 100%;
    }
`;

export const LicenseRequestInfoPopOver = inject('store')(
    observer(({ store }) => {
        const { pescalice } = store;

        const adjustHeight = useCallback((evt) => {
            const iframe = evt.currentTarget;
            const container = iframe.parentElement;
            container.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
            container.style.visibility = 'visible';
        }, []);

        if (!pescalice || !pescalice.currentPopOverRequestId) {
            return null;
        }

        return (
            <PopoverContainer>
                <iframe
                    src={`/pescalice/request-summary/pep/${pescalice.currentPopOverRequestId}`}
                    onLoad={adjustHeight}
                />
            </PopoverContainer>
        );
    })
);
