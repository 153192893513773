import React, { useContext } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';

import { usePaymentsList } from '@SUPPORT/queries';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const PaymentsFiltersPAP = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice, boats } = store;

    const currentYear = new Date().getFullYear();

    // const baseYear = pescalice.paymentBaseYearFilter;

    return (
        <div className="list subList show">
            <dl className="show">
                <div className="burger">
                    <span />
                </div>
                <h6>Filtres</h6>
                <dt>
                    Filtres
                    <SVGObject objectId="resetIco" onClick={() => pescalice.resetPAPPaymentsFilters()} />
                </dt>
                <dd className="noSelection">
                    <input
                        type="text"
                        placeholder="Nom du demandeur"
                        value={pescalice.papPaymentRequesterFilter}
                        onChange={(evt) => pescalice.setPAPPaymentRequesterFilter(evt.target.value)}
                    />
                </dd>
                <dd className="noSelection">
                    <select
                        value={pescalice.papPaymentBaseYearFilter}
                        onChange={(evt) => pescalice.setPAPPaymentBaseYearFilter(evt.target.value)}
                    >
                        <option value={currentYear + 1}>
                            {currentYear + 1}-{currentYear}
                        </option>
                        <option value={currentYear}>
                            {currentYear}-{currentYear - 1}
                        </option>
                        <option value={currentYear - 1}>
                            {currentYear - 1}-{currentYear - 2}
                        </option>
                        <option value={currentYear - 2}>
                            {currentYear - 2}-{currentYear - 3}
                        </option>
                        <option value={currentYear - 3}>
                            {currentYear - 3}-{currentYear - 4}
                        </option>
                        <option value={currentYear - 4}>
                            {currentYear - 4}-{currentYear - 5}
                        </option>
                    </select>
                </dd>
            </dl>
            <ul className="toolbar" />
        </div>
    );
});
