import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { withRouter, matchPath, Route, Redirect } from 'react-router';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@observer
export class LicenseSelector extends React.Component {
    _switchToAll = () => {
        this.props.history.push('/pescalice/licenses-pep');
    };

    render() {
        const { pescalice } = this.props.store;
        const match = matchPath(this.props.location.pathname, { path: `/pescalice/license-pep/:id` });

        return (
            <div className="list subList withToggle large show">
                <div className="toggle">
                    <span title="Liste des licences" onClick={this._switchToAll}>
                        <SVGObject objectId="allLicenses" />
                    </span>
                    <span className="listSolo selected" title="Fiches Licences">
                        <SVGObject objectId="singleLicense" />
                    </span>
                </div>
                <dl className="listSolo show">
                    <div className="burger">
                        <span />
                    </div>
                    <h6 />
                    <dt>Licences</dt>
                    {pescalice.proxies.map((proxy) => (
                        <ProxyRow
                            proxy={proxy}
                            selected={match && proxy.uniqueId === match.params.id}
                            key={proxy.uniqueId}
                        />
                    ))}
                </dl>
                <ul className="toolbar" />

                <Route
                    exact
                    path="/pescalice/license-pep"
                    render={() => {
                        const { proxies } = pescalice;
                        const firstProxy = proxies.length > 0 ? proxies[0] : null;
                        return firstProxy ? (
                            <Redirect to={`/pescalice/license-pep/${firstProxy.uniqueId}/situation`} />
                        ) : null;
                    }}
                />
            </div>
        );
    }
}

@withRouter
class ProxyRow extends React.Component {
    _select = () => {
        this.props.history.push(`/pescalice/license-pep/${this.props.proxy.uniqueId}`);
    };

    render() {
        const { proxy, selected } = this.props;
        return (
            <dd className={classNames({ selected })} title={proxy.name} onClick={this._select}>
                <span>{proxy.name}</span>
            </dd>
        );
    }
}
