import React from 'react';
import { withRouter } from 'react-router';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@observer
export class CompanyMembersSubSection extends React.Component {
    @observable members = [];

    componentDidMount() {
        this._update();
    }

    _update = () => {
        this.props.store.companies
            .listCompanyMembers(this.props.company.id)
            .then(action((list) => (this.members = list)));
    };

    _newMember = (evt) => {
        evt.preventDefault();
        this.props.store.companies.setShowNewCompanyMemberDialog(true, this.props.company, this.members.slice(), () =>
            this._update()
        );
    };

    _removeMember = (memberId) => {
        const members = this.members
            .filter((m) => m.userId !== memberId)
            .map((m) => ({ userId: m.userId, shares: 0.1, isMainPerson: m.isMainPerson }));
        this.props.store.companies.updateCompanyMembers(this.props.company.id, members).then(this._update);
    };

    render() {
        const { session } = this.props.store;
        const canEdit = !session.info.user.hasTag('psc_ro');

        return (
            <div className="panel ">
                <div className="scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom du collaborateur</th>
                                <th>Personne principale</th>
                                {canEdit && <th />}
                            </tr>
                        </thead>
                        <tbody>
                            {this.members.map((c) => (
                                <MemberRow
                                    company={this.props.company}
                                    canEdit={canEdit}
                                    member={c}
                                    removeMember={this._removeMember}
                                    key={c.userId}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                {canEdit && (
                    <div className="exit">
                        <div>
                            <button onClick={this._newMember}>Nouveau collaborateur</button>
                        </div>
                        <div />
                    </div>
                )}
            </div>
        );
    }
}

function MemberRow(props) {
    const { company, canEdit, member, removeMember } = props;
    const remove = (evt) => {
        evt.preventDefault();

        const ok = confirm(`Voulez vous vraiment détacher "${member.fullName}" de l'entreprise "${company.name}" ?`);
        if (ok) {
            removeMember(member.userId);
        }
    };

    return (
        <tr key={member.userId}>
            <td>
                <a href="#">{member.fullName}</a>
            </td>
            <td>{member.isMainPerson ? <SVGObject className="checkMainPerson" objectId="checkIco" /> : '-'}</td>
            {canEdit && (
                <td>
                    <SVGObject className="trash" objectId="removeIco" onClick={remove} />
                </td>
            )}
        </tr>
    );
}
