/* eslint-disable react/no-children-prop */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { AutoSizer, Table, Column } from 'react-virtualized';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class BoatsSection extends React.Component {
    _selectAllBoats = (evt) => {
        this.props.store.boats.selectAllBoats(evt.target.checked);
    };

    _newBoat = () => {
        this.props.store.boats.setShowNewBoatDialog();
    };

    render() {
        const { session, boats } = this.props.store;
        if (boats.filteredList.length === 0) {
            return (
                <div className="selected">
                    <EmptyContent />
                </div>
            );
        }

        const { t } = this.props;
        const canEdit = !session.info.user.hasTag('psc_ro');

        return (
            <div className="selected">
                <div className="header">
                    <h3>
                        {t('common:boat')}
                        <span>{t('common:x-boats', { count: boats.filteredList.length })}</span>
                    </h3>
                    <div className="toolkit not-implemented">
                        <button>Do something with xx selected boats</button>
                    </div>
                </div>
                <div className="scroll noButNoScroll" style={{ paddingBottom: 0 }}>
                    <AutoSizer
                        children={({ width, height }) => (
                            <Table
                                width={width}
                                height={height}
                                headerHeight={25}
                                rowHeight={25}
                                rowCount={boats.filteredList.length}
                                rowGetter={({ index }) => boats.filteredList[index]}
                            >
                                <Column
                                    label={t('common:name')}
                                    dataKey="name"
                                    cellRenderer={(props) => (
                                        <NameCell
                                            targetUrl={this.props.altUrl}
                                            history={this.props.history}
                                            {...props}
                                        />
                                    )}
                                    width={1}
                                    flexGrow={1}
                                />
                                <Column
                                    label="QM"
                                    dataKey="registration-district"
                                    cellRenderer={(props) => <RegistrationDistrictCell {...props} />}
                                    width={20}
                                />
                                <Column
                                    label={t('common:registration-num')}
                                    dataKey="registration"
                                    cellRenderer={(props) => <RegistrationCell {...props} />}
                                    width={1}
                                    flexGrow={1}
                                />
                                <Column
                                    label={t('common:owner')}
                                    dataKey="owner"
                                    cellRenderer={(props) => <OwnerCell history={this.props.history} {...props} />}
                                    width={1}
                                    flexGrow={1}
                                />
                                <Column
                                    label="Taille"
                                    dataKey="size"
                                    cellRenderer={(props) => <SizeCell {...props} />}
                                    headerStyle={{ textAlign: 'right' }}
                                    style={{ textAlign: 'right' }}
                                    width={80}
                                />
                                <Column
                                    label="Puissance"
                                    dataKey="motor"
                                    cellRenderer={(props) => <MotorCell {...props} />}
                                    headerStyle={{ textAlign: 'right' }}
                                    style={{ textAlign: 'right' }}
                                    width={80}
                                />
                            </Table>
                        )}
                    />
                </div>
                {canEdit && (
                    <div className="exit">
                        <div></div>
                        <div>
                            <button onClick={this._newBoat}>Nouveau navire</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function NameCell(props) {
    const { rowData, history, targetUrl } = props;
    const show = (evt) => {
        evt.preventDefault();
        history.push(`${targetUrl}/${rowData.id}/info`);
    };

    return (
        <a href="#" onClick={show}>
            {rowData.name}
        </a>
    );
}

function RegistrationDistrictCell(props) {
    const { rowData } = props;
    return rowData.registrationDistrict.code;
}

function RegistrationCell(props) {
    const { rowData } = props;
    return rowData.registration;
}

function SizeCell(props) {
    const { rowData } = props;
    return rowData.size ? `${rowData.size.value} ${rowData.size.unit}` : '-';
}

function MotorCell(props) {
    const { rowData } = props;
    return rowData.motor ? `${rowData.motor.value} ${rowData.motor.unit}` : '-';
}

function OwnerCell(props) {
    const { rowData, history } = props;

    const userUrl =
        rowData.ownerIds && rowData.ownerIds.length > 0 ? `/pescalice/user/${rowData.ownerIds[0]}/info` : '';
    const show = (evt) => {
        evt.preventDefault();
        if (userUrl) {
            history.push(userUrl);
        }
    };

    if (rowData.ownerNames && rowData.ownerNames.length > 0) {
        return (
            <a href={userUrl} onClick={show} title={rowData.ownerNames[0]}>
                {rowData.ownerNames[0]}
            </a>
        );
    } else {
        return '-';
    }
}
