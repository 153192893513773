/* eslint-disable react/no-children-prop */

import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter, matchPath } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { AutoSizer, List } from 'react-virtualized';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class UserSelector extends React.Component {
    _update = () => {
        this.props.store.users.listUsers();
    };

    _forcedUpdate = () => {
        this.props.store.users.listUsers(true);
    };

    _switchToAll = () => {
        this.props.history.push(this.props.altUrl);
    };

    _addUser = () => {
        this.props.store.app.setModal(true);
        this.props.store.users.setShowNewUserDialog();
    };

    render() {
        const usersList = this.props.store.users.list || [];

        let defaultRoute = null;
        const firstUser = usersList.length > 0 ? usersList[0] : null;
        if (firstUser) {
            const defaultSelection = `${this.props.baseUrl}/${firstUser.id}`;
            defaultRoute = <Route exact path={this.props.baseUrl} render={() => <Redirect to={defaultSelection} />} />;
        }

        let currentUserName = '';
        const match = matchPath(this.props.location.pathname, { path: `${this.props.baseUrl}/:id` });
        if (match) {
            const currentUser = usersList.find((user) => user.id === parseInt(match.params.id, 10));
            currentUserName = currentUser ? currentUser.fullName() : '';
        }

        const sessionUser = this.props.store.session.info.user;
        const canCreate = !sessionUser.hasTag('psc_ro');
        const { t } = this.props;

        return (
            <OrgChangeObserver onChange={this._forcedUpdate}>
                <div
                    className={classNames('list', 'subList', 'show', { withToggle: sessionUser.isNotRestricted })}
                    style={{ width: sessionUser.type === 'manager' ? 'var(--left-column-width-large)' : undefined }}
                >
                    {sessionUser.isNotRestricted && (
                        <div className="toggle">
                            <span
                                title={
                                    sessionUser.type === 'manager'
                                        ? 'Liste des personnes'
                                        : t('admin:user-selector.fishermen-list')
                                }
                                onClick={this._switchToAll}
                            >
                                <SVGObject objectId="allFishermen" />
                            </span>
                            <span
                                className="selected"
                                title={
                                    sessionUser.type === 'manager'
                                        ? 'Fiches demandeurs'
                                        : t('admin:user-selector.fishermen-cards')
                                }
                            >
                                <SVGObject objectId="singleFisherman" />
                            </span>
                        </div>
                    )}

                    <dl className="show">
                        <div className="burger">
                            <span />
                        </div>
                        <h6>{currentUserName}</h6>
                        <dt>{sessionUser.type === 'manager' ? 'Demandeurs' : t('common:fishermen')}</dt>
                        <AutoSizer
                            children={({ width, height }) => (
                                <List
                                    width={width}
                                    height={height}
                                    rowCount={usersList.length}
                                    overscanRowCount={10}
                                    rowHeight={25}
                                    rowRenderer={(props) => {
                                        const user = usersList[props.index];
                                        const label = user.fullName();
                                        const link = `${this.props.baseUrl}/${user.id}`;
                                        const classes = classNames({
                                            disabled:
                                                user[sessionUser.type === 'manager' ? 'statusPSC' : 'status'] ===
                                                'inactive',
                                            archived:
                                                user[sessionUser.type === 'manager' ? 'statusPSC' : 'status'] ===
                                                'archived',
                                            fromOutside: user.isFromAnotherOrg
                                        });

                                        return (
                                            <SelectorLink
                                                label={label}
                                                to={link}
                                                className={classes}
                                                style={props.style}
                                                key={user.id}
                                            />
                                        );
                                    }}
                                />
                            )}
                        />
                        {defaultRoute}
                    </dl>
                    <ul className="toolbar">{canCreate && <li className="plus" onClick={this._addUser} />}</ul>
                </div>
            </OrgChangeObserver>
        );
    }
}
