import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { observer, MobXProviderContext } from 'mobx-react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const ExportCsvDialogPAP = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    const [division, setDivision] = useState('');
    const [seasonId, setSeasonId] = useState();
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        if (pescalice.displayExportCsvDialogPAP) {
            const defaultSeason = pescalice.seasonsPAP[0];
            setSeasonId(defaultSeason ? defaultSeason.uniqueId : '');
            setDivision('');
        }
    }, [pescalice.displayExportCsvDialogPAP, pescalice.seasonsPAP]);

    const dismiss = (evt) => {
        evt.preventDefault();
        pescalice.setDisplayExportCsvDialogPAP(false);
    };

    const exportCsv = (evt) => {
        evt.preventDefault();

        const promise = pescalice.exportCsvCallbackPAP(division, seasonId) || Promise.resolve();

        setExporting(true);
        promise.then(() => {
            pescalice.setDisplayExportCsvDialogPAP(false);
            setExporting(false);
        });
    };

    return (
        <div className={classNames('modal', { show: pescalice.displayExportCsvDialogPAP })}>
            <div className="sheet">
                <div className="title">
                    <h3>Export CSV</h3>
                    <SVGObject objectId="exitCross" onClick={dismiss} />
                </div>

                <div className="scroll">
                    <form>
                        <div>
                            <label>Division :</label>
                            <select value={division} onChange={(evt) => setDivision(evt.target.value)}>
                                <option value="">Bretagne</option>
                                <option value="cotes-d-armor">Côtes d&apos;Armor</option>
                                <option value="finistere">Finistère</option>
                                <option value="ille-et-vilaine">Ille-et-Vilaine</option>
                                <option value="morbihan">Morbihan</option>
                            </select>
                        </div>
                        <div>
                            <label>Saison :</label>
                            <select value={seasonId} onChange={(evt) => setSeasonId(evt.target.value)}>
                                {pescalice.seasonsPAP.map((season) => (
                                    <option value={season.uniqueId} key={season.uniqueId}>
                                        {season.referenceYear - 1} - {season.referenceYear}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </form>
                </div>

                <div className="exit">
                    <div>
                        <div className={classNames('progressIndic', { show: exporting })} />
                    </div>
                    <div>
                        <button disabled={exporting} onClick={dismiss}>
                            Annuler
                        </button>
                        <button disabled={exporting} onClick={exportCsv}>
                            Exporter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});
