import React, { useContext } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';

import { usePaymentsList } from '@SUPPORT/queries';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const PaymentsFilters = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice, boats } = store;

    const currentYear = new Date().getFullYear();

    const baseYear = pescalice.paymentBaseYearFilter;
    const { data: paymentsMap = {} } = usePaymentsList(store, baseYear);

    const licensesMap = new Map();
    const registrationDistrictsMap = new Map();
    for (const userEntries of Object.values(paymentsMap)) {
        for (const userEntry of userEntries) {
            const user = store.users.userWithId(userEntry.telecapecheId);
            if (!user) {
                continue;
            }

            for (const request of userEntry.licenseRequests) {
                const { payments, license, boat } = request;
                if (payments.length === 0) {
                    continue;
                }

                if (!licensesMap.has(license.uniqueId)) {
                    licensesMap.set(license.uniqueId, license);
                }

                if (!registrationDistrictsMap.has(boat.registrationDistrictCode)) {
                    const rd = boats.registrationDistrictByCode(boat.registrationDistrictCode);
                    if (rd) {
                        registrationDistrictsMap.set(boat.registrationDistrictCode, rd);
                    }
                }
            }
        }
    }
    const licenses = Array.from(licensesMap.values()).sort((l1, l2) => l1.name.localeCompare(l2.name));
    const districts = Array.from(registrationDistrictsMap.values()).sort((r1, r2) => r1.code.localeCompare(r2.code));

    return (
        <div className="list subList show">
            <dl className="show">
                <div className="burger">
                    <span />
                </div>
                <h6>Filtres</h6>
                <dt>
                    Filtres
                    <SVGObject objectId="resetIco" onClick={() => pescalice.resetPaymentsFilters()} />
                </dt>
                <dd className="noSelection">
                    <input
                        type="text"
                        placeholder="Armateur"
                        value={pescalice.paymentRequesterFilter}
                        onChange={(evt) => pescalice.setPaymentRequesterFilter(evt.target.value)}
                    />
                </dd>
                <dd className="noSelection">
                    <select
                        value={pescalice.paymentBaseYearFilter}
                        onChange={(evt) => pescalice.setPaymentBaseYearFilter(evt.target.value)}
                    >
                        <option value={currentYear + 1}>{currentYear + 1}</option>
                        <option value={currentYear}>{currentYear}</option>
                        <option value={currentYear - 1}>{currentYear - 1}</option>
                        <option value={currentYear - 2}>{currentYear - 2}</option>
                        <option value={currentYear - 3}>{currentYear - 3}</option>
                        <option value={currentYear - 4}>{currentYear - 4}</option>
                    </select>
                </dd>
                <dd className="noSelection">
                    <select
                        disabled={licenses.length === 0}
                        value={pescalice.paymentLicenseFilter}
                        onChange={(evt) => pescalice.setPaymentLicenseFilter(evt.target.value)}
                    >
                        <option value="">Toutes les licenses</option>
                        {licenses.map((license) => (
                            <option value={license.uniqueId} key={license.uniqueId}>
                                {license.name}
                            </option>
                        ))}
                    </select>
                </dd>
                <dd className="noSelection">
                    <select
                        disabled={districts.length === 0}
                        value={pescalice.paymentDistrictFilter}
                        onChange={(evt) => pescalice.setPaymentDistrictFilter(evt.target.value)}
                    >
                        <option value="">Tous les quartiers maritimes</option>
                        {districts.map((rd) => (
                            <option value={rd.code} key={rd.id}>
                                {rd.code} - {rd.name}
                            </option>
                        ))}
                    </select>
                </dd>
            </dl>
            <ul className="toolbar" />
        </div>
    );
});
