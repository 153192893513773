import React from 'react';
import classNames from 'classnames';

export function SuggestionsPopup(props) {
    const { show, suggestions, onClick, getKey, getLabel } = props;
    return (
        <ul className={classNames({ show })}>
            {suggestions.map((suggestion) => (
                <li onClick={() => onClick(suggestion)} key={getKey(suggestion)}>
                    {getLabel(suggestion)}
                </li>
            ))}
        </ul>
    );
}
