import React, { useContext } from 'react';
import classNames from 'classnames';
import { observer, MobXProviderContext } from 'mobx-react';

import { onDateFieldChange, isValidDateValue } from '@SUPPORT/form';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export const ToDosFiltersPAP = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    return (
        <div className="list subList show">
            <dl className="show">
                <div className="burger">
                    <span />
                </div>
                <h6>Filtres</h6>
                <dt>
                    Filtres
                    <SVGObject objectId="resetIco" onClick={() => pescalice.resetTodoFiltersPAP()} />
                </dt>
                <dd className="noSelection selected">
                    <select
                        value={pescalice.todoDeptFilterPAP}
                        onChange={(evt) => pescalice.setTodoDeptFilterPAP(evt.target.value)}
                    >
                        <option value="">Tous les départements</option>
                        <option value="PAP22">22 - Côtes d&apos;Armor</option>
                        <option value="PAP29">29 - Finistère</option>
                        <option value="PAP35">35 - Ille et Vilaine</option>
                        <option value="PAP56">56 - Morbihan</option>
                        <option value="PAPXX">Hors Bretagne</option>
                    </select>
                </dd>
                <dd className="noSelection selected">
                    <input
                        type="text"
                        placeholder="Extrait"
                        value={pescalice.todoLicenseFilterPAP}
                        onChange={(evt) => pescalice.setTodoLicenseFilterPAP(evt.target.value)}
                    />
                </dd>
                <dd className="noSelection">
                    <input
                        type="text"
                        placeholder="Nom du demandeur"
                        value={pescalice.todoRequesterFilterPAP}
                        onChange={(evt) => pescalice.setTodoRequesterFilterPAP(evt.target.value)}
                    />
                </dd>
                {/*<dd className="noSelection aligned">
                    <select
                        value={pescalice.todoTypeFilterPAP}
                        onChange={(evt) => pescalice.setTodoTypeFilterPAP(evt.target.value)}
                    >
                        <option value="">Toutes les natures</option>
                        <option value="isFirstInstallation">1ères installations</option>
                        <option value="isLicenseRenewal">Renouvellements</option>
                        <option value="isNewLicenseRequest">Nouvelles demandes</option>
                    </select>
                </dd>
                <dd className="noSelection aligned">
                    <select
                        value={pescalice.todoStatusFilterPAP}
                        onChange={(evt) => pescalice.setTodoStatusFilterPAP(evt.target.value)}
                        disabled
                    >
                        <option value="">Tous les statuts</option>
                        <option value="kPSLicenseRequestGlobalStatusInitial">Demandes déposées</option>
                        <option value="kPSLicenseRequestGlobalStatusFrozen">Demandes en attente</option>
                        <option value="kPSLicenseRequestGlobalStatusSuspended">Demandes incomplètes</option>
                        <option value="kPSLicenseRequestGlobalStatusReserved">Demandes réservées</option>
                        <option value="kPSLicenseRequestGlobalStatusAllowed">Demandes attribuées</option>
                        <option value="kPSLicenseRequestGlobalStatusRefused">Demandes refusées</option>
                        <option value="kPSLicenseRequestGlobalStatusCancelled">Demandes annulées</option>
                    </select>
                </dd>*/}
                <dd className="noSelection aligned">
                    <label>À partir du :</label>
                    <input
                        type="text"
                        className={classNames('date', {
                            codeRed:
                                pescalice.todoFromDateFilterPAP &&
                                !isValidDateValue(pescalice.todoFromDateFilterPAP, 'dd/MM/yyyy')
                        })}
                        placeholder="dd/mm/yyyy"
                        value={pescalice.todoFromDateFilterPAP}
                        onChange={(evt) =>
                            onDateFieldChange(evt, pescalice.todoFromDateFilterPAP, pescalice.setTodoFromDateFilterPAP)
                        }
                    />
                </dd>
                <dd className="noSelection aligned">
                    <label>Jusqu&apos;au:</label>
                    <input
                        type="text"
                        className={classNames('date', {
                            codeRed:
                                pescalice.todoToDateFilterPAP &&
                                !isValidDateValue(pescalice.todoToDateFilterPAP, 'dd/MM/yyyy')
                        })}
                        placeholder="dd/mm/yyyy"
                        value={pescalice.todoToDateFilterPAP}
                        onChange={(evt) =>
                            onDateFieldChange(evt, pescalice.todoToDateFilterPAP, pescalice.setTodoToDateFilterPAP)
                        }
                    />
                </dd>
            </dl>
            <ul className="toolbar" />
        </div>
    );
});
