import React, { useEffect, useContext } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IS_PECALICE } from '@SUPPORT/utils';

import { Header } from '@COMPONENTS/common/Header';
import { Copyright } from '@COMPONENTS/common/Copyright';
import { ModalBackground } from '@COMPONENTS/common/ModalBackground';

import { GearsSelector } from '@COMPONENTS/common/GearsSelector';
import { GearSection } from './GearSection';

import { ToDosFilters } from '@COMPONENTS/pescalice/ToDosFilters';
import { ToDosFiltersPAP } from '@COMPONENTS/pescalice/ToDosFiltersPAP';
import { ToDosSection } from '@COMPONENTS/pescalice/ToDosSection';
import { LicensesSelector } from '@COMPONENTS/pescalice/LicensesSelector';
import { LicensesSection } from '@COMPONENTS/pescalice/LicensesSection';
import { LicenseSelector } from '@COMPONENTS/pescalice/LicenseSelector';
import { LicenseSection } from '@COMPONENTS/pescalice/LicenseSection';
import { LicenseRequestDialog } from '@COMPONENTS/pescalice/LicenseRequestDialog';
import { LicenseCampaignDialog } from '@COMPONENTS/pescalice/LicenseCampaignDialog';

import { ToDosSectionPAP } from '@COMPONENTS/pescalice/ToDosSectionPAP';
import { LicensesSelectorPAP } from '@COMPONENTS/pescalice/LicensesSelectorPAP';
import { LicensesSectionPAP } from '@COMPONENTS/pescalice/LicensesSectionPAP';
import { LicenseSelectorPAP } from '@COMPONENTS/pescalice/LicenseSelectorPAP';
import { LicenseSectionPAP } from '@COMPONENTS/pescalice/LicenseSectionPAP';
import { LicenseRequestDialogPAP } from '@COMPONENTS/pescalice/LicenseRequestDialogPAP';
import { ExportCsvDialogPEP } from '@COMPONENTS/pescalice/ExportCsvDialogPEP';
import { ExportCsvDialogPAP } from '@COMPONENTS/pescalice/ExportCsvDialogPAP';
import { GenerateNotificationDialog } from '@COMPONENTS/pescalice/GenerateNotificationDialog';
import { PaymentsFilters } from '@COMPONENTS/pescalice/PaymentsFilters';
import { PaymentsFiltersPAP } from '@COMPONENTS/pescalice/PaymentsFiltersPAP';
import { PaymentsSection } from '@COMPONENTS/pescalice/PaymentsSection';
import { PaymentsSectionPAP } from '@COMPONENTS/pescalice/PaymentsSectionPAP';
import { PaymentsDialog } from '@COMPONENTS/pescalice/PaymentsDialog';
// import { LicenseCampaignDialog } from '@COMPONENTS/pescalice/LicenseCampaignDialog';

// Import users and boat management UI directly from admin page
import { UsersSelector } from '@COMPONENTS/admin/people/UsersSelector';
import { UsersSection } from '@COMPONENTS/admin/people/UsersSection';
import { UserSelector } from '@COMPONENTS/admin/people/UserSelector';
import { UserSection } from '@COMPONENTS/admin/people/UserSection';
import { NewUserDialog } from '@COMPONENTS/admin/people/NewUserDialog';
import { BoatsSelector } from '@COMPONENTS/admin/things/BoatsSelector';
import { BoatsSection } from '@COMPONENTS/admin/things/BoatsSection';
import { BoatSelector } from '@COMPONENTS/admin/things/BoatSelector';
import { BoatSection } from '@COMPONENTS/admin/things/BoatSection';
import { NewBoatDialog } from '@COMPONENTS/admin/things/NewBoatDialog';
import { BoatOwnerRemovalDialog } from '@COMPONENTS/admin/things/BoatOwnerRemovalDialog';
import { CompaniesSection } from '@COMPONENTS/admin/things/CompaniesSection';
import { CompaniesSelector } from '@COMPONENTS/admin/things/CompaniesSelector';
import { CompanySection } from '@COMPONENTS/admin/things/CompanySection';
import { CompanySelector } from '@COMPONENTS/admin/things/CompanySelector';
import { NewCompanyDialog } from '@COMPONENTS/admin/things/NewCompanyDialog';
import { NewCompanyMemberDialog } from '@COMPONENTS/admin/things/NewCompanyMemberDialog';

import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { AlertDialog } from '@COMPONENTS/common/AlertDialog';
import { ErrorBoundary } from '@COMPONENTS/common/ErrorBoundary';
import { WithUserTag } from '@COMPONENTS/common/WithUserTag';
import { UserPicDialog } from '@COMPONENTS/common/UserPicDialog';

import { LicenseRequestInfoPopOver } from '@COMPONENTS/common/LicenseRequestInfoBtn';

export const ManagerRoot = observer(() => {
    const { t } = useTranslation(['common', 'manager']);
    const { store } = useContext(MobXProviderContext);
    const { session, app, users, pescalice } = store;

    useEffect(() => {
        document.title = IS_PECALICE ? 'Pescalice' : 'Telecapêche';
    }, []);

    useEffect(() => {
        if (session) {
            session.getSession().then(store.loadInitialData);
        }
    }, [store, session]);

    if (session.info === null) {
        return null;
    }

    return (
        <ErrorBoundary>
            <Header />

            {app.isReady && (
                <div id="content">
                    <WithUserTag tag="gear,psc">
                        <div className="list">
                            <dl className="show">
                                <div className="burger">
                                    <span />
                                </div>
                                <h6 />

                                <WithUserTag tag="gear">
                                    <dt>{t('manager:main-selector.things')}</dt>
                                    <SelectorLink label={t('common:gears')} to="/gears" />
                                </WithUserTag>

                                <WithUserTag tag="psc">
                                    <dt>Pêche embarquée</dt>
                                    <SelectorLink label={t('common:todos')} to="/pescalice/todos-pep" />
                                    <SelectorLink
                                        label={t('common:licenses')}
                                        to="/pescalice/licenses-pep"
                                        also="/pescalice/license-pep"
                                    />
                                    <WithUserTag tag="psc_payments">
                                        <SelectorLink
                                            label="Paiements"
                                            to="/pescalice/payments"
                                            also="/pescalice/payments"
                                        />
                                    </WithUserTag>

                                    <dt>Pêche à pied</dt>
                                    <SelectorLink label={t('common:todos')} to="/pescalice/todos-pap" />
                                    <SelectorLink
                                        label="Timbres"
                                        to="/pescalice/licenses-pap"
                                        also="/pescalice/license-pap"
                                    />
                                    <WithUserTag tag="psc_payments">
                                        <SelectorLink
                                            label="Paiements"
                                            to="/pescalice/payments-pap"
                                            also="/pescalice/payments-pap"
                                        />
                                    </WithUserTag>

                                    <dt>Gestion</dt>
                                    <SelectorLink label="Personnes" to="/pescalice/users" also="/pescalice/user/:id" />
                                    <SelectorLink
                                        label={t('common:companies')}
                                        to="/pescalice/companies"
                                        also="/pescalice/company"
                                    />
                                    <SelectorLink
                                        label={t('common:boats')}
                                        to="/pescalice/boats"
                                        also="/pescalice/boat/:id"
                                    />
                                </WithUserTag>
                            </dl>
                            <ul className="toolbar" />
                        </div>
                    </WithUserTag>

                    <WithUserTag tag="gear">
                        <Route path="/gears">
                            <GearsSelector baseUrl="/gears" />
                        </Route>
                    </WithUserTag>

                    <WithUserTag tag="psc">
                        <Route path="/pescalice/todos-pep">
                            <ToDosFilters baseUrl="/pescalice/todos-pep" />
                        </Route>
                        <Route path="/pescalice/todos-pap">
                            <ToDosFiltersPAP baseUrl="/pescalice/todos-pap" />
                        </Route>
                        <Route path="/pescalice/licenses-pep">
                            <LicensesSelector baseUrl="/pescalice/licenses-pep" />
                        </Route>
                        <Route path="/pescalice/license-pep">
                            <LicenseSelector baseUrl="/pescalice/license-pep" />
                        </Route>
                        <Route path="/pescalice/payments">
                            <PaymentsFilters baseUrl="/pescalice/payments" />
                        </Route>

                        <Route path="/pescalice/licenses-pap">
                            <LicensesSelectorPAP baseUrl="/pescalice/licenses-pap" />
                        </Route>
                        <Route path="/pescalice/license-pap">
                            <LicenseSelectorPAP baseUrl="/pescalice/license-pap" />
                        </Route>
                        <Route path="/pescalice/payments-pap">
                            <PaymentsFiltersPAP baseUrl="/pescalice/payments-pap" />
                        </Route>

                        <Route path="/pescalice/users">
                            <UsersSelector baseUrl="/pescalice/users" altUrl="/pescalice/user" />
                        </Route>
                        <Route path="/pescalice/user">
                            <UserSelector baseUrl="/pescalice/user" altUrl="/pescalice/users" />
                        </Route>

                        <Route path="/pescalice/companies">
                            <CompaniesSelector baseUrl="/pescalice/companies" altUrl="/pescalice/company" />
                        </Route>
                        <Route path="/pescalice/company">
                            <CompanySelector baseUrl="/pescalice/company" altUrl="/pescalice/companies" />
                        </Route>

                        <Route path="/pescalice/boats">
                            <BoatsSelector baseUrl="/pescalice/boats" altUrl="/pescalice/boat" />
                        </Route>
                        <Route path="/pescalice/boat">
                            <BoatSelector baseUrl="/pescalice/boat" altUrl="/pescalice/boats" />
                        </Route>
                    </WithUserTag>

                    <div className="listResult selected">
                        <div className="contentArea">
                            <WithUserTag tag="gear">
                                <Route path="/gears/:id">
                                    <GearSection />
                                </Route>
                            </WithUserTag>

                            <WithUserTag tag="psc">
                                <Route path="/pescalice/todos-pep">
                                    <ToDosSection />
                                </Route>
                                <Route path="/pescalice/licenses-pep">
                                    <LicensesSection />
                                </Route>
                                <Route path="/pescalice/license-pep">
                                    <LicenseSection />
                                </Route>
                                <WithUserTag tag="psc_payments">
                                    <Route path="/pescalice/payments">
                                        <PaymentsSection baseUrl="/pescalice/payments" altUrl="/pescalice/payments" />
                                    </Route>
                                </WithUserTag>
                                <Route path="/pescalice/todos-pap">
                                    <ToDosSectionPAP />
                                </Route>
                                <Route path="/pescalice/licenses-pap">
                                    <LicensesSectionPAP />
                                </Route>
                                <Route path="/pescalice/license-pap">
                                    <LicenseSectionPAP />
                                </Route>
                                <WithUserTag tag="psc_payments">
                                    <Route path="/pescalice/payments-pap">
                                        <PaymentsSectionPAP
                                            baseUrl="/pescalice/payments-pap"
                                            altUrl="/pescalice/payments-pap"
                                        />
                                    </Route>
                                </WithUserTag>
                                <Route path="/pescalice/users">
                                    <UsersSection baseUrl="/pescalice/users" altUrl="/pescalice/user" />
                                </Route>
                                <Route path="/pescalice/user/:id">
                                    <UserSection rootUrl="/pescalice" baseUrl="/pescalice/user" />
                                </Route>
                                <Route path="/pescalice/companies">
                                    <CompaniesSection baseUrl="/pescalice/companies" altUrl="/pescalice/company" />
                                </Route>
                                <Route path="/pescalice/company/:id">
                                    <CompanySection baseUrl="/pescalice/company" altUrl="/pescalice/companies" />
                                </Route>
                                <Route path="/pescalice/boats">
                                    <BoatsSection baseUrl="/pescalice/boats" altUrl="/pescalice/boat" />
                                </Route>
                                <Route path="/pescalice/boat/:id">
                                    <BoatSection baseUrl="/pescalice/boat" />
                                </Route>
                            </WithUserTag>
                        </div>
                        <Copyright key="copyright" />
                    </div>

                    <WithUserTag tag="gear">
                        <Route exact path="/">
                            <Redirect to="/gears" />
                        </Route>
                    </WithUserTag>
                    <WithUserTag tag="psc">
                        <Route exact path="/">
                            <Redirect to="/pescalice/todos-pep" />
                        </Route>
                        <Route path="/pescalice/todos">
                            <Redirect to="/pescalice/todos-pep" />
                        </Route>
                    </WithUserTag>
                </div>
            )}

            <LicenseRequestInfoPopOver />

            <ModalBackground display={app.inModal} />
            <AlertDialog />
            <LicenseRequestDialog />
            <LicenseRequestDialogPAP />
            <LicenseCampaignDialog show={pescalice && pescalice.displayNewCampaignDialog} />
            <NewUserDialog baseUrl="/pescalice" />
            <NewBoatDialog baseUrl="/pescalice" />
            <BoatOwnerRemovalDialog />
            <NewCompanyDialog baseUrl="/pescalice" />
            <NewCompanyMemberDialog />

            {users && <UserPicDialog />}
            {pescalice && <ExportCsvDialogPAP />}
            {pescalice && <ExportCsvDialogPEP />}
            {pescalice && <GenerateNotificationDialog />}
            {pescalice && <PaymentsDialog />}
        </ErrorBoundary>
    );
});
