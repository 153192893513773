import React, { useContext, useEffect, useCallback, useState } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';

export const CompanyBoatsSubSection = ({ rootUrl, company }) => {
    const { store } = useContext(MobXProviderContext);
    const { boats } = store;

    const [loading, setLoading] = useState(true);
    const [companyBoats, setCompanyBoats] = useState([]);

    useEffect(() => {
        if (company && company.mainUserId) {
            setLoading(true);
            boats
                .listUserBoats(company.mainUserId)
                .then((infos) => infos.filter((info) => info.relationship.companyId === company.id))
                .then(setCompanyBoats)
                .then(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [boats, company]);

    return (
        <div className="panel">
            {loading ? null : companyBoats && companyBoats.length === 0 ? (
                <EmptyContent />
            ) : (
                <div className="scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>QM</th>
                                <th>Immatriculation</th>
                                <th>Taille</th>
                                <th>Puissance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {companyBoats.map((info) => (
                                <BoatRow info={info} rootUrl={rootUrl} key={info.boat.id} />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

function BoatRow({ info, rootUrl }) {
    const { store } = useContext(MobXProviderContext);
    const { boats } = store;

    const history = useHistory();

    const { boat } = info;
    const registrationDistrict = boats.registrationDistrictById(boat.registrationDistrict);

    const show = useCallback(
        (evt) => {
            evt.preventDefault();
            history.push(`${rootUrl}/boat/${boat.id}/info`);
        },
        [history, rootUrl, boat]
    );

    return (
        <tr>
            <td>
                <a href="#" onClick={show}>
                    {boat.name}
                </a>
            </td>
            <td>{registrationDistrict ? registrationDistrict.code : '-'}</td>
            <td>{boat.registration}</td>
            <td>{boat.size ? `${boat.size.value} ${boat.size.unit}` : '-'}</td>
            <td>{boat.motor ? `${boat.motor.value} ${boat.motor.unit}` : '-'}</td>
        </tr>
    );
}
