/* eslint-disable react/no-children-prop */

import React from 'react';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { AutoSizer, Table, Column } from 'react-virtualized';

import { formatLocaleDate, formatPhoneNumber } from '@SUPPORT/utils';

import { UnlessUserTag } from '@COMPONENTS/common/UnlessUserTag';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

const MAX_SPECIES = 3;

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class UsersSection extends React.Component {
    _update = () => {
        this.props.store.users.listUsers();
    };

    _selectAllUsers = (evt) => {
        this.props.store.users.selectAllUsers(evt.target.checked);
    };

    _printCards = (evt) => {
        evt.preventDefault();
        const ids = Array.from(this.props.store.users.selection.keys());
        window.open(`/cards?${queryString.stringify({ id: ids })}`);
    };

    _downloadCSV = (evt) => {
        evt.preventDefault();

        const sessionUser = this.props.store.session.info.user;
        let filename = sessionUser.type === 'manager' ? 'demandeurs' : 'pecheurs';
        filename += '_' + formatLocaleDate(Date.now(), 'dd_MM_yyyy_HH_mm');
        filename += '.csv';

        this.props.store.users.downloadFilteredUsers(filename);
    };

    render() {
        const users = this.props.store.users.filteredList;
        const selection = this.props.store.users.selection;
        const sessionUser = this.props.store.session.info.user;
        const withCheckboxes =
            !sessionUser.hasTag('pal') && !sessionUser.hasTag('dpma') && sessionUser.type !== 'manager';

        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="selected">
                    <div className="header">
                        <div className="alert codeRed not-implemented">
                            Il vous reste 391 SMS disponibles (<a href="#">acheter des packs SMS</a>)
                        </div>
                        <h3>
                            {sessionUser.type === 'manager'
                                ? 'Liste des personnes'
                                : t('admin:user-selector.fishermen-list')}
                            <span>
                                {sessionUser.type === 'manager'
                                    ? t('common:x-requester', { count: users.length })
                                    : t('common:x-fishermen', { count: users.length })}
                            </span>
                        </h3>
                        <div className="toolkit">
                            <UnlessUserTag tag="pal,dpma,psc">
                                <button disabled={selection.size === 0} onClick={this._printCards}>
                                    {t('admin:users-section.print-x-cards', { count: selection.size })}
                                </button>
                            </UnlessUserTag>
                            <button onClick={this._downloadCSV}>Exporter CSV</button>
                        </div>
                    </div>
                    <div className="scroll noButNoScroll" style={{ paddingBottom: 0 }}>
                        <AutoSizer
                            children={({ width, height }) => (
                                <Table
                                    width={width}
                                    height={height}
                                    headerHeight={withCheckboxes ? 38 : 25}
                                    rowHeight={withCheckboxes ? 38 : 25}
                                    rowCount={users.length}
                                    rowGetter={({ index }) => users[index]}
                                >
                                    {withCheckboxes && (
                                        <Column
                                            dataKey="selection"
                                            headerRenderer={(props) => (
                                                <SelectionHeaderCell store={this.props.store} {...props} />
                                            )}
                                            cellRenderer={(props) => (
                                                <SelectionCell store={this.props.store} {...props} />
                                            )}
                                            width={60}
                                            flexGrow={0}
                                        />
                                    )}
                                    <Column
                                        label={t('common:name')}
                                        dataKey="name"
                                        cellRenderer={(props) => (
                                            <NameCell
                                                targetUrl={this.props.altUrl}
                                                history={this.props.history}
                                                {...props}
                                            />
                                        )}
                                        width={1}
                                        flexGrow={1}
                                    />
                                    {sessionUser.type !== 'manager' && (
                                        <Column
                                            label={t('common:identifier')}
                                            dataKey="identifier"
                                            cellRenderer={(props) => (
                                                <IdentifiersCell sessionUser={sessionUser} {...props} />
                                            )}
                                            width={1}
                                            flexGrow={1}
                                        />
                                    )}
                                    {sessionUser.hasTag('psc') && (
                                        <Column
                                            label="Numéro de Marin"
                                            dataKey="license_num"
                                            cellRenderer={(props) => (
                                                <LicenceNumCell sessionUser={sessionUser} {...props} />
                                            )}
                                            width={1}
                                            flexGrow={1}
                                        />
                                    )}
                                    {sessionUser.hasTag('psc') && (
                                        <Column
                                            label={t('common:zipcode')}
                                            dataKey="zipcode"
                                            cellRenderer={(props) => (
                                                <ZipCodeCell sessionUser={sessionUser} {...props} />
                                            )}
                                            width={1}
                                            flexGrow={1}
                                        />
                                    )}
                                    {sessionUser.hasTag('psc') && (
                                        <Column
                                            label={t('common:email')}
                                            dataKey="email"
                                            cellRenderer={(props) => <EmailCell sessionUser={sessionUser} {...props} />}
                                            width={1}
                                            flexGrow={1}
                                        />
                                    )}
                                    <Column
                                        label={t('common:mobile-number')}
                                        dataKey="mobileNumber"
                                        cellRenderer={(props) => <MobileNumberCell {...props} />}
                                        width={1}
                                        flexGrow={1}
                                    />
                                    {!sessionUser.hasTag('pal') && sessionUser.type !== 'manager' && (
                                        <Column
                                            label={t('common:species')}
                                            dataKey="species"
                                            cellRenderer={(props) => (
                                                <SpeciesCell store={this.props.store} {...props} />
                                            )}
                                            width={1}
                                            flexGrow={1}
                                        />
                                    )}
                                    {sessionUser.type !== 'manager' && !sessionUser.hasTag('dpma') && (
                                        <Column
                                            label={t('admin:users-section.last-declaration')}
                                            dataKey="lastDeclaration"
                                            cellRenderer={(props) => <LastDeclaration {...props} />}
                                            width={1}
                                            flexGrow={1}
                                        />
                                    )}
                                </Table>
                            )}
                        />
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}

function SelectionHeaderCell(props) {
    const selectAll = (evt) => props.store.users.selectAllUsers(evt.target.checked);
    return (
        <React.Fragment>
            <input type="checkbox" id="selectAll" onChange={selectAll} />
            <label htmlFor="selectAll" />
        </React.Fragment>
    );
}

function SelectionCell(props) {
    const { store, rowData } = props;
    const select = (evt) => store.users.selectUser(rowData.id, evt.target.checked);

    return (
        <React.Fragment>
            <input
                type="checkbox"
                id={`select-${rowData.id}`}
                checked={store.users.isSelected(rowData.id)}
                onChange={select}
            />
            <label
                htmlFor={`select-${rowData.id}`}
                style={{ visibility: rowData.isFromAnotherOrg ? 'hidden' : 'visible' }}
            />{' '}
        </React.Fragment>
    );
}

function NameCell(props) {
    const { rowData, history, targetUrl } = props;
    const show = (evt) => {
        evt.preventDefault();
        history.push(`${targetUrl}/${rowData.id}`);
    };

    return (
        <a href="#" onClick={show}>
            {rowData.fullName()}
        </a>
    );
}

function IdentifiersCell(props) {
    const { rowData, sessionUser } = props;
    const identifiers = rowData.identifier.match(/CRPMInsert/)
        ? ['-']
        : [rowData.identifier, rowData.identifier2, rowData.identifier3];

    return identifiers
        .filter((i) => i)
        .filter((i) => (sessionUser.hasTag('pap') && sessionUser.tags.length === 1 ? i.match(/\d{2}-\d{4}/) : i))
        .join(' / ');
}

function LicenceNumCell(props) {
    const { rowData } = props;
    return rowData.licenseNum || '-';
}

function ZipCodeCell(props) {
    const { rowData } = props;
    return rowData.zipCode || '-';
}

function EmailCell(props) {
    const { rowData } = props;
    return rowData.email ? <a href={`mailto:${rowData.email}`}>{rowData.email}</a> : '-';
}

function MobileNumberCell(props) {
    const { rowData } = props;
    return (
        <a href={formatPhoneNumber(rowData.mobileNum1, 'RFC3966')}>
            {formatPhoneNumber(rowData.mobileNum1, 'National', (rowData.countryCode || '').toUpperCase())}
        </a>
    );
}

function SpeciesCell(props) {
    const { store, rowData } = props;
    const userSpecies = rowData.species.reduce((arr, sp) => {
        if (sp.code === '***') {
            store.species.orgList
                .filter((sp) => !rowData.species.some((s) => s.code === sp.code))
                .forEach((sp) => arr.push(sp));
        } else {
            arr.push(sp);
        }

        return arr;
    }, []);

    const el1Index = userSpecies.findIndex((sp) => sp.code === 'EL1');
    if (el1Index !== -1 && el1Index !== 0) {
        const deleted = userSpecies.splice(el1Index, 1);
        userSpecies.unshift(deleted[0]);
    }

    const species = [];
    const max = userSpecies.length <= MAX_SPECIES ? userSpecies.length : MAX_SPECIES;
    for (let i = 0; i < max; i++) {
        const sp = userSpecies[i];
        species.push(
            <i className="yellow" title={`${sp.code} - ${sp.name}`} key={sp.code}>
                {sp.code}
            </i>
        );
    }

    if (userSpecies.length > MAX_SPECIES) {
        const remainderCount = userSpecies.length - MAX_SPECIES;
        const label = remainderCount === 1 ? '+1 espèce' : `+${remainderCount} espèces`;
        const title = [];
        const max = userSpecies.length;
        for (let i = MAX_SPECIES; i < max; i++) {
            const sp = userSpecies[i];
            title.push(sp.code);
        }

        species.push(
            <i className="yellow" title={title.join(', ')} key="remainder">
                {label}
            </i>
        );
    }

    return species;
}

function LastDeclaration(props) {
    const { rowData } = props;
    return (
        <React.Fragment>
            {rowData.lastDeclarationDate ? formatLocaleDate(rowData.lastDeclarationDate, 'P p') : '-'}
            {rowData.lastDeclarationSpecies ? <i className="yellow">{rowData.lastDeclarationSpecies}</i> : null}
        </React.Fragment>
    );
}
