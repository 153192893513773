import React from 'react';
import { observable, action, reaction, computed } from 'mobx';
import { inject, observer, disposeOnUnmount } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { formatLocaleDate } from '../../support/utils';

import { getRequestTypeLabel, getRequestStatusLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { LicenseRequestInfoBtn } from '@COMPONENTS/common/LicenseRequestInfoBtn';

@inject('store')
@withRouter
@observer
export class LicenseContingentsSubSection extends React.Component {
    @observable contingentFilter = '';
    @observable statusFilter = '';
    @observable typeFilter = '';
    @observable exporting = false;

    componentDidMount() {
        this._update();

        // When currentTodo switches to null, i.e. when the request dialog
        // is dismissed, reload the list.
        const { pescalice } = this.props.store;
        disposeOnUnmount(
            this,
            reaction(
                () => pescalice.currentTodo,
                () => {
                    if (pescalice.currentTodo === null) {
                        this._update(true);
                    }
                }
            )
        );
    }

    componentWillUnmount() {
        this.props.store.pescalice.resetLicenseContingents();
    }

    componentDidUpdate(prevProps) {
        if (this.props.season && this.props.season !== prevProps.season) {
            this._update();
        }
    }

    @computed get isFiltered() {
        return this.contingentFilter !== '' || this.statusFilter !== '' || this.typeFilter !== '';
    }

    @computed get filteredRequests() {
        const { pescalice } = this.props.store;
        return pescalice.licenseRequests.reduce((arr, request) => {
            if (this.contingentFilter !== '') {
                const quota = request.targetableQuotas.find((q) => q.uniqueId === this.contingentFilter);
                if (!quota) {
                    return arr;
                }
            }

            if (this.statusFilter !== '' && request.globalStatus !== this.statusFilter) {
                return arr;
            }

            if (this.typeFilter !== '' && !request[this.typeFilter]) {
                return arr;
            }

            arr.push(request);
            return arr;
        }, []);
    }

    _update = () => {
        if (this.props.season) {
            const { pescalice } = this.props.store;
            const license = this.props.season.license;
            pescalice.listLicenseContingents(license.uniqueId);
            this._initFiltersFromQuery();
        }
    };

    @action _updateContingentFilter = (evt) => {
        this.contingentFilter = evt.target.value;
        this._updateQuery();
    };

    @action _updateStatusFilter = (evt) => {
        this.statusFilter = evt.target.value;
        this._updateQuery();
    };

    @action _updateTypeFilter = (evt) => {
        this.typeFilter = evt.target.value;
        this._updateQuery();
    };

    @action _resetFilters = () => {
        this.contingentFilter = '';
        this.statusFilter = '';
        this.typeFilter = '';
        this._updateQuery();
    };

    @action _initFiltersFromQuery = () => {
        const urlParams = new URLSearchParams(this.props.history.location.search);
        if (urlParams.has('ctg')) {
            this.contingentFilter = urlParams.get('ctg');
        }
        if (urlParams.has('tp')) {
            this.typeFilter = urlParams.get('tp');
        }
        if (urlParams.has('st')) {
            this.statusFilter = urlParams.get('st');
        }
    };

    _updateQuery = () => {
        const urlParams = new URLSearchParams();
        if (this.contingentFilter !== '') {
            urlParams.append('ctg', this.contingentFilter);
        }
        if (this.typeFilter !== '') {
            urlParams.append('tp', this.typeFilter);
        }
        if (this.statusFilter !== '') {
            urlParams.append('st', this.statusFilter);
        }

        this.props.history.push({ search: urlParams.toString() });
    };

    @action setExporting(exporting = true) {
        this.exporting = exporting;
    }

    _exportCSV = (evt) => {
        evt.preventDefault();

        const { pescalice } = this.props.store;
        pescalice.setDisplayExportCsvDialogPEP(true, (exportType) => {
            this.setExporting();

            let filename = this.props.season.baseYear;
            filename += '_' + this.props.season.license.name.replace(/\s/g, '_');
            filename += '_' + formatLocaleDate(Date.now(), 'dd_MM_yyyy_HH_mm');
            filename += '.csv';

            const { pescalice } = this.props.store;
            const complete = exportType === 'complete';

            return pescalice
                .downloadRequestsCSVReport(filename, this.filteredRequests, complete, false)
                .finally(() => this.setExporting(false));
        });
    };

    render() {
        const { match, quotas, store } = this.props;
        if (!match || !quotas) {
            return null;
        }

        const { session } = store;
        const canEdit = !session.info.user.hasTag('psc_ro');

        return (
            <div className="panel">
                <div className="scroll">
                    <div className="toolkit">
                        <select value={this.contingentFilter} onChange={this._updateContingentFilter}>
                            <option value="">Tous les contingents</option>
                            {quotas.contingents
                                .filter((c) => c.id !== '-')
                                .map((contingent) => (
                                    <option value={contingent.id} key={contingent.id}>
                                        {contingent.name}
                                    </option>
                                ))}
                        </select>
                        <select value={this.typeFilter} onChange={this._updateTypeFilter}>
                            <option value="">Toutes les natures</option>
                            <option value="isLicenseRenewal">Renouvellement</option>
                            <option value="isFirstInstallation">1ère installation</option>
                            <option value="isNewLicenseRequest">Nouvelle demande</option>
                        </select>
                        <select value={this.statusFilter} onChange={this._updateStatusFilter}>
                            <option value="">Tous les statuts</option>
                            <option value="kPSLicenseRequestGlobalStatusInitial">Demandées</option>
                            <option value="kPSLicenseRequestGlobalStatusFrozen">En attente</option>
                            <option value="kPSLicenseRequestGlobalStatusSuspended">Incomplètes</option>
                            <option value="kPSLicenseRequestGlobalStatusReserved">Mises en réserve</option>
                            <option value="kPSLicenseRequestGlobalStatusAllowed">Attribuées</option>
                            <option value="kPSLicenseRequestGlobalStatusRefused">Refusées</option>
                            <option value="kPSLicenseRequestGlobalStatusCancelled">Annulées</option>
                        </select>
                        {this.isFiltered && <SVGObject objectId="resetIco" onClick={this._resetFilters} />}
                        <button
                            style={{ marginLeft: 'auto', alignSelf: 'flex-end' }}
                            onClick={this._exportCSV}
                            disabled={this.filteredRequests.length === 0 || this.exporting}
                        >
                            Exporter CSV
                        </button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Contingent</th>
                                <th>Date de dépôt</th>
                                <th>Armateur</th>
                                <th>Navire</th>
                                <th>Type</th>
                                <th>Statut</th>
                                {canEdit && <th />}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.filteredRequests.map((request) => (
                                <ContingentRow
                                    request={request}
                                    history={this.props.history}
                                    store={this.props.store}
                                    canEdit={canEdit}
                                    key={request.uniqueId}
                                    afterDelete={this._update}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function ContingentRow(props) {
    const { request, history, store, canEdit } = props;
    const { pescalice, users } = store;

    const openRequester = (evt) => {
        evt.preventDefault();
        const companyId = request.requestAuthor.companyTelecapecheId
        if (companyId != null) {
            history.push(`/pescalice/company/${companyId}/info`);
        }
        else {
            const userId = request.requestAuthor.telecapecheId;
            history.push(`/pescalice/user/${userId}/info`);    
        }            
    };

    const openBoat = (evt) => {
        evt.preventDefault();
        const boatId = request.boat.telecapecheId;
        history.push(`/pescalice/boat/${boatId}/info`);
    };

    const edit = (evt) => {
        evt.preventDefault();
        if (evt.shiftKey) {
            const ok = confirm('Voulez-vous vraiment supprimer cette demande ?');
            if (ok) {
                pescalice.deleteLicenseRequest(request.uniqueId).then(props.afterDelete);
            }
        } else {
            pescalice.setDisplayNewRequestDialog(true, request.uniqueId, props.afterDelete);
        }
    };

    const requester = users.userWithId(request.requestAuthor.telecapecheId);

    return (
        <tr>
            <td>
                <span>Contingent :</span>
                {request.targetableQuotas.length > 0
                    ? request.targetableQuotas
                          .slice()
                          .sort((q1, q2) => q1.name.localeCompare(q2.name))
                          .map((quota) => (
                              <p key={quota.uniqueId} style={{ font: 'var(--font-table-text)' }}>
                                  {quota.name}
                              </p>
                          ))
                    : '-'}
            </td>
            <td>
                <span>Date de dépôt:</span>
                {formatLocaleDate(request.depositDate, 'PP')}
            </td>
            <td>
                <span>Armateur :</span>
                <a href="#" onClick={openRequester}>
                    {request.requestAuthor.companyTelecapecheId ? requester && requester.fullCompanyName() ? requester.fullCompanyName() : '?' : requester && request.requestAuthor.fullName ? request.requestAuthor.fullName : ''}
                </a>
            </td>
            <td>
                <span>Navire :</span>
                <a
                    href="#"
                    onClick={openBoat}
                >{`${request.boat.registrationDistrictCode} ${request.boat.registration}`}</a>
            </td>
            <td>
                <span>Type :</span>
                {getRequestTypeLabel(request)}
            </td>
            <td className={getRequestStatusColorCode(request.globalStatus)}>
                <span>Statut :</span>
                {getRequestStatusLabel(request.globalStatus)}
            </td>
            {canEdit && (
                <td>
                    <span />
                    {request.globalStatus !== 'kPSLicenseRequestGlobalStatusCancelled' && (
                        <a href="#" onClick={edit}>
                            Éditer
                        </a>
                    )}
                </td>
            )}
            <td>
                <LicenseRequestInfoBtn requestId={request.uniqueId} />
            </td>
        </tr>
    );
}
