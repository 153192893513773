import '@CSS/reset.css';
import '@CSS/form-controls.css';
import '@CSS/modal.css';
import '@CSS/variables.css';
import '@CSS/telecapeche2.css';

import 'react-virtualized/styles.css';
import '@CSS/virtualized.css';

import { configureRollbar } from '@SUPPORT/rollbar';
configureRollbar();

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { store } from '@STORES/manager';
import { ManagerRoot } from '@COMPONENTS/manager/Root';

import { LanguageLoader } from '@COMPONENTS/common/LanguageLoader';

import { adaptToPlatform } from '@SUPPORT/utils';
adaptToPlatform();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            onError: (err) => Rollbar.error(err.message, err)
        },
        mutations: {
            onError: (err, vars) => Rollbar.error(err.message, err, vars)
        }
    }
});

const root = document.getElementById('root');
ReactDOM.render(
    <Provider store={store}>
        <LanguageLoader namespace="manager">
            <BrowserRouter basename="/manager">
                <QueryClientProvider client={queryClient}>
                    <Route component={ManagerRoot} />
                </QueryClientProvider>
            </BrowserRouter>
        </LanguageLoader>
    </Provider>,
    root
);
