import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import groupBy from 'lodash.groupby';
import { observable, computed, when, reaction, toJS } from 'mobx';
import { inject, observer, disposeOnUnmount } from 'mobx-react';
import styled from 'styled-components';

import { getOptionStatusLabel } from '@STORES/common/pescalice';

import { validator } from '@SUPPORT/validator';
import { enhanceDateField } from '@SUPPORT/form';
import { formatLocaleDate, reportError, parseFloatingNum } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { randomId } from '../../support/utils';

const LiStyled = styled.li`
    display: flex;
    align-items: center;

    .newWizard > ul > & input + label:first-of-type {
        width: auto;
    }
`;

const MinusPlusSVG = styled(SVGObject)`
    position: relative;
    top: 3px;
    flex-shrink: 0;
    display: block;
    visibility: visible;
    width: 18px;
    aspect-ratio: 1;
    margin-right: 5px;
    cursor: pointer;
    fill: var(--dark-blue);
`;

const InputWrapper = styled.div`
    position: relative;
    top: 7px;
    margin: -13px 5px 0 5px;
`;

const WrapperCheckAmount = styled.div`
    position: relative;
    display: flex;
    gap: 5px;
    margin-left: 5px;

    .newWizard > ul > li & label:first-of-type {
        width: auto;
        margin-top: 0;
        margin-right: 0;
    }

    & svg {
        top: 0;
    }
`;

function requestLabelType(key) {
    switch (key) {
        case 'isNewLicenseRequest':
            return 'Nouvelle demande';
        case 'isLicenseRenewal':
            return 'Renouvellement';
        case 'isFirstInstallation':
            return '1ère installation';
        default:
            return '-';
    }
}

function decisionLabel(decision) {
    switch (decision) {
        case 'kPSLicenseRequestGlobalStatusFrozen':
            return 'En attente';
        case 'kPSLicenseRequestGlobalStatusSuspended':
            return 'Incomplète';
        case 'kPSLicenseRequestGlobalStatusReserved':
            return 'Mise en réserve';
        case 'kPSLicenseRequestGlobalStatusAllowed':
            return 'Attribuée';
        case 'kPSLicenseRequestGlobalStatusRefused':
            return 'Refusée';
        case '$canceled':
            return 'Annulée';
        default:
            return '-';
    }
}

const INFO_PANE = 'info';
const ADMIN_CRITERIA_PANE = 'adminCriteria';
const ZONE_PANE = 'zones';
const PAYEMENTS_PANE = 'payments';
const OVERVIEW_PANE = 'overview';
const END_PANE = 'end';

function nextPane(currentPane, withZones) {
    switch (currentPane) {
        case INFO_PANE:
            return withZones ? ZONE_PANE : ADMIN_CRITERIA_PANE;
        case ZONE_PANE:
            return ADMIN_CRITERIA_PANE;
        case ADMIN_CRITERIA_PANE:
            return PAYEMENTS_PANE;
        case PAYEMENTS_PANE:
            return OVERVIEW_PANE;
        case OVERVIEW_PANE:
            return END_PANE;
        default:
            return null;
    }
}

function previousPane(currentPane, withZones) {
    switch (currentPane) {
        case OVERVIEW_PANE:
            return PAYEMENTS_PANE;
        case PAYEMENTS_PANE:
            return ADMIN_CRITERIA_PANE;
        case ADMIN_CRITERIA_PANE:
            return withZones ? ZONE_PANE : INFO_PANE;
        case ZONE_PANE:
            return INFO_PANE;
        default:
            return null;
    }
}

@inject('store')
@observer
export class LicenseRequestDialog extends React.Component {
    @observable prefetching = true;
    @observable progressCount = 0;
    @observable currentPaneIdentifier = INFO_PANE;
    @observable displayInfoBox = false;

    @observable license = null;
    @observable licenseSuggestions = null;
    @observable boat = null;
    @observable boatPeople = [];
    @observable boatSuggestions = null;
    @observable requesterIsNotBoatPerson = false;
    @observable allowRequestNature = false;
    @observable requestNatureHint = null;
    @observable globalStatusInfo = null;
    @observable diverSuggestions = [null, null, null];
    @observable diver = [null, null, null];
    @observable zones = null;
    @observable targetedZones = new Set();
    @observable obtainedZones = new Set();

    // Manually handle payments form data because mobx-react-form is a piece of shit library
    // that hasn't been updated since the middle ages.
    @observable payments = [];
    _pricing = null;

    _privateCommentTextArea = React.createRef();
    _publicCommentTextArea = React.createRef();

    componentDidMount() {
        this.form = new ReactForm(
            {
                fields: {
                    license: {
                        type: 'text',
                        rules: 'required',
                        hooks: {
                            onChange: (field) => this._suggestLicense(field.value)
                        }
                    },
                    season: {
                        rules: 'required',
                        hooks: {
                            onChange: (field) => this._handleSeasonChange(field.value)
                        }
                    },
                    boat: {
                        type: 'text',
                        rules: 'required',
                        hooks: {
                            onChange: (field) => this._suggestBoat(field.value)
                        }
                    },
                    requester: {
                        rules: 'required',
                        hooks: {
                            onChange: () => this._checkHints()
                        }
                    },
                    requestDate: {
                        type: 'text',
                        rules: 'required|fdate',
                        placeholder: 'jj/mm/aaaa'
                    },
                    requestType: {
                        rules: 'required|in:isFirstInstallation,isLicenseRenewal,isNewLicenseRequest',
                        value: 'isNewLicenseRequest'
                    },
                    paymentMode: {
                        value: '',
                        hooks: {
                            onChange: (field) => {
                                if (!this.currentTodo || this.currentTodo.payments.length === 0) {
                                    if (field.value === 'kPSPaymentMethodDebit') {
                                        this.payments = [
                                            {
                                                amount: this._pricing || 0,
                                                paid: false,
                                                method: 'kPSPaymentMethodDebit',
                                                _uniqueId: randomId()
                                            }
                                        ];
                                    } else if (field.value === 'kPSPaymentMethodCheck') {
                                        this.payments = [
                                            {
                                                amount: 0,
                                                reference: '',
                                                method: 'kPSPaymentMethodCheck',
                                                _uniqueId: randomId()
                                            }
                                        ];
                                    } else {
                                        this.payments = [];
                                    }
                                }
                            }
                        }
                    },

                    knowsDeliberationsForTheSeason: {
                        type: 'checkbox',
                        id: 'knowsDeliberationsForTheSeason',
                        value: true
                    },
                    paymentsToProfessionalOrganizationsDone: {
                        type: 'checkbox',
                        id: 'paymentsToProfessionalOrganizationsDone',
                        value: true
                    },
                    boatPermitIsValid: {
                        type: 'checkbox',
                        id: 'boatPermitIsValid',
                        value: true
                    },
                    hasGivenFishingStatisticsForPreviousSeason: {
                        type: 'checkbox',
                        id: 'hasGivenFishingStatisticsForPreviousSeason',
                        value: true
                    },
                    hasPaidForLicenseRequest: {
                        type: 'checkbox',
                        id: 'hasPaidForLicenseRequest',
                        value: true
                    },
                    boatRegistrationIsFrench: {
                        type: 'checkbox',
                        id: 'boatRegistrationIsFrench',
                        value: true
                    },

                    globalStatus: {
                        rules: 'required'
                    },
                    privateComment: {},
                    publicComment: {}
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnInit: true, validateOnChange: true, validateOnBlur: false }
            }
        );

        enhanceDateField(this.form.select('requestDate'));

        when(
            () => this.props.store.pescalice,
            () => {
                disposeOnUnmount(
                    this,
                    reaction(
                        () => this.props.store.pescalice.displayNewRequestDialog,
                        (show) => {
                            if (show) {
                                this._prefetchData();
                                if (this.props.store.pescalice.currentTodo) {
                                    this._initWithTodo(this.props.store.pescalice.currentTodo);
                                }
                            }
                        }
                    )
                );

                // disposeOnUnmount(
                //     this,
                //     reaction(() => this.props.store.pescalice.currentTodo, this._initWithTodo)
                // );

                disposeOnUnmount(
                    this,
                    reaction(
                        () => this.globalStatusInfo,
                        () => {
                            if (this.globalStatusInfo) {
                                this.form.select('globalStatus').set(this.globalStatusInfo.globalStatus);
                            }
                        }
                    )
                );
            }
        );
    }

    _startProgress = () => {
        this.progressCount += 1;
    };

    _stopProgress = () => {
        this.progressCount -= 1;
        if (this.progressCount < 0) {
            this.progressCount = 0;
        }
    };

    _prefetchData = () => {
        this._startProgress();
        this.prefetching = true;
        this.props.store.pescalice.prefetchNewRequestData().then(() => {
            this.prefetching = false;
            this._stopProgress();
        });
    };

    _initWithTodo = (todo) => {
        if (!todo) {
            const now = new Date();
            this.form.select('requestDate').set(formatLocaleDate(now, 'P'));
            this.form.select('requestType').set('isLicenseRenewal');
            return;
        }

        console.group('Editing LicenseRequest');
        console.debug('id', todo.uniqueId);
        console.debug('boat', todo.boat.telecapecheId);
        console.debug('requestAuthor', todo.requestAuthor.telecapecheId);
        console.groupEnd();

        this.form.select('requestDate').set(formatLocaleDate(todo.depositDate, 'P'));

        const requestTypeField = this.form.select('requestType');
        if (todo.isFirstInstallation) {
            requestTypeField.set('isFirstInstallation');
        } else if (todo.isLicenseRenewal) {
            requestTypeField.set('isLicenseRenewal');
        } else if (todo.isNewLicenseRequest) {
            requestTypeField.set('isNewLicenseRequest');
        }

        this.form.select('paymentMode').set('');
        if (todo.payments.length > 0) {
            if (todo.payments.some((payment) => payment.method.trim() === 'kPSPaymentMethodDebit')) {
                this.form.select('paymentMode').set('kPSPaymentMethodDebit');
            } else {
                this.form.select('paymentMode').set('kPSPaymentMethodCheck');
            }

            this.payments = toJS(todo.payments);
        } else {
            this.payments = [];
        }
        this._pricing = null;

        this.form
            .select('knowsDeliberationsForTheSeason')
            .set(todo.administrativeRequirement.knowsDeliberationsForTheSeason);
        this.form
            .select('paymentsToProfessionalOrganizationsDone')
            .set(todo.administrativeRequirement.paymentsToProfessionalOrganizationsDone);
        this.form.select('boatPermitIsValid').set(todo.administrativeRequirement.boatPermitIsValid);
        this.form
            .select('hasGivenFishingStatisticsForPreviousSeason')
            .set(todo.administrativeRequirement.hasGivenFishingStatisticsForPreviousSeason);
        this.form.select('hasPaidForLicenseRequest').set(todo.administrativeRequirement.hasPaidForLicenseRequest);
        this.form.select('boatRegistrationIsFrench').set(todo.administrativeRequirement.boatRegistrationIsFrench);

        this.form.select('globalStatus').set(todo.globalStatus);

        if (todo.globalStatuses && todo.globalStatuses.length > 0) {
            const status = todo.globalStatuses[todo.globalStatuses.length - 1];
            this.form.select('privateComment').set(status.privateComment || '');
            this.form.select('publicComment').set(status.publicComment || '');
        }

        this.targetedZones = new Set(todo.targetedZones || []);
        this.obtainedZones = new Set(todo.obtainedZones || []);

        when(
            () => !this.prefetching,
            () => {
                const { pescalice, boats, users } = this.props.store;
                const boat = boats.boatWithId(todo.boat.telecapecheId);

                const license = pescalice.proxyWithId(todo.license.pescaliceIdentifier);
                if (license) {
                    let season = license.seasons.find((s) => s.uniqueId === todo.license.season);
                    if (!season) {
                        season = license.seasons[0];
                    }

                    this.license = license;
                    this.form.select('license').set(license.name);
                    this.form.select('season').set(season.uniqueId);
                    this._handleSeasonChange(season.uniqueId);

                    const { additionalMetadata } = season.license;

                    if (additionalMetadata && additionalMetadata.modeLicenceExtraits === 'licenceXORextrait') {
                        this.form
                            .select('mode')
                            .set(todo.additionalMetadata ? todo.additionalMetadata.modeLicenceExtraits : 'licence');
                    }

                    if (additionalMetadata && additionalMetadata.wizardInitialStepOptionAccessoryView) {
                        if (additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionPlongee') {
                            const { option } = todo.additionalMetadata;
                            this.form.select('option').set(option);
                            this._handleSubOptions(option);

                            if (option === 'plongee') {
                                this._initDiversFields(todo);
                            }
                        } else if (
                            additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionSpecies'
                        ) {
                            const { option } = todo.additionalMetadata;
                            this.form.select('option').set(option);
                        } else if (
                            additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionSeiche'
                        ) {
                            const { option } = todo.additionalMetadata;
                            this.form.select('option').set(option);
                        } else if (additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'diverRows') {
                            this._addDiversFields();
                            this._initDiversFields(todo);
                        } else if (
                            additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionNbDrague'
                        ) {
                            const { option } = todo.additionalMetadata;
                            this.form.select('option').set(option);
                        } else if (
                            additionalMetadata.wizardInitialStepOptionAccessoryView.type ===
                                'popupOptionNbHommesABord' &&
                            boat &&
                            boat.navCategory === 3
                        ) {
                            const { option } = todo.additionalMetadata;
                            this.form.select('option').set(option);
                        }
                    }

                    if (additionalMetadata && additionalMetadata.administrativeRequirementsAdditions) {
                        if (additionalMetadata.administrativeRequirementsAdditions.diversAllowedCertificate) {
                            this.form
                                .select('diversAllowedCertificate')
                                .set(todo.administrativeRequirement.diversAllowedCertificate);
                        }
                        if (additionalMetadata.administrativeRequirementsAdditions.ownsLicenseCSJNationale) {
                            this.form
                                .select('ownsLicenseCSJNationale')
                                .set(todo.administrativeRequirement.ownsLicenseCSJNationale);
                        }
                        if (
                            additionalMetadata.administrativeRequirementsAdditions.allowsOctopusCaptureDataCommunication
                        ) {
                            this.form
                                .select('allowsOctopusCaptureDataCommunication')
                                .set(todo.administrativeRequirement.allowsOctopusCaptureDataCommunication);
                        }
                    }
                }

                if (boat) {
                    this.boat = boat;
                    this.form.select('boat').set(boat.fullDisplayName);
                    boat.listPeople().then(() => {
                        this.boatPeople = boat.people.slice();

                        const boatPerson = boat.personWithId(
                            todo.requestAuthor.telecapecheId,
                            todo.requestAuthor.companyTelecapecheId
                        );
                        this.requesterIsNotBoatPerson = !boatPerson;
                        if (this.requesterIsNotBoatPerson) {
                            let user = users.userWithId(todo.requestAuthor.telecapecheId);
                            if (user) {
                                if (!todo.requestAuthor.companyTelecapecheId) {
                                    user = user.withoutCompanyInfo();
                                }
                                this.boatPeople.push(user);
                            }
                        }

                        this.form
                            .select('requester')
                            .set(
                                todo.requestAuthor.companyTelecapecheId
                                    ? `${todo.requestAuthor.telecapecheId}-${todo.requestAuthor.companyTelecapecheId}`
                                    : todo.requestAuthor.telecapecheId
                            );
                    });
                }
            }
        );
    };

    _suggestLicense = (query) => {
        if (query === '') {
            this.licenseSuggestions = null;
            return;
        }

        const { pescalice } = this.props.store;
        this._startProgress();
        pescalice.suggestLicenses(query).then((licenses) => {
            this.licenseSuggestions = licenses.length > 0 ? licenses : null;
            this._stopProgress();
        });
    };

    _selectSuggestedLicense = (license) => {
        this.license = license;
        this.licenseSuggestions = null;

        this.form.select('license').set(license.name);
        this.form.select('season').set(license.seasons[0].uniqueId);

        this._clearOptionalFields();
        this._handleSeasonChange(license.seasons[0].uniqueId);
    };

    _handleSeasonChange = (seasonId) => {
        const season = this.license.seasons.find((s) => s.uniqueId === seasonId);
        if (!season) {
            return;
        }

        const { zones, additionalMetadata } = season.license;
        if (zones && zones.length > 0) {
            this.zones = groupBy(zones, (z) => (z.parentZoneName === '' ? null : z.parentZoneName));
            this._zoneGroups.forEach(
                (group) =>
                    (this.zones[group] = this.zones[group]
                        .slice()
                        .sort((z1, z2) => z1.name.localeCompare(z2.name, 'fr', { numeric: true })))
            );
        } else {
            this.zones = null;
            this.targetedZones = new Set();
            this.obtainedZones = new Set();
        }

        this._checkHints();

        if (!additionalMetadata) {
            return;
        }

        if (additionalMetadata.modeLicenceExtraits === 'licenceXORextrait') {
            this.form.add({
                key: 'mode',
                rules: 'required',
                value: 'licence'
            });
        }

        if (additionalMetadata.wizardInitialStepOptionAccessoryView) {
            if (additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionPlongee') {
                this.form.add({
                    key: 'option',
                    rules: 'required',
                    value: additionalMetadata.defaultOption || '',
                    extra: { values: additionalMetadata.options || [] },
                    hooks: {
                        onChange: (field) => this._handleSubOptions(field.value)
                    }
                });
            } else if (additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionSpecies') {
                this.form.add({
                    key: 'option',
                    rules: 'required',
                    value: additionalMetadata.defaultOption || '',
                    extra: { values: additionalMetadata.options || [] }
                });
            } else if (additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionSeiche') {
                this.form.add({
                    key: 'option',
                    rules: 'required',
                    value: additionalMetadata.defaultOption || '',
                    extra: { values: additionalMetadata.options || [] }
                });
            } else if (additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'diverRows') {
                this._addDiversFields();
            } else if (
                additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionNbDrague' ||
                additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionNbHommesABord'
            ) {
                this.form.add({
                    key: 'option',
                    rules: 'required',
                    value: additionalMetadata.defaultOption || '',
                    extra: { values: additionalMetadata.options || [] }
                });
            }
        }

        if (additionalMetadata.administrativeRequirementsAdditions) {
            if (additionalMetadata.administrativeRequirementsAdditions.diversAllowedCertificate) {
                this.form.add({
                    key: 'diversAllowedCertificate',
                    id: 'diversAllowedCertificate',
                    type: 'checkbox',
                    value: true
                });
            }
            if (additionalMetadata.administrativeRequirementsAdditions.ownsLicenseCSJNationale) {
                this.form.add({
                    key: 'ownsLicenseCSJNationale',
                    id: 'ownsLicenseCSJNationale',
                    type: 'checkbox',
                    value: true
                });
            }
            if (additionalMetadata.administrativeRequirementsAdditions.allowsOctopusCaptureDataCommunication) {
                this.form.add({
                    key: 'allowsOctopusCaptureDataCommunication',
                    id: 'allowsOctopusCaptureDataCommunication',
                    type: 'checkbox',
                    value: true
                });
            }
        }
    };

    _handleSubOptions = (option) => {
        if (option === 'drague') {
            this._clearDiversFields();
        } else {
            const seasonId = this.form.select('season').value;
            const season = this.license.seasons.find((s) => s.uniqueId === seasonId);
            if (season.baseYear < 2023) {
                this._addDiversFields();
            }
        }
    };

    _addDiversFields = () => {
        for (let i = 1; i <= 3; i++) {
            this.form.add({
                key: `diver${i}`,
                type: 'text',
                hooks: {
                    onChange: (field) => this._suggestDiver(i - 1, field.value)
                }
            });
            this.form.add({ key: `diver${i}Enim`, type: 'text', placeholder: 'ENIM' });
            this.form.add({ key: `diver${i}DecisionNum`, type: 'text', placeholder: 'Decision' });
        }
    };

    _initDiversFields = (todo) => {
        const { pescalice } = this.props.store;
        for (let i = 0; i < 3; i++) {
            const diverInfo = todo.divers[i];
            if (diverInfo) {
                if (diverInfo.telecapecheId === 0) {
                    this.diver[i] = diverInfo;
                    this.form.select(`diver${i + 1}`).set(`${diverInfo.firstName} ${diverInfo.lastName}`);
                } else {
                    const diver = pescalice.userWithId(diverInfo.telecapecheId);
                    this.diver[i] = diver;
                    this.form.select(`diver${i + 1}`).set(diver ? diver.fullName(false) : '');
                }
                this.form.select(`diver${i + 1}Enim`).set(diverInfo.enim || '');
                this.form.select(`diver${i + 1}DecisionNum`).set(diverInfo.decisionNum || '');
            }
        }
    };

    _getDiversArray = () => {
        return this.diver.reduce((arr, diver, i) => {
            if (diver) {
                arr.push({
                    telecapecheId: diver.id,
                    firstName: diver.firstName,
                    lastName: diver.lastName,
                    enim: this.form.select(`diver${i + 1}Enim`).value,
                    decisionNumber: this.form.select(`diver${i + 1}DecisionNum`).value
                });
            }
            return arr;
        }, []);
    };

    _clearDiversFields = () => {
        this.diver = [null, null, null];
        this.diverSuggestions = [null, null, null];
        for (let i = 1; i <= 3; i++) {
            this._clearFormField(`diver${i}`);
            this._clearFormField(`diver${i}Enim`);
            this._clearFormField(`diver${i}DecisionNum`);
        }
    };

    _suggestBoat = (query) => {
        if (query === '') {
            this.boatSuggestions = null;
            return;
        }

        const { pescalice } = this.props.store;
        this._startProgress();
        pescalice.suggestBoats(query).then((boats) => {
            this.boatSuggestions = boats.length > 0 ? boats : null;
            this._stopProgress();
        });
    };

    _selectSuggestedBoat = (boat) => {
        this.boat = boat;
        this.requesterIsNotBoatPerson = false;
        this.boatSuggestions = null;
        this.form.select('boat').set(boat.fullDisplayName);

        this._startProgress();
        boat.listPeople().then(() => {
            this.boatPeople = boat.people.slice();
            this._stopProgress();
            this._selectDefaultRequester();
            this._checkHints();
        });

        const seasonId = this.form.select('season').value;
        const season = this.license.seasons.find((s) => s.uniqueId === seasonId);
        if (
            season &&
            season.license &&
            season.license.additionalMetadata &&
            season.license.additionalMetadata.wizardInitialStepOptionAccessoryView &&
            season.license.additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionNbHommesABord'
        ) {
            if (this.boat.navCategory === 3) {
                this.form.add({
                    key: 'option',
                    rules: 'required',
                    value: season.license.additionalMetadata.defaultOption || '',
                    extra: { values: season.license.additionalMetadata.options || [] }
                });
            } else {
                this._clearFormField('option');
            }
        }
    };

    _selectDefaultRequester = () => {
        const requesterField = this.form.select('requester');

        const renter = this.boatPeople.find((p) => p.isRenter);
        if (renter) {
            requesterField.set(renter.userId);
            return;
        }

        const principalOwner = this.boatPeople.find((p) => p.isPrincipalOwner);
        if (principalOwner && principalOwner.companyId) {
            requesterField.set(`${principalOwner.userId}-${principalOwner.companyId}`);
            return;
        } else if (principalOwner) {
            requesterField.set(principalOwner.userId);
            return;
        }

        const owners = this.boatPeople
            .filter((p) => p.isOwner)
            .sort((o1, o2) => (o2.ownerShares || 0) - (o1.ownerShares || 0));
        const owner = owners[0];
        if (owner && owner.companyId) {
            requesterField.set(`${owner.userId}-${owner.companyId}`);
            return;
        } else if (owner) {
            requesterField.set(owner.userId);
            return;
        }

        const sailors = this.boatPeople.filter((p) => !p.isRenter && !p.isOwner && p.isEmbedded);
        const sailor = sailors[0];
        if (sailor && sailor.companyId) {
            requesterField.set(`${sailor.userId}-${sailor.companyId}`);
        } else if (sailor) {
            requesterField.set(sailor.userId);
        }
    };

    _checkHints = () => {
        const { pescalice } = this.props.store;
        if (!pescalice.currentTodoIsNew) {
            return;
        }

        if (!this.license) {
            return;
        }

        if (!this.boat) {
            return;
        }

        if (!this._selectedRequester) {
            return null;
        }

        this.allowRequestNature = false;
        this.requestNatureHint = null;

        const seasonId = this.form.select('season').value;
        const season = this.license.seasons.find((s) => s.uniqueId === seasonId);
        pescalice
            .fetchRequestNatureHint(season.license, this._selectedRequester, this.boat)
            .then((info) => {
                if (info) {
                    const requestTypeField = this.form.select('requestType');
                    if (info.isFirstInstallation) {
                        requestTypeField.set('isFirstInstallation');
                    } else if (info.isLicenseRenewal) {
                        requestTypeField.set('isLicenseRenewal');
                    } else if (info.isNewLicenseRequest) {
                        requestTypeField.set('isNewLicenseRequest');
                    }

                    this.requestNatureHint = info.reason;

                    if (info.proposedZones && this.zones) {
                        info.proposedZones.forEach((zoneId) => {
                            for (const zones of Object.values(this.zones)) {
                                for (const zone of zones) {
                                    if (zone.uniqueId === zoneId) {
                                        this.obtainedZones.add(zone);
                                    }
                                }
                            }
                        });
                    }
                }
            })
            .finally(() => {
                this.allowRequestNature = true;
            });

        return;
    };

    _suggestDiver = (index, query) => {
        if (query === '') {
            this.diver[index] = null;
            this.diverSuggestions[index] = null;
            return;
        }
        const { pescalice } = this.props.store;
        this._startProgress();
        pescalice.suggestUsers(query).then((users) => {
            this.diverSuggestions[index] = users.length > 0 ? users : null;
            this._stopProgress();
        });
    };

    _selectSuggestedDiver = (index, diver) => {
        this.diver[index] = diver;
        this.diverSuggestions[index] = null;
        this.form.select(`diver${index + 1}`).set(diver.fullName(false));
    };

    _handleZoneStateChange = (zone, state, syncGroup) => {
        this.targetedZones.delete(zone);
        this.obtainedZones.delete(zone);
        if (state === 'two') {
            this.targetedZones.add(zone);
        } else if (state === 'three') {
            this.obtainedZones.add(zone);
        }

        if (syncGroup) {
            const zoneGroup = Object.keys(this.zones).find((g) => this.zones[g].includes(zone));
            if (zoneGroup) {
                this.zones[zoneGroup].forEach((z) => this._handleZoneStateChange(z, state, false));
            }
        }
    };

    _handlePrivateDecisionReasonChange = (evt) => {
        if (this.form.select('globalStatus').value === '$canceled' && evt.target.value === 'Erreur de saisie') {
            const destroy = confirm(
                "Souhaitez vous\n\n- Supprimer définitivement cette demande de Pescalice puisque vous l'annulez pour 'Erreur de Saisie' ? (cliquez OK)\n\n- Conserver cette demande avec un statut 'Annulé' et motif 'Erreur de Saisie' ? (cliquez Annuler)"
            );

            if (destroy) {
                const { pescalice } = this.props.store;
                pescalice.deleteLicenseRequest(pescalice.currentTodo.uniqueId).then(() => {
                    this._dismiss();
                    if (pescalice.currentTodAfterDelete) {
                        pescalice.currentTodAfterDelete();
                    }
                });
                return;
            }
        }

        this._handleDecisionReasonChange('privateComment', evt.target.value);
        evt.target.value = '';
        this._privateCommentTextArea.current.focus();
        setTimeout(
            () => (this._privateCommentTextArea.current.scrollTop = this._privateCommentTextArea.current.scrollHeight),
            50
        );
    };

    _handlePublicDecisionReasonChange = (evt) => {
        this._handleDecisionReasonChange('publicComment', evt.target.value);
        evt.target.value = '';
        this._publicCommentTextArea.current.focus();
        setTimeout(
            () => (this._publicCommentTextArea.current.scrollTop = this._publicCommentTextArea.current.scrollHeight),
            50
        );
    };

    _handleDecisionReasonChange = (fieldName, value) => {
        const now = formatLocaleDate(new Date(), 'Pp');
        let privateComment = this.form.select(fieldName).value;
        privateComment += privateComment.length > 0 ? '\r\n' : '';
        privateComment += `• ${now} : ${value === '-' ? '' : value}`;
        this.form.select(fieldName).set(privateComment);
    };

    @computed
    get _isBusy() {
        return this.progressCount > 0;
    }

    @computed
    get _canGoForward() {
        if (this.currentPaneIdentifier === INFO_PANE) {
            let validDivers = true;
            if (
                (this.form.has('diver1') && this.form.select('diver1').value !== '' && !this.diver[0]) ||
                (this.form.has('diver2') && this.form.select('diver2').value !== '' && !this.diver[1]) ||
                (this.form.has('diver3') && this.form.select('diver3').value !== '' && !this.diver[2])
            ) {
                validDivers = false;
            }

            return (
                this.license &&
                this.form.select('license').isDirty &&
                this.form.select('license').isValid &&
                this.form.select('season').isDirty &&
                this.form.select('season').isValid &&
                this.boat &&
                this.form.select('boat').isDirty &&
                this.form.select('boat').isValid &&
                this._selectedRequester &&
                this.form.select('requestDate').isDirty &&
                this.form.select('requestDate').isValid &&
                validDivers
            );
        } else if (this.currentPaneIdentifier === ADMIN_CRITERIA_PANE) {
            return true;
        } else if (this.currentPaneIdentifier === ZONE_PANE) {
            return true;
        } else if (this.currentPaneIdentifier === PAYEMENTS_PANE) {
            return this.payments.every(
                (payment) =>
                    payment.amount > 0 && (payment.method === 'kPSPaymentMethodDebit' || payment.reference !== '')
            );
        } else if (this.currentPaneIdentifier === OVERVIEW_PANE) {
            return true;
        }

        return false;
    }

    @computed
    get _canGoBackward() {
        return this.currentPaneIdentifier !== INFO_PANE;
    }

    @computed
    get _selectedRequester() {
        if (!this.boat) {
            return null;
        }

        const key = this.form.select('requester').value;
        if (typeof key === 'number') {
            return this.boatPeople.find((person) => person.userId === key || person.id === key);
        } else if (key === '') {
            return null;
        }

        const [userIdStr, companyIdStr] = key.split('-');
        const userId = parseInt(userIdStr, 10);
        const companyId = companyIdStr ? parseInt(companyIdStr, 10) : null;
        return this.boatPeople.find((person) => person.userId === userId && person.companyId === companyId);
    }

    @computed
    get _zoneGroups() {
        if (!this.zones) {
            return [];
        }

        return Object.keys(this.zones).sort();
    }

    _showInfoBox = () => (this.displayInfoBox = true);
    _hideInfoBox = (evt) => {
        evt.preventDefault();
        this.displayInfoBox = false;
    };

    _commitPaneContent = () => {
        const { pescalice } = this.props.store;

        const seasonId = this.form.select('season').value;
        const season = this.license.seasons.find((s) => s.uniqueId === seasonId);
        if (!season) {
            return Promise.reject();
        }

        const { additionalMetadata } = season.license;

        if (this.currentPaneIdentifier === INFO_PANE) {
            const adminDivision = pescalice.administrativeDivisionWithCode(this.boat.registrationDistrict.code);
            const data = {
                isFirstInstallation: this.form.select('requestType').value === 'isFirstInstallation',
                isLicenseRenewal: this.form.select('requestType').value === 'isLicenseRenewal',
                isNewLicenseRequest: this.form.select('requestType').value === 'isNewLicenseRequest',
                administrativeRequirement: {
                    knowsDeliberationsForTheSeason: this.form.select('knowsDeliberationsForTheSeason').value,
                    paymentsToProfessionalOrganizationsDone: this.form.select('paymentsToProfessionalOrganizationsDone')
                        .value,
                    boatPermitIsValid: this.form.select('boatPermitIsValid').value,
                    hasGivenFishingStatisticsForPreviousSeason: this.form.select(
                        'hasGivenFishingStatisticsForPreviousSeason'
                    ).value,
                    hasPaidForLicenseRequest: this.form.select('hasPaidForLicenseRequest').value,
                    boatRegistrationIsFrench: this.form.select('boatRegistrationIsFrench').value
                }
            };

            // On POST: specify license id, include full boat and requester objects
            // On PATCH: don't specify license, only specify boat and requester ids
            if (pescalice.currentTodo) {
                data.boat = this.boat.id;
            } else {
                data.license = season.license.uniqueId;
                data.boat = {
                    telecapecheId: this.boat.id,
                    name: this.boat.name,
                    administrativeDivisionPath: adminDivision.administrativeDivisionPath,
                    length: this.boat.lengthInMeters,
                    motorPower: this.boat.motorPowerInWatts,
                    registration: this.boat.registration,
                    registrationDistrictCode: this.boat.registrationDistrict.code,
                    navigationCategory: this.boat.navCategory
                };

                // Full user data will be fetched before saving
                data.requestAuthor = {
                    telecapecheId: this._selectedRequester.userId,
                    companyTelecapecheId: this._selectedRequester.companyId
                };
            }

            const requestDate = this.form.select('requestDate').value;
            if (requestDate) {
                const date = new Date(requestDate.replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3-$2-$1'));
                data.depositDate = date.toISOString();
            }

            if (this.form.has('mode') || this.form.has('option')) {
                data.additionalMetadata = {};
            }

            if (this.form.has('mode')) {
                const mode = this.form.select('mode').value;
                data.additionalMetadata.modeLicenceExtraits = mode;
            }

            if (this.form.has('option')) {
                const option = this.form.select('option').value;
                data.additionalMetadata.option = option;

                if (option === 'plongee') {
                    data.divers = this._getDiversArray();
                }
            } else if (
                additionalMetadata &&
                additionalMetadata.wizardInitialStepOptionAccessoryView &&
                additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'diverRows'
            ) {
                data.divers = this._getDiversArray();
            }

            if (additionalMetadata && additionalMetadata.administrativeRequirementsAdditions) {
                if (additionalMetadata.administrativeRequirementsAdditions.diversAllowedCertificate) {
                    data.administrativeRequirement.diversAllowedCertificate = this.form.select(
                        'diversAllowedCertificate'
                    ).value;
                }
                if (additionalMetadata.administrativeRequirementsAdditions.ownsLicenseCSJNationale) {
                    data.administrativeRequirement.ownsLicenseCSJNationale = this.form.select(
                        'ownsLicenseCSJNationale'
                    ).value;
                }
                if (additionalMetadata.administrativeRequirementsAdditions.allowsOctopusCaptureDataCommunication) {
                    data.administrativeRequirement.allowsOctopusCaptureDataCommunication = this.form.select(
                        'allowsOctopusCaptureDataCommunication'
                    ).value;
                }
            }

            return pescalice.saveLicenseRequest(data).then(this._capturePricing);
        } else if (this.currentPaneIdentifier === ADMIN_CRITERIA_PANE) {
            const data = {
                administrativeRequirement: {
                    knowsDeliberationsForTheSeason: this.form.select('knowsDeliberationsForTheSeason').value,
                    paymentsToProfessionalOrganizationsDone: this.form.select('paymentsToProfessionalOrganizationsDone')
                        .value,
                    boatPermitIsValid: this.form.select('boatPermitIsValid').value,
                    hasGivenFishingStatisticsForPreviousSeason: this.form.select(
                        'hasGivenFishingStatisticsForPreviousSeason'
                    ).value,
                    hasPaidForLicenseRequest: this.form.select('hasPaidForLicenseRequest').value,
                    boatRegistrationIsFrench: this.form.select('boatRegistrationIsFrench').value
                }
            };

            if (additionalMetadata && additionalMetadata.administrativeRequirementsAdditions) {
                if (additionalMetadata.administrativeRequirementsAdditions.diversAllowedCertificate) {
                    data.administrativeRequirement.diversAllowedCertificate = this.form.select(
                        'diversAllowedCertificate'
                    ).value;
                }
                if (additionalMetadata.administrativeRequirementsAdditions.ownsLicenseCSJNationale) {
                    data.administrativeRequirement.ownsLicenseCSJNationale = this.form.select(
                        'ownsLicenseCSJNationale'
                    ).value;
                }
                if (additionalMetadata.administrativeRequirementsAdditions.allowsOctopusCaptureDataCommunication) {
                    data.administrativeRequirement.allowsOctopusCaptureDataCommunication = this.form.select(
                        'allowsOctopusCaptureDataCommunication'
                    ).value;
                }
            }

            return pescalice
                .saveLicenseRequest(data)
                .then(this._capturePricing)
                .then(() => pescalice.processGlobalStatus())
                .then(this._updateGlobalStatusInfo);
        } else if (this.currentPaneIdentifier === ZONE_PANE) {
            const data = {
                targetedZones: Array.from(this.targetedZones).map((z) => z.uniqueId),
                obtainedZones: Array.from(this.obtainedZones).map((z) => z.uniqueId)
            };

            return pescalice
                .saveLicenseRequest(data)
                .then(this._capturePricing)
                .then(() => pescalice.processGlobalStatus())
                .then(this._updateGlobalStatusInfo);
        } else if (this.currentPaneIdentifier === PAYEMENTS_PANE) {
            const data = { payments: this.payments };
            data.payments.forEach((payment) => delete payment._uniqueId);

            return pescalice
                .saveLicenseRequest(data)
                .then(this._capturePricing)
                .then(() => pescalice.processGlobalStatus())
                .then(this._updateGlobalStatusInfo);
        } else if (this.currentPaneIdentifier === OVERVIEW_PANE) {
            const status = this.form.select('globalStatus').value;
            const canceled = status === '$canceled';
            const data = {
                privateComment: this.form.select('privateComment').value,
                publicComment: this.form.select('publicComment').value
            };

            if ((this.globalStatusInfo && this.globalStatusInfo.globalStatus !== data.globalStatus) || canceled) {
                data.isManuallyForced = true;
            }

            let promise;
            if (canceled) {
                const ok = confirm('Voulez vous réellement annuler cette demande ?');
                if (ok) {
                    promise = pescalice.cancelRequest(data);
                } else {
                    return Promise.reject();
                }
            } else {
                data.globalStatus = status;
                promise = pescalice.saveGlobalStatus(data);
            }

            return promise.then(this._updateGlobalStatusInfo);
        }

        return Promise.resolve();
    };

    _capturePricing = (todo) => {
        if (todo && todo.pricings) {
            this._pricing = todo.pricings.reduce((total, p) => total + p, 0);
        }
    };

    _updateGlobalStatusInfo = (info) => {
        if (!info) {
            return;
        }

        this.globalStatusInfo = info;
        if (info.globalStatuses && info.globalStatuses.length > 0) {
            const status = info.globalStatuses[info.globalStatuses.length - 1];
            this.form.select('privateComment').set(status.privateComment || '');
            this.form.select('publicComment').set(status.publicComment || '');
        }
    };

    _processGlobalStatus = (evt) => {
        evt.preventDefault();
        this._startProgress();
        this.props.store.pescalice
            .processGlobalStatus()
            .then(this._updateGlobalStatusInfo)
            .then(() => this._stopProgress())
            .catch((err) => {
                this._stopProgress();
                reportError(err.wrapped);
            });
    };

    get withZones() {
        if (!this.license) {
            return false;
        }

        const seasonId = this.form.select('season').value;
        const season = this.license.seasons.find((s) => s.uniqueId === seasonId);
        if (
            season &&
            season.license.additionalMetadata &&
            season.license.additionalMetadata.wizardInitialStepOptionAccessoryView &&
            season.license.additionalMetadata.wizardInitialStepOptionAccessoryView.type === 'popupOptionSpecies'
        ) {
            const species = this.form.has('option') ? this.form.select('option').value : null;
            return ['Laminaria Digitata', 'Laminaria Hyperborea + Laminaria Digitata'].includes(species);
        }

        return !!this.zones;
    }

    _nextPane = (evt) => {
        evt.preventDefault();

        this._startProgress();
        this._commitPaneContent()
            .then(() => {
                this._stopProgress();
                this.currentPaneIdentifier = nextPane(this.currentPaneIdentifier, this.withZones);
            })
            .catch((err) => {
                console.log(err);
                this._stopProgress();
                reportError(err.wrapped);
            });
    };

    _previousPane = (evt) => {
        evt.preventDefault();
        this.currentPaneIdentifier = previousPane(this.currentPaneIdentifier, this.withZones);
    };

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _clearFormField = (name) => {
        if (this.form.has(name)) {
            this.form.del(name);
        }
    };

    _clearOptionalFields = () => {
        this._clearFormField('mode');
        this._clearFormField('option');
        this._clearFormField('diversAllowedCertificate');
        this._clearFormField('ownsLicenseCSJNationale');
        this._clearFormField('allowsOctopusCaptureDataCommunication');
        this._clearDiversFields();
    };

    _restart = (evt) => {
        evt.preventDefault();
        this._dismiss(true);
    };

    _dismiss = (restart = false) => {
        const { pescalice } = this.props.store;

        // If the globalStatus changed and is not "started" or "waiting", remove todo
        if (
            pescalice.currentTodo &&
            this.globalStatusInfo &&
            this.globalStatusInfo.globalStatus !== 'kPSLicenseRequestGlobalStatusFrozen' &&
            this.globalStatusInfo.globalStatus !== 'kPSLicenseRequestGlobalStatusInitial'
        ) {
            pescalice.removeLicenseRequest(pescalice.currentTodo.uniqueId);
        }

        // Hide dialog if the user did not ask for a new request
        pescalice.setDisplayNewRequestDialog(restart);

        // Reset non reusable dialog state
        this.progressCount = 0;
        this.currentPaneIdentifier = INFO_PANE;
        this.license = null;
        this.licenseSuggestions = null;
        this.allowRequestNature = false;
        this.requestNatureHint = null;
        this.zones = null;
        this.targetedZones = new Set();
        this.obtainedZones = new Set();
        this.boatSuggestions = null;

        // Save reusable dialog state in case the user asked for a new request
        let requester;
        let requestDate;
        let knowsDeliberationsForTheSeason;
        let paymentsToProfessionalOrganizationsDone;
        let boatPermitIsValid;
        let hasGivenFishingStatisticsForPreviousSeason;
        let hasPaidForLicenseRequest;
        let boatRegistrationIsFrench;

        if (restart) {
            requester = this.form.select('requester').value;
            requestDate = this.form.select('requestDate').value;
            knowsDeliberationsForTheSeason = this.form.select('knowsDeliberationsForTheSeason').value;
            paymentsToProfessionalOrganizationsDone = this.form.select('paymentsToProfessionalOrganizationsDone').value;
            boatPermitIsValid = this.form.select('boatPermitIsValid').value;
            hasGivenFishingStatisticsForPreviousSeason = this.form.select('hasGivenFishingStatisticsForPreviousSeason')
                .value;
            hasPaidForLicenseRequest = this.form.select('hasPaidForLicenseRequest').value;
            boatRegistrationIsFrench = this.form.select('boatRegistrationIsFrench').value;
        }

        // Reset form
        this.form.reset();
        this._clearOptionalFields();

        // Repopulate form if a new request has been asked, finish reset otherwise
        if (restart) {
            this.form.select('boat').set(this.boat.fullDisplayName);
            this.form.select('requester').set(requester);
            this.form.select('requestDate').set(requestDate);
            this.form.select('knowsDeliberationsForTheSeason').set(knowsDeliberationsForTheSeason);
            this.form.select('paymentsToProfessionalOrganizationsDone').set(paymentsToProfessionalOrganizationsDone);
            this.form.select('boatPermitIsValid').set(boatPermitIsValid);
            this.form
                .select('hasGivenFishingStatisticsForPreviousSeason')
                .set(hasGivenFishingStatisticsForPreviousSeason);
            this.form.select('hasPaidForLicenseRequest').set(hasPaidForLicenseRequest);
            this.form.select('boatRegistrationIsFrench').set(boatRegistrationIsFrench);

            this.form.validate();
        } else {
            this.boat = null;
            this.boatPeople = [];
            this.requesterIsNotBoatPerson = false;
        }
    };

    render() {
        const { pescalice } = this.props.store;
        if (!pescalice) {
            return null;
        }

        let statuses = [];
        if (
            this.globalStatusInfo &&
            this.globalStatusInfo.globalStatuses &&
            this.globalStatusInfo.globalStatuses.length > 1
        ) {
            const status = this.globalStatusInfo.globalStatuses[this.globalStatusInfo.globalStatuses.length - 1];
            statuses = status.nonAllowedReasonObject ? status.nonAllowedReasonObject.failureMessages || [] : [];
        }

        let targetedContingentInfo = ['Aucun'];
        if (
            this.globalStatusInfo &&
            this.globalStatusInfo.targetableQuotas &&
            this.globalStatusInfo.targetableQuotas.length > 0
        ) {
            targetedContingentInfo = this.globalStatusInfo.targetableQuotas.map((quota) => {
                let info = quota.name;
                if (quota.isModeLicensesExtraits) {
                    info += ` (licence : ${quota.remainingLicensesCount} dispo sur ${quota.initialLicensesCount}`;
                    info += ' / ';
                    info += ` extrait : ${quota.remainingExtraitsCount} dispo sur ${quota.initialExtraitsCount})`;
                } else {
                    info += ` (${quota.remainingSlotsCount} dispo sur ${quota.initialSlotsCount})`;
                }
                return info;
            });
        }

        let obtainedContingentInfo = ['Aucun'];
        if (
            this.globalStatusInfo &&
            this.globalStatusInfo.inUseQuotas &&
            this.globalStatusInfo.inUseQuotas.length > 0
        ) {
            obtainedContingentInfo = this.globalStatusInfo.inUseQuotas.map((quota) => {
                let info = quota.name;
                if (quota.isModeLicensesExtraits) {
                    info += ` (licence : ${quota.remainingLicensesCount} dispo sur ${quota.initialLicensesCount}`;
                    info += ' / ';
                    info += ` extrait : ${quota.remainingExtraitsCount} dispo sur ${quota.initialExtraitsCount})`;
                } else {
                    info += ` (${quota.remainingSlotsCount} dispo sur ${quota.initialSlotsCount})`;
                }
                return info;
            });
        }

        let allowDebitPayments = false;
        if (this._selectedRequester) {
            const user = this.props.store.users.userWithId(this._selectedRequester.userId);
            if (user && user.bankInfos) {
                const bankInfos = JSON.parse(user.bankInfos) || [];
                const bankInfo = bankInfos.find((info) => info.company_id === this._selectedRequester.companyId);
                if (bankInfo) {
                    allowDebitPayments = true;
                }
            }
        }

        const paymentMode = this.form.select('paymentMode').value;
        const payments = this.payments.filter((p) => p.method === paymentMode);
        const hasPaidDebit = this.payments.some(
            (payment) => payment.method === 'kPSPaymentMethodDebit' && payment.paid
        );

        return (
            <div className={classNames('modal', 'superWide', { show: pescalice.displayNewRequestDialog })}>
                <div className="sheet">
                    <div className="title">
                        <h3>
                            {pescalice.currentTodoIsNew
                                ? "Création d'une demande de licence de Pêche Embarquée"
                                : "Edition d'une demande de licence de Pêche Embarquée"}
                        </h3>
                        <div>
                            <div className={classNames('progressIndic', { show: this._isBusy })} />
                        </div>
                    </div>
                    <form className="newWizard" autoComplete="off">
                        <ul
                            inert={this.currentPaneIdentifier !== INFO_PANE ? 'true' : undefined}
                            className={classNames({
                                left: [
                                    ADMIN_CRITERIA_PANE,
                                    ZONE_PANE,
                                    PAYEMENTS_PANE,
                                    OVERVIEW_PANE,
                                    END_PANE
                                ].includes(this.currentPaneIdentifier)
                            })}
                        >
                            <li className="required">
                                <label>Licence :</label>
                                <div className="datalist">
                                    <input
                                        {...this.form.select('license').bind()}
                                        readOnly={this.prefetching || pescalice.currentTodo}
                                    />
                                    <ul className={classNames({ show: !!this.licenseSuggestions })}>
                                        {this.licenseSuggestions &&
                                            this.licenseSuggestions.map((license) => (
                                                <li
                                                    key={license.uniqueId}
                                                    onClick={() => this._selectSuggestedLicense(license)}
                                                >
                                                    {license.name}
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </li>
                            <li className="required">
                                <label>Campagne :</label>
                                <select
                                    {...this.form.select('season').bind()}
                                    disabled={
                                        this.prefetching ||
                                        pescalice.currentTodo ||
                                        !this.license ||
                                        (this.license && this.license.seasons.length === 0)
                                    }
                                >
                                    {this.license ? (
                                        this.license.seasons.map((season) => (
                                            <option value={season.uniqueId} key={season.uniqueId}>
                                                {season.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" />
                                    )}
                                </select>
                            </li>
                            <li className="required">
                                <label>Navire :</label>
                                <div className="datalist">
                                    <input
                                        {...this.form.select('boat').bind()}
                                        readOnly={this.prefetching || pescalice.currentTodo}
                                    />
                                    <ul className={classNames({ show: !!this.boatSuggestions })}>
                                        {this.boatSuggestions &&
                                            this.boatSuggestions.map((boat) => (
                                                <li key={boat.id} onClick={() => this._selectSuggestedBoat(boat)}>
                                                    {boat.fullDisplayName}
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </li>
                            <li className="required">
                                <label>Armateur :</label>
                                <select
                                    {...this.form.select('requester').bind()}
                                    disabled={this.prefetching || pescalice.currentTodo}
                                >
                                    <option value="">Selectionner...</option>
                                    {this.boatPeople.map((person) => (
                                        <option
                                            value={
                                                person.companyId
                                                    ? `${person.userId || person.id}-${person.companyId}`
                                                    : person.userId || person.id
                                            }
                                            key={
                                                person.companyId
                                                    ? `${person.userId || person.id}-${person.companyId}`
                                                    : person.userId || person.id
                                            }
                                        >
                                            {person.fullCompanyName() || person.fullName(false)}
                                            {person.isRenter ? ' - affréteur' : ''}
                                            {person.isEmbedded ? ' - marin embarqué' : ''}
                                        </option>
                                    ))}
                                </select>
                                {this.requesterIsNotBoatPerson && (
                                    <label title="L'armateur n'est pas dans la liste actuelle des bénéficiaires du navire.">
                                        <SVGObject className="warning" objectId="warningIcon" />
                                    </label>
                                )}
                            </li>
                            <li className="required">
                                <label>Date de dépot:</label>
                                <input
                                    className={classNames('date', {
                                        codeRed:
                                            this.form.select('requestDate').isDirty &&
                                            !this.form.select('requestDate').isValid
                                    })}
                                    {...this.form.select('requestDate').bind()}
                                    readOnly={this.prefetching}
                                />
                            </li>
                            <li className="required">
                                <label>Nature :</label>
                                <select
                                    {...this.form.select('requestType').bind()}
                                    disabled={this.prefetching || !this.allowRequestNature || pescalice.currentTodo}
                                >
                                    <option value="isNewLicenseRequest">
                                        {requestLabelType('isNewLicenseRequest')}
                                    </option>
                                    <option value="isLicenseRenewal">{requestLabelType('isLicenseRenewal')}</option>
                                    <option value="isFirstInstallation">
                                        {requestLabelType('isFirstInstallation')}
                                    </option>
                                </select>
                                {this.requestNatureHint && (
                                    <span
                                        style={{
                                            alignSelf: 'center',
                                            marginLeft: 10,
                                            fontSize: 10,
                                            color: 'var(--disabled-color)'
                                        }}
                                    >
                                        ({this.requestNatureHint})
                                    </span>
                                )}
                            </li>

                            {this.form.has('mode') && (
                                <li>
                                    <label>Mode :</label>
                                    <select {...this.form.select('mode').bind()}>
                                        <option value="licence">Licence</option>
                                        <option value="extrait">Extrait</option>
                                    </select>
                                </li>
                            )}
                            {this.form.has('option') && (
                                <li>
                                    <label>Option :</label>
                                    <select {...this.form.select('option').bind()}>
                                        {this.form.select('option').extra.values.map((v) => (
                                            <option value={v} key={v}>
                                                {getOptionStatusLabel(v)}
                                            </option>
                                        ))}
                                    </select>
                                </li>
                            )}
                            {this.form.has('diver1') && (
                                <li>
                                    <label>Plongeur 1 :</label>
                                    <div className="datalist">
                                        <input {...this.form.select('diver1').bind()} />
                                        <ul className={classNames({ show: !!this.diverSuggestions[0] })}>
                                            {this.diverSuggestions[0] &&
                                                this.diverSuggestions[0].map((diver) => (
                                                    <li
                                                        key={diver.id}
                                                        onClick={() => this._selectSuggestedDiver(0, diver)}
                                                    >
                                                        {diver.fullName(false)}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <input
                                        style={{ width: '19%', marginLeft: 5 }}
                                        {...this.form.select('diver1Enim').bind()}
                                    />
                                    <input
                                        style={{ width: '19%', marginLeft: 5 }}
                                        {...this.form.select('diver1DecisionNum').bind()}
                                    />
                                </li>
                            )}
                            {this.form.has('diver2') && (
                                <li>
                                    <label>Plongeur 2 :</label>
                                    <div className="datalist">
                                        <input {...this.form.select('diver2').bind()} disabled={!this.diver[0]} />
                                        <ul className={classNames({ show: !!this.diverSuggestions[1] })}>
                                            {this.diverSuggestions[1] &&
                                                this.diverSuggestions[1].map((diver) => (
                                                    <li
                                                        key={diver.id}
                                                        onClick={() => this._selectSuggestedDiver(1, diver)}
                                                    >
                                                        {diver.fullName(false)}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <input
                                        {...this.form.select('diver2Enim').bind()}
                                        style={{ width: '19%', marginLeft: 5 }}
                                        disabled={!this.diver[0]}
                                    />
                                    <input
                                        {...this.form.select('diver2DecisionNum').bind()}
                                        style={{ width: '19%', marginLeft: 5 }}
                                        disabled={!this.diver[0]}
                                    />
                                </li>
                            )}
                            {this.form.has('diver3') && (
                                <li>
                                    <label>Plongeur 3 :</label>
                                    <div className="datalist">
                                        <input {...this.form.select('diver3').bind()} disabled={!this.diver[1]} />
                                        <ul className={classNames({ show: !!this.diverSuggestions[2] })}>
                                            {this.diverSuggestions[2] &&
                                                this.diverSuggestions[2].map((diver) => (
                                                    <li
                                                        key={diver.id}
                                                        onClick={() => this._selectSuggestedDiver(2, diver)}
                                                    >
                                                        {diver.fullName(false)}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <input
                                        {...this.form.select('diver3Enim').bind()}
                                        style={{ width: '19%', marginLeft: 5 }}
                                        disabled={!this.diver[1]}
                                    />
                                    <input
                                        {...this.form.select('diver3DecisionNum').bind()}
                                        style={{ width: '19%', marginLeft: 5 }}
                                        disabled={!this.diver[1]}
                                    />
                                </li>
                            )}
                        </ul>

                        {this.withZones && (
                            <ul
                                inert={this.currentPaneIdentifier !== ZONE_PANE ? 'true' : undefined}
                                className={classNames({
                                    left: [ADMIN_CRITERIA_PANE, PAYEMENTS_PANE, OVERVIEW_PANE, END_PANE].includes(
                                        this.currentPaneIdentifier
                                    ),
                                    right: [INFO_PANE].includes(this.currentPaneIdentifier)
                                })}
                                style={{ left: '-100%' }}
                            >
                                <div className="multiLicensesOptions">
                                    <h4>Zones licence {this.license ? this.license.name : ''}</h4>
                                    {this._zoneGroups.map((group) => (
                                        <ol key={group}>
                                            {group !== 'null' && <h2>{group}</h2>}
                                            {this.zones[group].map((zone) => (
                                                <TriStateCheckBox
                                                    zone={zone}
                                                    isTargeted={this.targetedZones.has(zone)}
                                                    isObtained={this.obtainedZones.has(zone)}
                                                    setZoneState={this._handleZoneStateChange}
                                                    key={zone.uniqueId}
                                                />
                                            ))}
                                        </ol>
                                    ))}
                                </div>
                            </ul>
                        )}

                        <ul
                            inert={this.currentPaneIdentifier !== ADMIN_CRITERIA_PANE ? 'true' : undefined}
                            className={classNames({
                                left: [PAYEMENTS_PANE, OVERVIEW_PANE, END_PANE].includes(this.currentPaneIdentifier),
                                right: [INFO_PANE, ZONE_PANE].includes(this.currentPaneIdentifier)
                            })}
                            style={{ left: this.withZones ? '-200%' : '-100%' }}
                        >
                            <h3>Critères administratifs</h3>
                            <li>
                                <label className="short" />
                                <input {...this.form.select('knowsDeliberationsForTheSeason').bind()} />
                                <label htmlFor={this.form.select('knowsDeliberationsForTheSeason').id}>
                                    A pris connaissance délibération relative à la campagne
                                </label>
                            </li>
                            <li>
                                <label className="short" />
                                <input {...this.form.select('paymentsToProfessionalOrganizationsDone').bind()} />
                                <label htmlFor={this.form.select('paymentsToProfessionalOrganizationsDone').id}>
                                    Est à jour des contributions pro. dûes aux organismes
                                </label>
                            </li>
                            <li>
                                <label className="short" />
                                <input {...this.form.select('boatPermitIsValid').bind()} />
                                <label htmlFor={this.form.select('boatPermitIsValid').id}>
                                    Possède navire détenteur PME + permis nav. à jour
                                </label>
                            </li>
                            <li>
                                <label className="short" />
                                <input {...this.form.select('hasGivenFishingStatisticsForPreviousSeason').bind()} />
                                <label htmlFor={this.form.select('hasGivenFishingStatisticsForPreviousSeason').id}>
                                    A joint statistiques pour campagne précédente
                                </label>
                            </li>
                            <li>
                                <label className="short" />
                                <input {...this.form.select('hasPaidForLicenseRequest').bind()} />
                                <label htmlFor={this.form.select('hasPaidForLicenseRequest').id}>
                                    A correctement payé chaque licence demandée
                                </label>
                            </li>
                            <li>
                                <label className="short" />
                                <input {...this.form.select('boatRegistrationIsFrench').bind()} />
                                <label htmlFor={this.form.select('boatRegistrationIsFrench').id}>
                                    A joint l&apos;acte de francisation (certif enregistrement) 1e install / changement
                                    nav.
                                </label>
                            </li>
                            {this.form.has('ownsLicenseCSJNationale') && (
                                <li>
                                    <label className="short" />
                                    <input {...this.form.select('ownsLicenseCSJNationale').bind()} />
                                    <label htmlFor={this.form.select('ownsLicenseCSJNationale').id}>
                                        Le demandeur possède la licence CSJ nationale
                                    </label>
                                </li>
                            )}
                            {this.form.has('diversAllowedCertificate') && (
                                <li>
                                    <label className="short" />
                                    <input {...this.form.select('diversAllowedCertificate').bind()} />
                                    <label htmlFor={this.form.select('diversAllowedCertificate').id}>
                                        A joint les certificats plongeurs (autorisation admin.)
                                    </label>
                                </li>
                            )}
                            {this.form.has('allowsOctopusCaptureDataCommunication') && (
                                <li>
                                    <label className="short" />
                                    <input {...this.form.select('allowsOctopusCaptureDataCommunication').bind()} />
                                    <label htmlFor={this.form.select('allowsOctopusCaptureDataCommunication').id}>
                                        Autorise le ministère en charge des pêches (DGAMPA) à communiquer au CRPMEM ses
                                        données individuelles de captures de poulpes
                                    </label>
                                </li>
                            )}
                        </ul>

                        <ul
                            inert={this.currentPaneIdentifier !== PAYEMENTS_PANE ? 'true' : undefined}
                            className={classNames({
                                left: [OVERVIEW_PANE, END_PANE].includes(this.currentPaneIdentifier),
                                right: [INFO_PANE, ADMIN_CRITERIA_PANE, ZONE_PANE].includes(this.currentPaneIdentifier)
                            })}
                            style={{ left: this.withZones ? '-300%' : '-200%' }}
                        >
                            <h3>Paiement :</h3>
                            <li>
                                <label>Mode :</label>
                                <select
                                    {...this.form.select('paymentMode').bind()}
                                    disabled={this.prefetching || hasPaidDebit}
                                >
                                    <option value="">Aucun</option>
                                    <option value="kPSPaymentMethodDebit" disabled={!allowDebitPayments}>
                                        Prélèvement
                                    </option>
                                    <option value="kPSPaymentMethodCheck">Chèque(s)</option>
                                </select>
                            </li>
                            {this.form.select('paymentMode').value === 'kPSPaymentMethodDebit' &&
                                payments.map((payment, i) => (
                                    <LiStyled
                                        className="aligned"
                                        style={{ alignItems: 'baseline' }}
                                        key={payment.uniqueId || payment._uniqueId}
                                    >
                                        <label>Montant :</label>
                                        <input
                                            type="text"
                                            value={payment.amount}
                                            onChange={(evt) => {
                                                const newAmount = parseInt(evt.target.value);
                                                if (!isNaN(newAmount)) {
                                                    payments[i].amount = newAmount;
                                                } else {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            className="short"
                                        />
                                        <InputWrapper>
                                            <input
                                                type="checkbox"
                                                checked={payment.paid}
                                                disabled={true}
                                                id={payment.uniqueId || payment._uniqueId}
                                            />
                                            <label htmlFor={payment.uniqueId || payment._uniqueId}>Payé</label>
                                        </InputWrapper>
                                        {payments.length > 1 && (
                                            <MinusPlusSVG
                                                objectId="minus"
                                                onClick={() => this.payments.splice(this.payments.indexOf(payment), 1)}
                                            />
                                        )}
                                        {i === payments.length - 1 && (
                                            <MinusPlusSVG
                                                objectId="plus"
                                                onClick={() =>
                                                    this.payments.push({
                                                        amount: 0,
                                                        paid: false,
                                                        method: 'kPSPaymentMethodDebit',
                                                        _uniqueId: randomId()
                                                    })
                                                }
                                            />
                                        )}
                                    </LiStyled>
                                ))}

                            {this.form.select('paymentMode').value === 'kPSPaymentMethodCheck' &&
                                payments.map((payment, i) => (
                                    <Fragment key={payment.uniqueId || payment._uniqueId}>
                                        <LiStyled
                                            className="aligned"
                                            style={{ alignItems: 'baseline' }}
                                            key={payment.uniqueId || payment._uniqueId}
                                        >
                                            <label>Montant chèque {i + 1} :</label>
                                            <WrapperCheckAmount>
                                                <input
                                                    type="text"
                                                    value={payment.amount}
                                                    readOnly={this.prefetching}
                                                    className="short"
                                                    onChange={(evt) => {
                                                        const newAmount = parseInt(evt.target.value);
                                                        if (!isNaN(newAmount)) {
                                                            payments[i].amount = newAmount;
                                                        } else {
                                                            evt.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <label>Référence :</label>
                                                <input
                                                    type="text"
                                                    value={payment.reference}
                                                    readOnly={this.prefetching}
                                                    onChange={(evt) => (payments[i].reference = evt.target.value)}
                                                />
                                                {payments.length > 1 && (
                                                    <MinusPlusSVG
                                                        objectId="minus"
                                                        onClick={() =>
                                                            this.payments.splice(this.payments.indexOf(payment), 1)
                                                        }
                                                    />
                                                )}
                                                {i === payments.length - 1 && (
                                                    <MinusPlusSVG
                                                        objectId="plus"
                                                        onClick={() =>
                                                            this.payments.push({
                                                                amount: 0,
                                                                reference: '',
                                                                method: 'kPSPaymentMethodCheck',
                                                                _uniqueId: randomId()
                                                            })
                                                        }
                                                    />
                                                )}
                                            </WrapperCheckAmount>
                                        </LiStyled>
                                    </Fragment>
                                ))}
                        </ul>

                        <ul
                            inert={this.currentPaneIdentifier !== OVERVIEW_PANE ? 'true' : undefined}
                            className={classNames({
                                left: [END_PANE].includes(this.currentPaneIdentifier),
                                right: [INFO_PANE, ADMIN_CRITERIA_PANE, ZONE_PANE, PAYEMENTS_PANE].includes(
                                    this.currentPaneIdentifier
                                )
                            })}
                            style={{ left: this.withZones ? '-400%' : '-300%' }}
                        >
                            <li>
                                <label>Armateur :</label>
                                <input
                                    type="text"
                                    value={
                                        this._selectedRequester && this._selectedRequester.companyId ? this._selectedRequester.fullCompanyName() : this._selectedRequester ? this._selectedRequester.fullName(false) : ''                                         
                                    }
                                    readOnly
                                />
                            </li>
                            <li>
                                <label>Navire :</label>
                                <input type="text" value={this.boat ? this.boat.fullDisplayName : ''} readOnly />
                            </li>
                            <li>
                                <label>Licence :</label>
                                <input type="text" value={this.form.select('license').value} readOnly />
                            </li>
                            <li>
                                <label>Type de demande :</label>
                                <input
                                    type="text"
                                    value={requestLabelType(this.form.select('requestType').value)}
                                    readOnly
                                />
                            </li>
                            <li>
                                <label>Date de dépôt :</label>
                                <input type="text" value={this.form.select('requestDate').value} readOnly />
                            </li>
                            <li>
                                <label>Campagne :</label>
                                <input
                                    type="text"
                                    value={
                                        this.license
                                            ? (
                                                  this.license.seasons.find(
                                                      (s) => s.uniqueId === this.form.select('season').value
                                                  ) || {}
                                              ).name || '-'
                                            : '-'
                                    }
                                    readOnly
                                />
                            </li>
                            <li>
                                <label>
                                    {targetedContingentInfo.length > 1 ? 'Contingents ciblés :' : 'Contingent ciblé :'}
                                </label>
                                <textarea
                                    rows="2"
                                    value={targetedContingentInfo.join(', ')}
                                    readOnly
                                    style={{ height: 'auto' }}
                                />
                            </li>
                            <li>
                                <label>Critères :</label>
                                {this.globalStatusInfo && (
                                    <p className="criteriaList" onClick={this._showInfoBox}>
                                        <span>Technique</span>
                                        <SVGObject
                                            className={classNames('status', {
                                                ok:
                                                    this.globalStatusInfo.technicalStatus ===
                                                    'kPSLicenseRequestTechnicalStatusEligible',
                                                error:
                                                    this.globalStatusInfo.technicalStatus !==
                                                    'kPSLicenseRequestTechnicalStatusEligible'
                                            })}
                                            objectId={
                                                this.globalStatusInfo.technicalStatus ===
                                                'kPSLicenseRequestTechnicalStatusEligible'
                                                    ? 'checkIco'
                                                    : 'crossIco'
                                            }
                                        />
                                        <span>Éligibilité</span>
                                        <SVGObject
                                            className={classNames('status', {
                                                ok:
                                                    this.globalStatusInfo.quotaStatus ===
                                                    'kPSLicenseRequestQuotaStatusEligible',
                                                error:
                                                    this.globalStatusInfo.quotaStatus !==
                                                    'kPSLicenseRequestQuotaStatusEligible'
                                            })}
                                            objectId={
                                                this.globalStatusInfo.quotaStatus ===
                                                'kPSLicenseRequestQuotaStatusEligible'
                                                    ? 'checkIco'
                                                    : 'crossIco'
                                            }
                                        />
                                        <span>Administratif</span>
                                        <SVGObject
                                            className={classNames('status', {
                                                ok:
                                                    this.globalStatusInfo.administrativeStatus ===
                                                    'kPSLicenseRequestAdministrativeStatusEligible',
                                                error:
                                                    this.globalStatusInfo.administrativeStatus !==
                                                    'kPSLicenseRequestAdministrativeStatusEligible'
                                            })}
                                            objectId={
                                                this.globalStatusInfo.administrativeStatus ===
                                                'kPSLicenseRequestAdministrativeStatusEligible'
                                                    ? 'checkIco'
                                                    : 'crossIco'
                                            }
                                        />
                                    </p>
                                )}
                            </li>
                            <li className="aligned" style={{ alignItems: 'baseline' }}>
                                <label>Décision :</label>
                                <select {...this.form.select('globalStatus').bind()} style={{ marginRight: 8 }}>
                                    <option value="kPSLicenseRequestGlobalStatusFrozen">En attente</option>
                                    <option value="kPSLicenseRequestGlobalStatusSuspended">Incomplètes</option>
                                    <option value="kPSLicenseRequestGlobalStatusReserved">Mise en réserve</option>
                                    <option value="kPSLicenseRequestGlobalStatusAllowed">Attribuée</option>
                                    <option value="kPSLicenseRequestGlobalStatusRefused">Refusée</option>
                                    <option value="$canceled">Annulée</option>
                                </select>
                                {pescalice.currentTodo &&
                                    pescalice.currentTodo.globalStatuses &&
                                    pescalice.currentTodo.globalStatuses.length > 0 &&
                                    `(le ${formatLocaleDate(pescalice.currentTodo.globalStatuses.at(-1).date, 'P')})`}
                                {!pescalice.currentTodoIsNew &&
                                    this.form.select('globalStatus').value !==
                                        'kPSLicenseRequestGlobalStatusInitial' && (
                                        <button
                                            style={{ marginLeft: 8, marginTop: 0, marginBottom: 0, height: 26 }}
                                            onClick={this._processGlobalStatus}
                                            disabled={this.processingStatus}
                                        >
                                            Calculer
                                        </button>
                                    )}
                            </li>
                            <li>
                                <label>Motif :</label>
                                <select onChange={this._handlePrivateDecisionReasonChange}>
                                    <option value="">Sélectionner...</option>
                                    <option value="-">Motif personnalisé</option>

                                    {this.form.select('globalStatus').value ===
                                        'kPSLicenseRequestGlobalStatusSuspended' && (
                                        <React.Fragment>
                                            <option value="CPO">CPO</option>
                                            <option value="Désarmé">Désarmé</option>
                                            <option value="Navire vendu">Navire vendu</option>
                                            <option value="Permis de navigation échu">Permis de navigation échu</option>
                                            <option value="Défaut déclarations captures">
                                                Défaut déclarations captures
                                            </option>
                                            <option value="Naufrage">Naufrage</option>
                                            <option value="Décès ">Décès </option>
                                            <option value="Pénalités de retard">Pénalités de retard</option>
                                            <option value="Absence de paiement ">Absence de paiement </option>
                                            <option value="chèque non conforme">chèque non conforme</option>
                                            <option value="Conditions d'exploitation">
                                                Conditions d&apos;exploitation
                                            </option>
                                            <option value="En attente d’avis CRPM">En attente d&apos;avis CRPM</option>
                                            <option value="Décision administrative ">Décision administrative </option>
                                            <option value="Défaut d’autorisation administrative (plongée)">
                                                Défaut d’autorisation administrative (plongée)
                                            </option>
                                        </React.Fragment>
                                    )}

                                    {this.form.select('globalStatus').value ===
                                        'kPSLicenseRequestGlobalStatusReserved' && (
                                        <React.Fragment>
                                            <option value="En construction">En construction</option>
                                            <option value="Achat en cours">Achat en cours</option>
                                            <option value="Demande de PME">Demande de PME</option>
                                        </React.Fragment>
                                    )}

                                    {this.form.select('globalStatus').value ===
                                        'kPSLicenseRequestGlobalStatusRefused' && (
                                        <React.Fragment>
                                            <option value="Décision CRPM">Décision CRPM</option>
                                            <option value="Hors normes">Hors normes</option>
                                            <option value="Contingent atteint">Contingent atteint</option>
                                            <option value="Changement de quartier maritime : contingent non disponible">
                                                Changement de quartier maritime : contingent non disponible
                                            </option>
                                            <option value="Bloquée aux renouvellements">
                                                Bloquée aux renouvellements
                                            </option>
                                            <option value="Bloquée aux renouvellements et 1ères installations">
                                                Bloquée aux renouvellements et 1ères installations
                                            </option>
                                            <option value="Défaut licence communautaire">
                                                Défaut licence communautaire
                                            </option>
                                        </React.Fragment>
                                    )}

                                    {this.form.select('globalStatus').value === '$canceled' && (
                                        <React.Fragment>
                                            <option value="Renonciation">Renonciation</option>
                                            <option value="Transfert de licence">Transfert de licence</option>
                                            <option value="Vente">Vente</option>
                                            <option value="Naufrage">Naufrage</option>
                                            <option value="Décès">Décès</option>
                                            <option value="Achat ou construction annulée">
                                                Achat ou construction annulée
                                            </option>
                                            <option value="Mise en réserve échue">Mise en réserve échue</option>
                                            <option value="Erreur de saisie">Erreur de saisie</option>
                                        </React.Fragment>
                                    )}
                                </select>
                            </li>
                            <li>
                                <label>Commentaire privé :</label>
                                <textarea
                                    className="smallText"
                                    {...this.form.select('privateComment').bind()}
                                    ref={this._privateCommentTextArea}
                                ></textarea>
                            </li>
                            <li>
                                <label>Motif :</label>
                                <select onChange={this._handlePublicDecisionReasonChange}>
                                    <option value="">Sélectionner...</option>
                                    <option value="-">Motif personnalisé</option>
                                    {this.license &&
                                        (this.license.pescaliceIdentifier ===
                                            'pescalice.peche-embarquee.license.bretagne.canot' ||
                                            this.license.pescaliceIdentifier ===
                                                'pescalice.peche-embarquee.license.bretagne.crustaces' ||
                                            this.license.pescaliceIdentifier ===
                                                'pescalice.peche-embarquee.license.bretagne.filets-poisson') && (
                                            <React.Fragment>
                                                <option value="Limité à la palangre">Limité à la palangre</option>
                                                <option value="Limité aux casiers">Limité aux casiers</option>
                                                <option value="Limité aux filets">Limité aux filets</option>
                                                <option value="Limité aux filets (pas d’AEP SMO - maillage < 220 mm non autorisé)">
                                                    Limité aux filets (pas d’AEP SMO - maillage &lt; 220 mm non
                                                    autorisé)
                                                </option>
                                                <option value="Pas d’AEP SMO - maillage < 220 mm non autorisé">
                                                    Pas d’AEP SMO - maillage &lt; 220 mm non autorisé
                                                </option>
                                                <option value="Dérogataire (Décision n° XX-XX) A remplir manuellement">
                                                    Dérogataire (Décision n° XX-XX) A remplir manuellement
                                                </option>
                                            </React.Fragment>
                                        )}
                                </select>
                            </li>
                            <li>
                                <label>Commentaire public :</label>
                                <textarea
                                    className="smallText"
                                    {...this.form.select('publicComment').bind()}
                                    ref={this._publicCommentTextArea}
                                ></textarea>
                            </li>

                            <li className={classNames('infoBox', { show: this.displayInfoBox })}>
                                <div>
                                    <h3>Critères non vérifiés</h3>
                                    {statuses.map((st, i) => (
                                        <div key={i}>
                                            <p>
                                                <SVGObject className="status error" objectId="crossIco" />
                                                {st}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                                <div className="exit">
                                    <div></div>
                                    <div>
                                        <button onClick={this._hideInfoBox}>Ok</button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <ul
                            inert={this.currentPaneIdentifier !== END_PANE ? 'true' : undefined}
                            className={classNames({
                                right: [
                                    INFO_PANE,
                                    ADMIN_CRITERIA_PANE,
                                    ZONE_PANE,
                                    PAYEMENTS_PANE,
                                    OVERVIEW_PANE
                                ].includes(this.currentPaneIdentifier)
                            })}
                            style={{ left: this.withZones ? '-500%' : '-400%' }}
                        >
                            <div className="recap">
                                <h3>La demande a été traitée</h3>
                                <ul style={{ marginBottom: 20, textAlign: 'center' }}>
                                    <li>Décision : {decisionLabel(this.form.select('globalStatus').value)}</li>
                                    <li>
                                        {obtainedContingentInfo.length > 1
                                            ? 'Contingents obtenus :'
                                            : 'Contingent obtenu : '}
                                        {obtainedContingentInfo.join(', ')}
                                    </li>
                                </ul>
                                {pescalice.currentTodoIsNew && (
                                    <button onClick={this._restart}>Enchaîner les demandes (même armateur)</button>
                                )}
                                <button onClick={this._cancel}>Fermer</button>
                            </div>
                        </ul>
                    </form>

                    <div className="exit">
                        <div>
                            <button
                                className={classNames({ out: this.currentPaneIdentifier === END_PANE })}
                                onClick={() => this._dismiss()}
                                disabled={this._isBusy || this.displayInfoBox}
                            >
                                Annuler
                            </button>
                        </div>
                        <div>
                            <button
                                className={classNames({ out: this.currentPaneIdentifier === END_PANE })}
                                onClick={this._previousPane}
                                disabled={this._isBusy || this.displayInfoBox || !this._canGoBackward}
                            >
                                Précédent
                            </button>
                            <button
                                className={classNames({ out: this.currentPaneIdentifier === END_PANE })}
                                onClick={this._nextPane}
                                disabled={this._isBusy || this.displayInfoBox || !this._canGoForward}
                            >
                                Suivant
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function nextTriState(currentState) {
    switch (currentState) {
        case 'one':
            return 'two';
        case 'two':
            return 'three';
        case 'three':
        default:
            return 'one';
    }
}

function TriStateCheckBox(props) {
    const { zone, isTargeted, isObtained, setZoneState } = props;
    const state = isTargeted ? 'two' : isObtained ? 'three' : 'one';
    const toggleState = (evt) => setZoneState(zone, nextTriState(state), evt.altKey);

    return (
        <li onClick={toggleState}>
            <div>
                <div className={classNames('threeStatesCheckbox', state)}>
                    <span className="one"></span>
                    <span className="two"></span>
                    <span className="three"></span>
                </div>
                <label>{zone.name}</label>
            </div>
            <h6>{zone.information || ''}</h6>
        </li>
    );
}

TriStateCheckBox.propTypes = {
    zone: PropTypes.any.isRequired,
    isTargeted: PropTypes.bool.isRequired,
    isObtained: PropTypes.bool.isRequired,
    setZoneState: PropTypes.func.isRequired
};
