import { types } from 'mobx-state-tree';

const AuditOperationType = types.enumeration('AuditOperationType', ['I', 'U', 'D', 'T']);

const AuditOperation = types.model('AuditOperation', {
    field: types.string,
    previousValue: types.frozen(),
    newValue: types.frozen()
});

export const AuditEntry = types
    .model('AuditEntry', {
        id: types.identifierNumber,
        eventDate: types.Date,
        type: AuditOperationType,
        userFirstName: types.maybeNull(types.string),
        userLastName: types.maybeNull(types.string),
        operations: types.array(AuditOperation)
    })
    .views((self) => ({
        get userInfo() {
            if (self.userFirstName && !self.userLastName) {
                return self.userFirstName;
            } else if (!self.userFirstName && self.userLastName) {
                return self.userLastName;
            } else if (!self.userFirstName && !self.userLastName) {
                return '-';
            }

            return `${self.userFirstName} ${self.userLastName}`;
        }
    }))
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        eventDate: typeof snapshot.eventDate === 'string' ? new Date(snapshot.eventDate) : snapshot.eventDate
    }));
