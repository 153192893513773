import React, { useContext, useCallback, useState, useEffect, useMemo, Fragment, useRef } from 'react';
import classNames from 'classnames';
import { observer, MobXProviderContext } from 'mobx-react';

import { getSavableDataFromRequest } from '@STORES/common/pescalice';

import { formatLocaleDate } from '@SUPPORT/utils';
import { onDateFieldChange, isValidDateValue, dateFieldISOValue } from '@SUPPORT/form';

import { SVGObject } from '../common/SVGObject';
import { UserAvatar } from '../common/UserAvatar';

const PAPWizardContext = React.createContext();
const PAPWizardContextProvider = ({ children }) => {
    const [busyCount, setBusyCount] = useState(0);
    const [ready, setReady] = useState(false);
    const [currentPanel, setCurrentPanel] = useState(0);
    const [panelValidity, setPanelValidityArray] = useState([]);
    const [mustValidate, setMustValidate] = useState(true);
    const [panelCommitPromise, setPanelCommitPromiseArray] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [allowAllExtracts, setAllowAllExtracts] = useState(false);

    const busy = busyCount > 0;
    const setBusy = useCallback((bsy = true) => {
        setBusyCount((count) => count + (bsy ? 1 : -1));
    }, []);

    const setPanelValidity = useCallback((index, isValid) => {
        setPanelValidityArray((array) => {
            const validity = array.slice();
            validity[index] = isValid;
            return validity;
        });
    }, []);

    const setPanelCommitPromise = useCallback((index, promise) => {
        setPanelCommitPromiseArray((array) => {
            const promises = array.slice();
            promises[index] = promise;
            return promises;
        });
    }, []);

    const contextValue = {
        busy,
        setBusy,
        ready,
        setReady,
        currentPanel,
        setCurrentPanel,
        panelValidity,
        setPanelValidity,
        mustValidate,
        setMustValidate,
        panelCommitPromise,
        setPanelCommitPromise,
        selectedSeason,
        setSelectedSeason,
        allowAllExtracts,
        setAllowAllExtracts
    };

    return <PAPWizardContext.Provider value={contextValue}>{children}</PAPWizardContext.Provider>;
};

export const LicenseRequestDialogPAP = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;
    if (!pescalice) {
        return null;
    }

    const show = pescalice.displayNewRequestDialogPAP;
    return (
        <PAPWizardContextProvider>
            <div className={classNames('modal', 'superWide', { show })}>
                <WizardContent />
            </div>
        </PAPWizardContextProvider>
    );
});

const WizardContent = observer(() => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    const {
        busy,
        setBusy,
        ready,
        setReady,
        currentPanel,
        mustValidate,
        setMustValidate,
        setCurrentPanel,
        panelCommitPromise,
        panelValidity,
        setSelectedSeason,
        allowAllExtracts,
        setAllowAllExtracts
    } = useContext(PAPWizardContext);

    const show = pescalice.displayNewRequestDialogPAP;
    useEffect(() => {
        if (show) {
            setBusy();
            pescalice.listPAPSeasons().then(() => {
                if (pescalice.currentTodoPAP) {
                    const season = pescalice.seasonPAPWithId(pescalice.currentTodoPAP.season);
                    setSelectedSeason(season || pescalice.seasonsPAP[0]);
                } else {
                    setSelectedSeason(pescalice.seasonsPAP[0]);
                }
                setBusy(false);
                setReady(true);
            });
        }
    }, [pescalice, show, setBusy, setReady, setSelectedSeason]);

    const dismissWizard = useCallback(() => {
        pescalice.setDisplayNewRequestDialogPAP(false);
        setCurrentPanel(0);
        setAllowAllExtracts(false);
        setReady(false);
    }, [pescalice, setCurrentPanel, setAllowAllExtracts, setReady]);

    const canGoBackward = currentPanel > 0;
    const canGoForward = currentPanel <= 4 && panelValidity[currentPanel] && !!panelCommitPromise[currentPanel];
    const previousPanel = useCallback(
        (evt) => {
            evt.preventDefault();
            if (canGoBackward) {
                setCurrentPanel((idx) => idx - 1);
            }
        },
        [canGoBackward, setCurrentPanel]
    );
    const nextPanel = useCallback(
        (evt) => {
            evt.preventDefault();
            if (canGoForward && currentPanel < 3) {
                setBusy(true);

                const promise =
                    currentPanel == 2 && !mustValidate ? Promise.resolve() : panelCommitPromise[currentPanel]();
                promise
                    .then((res) => {
                        if (res && res.wrapped && res.wrapped.status === 'error') {
                            alert(res.wrapped.errorMessage);
                        } else if (currentPanel === 2 && mustValidate) {
                            setMustValidate(false);
                        } else {
                            setCurrentPanel((idx) => idx + 1);
                        }
                    })
                    .finally(() => setBusy(false));
            } else if (currentPanel === 3) {
                dismissWizard();
            }
        },
        [
            canGoForward,
            panelCommitPromise,
            currentPanel,
            mustValidate,
            setMustValidate,
            setBusy,
            setCurrentPanel,
            dismissWizard
        ]
    );

    const dismiss = useCallback(
        (evt) => {
            evt.preventDefault();

            let proceed = true;
            if (
                pescalice.currentTodoPAP &&
                (!pescalice.currentTodoPAP.extractRequests || pescalice.currentTodoPAP.extractRequests.length === 0)
            ) {
                proceed = confirm(
                    'La demande de licence ne contient aucune demande de timbre. Voulez vous vraiment annuler et supprimer la demande ?'
                );
                if (proceed) {
                    pescalice.deleteLicenseRequestPAP(pescalice.currentTodoPAP.uniqueId);
                }
            }

            if (proceed) {
                dismissWizard();
            }
        },
        [pescalice, dismissWizard]
    );

    return (
        <div className="sheet">
            <div className="title">
                <h3>
                    {pescalice && pescalice.currentTodoIsNewPAP
                        ? "Création d'une demande de licence de Pêche à Pied"
                        : "Edition d'une demande de licence de Pêche à Pied"}
                </h3>
                {pescalice.currentTodoPAP && pescalice.currentTodoPAP.isUserDeposited ? (
                    <SVGObject
                        objectId="singleFisherman"
                        style={{
                            fill: 'blue',
                            width: 18,
                            opacity: 0.15,
                            height: 18,
                            marginRight: 'auto',
                            marginTop: 10
                        }}
                    />
                ) : null}
                <div>
                    <div className={classNames('progressIndic', { show: busy })} />
                </div>
                {currentPanel === 2 && (
                    <div>
                        <input
                            type="checkbox"
                            checked={allowAllExtracts}
                            onChange={(evt) => setAllowAllExtracts(evt.target.checked)}
                            id="allowAllExtracts"
                        />
                        <label htmlFor="allowAllExtracts">Autoriser la saisie de tous les timbres</label>
                    </div>
                )}
            </div>
            {show && (
                <form className="newWizard">
                    <GeneralInfoPanel index={0} />
                    <AdministrativeCriteriaPanel index={1} />
                    <ExtractsPanel index={2} />
                    <SummaryPanel index={3} />
                </form>
            )}
            <div className="exit">
                <div>
                    {currentPanel < 3 && (
                        <button onClick={dismiss} disabled={!ready || busy}>
                            Annuler
                        </button>
                    )}
                </div>
                <div>
                    <button onClick={previousPanel} disabled={!canGoBackward || !ready || busy}>
                        Précédent
                    </button>
                    <button
                        className={classNames({ codeOrange: currentPanel === 2 && mustValidate })}
                        onClick={nextPanel}
                        disabled={!canGoForward || !ready || busy}
                    >
                        {currentPanel === 2 && mustValidate ? 'Valider' : currentPanel === 3 ? 'Terminer' : 'Suivant'}
                    </button>
                </div>
            </div>
        </div>
    );
});

const WizardPanel = ({ index, noScroll, children }) => {
    const { currentPanel } = useContext(PAPWizardContext);
    const className = classNames({ left: index < currentPanel, right: index > currentPanel, noScroll });
    return (
        <ul className={className} style={{ left: `-${index * 100}%` }}>
            {children}
        </ul>
    );
};

const GeneralInfoPanel = observer(({ index }) => {
    const { ready, setBusy, selectedSeason, setSelectedSeason, setPanelCommitPromise, setPanelValidity } = useContext(
        PAPWizardContext
    );
    const { store } = useContext(MobXProviderContext);
    const { pescalice, users } = store;

    const selectSeason = (evt) => setSelectedSeason(pescalice.seasonPAPWithId(evt.target.value));

    const [userValue, setUserValue] = useState('');
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const suggestRequester = (evt) => {
        setUserValue(evt.target.value);
        if (evt.target.value === '') {
            setSuggestedUsers([]);
            return;
        }

        setBusy(true);
        pescalice.suggestUsers(evt.target.value).then((users) => {
            setSuggestedUsers(users.filter((user) => !!user.tags && user.tags.includes('psc_pap')));
            setBusy(false);
        });
    };
    const selectSuggestedUser = (user) => {
        setSelectedUser(user);
        setSuggestedUsers([]);
        setUserValue(user.fullName(false));
        setPAPNum(user.uniqueIdentifier);
    };

    const [papNum, setPAPNum] = useState('');
    const [depositDate, setDepositDate] = useState(null);
    const [nature, setNature] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [paymentReference, setPaymentReference] = useState('');
    const [paymentAmount, setPaymentAmount] = useState(65); // FIXME: hardcoded default value should be replaced by suggested valued from backend - LH

    useEffect(() => {
        const isValid =
            !!selectedSeason &&
            !!selectedUser &&
            !!selectedUser.licenseNum &&
            !!nature &&
            isValidDateValue(depositDate, 'dd/MM/yyyy') &&
            (paymentMode === '' ||
                (paymentMode === 'kPSPaymentMethodDebit' && paymentAmount > 0) ||
                (paymentMode === 'kPSPaymentMethodCheck' && paymentReference !== '' && paymentAmount > 0));

        setPanelValidity(index, isValid);
    }, [
        index,
        selectedSeason,
        selectedUser,
        nature,
        depositDate,
        paymentMode,
        paymentReference,
        paymentAmount,
        setPanelValidity
    ]);

    useEffect(() => {
        setPanelCommitPromise(index, () => {
            setBusy(true);
            return users
                .listAvatarImages(selectedUser.id)
                .then((images) => {
                    const photoURL = images && images.length > 0 ? images[0].url : null;
                    const data = {
                        depositDate: dateFieldISOValue(depositDate + ' 11:30', 'dd/MM/yyyy HH:mm'),
                        isNewLicenseRequest: nature === 'isNewLicenseRequest',
                        isLicenseRenewal: nature === 'isLicenseRenewal',
                        isFirstInstallation: nature === 'isFirstInstallation',
                        papPermitNumber: papNum,
                        season: selectedSeason.uniqueId,
                        requestAuthor: {
                            telecapecheId: selectedUser.id,
                            [selectedUser.socialRegime.toLowerCase()]: selectedUser.licenseNum || '',
                            photoURL
                        }
                    };

                    if (paymentMode !== '') {
                        data.payments = [
                            {
                                method: paymentMode,
                                reference: paymentReference,
                                amount: paymentAmount,
                                reason: 'pescalice.peche-a-pied.license.payment'
                            }
                        ];
                    } else {
                        data.payments = [];
                    }

                    return pescalice.saveLicenseRequestPAP(data);
                })
                .finally(() => setBusy(false));
        });
    }, [
        index,
        users,
        pescalice,
        selectedSeason,
        selectedUser,
        depositDate,
        nature,
        papNum,
        paymentMode,
        paymentReference,
        paymentAmount,
        setBusy,
        setPanelCommitPromise
    ]);

    const requester = useRef();
    useEffect(() => {
        const request = pescalice.currentTodoPAP;
        if (request) {
            const user = users.userWithId(request.requestAuthor.telecapecheId);
            if (user) {
                selectSuggestedUser(user);
                setPAPNum(user.uniqueIdentifier);
            }
            setDepositDate(formatLocaleDate(request.depositDate, 'dd/MM/yyyy'));
            setNature(
                !request
                    ? ''
                    : request.isLicenseRenewal
                    ? 'isLicenseRenewal'
                    : request.isFirstInstallation
                    ? 'isFirstInstallation'
                    : 'isNewLicenseRequest'
            );
            if (request.payments && request.payments.length > 0) {
                const payment = request.payments[0];
                setPaymentMode(payment.method);
                setPaymentReference(payment.reference);
                setPaymentAmount(payment.amount);
            }
        } else {
            setTimeout(() => requester.current.focus(), 250);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let allowDebitPayments = false;
    if (selectedUser && selectedUser.bankInfos) {
        const bankInfos = JSON.parse(selectedUser.bankInfos) || [];
        const bankInfo = bankInfos.find((info) => !info.company_id);
        if (bankInfo) {
            allowDebitPayments = true;
        }
    }

    return (
        <WizardPanel index={index}>
            <li className="required">
                <label>Campagne :</label>
                <select value={selectedSeason ? selectedSeason.uniqueId : ''} onChange={selectSeason}>
                    {pescalice.seasonsPAP.map((season) => (
                        <option value={season.uniqueId} key={season.uniqueId}>
                            {season.referenceYear - 1} - {season.referenceYear}
                        </option>
                    ))}
                </select>
            </li>
            <li>
                <label>Demandeur :</label>
                <div className="datalist">
                    <input
                        type="text"
                        value={userValue}
                        onChange={suggestRequester}
                        readOnly={!ready || !pescalice.currentTodoIsNewPAP}
                        ref={requester}
                    />
                    <ul className={classNames({ show: suggestedUsers.length > 0 })}>
                        {suggestedUsers.map((user) => (
                            <li key={user.id} onClick={() => selectSuggestedUser(user)}>
                                {user.fullName(false)}
                            </li>
                        ))}
                    </ul>
                </div>
            </li>
            <li className="profilePic">
                <UserAvatar userId={selectedUser ? selectedUser.id : null} editable={false} />
            </li>
            <li>
                <label>Numéro ENIM :</label>
                <input
                    type="text"
                    value={selectedUser && selectedUser.socialRegime === 'ENIM' ? selectedUser.licenseNum : ''}
                    className={classNames({
                        codeRed: selectedUser && (!selectedUser.socialRegime || !selectedUser.licenseNum)
                    })}
                    readOnly
                />
            </li>
            <li>
                <label>Numéro MSA :</label>
                <input
                    type="text"
                    value={selectedUser && selectedUser.socialRegime === 'MSA' ? selectedUser.licenseNum : ''}
                    className={classNames({
                        codeRed: selectedUser && (!selectedUser.socialRegime || !selectedUser.licenseNum)
                    })}
                    readOnly
                />
            </li>
            <li>
                <label>Permis PAP :</label>
                <input
                    type="text"
                    value={papNum}
                    onChange={(evt) => setPAPNum(evt.target.value)}
                    readOnly={pescalice.currentTodoIsNewPAP || nature !== 'isFirstInstallation'}
                />
            </li>
            <li>
                <label>Date de dépot :</label>
                <input
                    type="text"
                    className={classNames('date', {
                        codeRed: depositDate !== null && !isValidDateValue(depositDate, 'dd/MM/yyyy')
                    })}
                    placeholder="jj/mm/aaaa"
                    value={depositDate || ''}
                    onChange={(evt) => onDateFieldChange(evt, depositDate, setDepositDate)}
                />
            </li>
            <li>
                <label>Nature de la demande :</label>
                <select
                    value={nature}
                    onChange={(evt) => setNature(evt.target.value)}
                    disabled={!pescalice.currentTodoIsNewPAP}
                >
                    <option value="">Sélectionner...</option>
                    <option value="isLicenseRenewal">Renouvellement</option>
                    <option value="isNewLicenseRequest">Nouvelle demande</option>
                    <option value="isFirstInstallation">Première installation</option>
                </select>
            </li>
            <li>
                <label>Mode de paiement :</label>
                <select value={paymentMode} onChange={(evt) => setPaymentMode(evt.target.value)}>
                    <option value="">Aucun</option>
                    <option value="kPSPaymentMethodDebit" disabled={!allowDebitPayments}>
                        Prélèvement
                    </option>
                    <option value="kPSPaymentMethodCheck">Chèque(s)</option>
                </select>
            </li>
            {paymentMode === 'kPSPaymentMethodCheck' && (
                <li>
                    <label>Référence chèque licence :</label>
                    <input
                        type="text"
                        value={paymentReference}
                        onChange={(evt) => setPaymentReference(evt.target.value)}
                    />
                </li>
            )}
            {paymentMode !== '' && (
                <li>
                    <label>Montant :</label>
                    <input
                        type="number"
                        min="0"
                        placeholder="0"
                        value={paymentAmount === 0 ? '0' : paymentAmount}
                        onChange={(evt) => {
                            const amount = parseInt(evt.target.value, 10);
                            setPaymentAmount(isNaN(amount) ? 0 : amount);
                        }}
                        className="midSize"
                    />
                    <div style={{ marginTop: '-5px' }}>
                        <input
                            type="checkbox"
                            checked={
                                pescalice.currentTodoPAP &&
                                pescalice.currentTodoPAP.payments &&
                                pescalice.currentTodoPAP.payments[0] &&
                                pescalice.currentTodoPAP.payments[0].paid
                            }
                            disabled={true}
                            id="requestLicensePayment"
                        />
                        <label htmlFor="requestLicensePayment">Payé</label>
                    </div>
                </li>
            )}
            <li>
                <label>Navire :</label>
                <select disabled>
                    <option>Sélectionner...</option>
                </select>
            </li>
        </WizardPanel>
    );
});

const AdministrativeCriteriaPanel = observer(({ index }) => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    const { setBusy, setPanelValidity, setPanelCommitPromise } = useContext(PAPWizardContext);

    const [hasGivenProofOfPermitRequestForSeason, setHasGivenProofOfPermitRequestForSeason] = useState(true);
    const [hasObtainedPermitForSeason, setHasObtainedPermitForSeason] = useState(true);
    const [hasGivenFishingStatisticsForPreviousSeason, setHasGivenFishingStatisticsForPreviousSeason] = useState(true);
    const [
        hasGivenProofOfPaymentToProfessionalOrganizations,
        setHasGivenProofOfPaymentToProfessionalOrganizations
    ] = useState(true);
    const [affiliatedToMSAorEnim, setAffiliatedToMSAorEnim] = useState(true);
    const [
        hasGivenPhotoIfFirstInstallationBeforeSeasonStart,
        setHasGivenPhotoIfFirstInstallationBeforeSeasonStart
    ] = useState(true);

    useEffect(() => {
        if (pescalice.currentTodoPAP && pescalice.currentTodoPAP.administrativeRequirement) {
            const { administrativeRequirement } = pescalice.currentTodoPAP;
            setHasGivenProofOfPermitRequestForSeason(administrativeRequirement.hasGivenProofOfPermitRequestForSeason);
            setHasObtainedPermitForSeason(administrativeRequirement.hasObtainedPermitForSeason);
            setHasGivenFishingStatisticsForPreviousSeason(
                administrativeRequirement.hasGivenFishingStatisticsForPreviousSeason
            );
            setHasGivenProofOfPaymentToProfessionalOrganizations(
                administrativeRequirement.hasGivenProofOfPaymentToProfessionalOrganizations
            );
            setAffiliatedToMSAorEnim(administrativeRequirement.affiliatedToMSAorEnim);
            setHasGivenPhotoIfFirstInstallationBeforeSeasonStart(
                administrativeRequirement.hasGivenPhotoIfFirstInstallationBeforeSeasonStart
            );
        }
    }, [pescalice.currentTodoPAP]);

    useEffect(() => {
        setPanelValidity(index, true);
        setPanelCommitPromise(index, async () => {
            const data = {
                administrativeRequirement: {
                    hasGivenProofOfPermitRequestForSeason,
                    hasObtainedPermitForSeason,
                    hasGivenFishingStatisticsForPreviousSeason,
                    hasGivenProofOfPaymentToProfessionalOrganizations,
                    affiliatedToMSAorEnim,
                    hasGivenPhotoIfFirstInstallationBeforeSeasonStart
                }
            };

            setBusy(true);
            return pescalice.saveLicenseRequestPAP(data).finally(() => setBusy(false));
        });
    }, [
        index,
        pescalice,
        setBusy,
        setPanelCommitPromise,
        setPanelValidity,
        hasGivenProofOfPermitRequestForSeason,
        hasObtainedPermitForSeason,
        hasGivenFishingStatisticsForPreviousSeason,
        hasGivenProofOfPaymentToProfessionalOrganizations,
        affiliatedToMSAorEnim,
        hasGivenPhotoIfFirstInstallationBeforeSeasonStart
    ]);

    return (
        <WizardPanel index={index}>
            <h3>Critères administratifs</h3>
            <li>
                <label className="short"></label>
                <input
                    type="checkbox"
                    checked={hasGivenProofOfPermitRequestForSeason}
                    onChange={(evt) => setHasGivenProofOfPermitRequestForSeason(evt.target.checked)}
                    id="hasGivenProofOfPermitRequestForSeason"
                />
                <label htmlFor="hasGivenProofOfPermitRequestForSeason">
                    A joint le justificatif de demande de permis PAP
                </label>
            </li>
            <li>
                <label className="short"></label>
                <input
                    type="checkbox"
                    checked={hasObtainedPermitForSeason}
                    onChange={(evt) => setHasObtainedPermitForSeason(evt.target.checked)}
                    id="hasObtainedPermitForSeason"
                />
                <label htmlFor="hasObtainedPermitForSeason">A obtenu le permis PAP pour la saison</label>
            </li>
            <li>
                <label className="short"></label>
                <input
                    type="checkbox"
                    checked={hasGivenFishingStatisticsForPreviousSeason}
                    onChange={(evt) => setHasGivenFishingStatisticsForPreviousSeason(evt.target.checked)}
                    id="hasGivenFishingStatisticsForPreviousSeason"
                />
                <label htmlFor="hasGivenFishingStatisticsForPreviousSeason">
                    A fourni les statistiques de pêche pour la campagne précédente
                </label>
            </li>
            <li>
                <label className="short"></label>
                <input
                    type="checkbox"
                    checked={hasGivenProofOfPaymentToProfessionalOrganizations}
                    onChange={(evt) => setHasGivenProofOfPaymentToProfessionalOrganizations(evt.target.checked)}
                    id="hasGivenProofOfPaymentToProfessionalOrganizations"
                />
                <label htmlFor="hasGivenProofOfPaymentToProfessionalOrganizations">Attestation paiement CPO</label>
            </li>
            <li>
                <label className="short"></label>
                <input
                    type="checkbox"
                    checked={affiliatedToMSAorEnim}
                    onChange={(evt) => setAffiliatedToMSAorEnim(evt.target.checked)}
                    id="affiliatedToMSAorEnim"
                />
                <label htmlFor="affiliatedToMSAorEnim">Photocopie attestation MSA/ENIM</label>
            </li>
            <li>
                <label className="short"></label>
                <input
                    type="checkbox"
                    checked={hasGivenPhotoIfFirstInstallationBeforeSeasonStart}
                    onChange={(evt) => setHasGivenPhotoIfFirstInstallationBeforeSeasonStart(evt.target.checked)}
                    id="hasGivenPhotoIfFirstInstallationBeforeSeasonStart"
                />
                <label htmlFor="hasGivenPhotoIfFirstInstallationBeforeSeasonStart">
                    A joint une photo en cas de 1ère installation (avant le début de la saison)
                </label>
            </li>
        </WizardPanel>
    );
});

const makeEmptyPayment = (method = 'kPSPaymentMethodCheck', amount = 0) => ({ reference: '', amount, method });

const ExtractsPanel = observer(({ index }) => {
    const { selectedSeason, setBusy, setMustValidate, setPanelValidity, setPanelCommitPromise } = useContext(
        PAPWizardContext
    );
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    const divisions = useMemo(() => pescalice.seasonDivisionsPAP(selectedSeason), [pescalice, selectedSeason]);
    const [currentTab, setCurrentTab] = useState();

    const [extractRequests, setExtractRequests] = useState([]);

    useEffect(() => {
        if (divisions.length > 0) {
            setCurrentTab(divisions[0].code);
        }
    }, [divisions]);

    useEffect(() => {
        setPanelValidity(index, true); // $$$$ FIXME: IMPLEMENT VALIDATION
        setPanelCommitPromise(index, async () => {
            const data = {
                ...getSavableDataFromRequest(pescalice.currentTodoPAP),
                extractRequests
            };

            setBusy(true);
            return pescalice.saveLicenseRequestPAP(data).finally(() => setBusy(false));
        });
    }, [index, pescalice, setBusy, setPanelValidity, setPanelCommitPromise, extractRequests]);

    useEffect(() => {
        if (pescalice.currentTodoPAP) {
            setExtractRequests(pescalice.currentTodoPAP.extractRequests);
        }
    }, [pescalice, pescalice.currentTodoPAP]);

    const addRequest = useCallback(
        (extractId, initialNatureData) => {
            setMustValidate(true);
            setExtractRequests((requests) => [
                ...requests,
                { targetedExtract: extractId, payments: [], ...initialNatureData }
            ]);
        },
        [setMustValidate]
    );

    const removeRequest = useCallback(
        (extractId) => {
            setMustValidate(true);
            setExtractRequests((requests) => requests.filter((req) => req.targetedExtract !== extractId));
        },
        [setMustValidate]
    );

    const updateRequest = useCallback(
        (extractId, data) => {
            setMustValidate(true);
            setExtractRequests((requests) => {
                let updated = requests;
                const i = requests.findIndex((req) => req.targetedExtract === extractId);
                if (i >= 0) {
                    updated = requests.slice();
                    updated[i] = data;
                }
                return updated;
            });
        },
        [setMustValidate]
    );

    return (
        <WizardPanel index={index} noScroll>
            <div className="multiStampOptions">
                <nav style={{ marginBottom: 10 }}>
                    <ul className="left">
                        {divisions.map((division) => (
                            <ExtractPanelTab
                                key={division.code}
                                code={division.code}
                                label={division.name}
                                current={currentTab}
                                select={setCurrentTab}
                            />
                        ))}
                    </ul>
                </nav>
                <div className="styledList">
                    <ExtractPanelTabContent
                        code={currentTab}
                        extractRequests={extractRequests}
                        addRequest={addRequest}
                        updateRequest={updateRequest}
                        removeRequest={removeRequest}
                    />
                </div>
            </div>
        </WizardPanel>
    );
});

const ExtractPanelTab = observer(({ code, label, current, select }) => {
    const { selectedSeason } = useContext(PAPWizardContext);
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    let hasError = false;
    if (pescalice.currentTodoPAP) {
        hasError = pescalice.currentTodoPAP.extractRequests
            .filter((req) => {
                const extract = pescalice.seasonExtractPAPWithId(selectedSeason, req.targetedExtract);
                return !!extract && extract.administrativeDivisionZipCode === code;
            })
            .some((req) => pescalice.extractRequestHasWarningPAP(req) || pescalice.extractRequestHasErrorPAP(req));
    }

    return (
        <li className={classNames({ selected: current === code, warning: hasError })} onClick={() => select(code)}>
            {code} - {label}
            {hasError && <SVGObject className="warning" objectId="warningIcon" />}
        </li>
    );
});

const ExtractPanelTabContent = observer(({ code, extractRequests, addRequest, updateRequest, removeRequest }) => {
    const { selectedSeason } = useContext(PAPWizardContext);
    const { store } = useContext(MobXProviderContext);
    const { pescalice } = store;

    return (
        <div className="scroll">
            {pescalice.seasonShoresPAP(selectedSeason, code).map((shore) => (
                <React.Fragment key={shore}>
                    <h4>{shore}</h4>
                    <ul className="formStyle left">
                        {pescalice.seasonExtractsPAPFor(selectedSeason, code, shore).map((extract) => (
                            <ExtractForm
                                extract={extract}
                                request={extractRequests.find((req) => req.targetedExtract === extract.uniqueId)}
                                addRequest={addRequest}
                                updateRequest={updateRequest}
                                removeRequest={removeRequest}
                                key={extract.uniqueId}
                            />
                        ))}
                    </ul>
                </React.Fragment>
            ))}
        </div>
    );
});

const ExtractForm = observer(({ extract, request, addRequest, updateRequest, removeRequest }) => {
    const { store } = useContext(MobXProviderContext);
    const { pescalice, users } = store;

    const { allowAllExtracts } = useContext(PAPWizardContext);

    useEffect(() => {
        // Move comments from the last globalStatus to the root of the extract
        // so they can be edited.
        if (pescalice.extractRequestHasCommentPAP(request) && !request.publicComment && !request.privateComment) {
            updateRequest(extract.uniqueId, {
                ...request,
                publicComment: request.globalStatuses[request.globalStatuses.length - 1].publicComment || '',
                privateComment: request.globalStatuses[request.globalStatuses.length - 1].privateComment || ''
            });
        }
    }, [pescalice, request, updateRequest, extract.uniqueId]);

    const [forceComments, setForceComments] = useState(false);
    const displayCommentSection = useCallback((evt) => {
        evt.preventDefault();
        setForceComments(true);
    }, []);

    const selectNature = useCallback(
        (evt) => {
            const natureData = {
                isExtractRenewal: evt.target.value === 'isExtractRenewal',
                isFirstInstallation: evt.target.value === 'isFirstInstallation',
                isNewExtractRequest: evt.target.value === 'isNewExtractRequest'
            };

            if (evt.target.value === '') {
                setPaymentMode('');
                removeRequest(extract.uniqueId);
            } else if (request) {
                updateRequest(extract.uniqueId, { ...request, ...natureData });
            } else {
                addRequest(extract.uniqueId, natureData);
            }
        },
        [extract.uniqueId, request, addRequest, updateRequest, removeRequest]
    );

    const selectDecision = useCallback(
        (evt) => {
            const decision = evt.target.value;
            if (decision === '') {
                delete request.isManuallyForced;
                delete request.globalStatus;
                updateRequest(extract.uniqueId, { ...request });
            } else {
                updateRequest(extract.uniqueId, {
                    ...request,
                    isManuallyForced: true,
                    globalStatus: evt.target.value
                });
            }
        },
        [extract.uniqueId, request, updateRequest]
    );

    const addPayment = useCallback(
        (method) => {
            updateRequest(extract.uniqueId, {
                ...request,
                payments:
                    request.payments.length < 10 ? [...request.payments, makeEmptyPayment(method)] : request.payments
            });
        },
        [extract.uniqueId, request, updateRequest]
    );

    const removePayment = useCallback(
        (paymentIndex) => {
            request.payments.splice(paymentIndex, 1);
            updateRequest(extract.uniqueId, { ...request });
        },
        [extract.uniqueId, request, updateRequest]
    );

    const updatePayment = useCallback(
        (index, key, value) => {
            request.payments[index][key] = value;
            updateRequest(extract.uniqueId, { ...request, payments: request.payments.slice() });
        },
        [extract.uniqueId, request, updateRequest]
    );

    const updatePublicComment = useCallback(
        (evt) => {
            updateRequest(extract.uniqueId, {
                ...request,
                publicComment: evt.target.value
            });
        },
        [extract.uniqueId, request, updateRequest]
    );

    const updatePrivateComment = useCallback(
        (evt) => {
            updateRequest(extract.uniqueId, {
                ...request,
                privateComment: evt.target.value
            });
        },
        [extract.uniqueId, request, updateRequest]
    );

    const selectCommentReason = useCallback(
        (commentType, value) => {
            const now = formatLocaleDate(new Date(), 'Pp');
            let comment = request[commentType] || '';
            comment += comment.length > 0 ? '\r\n' : '';
            comment += `• ${now} : ${value === '-' ? '' : value}`;

            updateRequest(extract.uniqueId, { ...request, [commentType]: comment });
        },
        [extract.uniqueId, request, updateRequest]
    );

    const selectPublicCommentReason = useCallback(
        (evt) => {
            selectCommentReason('publicComment', evt.target.value);
            evt.target.value = '';
        },
        [selectCommentReason]
    );

    const selectPrivateCommentReason = useCallback(
        (evt) => {
            selectCommentReason('privateComment', evt.target.value);
            evt.target.value = '';
        },
        [selectCommentReason]
    );

    const nature = !request
        ? ''
        : request.isExtractRenewal
        ? 'isExtractRenewal'
        : request.isFirstInstallation
        ? 'isFirstInstallation'
        : 'isNewExtractRequest';
    const decision = !request ? '' : request.globalStatus || 'kPSLicenseRequestGlobalStatusInitial';
    const payments = request ? request.payments : [];
    const [paymentMode, setPaymentMode] = useState(
        request && request.payments && request.payments.length > 0 ? request.payments[0].method : ''
    );
    const handlePaymentMode = (evt) => {
        setPaymentMode(evt.target.value);
        updateRequest(extract.uniqueId, {
            ...request,
            payments: evt.target.value !== '' ? [makeEmptyPayment(evt.target.value, extract.basePrice)] : []
        });
    };

    const disableNewExtract =
        (pescalice.currentTodoPAP &&
            !pescalice.currentTodoPAP.isNewLicenseRequest &&
            !pescalice.currentTodoPAP.isLicenseRenewal) ||
        isRequestNatureDisabled(extract, 'newExtractRequest');
    const disableRenewal =
        (pescalice.currentTodoPAP && !pescalice.currentTodoPAP.isLicenseRenewal) ||
        isRequestNatureDisabled(extract, 'extractRenewal');
    const disableFirstInstall =
        (pescalice.currentTodoPAP && !pescalice.currentTodoPAP.isFirstInstallation) ||
        isRequestNatureDisabled(extract, 'firstInstallation');
    const disableExtract = !allowAllExtracts && disableNewExtract && disableRenewal && disableFirstInstall;
    const hasWarning = pescalice.extractRequestHasWarningPAP(request);
    const hasError = pescalice.extractRequestHasErrorPAP(request);
    const hasComment = pescalice.extractRequestHasCommentPAP(request);

    let allowDebitPayments = false;
    if (pescalice.currentTodoPAP) {
        const user = users.userWithId(pescalice.currentTodoPAP.requestAuthor.telecapecheId);
        if (user && user.bankInfos) {
            const bankInfos = JSON.parse(user.bankInfos) || [];
            const bankInfo = bankInfos.find((info) => !info.company_id);
            if (bankInfo) {
                allowDebitPayments = true;
            }
        }
    }

    return (
        <li style={disableExtract ? { opacity: 0.3 } : null}>
            <header className={classNames({ warning: hasWarning || hasError })}>
                <h2>
                    <p>{extract.name}</p>
                    {extract.additionalMetadata && extract.additionalMetadata.notes && (
                        <span title={extract.additionalMetadata.notes.join('\n\n')}>
                            <svg className="info">
                                <use xlinkHref="/graphics/symbols.svg#infoIco"></use>
                            </svg>
                        </span>
                    )}
                </h2>
            </header>
            <figure>
                <div>
                    <label>Nature de la demande :</label>
                    <select
                        value={nature}
                        onChange={selectNature}
                        disabled={disableExtract || payments.some((p) => p.paid)}
                    >
                        <option value="">Non demandé</option>
                        <option value="isNewExtractRequest" disabled={!allowAllExtracts && disableNewExtract}>
                            Nouvelle demande
                        </option>
                        <option value="isExtractRenewal" disabled={!allowAllExtracts && disableRenewal}>
                            Renouvellement
                        </option>
                        <option value="isFirstInstallation" disabled={!allowAllExtracts && disableFirstInstall}>
                            1ère installation
                        </option>
                    </select>
                </div>
                <div>
                    <label>Décision :</label>
                    <div className="oneLine">
                        <select
                            className="autoWidth"
                            value={decision}
                            onChange={selectDecision}
                            disabled={nature === '' || disableExtract}
                            style={{ marginRight: 8 }}
                        >
                            {nature === '' && <option value="">-</option>}
                            {nature !== '' && <option value="kPSLicenseRequestGlobalStatusInitial">Automatique</option>}
                            <option value="kPSLicenseRequestGlobalStatusFrozen">En attente</option>
                            <option value="kPSLicenseRequestGlobalStatusSuspended">Incomplète</option>
                            <option value="kPSLicenseRequestGlobalStatusReserved">Mise en réserve</option>
                            <option value="kPSLicenseRequestGlobalStatusAllowed">Attribuée</option>
                            <option value="kPSLicenseRequestGlobalStatusRefused">Refusée</option>
                            <option value="kPSLicenseRequestGlobalStatusCancelled">Annulée</option>
                        </select>
                        {request &&
                            request.globalStatuses &&
                            request.globalStatuses.length > 0 &&
                            `(le ${formatLocaleDate(request.globalStatuses.at(-1).date, 'P')})`}
                    </div>
                </div>

                <div>
                    <label>Mode de paiment :</label>
                    <div>
                        <select
                            value={paymentMode}
                            onChange={handlePaymentMode}
                            disabled={disableExtract || nature === ''}
                        >
                            <option value="">Aucun</option>
                            <option value="kPSPaymentMethodDebit" disabled={!allowDebitPayments}>
                                Prélèvement
                            </option>
                            <option value="kPSPaymentMethodCheck">Chèque(s)</option>
                        </select>
                    </div>
                </div>

                {nature !== '' && !disableExtract ? (
                    payments.map((payment, index) => (
                        <ExtractPayment
                            payment={payment}
                            index={index}
                            isFirst={index === 0}
                            isLast={index === payments.length - 1}
                            hasMore={index < payments.length - 1}
                            addPayment={addPayment}
                            removePayment={removePayment}
                            updatePayment={updatePayment}
                            key={index}
                        />
                    ))
                ) : (
                    <div className="return"></div>
                )}
                {nature !== '' && !hasComment && !forceComments && (
                    <div>
                        <a href="#" onClick={displayCommentSection}>
                            Ajouter un commentaire
                        </a>
                    </div>
                )}
                {(hasComment || forceComments) && (
                    <Fragment>
                        <div>
                            <label>Commentaire public :</label>
                            <select style={{ fontSize: 12, marginBottom: 2 }} onChange={selectPublicCommentReason}>
                                <option value="">Ajouter un motif...</option>
                                <option value="Motif 1">Motif 1</option>
                                <option value="Motif 2">Motif 2</option>
                                <option value="Motif 3">Motif 3</option>
                            </select>
                            <textarea value={request.publicComment} onChange={updatePublicComment}></textarea>
                        </div>
                        <div>
                            <label>Commentaire privé :</label>
                            <select style={{ fontSize: 12, marginBottom: 2 }} onChange={selectPrivateCommentReason}>
                                <option value="">Ajouter un motif...</option>
                                <option value="Motif 1">Motif 1</option>
                                <option value="Motif 2">Motif 2</option>
                                <option value="Motif 3">Motif 3</option>
                            </select>
                            <textarea value={request.privateComment} onChange={updatePrivateComment}></textarea>
                        </div>
                    </Fragment>
                )}
                {(hasWarning || hasError) && (
                    <div style={{ gridColumn: '1 / 3' }}>
                        {pescalice.extractRequestErrorsPAP(request).map((errMsg, i) => (
                            <p key={i} style={{ fontSize: 12, textAlign: 'left', color: 'var(--red)' }}>
                                {errMsg}
                            </p>
                        ))}
                        {pescalice.extractRequestWarningsPAP(request).map((errMsg, i) => (
                            <p key={i} style={{ fontSize: 12, textAlign: 'left', color: 'var(--orange)' }}>
                                {errMsg}
                            </p>
                        ))}
                    </div>
                )}
            </figure>
        </li>
    );
});

const ExtractPayment = ({ payment, index, isFirst, isLast, hasMore, addPayment, removePayment, updatePayment }) => {
    const addNewPayment = useCallback(
        (evt) => {
            evt.preventDefault();
            addPayment(payment.method);
        },
        [addPayment]
    );

    const deletePayment = useCallback(
        (evt) => {
            evt.preventDefault();
            removePayment(index);
        },
        [index, removePayment]
    );

    const updateReference = useCallback(
        (evt) => {
            updatePayment(index, 'reference', evt.target.value);
        },
        [index, updatePayment]
    );

    const updateAmount = useCallback(
        (evt) => {
            const amount = parseInt(evt.target.value, 10) ?? '';
            updatePayment(index, 'amount', isNaN(amount) ? '' : amount);
        },
        [index, updatePayment]
    );

    const isValid =
        payment.method === 'kPSPaymentMethodCheck'
            ? payment.reference !== '' && payment.amount > 0
            : payment.amount > 0;

    return payment.method === 'kPSPaymentMethodCheck' ? (
        <Fragment>
            <div className="return"></div>
            <div>
                <label>Reférence du paiement {index + 1} :</label>
                <input type="text" value={payment.reference} onChange={updateReference} />
            </div>
            <div>
                <label>Montant du chèque {index + 1} :</label>
                <div className="oneLine">
                    <input
                        className="midSize"
                        type="number"
                        placeholder="0"
                        min="0"
                        value={payment.amount}
                        onChange={updateAmount}
                    />
                    €
                    <div className="buttons">
                        {isValid && isLast && <SVGObject objectId="moreCircleIco" onClick={addNewPayment} />}
                        {(!isFirst || hasMore) && <SVGObject objectId="lessCircleIco" onClick={deletePayment} />}
                    </div>
                </div>
            </div>
        </Fragment>
    ) : (
        <Fragment>
            <div className="return"></div>
            <div>
                <label>Montant du prélèvement {index + 1} :</label>
                <div className="oneLine">
                    <input
                        className="midSize"
                        type="number"
                        placeholder="0"
                        min="0"
                        value={payment.amount}
                        onChange={updateAmount}
                    />
                    €
                    <input type="checkbox" checked={payment.paid} disabled={true} id="requestLicensePayment" />
                    <label htmlFor="requestLicensePayment" style={{ marginLeft: '10px' }}>
                        Payé
                    </label>
                    <div className="buttons">
                        {isValid && isLast && <SVGObject objectId="moreCircleIco" onClick={addNewPayment} />}
                        {(!isFirst || hasMore) && <SVGObject objectId="lessCircleIco" onClick={deletePayment} />}
                    </div>
                </div>
            </div>
            <div className="return"></div>
        </Fragment>
    );
};

function isRequestNatureDisabled(extract, nature) {
    return (
        extract.additionalMetadata &&
        extract.additionalMetadata['requestNature.disablings'] &&
        extract.additionalMetadata['requestNature.disablings'][nature]
    );
}

const SummaryPanel = observer(({ index }) => {
    const { setPanelValidity, setPanelCommitPromise } = useContext(PAPWizardContext);
    const { store } = useContext(MobXProviderContext);
    const { pescalice, users } = store;

    useEffect(() => {
        setPanelValidity(index, true);
        setPanelCommitPromise(index, Promise.resolve());
    }, [index, setPanelValidity, setPanelCommitPromise]);

    const request = pescalice.currentTodoPAP;
    if (!request) {
        return null;
    }

    const season = pescalice.seasonPAPWithId(request.season.uniqueId || request.season);
    const user = users.userWithId(request.requestAuthor.telecapecheId);
    const summary = pescalice.extractRequestPAPSummary(season, request);

    return (
        <WizardPanel index={index}>
            <li>
                <label>Campagne :</label>
                <input type="text" value={`${season.referenceYear - 1} - ${season.referenceYear}`} readOnly />
            </li>
            <li>
                <label>Demandeur :</label>
                <input type="text" value={user.fullName(false)} readOnly />
            </li>
            <li>
                <label>{user.socialRegime} :</label>
                <input type="text" value={user.licenseNum} readOnly />
            </li>
            <li>
                <label>Numéro de permis PAP :</label>
                <input type="text" value={user.uniqueIdentifier} readOnly />
            </li>
            <li>
                <label>Nature :</label>
                <input
                    type="text"
                    value={
                        request.isFirstInstallation
                            ? 'Première installation'
                            : request.isNewLicenseRequest
                            ? 'Nouvelle demande'
                            : 'Renouvellement'
                    }
                    readOnly
                />
            </li>
            {(pescalice.extractRequestHasWarningPAP(request) || pescalice.extractRequestHasErrorPAP(request)) && (
                <li style={{ marginTop: 18 }}>
                    <label>Alertes/Erreurs :</label>
                    <div style={{ flexDirection: 'column', marginLeft: 8 }}>
                        {pescalice.extractRequestErrorsPAP(request).map((errMsg, i) => (
                            <p key={i} style={{ minHeight: 0, textAlign: 'left', color: 'var(--red)' }}>
                                {errMsg}
                            </p>
                        ))}
                        {pescalice.extractRequestWarningsPAP(request).map((errMsg, i) => (
                            <p key={i} style={{ minHeight: 0, textAlign: 'left', color: 'var(--orange)' }}>
                                {errMsg}
                            </p>
                        ))}
                    </div>
                </li>
            )}
            <li style={{ marginTop: 18 }}>
                <label>Résumé :</label>
                <div style={{ flexDirection: 'column', marginLeft: 8 }}>
                    <ExtractSummary code={22} amount={summary[22]} />
                    <ExtractSummary code={29} amount={summary[29]} />
                    <ExtractSummary code={35} amount={summary[35]} />
                    <ExtractSummary code={56} amount={summary[56]} />
                </div>
            </li>
        </WizardPanel>
    );
});

const ExtractSummary = ({ code, amount }) => {
    return (
        <p style={{ minHeight: 0, textAlign: 'left', opacity: amount === 0 ? 0.2 : 1 }}>
            {amount === 0
                ? `Aucun timbre demandé dans le ${code}`
                : amount === 1
                ? `1 timbre demandé dans le ${code}`
                : `${amount} timbres demandés dans le ${code}`}
        </p>
    );
};
