import React from 'react';

export class LicenseHistorySubSection extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="scroll">
                    <table>
                        <tbody>
                            <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Ancienne valeur</th>
                                <th>Nouvelle valeur</th>
                                <th>Par</th>
                            </tr>
                            <tr>
                                <td>
                                    <span>Date :</span>12/04/2017
                                </td>
                                <td>
                                    <span>Type :</span>Prix
                                </td>
                                <td>
                                    <span>Ancienne valeur :</span>103 €
                                </td>
                                <td>
                                    <span>Nouvelle valeur :</span>105 €
                                </td>
                                <td>
                                    <span>Par :</span>Guillaume Le Priellec
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Date :</span>12/04/2017
                                </td>
                                <td>
                                    <span>Type :</span>Taille
                                </td>
                                <td>
                                    <span>Ancienne valeur :</span>≥ 10m
                                </td>
                                <td>
                                    <span>Nouvelle valeur :</span>&gt; 10m
                                </td>
                                <td>
                                    <span>Par :</span>Joséphine Backer
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
