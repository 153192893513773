import React from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withRouter
@inject('store')
@observer
export class LicensesSelector extends React.Component {
    _switchToSingle = () => {
        this.props.history.push('/pescalice/license-pep');
    };

    _setDivisionFilter = (evt) => {
        this.props.store.pescalice.setProxyDivisionFilter(evt.target.value);
    };

    _setNameFilter = (evt) => {
        this.props.store.pescalice.setProxyNameFilter(evt.target.value);
    };

    _resetFilters = () => {
        this.props.store.pescalice.resetProxyFilters();
    };

    render() {
        const { pescalice } = this.props.store;
        return (
            <div className="list subList withToggle large show">
                <div className="toggle">
                    <span className="selected" title="Liste des licences">
                        <SVGObject objectId="allLicenses" />
                    </span>
                    <span className="listSolo" title="Fiches Licences" onClick={this._switchToSingle}>
                        <SVGObject objectId="singleLicense" />
                    </span>
                </div>
                <dl className="show">
                    <div className="burger">
                        <span />
                    </div>
                    <h6></h6>
                    <dt>
                        Filtres <SVGObject objectId="resetIco" onClick={this._resetFilters} />
                    </dt>
                    <dd className="noSelection">
                        <select value={pescalice.proxyDivisionFilter} onChange={this._setDivisionFilter}>
                            <option value="">Tous</option>
                            <option value="bretagne">Bretagne</option>
                            <option value="cotes-d-armor">Côtes d&apos;Armor</option>
                            <option value="finistere">Finistère</option>
                            <option value="ille-et-vilaine">Ille-et-Vilaine</option>
                            <option value="morbihan">Morbihan</option>
                        </select>
                    </dd>
                    <dd className="noSelection">
                        <input
                            type="text"
                            placeholder="Licence"
                            value={pescalice.proxyNameFilter}
                            onChange={this._setNameFilter}
                        />
                    </dd>
                </dl>
                <ul className="toolbar" />
            </div>
        );
    }
}
