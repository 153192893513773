import React from 'react';

export class UserMessagesSubSection extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="scroll">
                    <table>
                        <tbody>
                            <tr>
                                <th>Envoyé à</th>
                                <th>Message envoyé</th>
                                <th>Statut</th>
                            </tr>
                            <tr>
                                <td>
                                    <span>Envoyé à:</span>18:42:45
                                </td>
                                <td>
                                    <span>Contenu du message:</span>Michel, une anomalie dans votre dernier envoi a été
                                    détecté.
                                </td>
                                <td>
                                    <span>Statut:</span>Reçu
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
